.container.slider-simulations{
    background-color: $colorWhite;
    padding-left: 5rem;
    padding-right: 5rem;
    position: relative;
    padding-top: 8.3rem;
    padding-bottom: 5.4rem;
    margin-bottom: 9.9rem;
    max-width: unset;

    &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: calc(50% + (((50% - 5rem) - (((100% - 1280px) / 2) - 5rem)) / 2));
        height: 100%;
        background-color: $colorRoseLight;
    }

    .text.generic{
        z-index: 1;
        position: relative;
        >p:not(:last-of-type){
            padding-bottom: 1.5rem;
        }

    }
    .wrap-btn{
        flex-grow: 1;
        justify-content: flex-end;
    }
    .wrap-btnDefault{
        margin-top: 5.3rem;
        align-self: flex-start;
    }
    .box-content .title{
        font-size: 4.5rem;
    }
    .section-simulations-wrap{     
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 0;
    }

    .slider-home-simulations-list{
        width: 100%;
            .wrap-slider-simulation-item{              
                .wrap-text{
                    padding: 0 6.2rem;
                    padding-right: 0;

                    .section-title, .section-subtitle{
                        text-align: left;
                        text-transform: capitalize;
                        margin: 0;
                    }
                    .section-title{
                        font-size: 6rem;
                        margin: 0;

                    }
                    .line-separation{
                        justify-content: flex-start;
                    }

                    .section-subtitle{
                        text-transform: uppercase;                        
                    }                       
                        
                }
                .slider-simulation-item, .wrap-text{
                    width: 50%;
                }                

            }
        .slick-dotted.slick-slider{
            margin-bottom: 0;
        }
        .slick-next{
            opacity: 1;
            right: 0;
            width: 4rem;
            height: 4rem;
            &:before{
                content: '';
                background-image: url('/images/right.svg');
                width: 4rem;
                height: 4rem;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: 0;
                left: 0;
            }

        }
        .slick-prev{
            opacity: 1;
            left: 0;
            width: 4rem;
            height: 4rem;
            &:before{
                content: '';
                background-image: url('/images/left.svg');
                width: 4rem;
                height: 4rem;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: 0;
                left: 0;
            }

        }
    }
  
    .slider-type-simulation.slider{
        .slick-list{
            padding: 0 !important;
            .slick-slide{
                .item{
                    margin-right: 0;
                    position: relative;
                    padding-top: 60%;
                    .img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;                        
                    }
                }
            }
        }
    }
      .slider-type-simulation.slider .slick-dots {
        bottom: 0;
        height: 5rem;
      }
      
      .slider-type-simulation.slider .slick-dots li {
        width: 5rem;
        height: 5rem;
        opacity: .8;
        button{
            border: 2px solid #fff;
        };
       
      }
      
      .slider-type-simulation.slider .slick-dots li.slick-active {
        opacity: 1;
        button{
            border: 3px solid #fff;
        }
        
      }
      
      .slider-type-simulation.slider .slick-dots li.slick-active button,
      .slider-type-simulation.slider .slick-dots li:hover button {
        opacity: 1;
        transform: scale(1,1);
        
      }
      
      .slider-type-simulation.slider .slick-dots li button {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
        transition: all .5s ease;
        transform: scale(.75,.75);
      }
      
      .slider-type-simulation.slider .slick-dots li button img {
        display: block;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      
      .slider-type-simulation.slider .slick-dots li button:before {
        display: block;
        position: relative;
        content: '';
        width: 100%;
        padding-top: 100%;
        height: auto;
      }
}


@media (max-width: $md-width) {   
    .container.slider-simulations{
        padding-top: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 5.1rem;
        padding-bottom: 6.2rem;
        margin-bottom: 6.2rem;

        .text.generic{
            text-align: center;
        }
        &::before{
            width: 100%;
        }
        .box-content .title {
            font-size: 2.9rem;
            text-align: center;
        }
        .small-line:not(.sinBorder):before {
            left: 50%;
            transform: translateX(-50%);
        }
        .wrap-btnDefault{
            margin: 0 auto;
            margin-top: 4.2rem;

            .btnDefault{
                padding: 0.9rem;
                font-size: 1.5rem;
                letter-spacing: .2rem;
            }
        }
        .section-simulations-wrap{
            padding: 0;

            .slick-arrow{
                transform: translateY(-1000%);
            }
            .wrap-text{
                display: flex;
                flex-direction: column;
                padding-bottom: 4rem;
            }  
        }

        .slider-home-simulations-list{     
            width: 100%;     
            max-width: 100%;     

            .wrap-slider-simulation-item{
                flex-direction: column;
                .slider-simulation-item,
                .wrap-text {
                    width: 100%;
                    padding: 0;
                    margin-top: 3.3rem;
                }
                .slider-simulation-item{
                    margin-top: 0;
                }
            }
        }
    
        .slider-type-simulation.slider{
            .slick-list{
                padding: 0 !important;
                .slick-slide{
                    .item{
                        margin-right: 0;
                        position: relative;
                        padding-top: 60%;
                        .img{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        .slider-type-simulation.slider .slick-dots {
            bottom: 0;
            height: 3.5rem;
        }
        
        .slider-type-simulation.slider .slick-dots li {
            width: 3.5rem;
            height: 3.5rem;
            opacity: .8;
            button{
                border: 2px solid #fff;
            };        
        }
        
        .slider-type-simulation.slider .slick-dots li.slick-active {
            opacity: 1;
            button{
                border: 3px solid #fff;
            }            
        }
        
        .slider-type-simulation.slider .slick-dots li.slick-active button,
        .slider-type-simulation.slider .slick-dots li:hover button {
            opacity: 1;
            transform: scale(1,1);            
        }
        
        .slider-type-simulation.slider .slick-dots li button {
            display: block;
            overflow: hidden;
            position: relative;
            width: 100%;
            height: auto;
            padding: 0;
            transition: all .5s ease;
            transform: scale(.75,.75);
        }
        
        .slider-type-simulation.slider .slick-dots li button img {
            display: block;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        
        .slider-type-simulation.slider .slick-dots li button:before {
            display: block;
            position: relative;
            content: '';
            width: 100%;
            padding-top: 100%;
            height: auto;
        }
    }
}