.container.home-logos{
    background-color: #fff;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    flex-grow: unset;
    max-width: unset;
    height: auto;

    .wrap-logos-list{
        width: 100%;
        height: 5.8rem;
        border-top: 1px solid $colorCorporative;
        border-bottom: 1px solid $colorCorporative;
        position: relative;     
        overflow: hidden;
        display: flex;
        align-items: center;
        background-color: $colorRoseLight;

        .logos-list{           
            animation: banner 70s infinite linear;
            align-items: center;
         
            .logo{
                height: 2.3rem;
                margin-left: 4.4rem;
                
                .img{
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

@keyframes banner {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: $md-width) {
  .container.home-logos{
      padding-top: 0;
  }
}