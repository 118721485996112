* {
	box-sizing: border-box;
	outline: 0;
    background: transparent none repeat scroll 0 0;
    border: 0 none;
    font-size: 100%;
    margin: 0;
    outline: 0 none;
    padding: 0;
    text-decoration: none;
    vertical-align: baseline;
    scroll-behavior: smooth !important;
}

html {
    margin: 0;
	font-size: 10px;

    @media (min-width: 992px) and (max-width: 1260px){
        font-size: 10px;
    }
    @media (max-width: 991px){
        font-size: 10px;
    }
 }

body {
	color: $grayDark2;
    @include font_mont(Regular, 1.2);
	line-height: normal;
	margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .container{
        flex-grow: 1;
        position: relative;   
        height: 100%;
        max-width: unset;
    }
    &.stopSrcoll{
        overflow-y: hidden !important;
    }    
}

body a{
    text-decoration: none;
    color: $colorBlack;

    &:hover{
        text-decoration: none;
        color: inherit;
    }
}
ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
p{
    margin: 0;
}
button {
    outline: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
}
b, strong {
    font-weight: normal;
    font-family: 'Montserrat-Bold';
}
