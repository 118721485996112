.new-store{
    .wrap-content{
        .article-finance{
            .box-content{
                .title{
                    //margin-bottom: 2.5rem;
                }
            }
            .subtitle.text-subtitle{
                max-width: unset;
            }
            .content{
                max-width: $max-width-detail-store ;
                margin: 0 auto;
                width: 100%;
            }
            .part-content{
                width: 44%;
                padding-left: 4rem;

                .wrap-one-img{
                    .wrap-img{
                        margin-bottom: 2.5rem;
                    }
                }
            }
            .part-slider {
                width: 56%;

                .aux-slider{
                    .wrap-slider{
                        width: 100%;
    
                        .aux-box{
                            padding-bottom: 3.7rem;
                        }
                        .slider-slick-detail-store.slick-slider{
                            .slick-arrow{
                                display: none !important;
                            }
                            .slick-list{
                                padding: 0 !important;
                                cursor: grab;                                
                                &.dragging, &.draggable:active:hover {
                                    cursor: grabbing;
                                }
                                .slick-slide{                               
                                    .item{
                                        position: relative;
                                        padding-top: 120%;  
                                        width: 100%;                                  
                                        .img{
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.desk{
                        flex-grow: 1;
                    }
                }




/**
                .aux-slider.wrap-slider-content{
                    width: 100%;
                    max-width: 100%;
                    .control{
                        display: none;
                    }

                    .wrap-img{
                        
                        padding-bottom: 120%;
                        position: relative;
                        overflow: hidden;
                        
                    }
                    .pasadorPost .swiper-wrapper .swiper-slide .img-responsive{
                        
                        position: absolute;
                        top: 0;
                        left: 0;
                        max-height: unset;
                        width: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                        max-width: unset;
                        height: 100%;
                        
                    }
                    .wrap-slider.slider-detail-store-finance{
                        width: 100%;
                        max-height: unset;
                        .swiper-wrapper {
                            .swiper-slide{
                                width: 100%;
                            }
                        }

                    }
                }
                */
            }
        }
    }
}

@media (max-width: $md-width) {
    .new-store{
    
        .wrap-content{
            .article-finance{
                margin-bottom: 7.7rem;
                
                .content{
                    flex-direction: column;
                }
                .part-slider{
                    order: 2;
                    width: 100%;

                    .aux-slider{
                        width: 100%;
                        transform: unset;
                    }
                }
 
                .part-content{
                    order: 1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0;

                    .wrap-one-img{
                        order: 4;
                        margin-bottom: 1rem;
                        margin-top: 3rem;
                    }
                }

            }
        }
    }
}
