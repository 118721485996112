//***************************************************************************************************//
// LOGIN 

#login-shops{
	position: fixed;
	top: 50%;
    left: 50%;
	bottom: 0;
	right: 0;
	margin: auto;
	background-color: $colorWhite;
	background-color: rgba($colorWhite,0.95);
	width: 0;
	height: 0;
	padding: 1.5rem;
	z-index: 10;
	opacity: 0;
	border: 1px solid #1197a5;
	-webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
	transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
	font-weight: normal;
	overflow: hidden;
    transform: translateX(-50%) translateY(-50%);

	&.google_loggedin {
		height: 23.5rem !important;
	}

	&.in{		
		top: 0;	
        height: 24rem;
		width: 34.8rem;
		-webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
		transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
		opacity: 1;
        display: block !important;
	}

	.login-title{
		font-size: 1.4rem;
		font-family: 'Montserrat-Regular';
		text-transform: uppercase;
		margin-bottom: 2.3rem;
	}

	.form-group	{
		margin-bottom: 2rem;
	}
    
    .form-control {
        border-radius: 0;
		border-color: #575252;
		background-color: transparent;
        display: block;
        width: 100%;
        height: 3.5rem;
        padding: 0.375rem 0.75rem;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1;
        color: #495057;
        background-color: #ffffff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
       
    }

	.btns{		
		padding: 0 1.5rem 0;
        .wrap-btns{
            width: 100%;
            justify-content: space-between;
        }
        .wrap-btnDefault{
            width: 45%;
            min-width: 45%;
            .btnDefault{
                padding: 1rem;
                font-size: 1rem;
            }
        }
        .wrap-employeeLogin{           
            padding: 0;
            margin-top: 4.5rem;
            width: 100%;

            .employee_login{
                padding-right: 1rem;
                font-size: 1.2rem;
            }       
        }
    }
    .label{
        background-color: #fff;
        font-family: 'Montserrat-Regular';
        font-size: 1.5rem;
        font-weight: normal;
        color: #575252;
        margin: 0;
        top: calc(50% - 0.1rem);
        transform: translateY(-50%);
    }
    input{
        box-shadow: none;   
    }
    input:focus{
        box-shadow: none;
        border: 1px solid #000;
    }
    input:focus  +.label, input:valid  +.label{
        top: 0;
        color: #000;
        transform: translateY(-50%) scale(0.8);
    }
}
//END LOGIN 