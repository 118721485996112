
.conteiner-content.simulations{
    padding-top: 16.4rem;
    padding-left: 5rem;
    padding-right: 5rem;
    position: relative;
    .aux{
        height: 60rem;
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc((100% - 0.9rem) - 41%);
        background-color: $colorCorporative;
    }

    .limited-wrap{
        width: 100%;
        max-width: 1280px !important;
        margin: 0 auto;

        .wrap-content{
            justify-content: space-between;
            margin-top: 5rem;
            padding-bottom: 10rem;

            .part-left{
                width: 33%;
                min-width: 32rem;

                .menu-list{
                    border-bottom: 1px solid $colorCorporative;
                    border-top: 1px solid $colorCorporative;
                    padding: 3rem 0.5rem;
                    margin: 5rem 0;

                    .list-item{
                        line-height: normal;
                        padding: 1rem 3rem;
                        padding-right: 0;
                        font-size: 1.9rem;

                        .list-text{
                            position: relative;
                            font-family: 'Montserrat-SemiBold';
                            font-size: 1.9rem;
                            letter-spacing: 0;
                            padding-top: 0;
                            padding-left: 0;
                            color: $gray;
                        }
                        &.isActive{

                            .list-text{
                                position: relative;
                                color: $colorCorporative;

                                &::before{
                                    content: "";
                                    width: 1rem;
                                    height: 1rem;
                                    border-radius: 50%;
                                    background-color: $colorCorporative;
                                    top: 50%;
                                    transform: translateY(-50%) translateX(-25px);
                                    position: absolute;
                                    transition: 0.3s all ease;
                                }
                            }

                        }
                    }
                }
            }

            .part-right{
                width: 59%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                min-width: auto;
                .part-down{
                    display: flex;
                    flex-direction: column;
                    .two{
                        position: absolute;
                        bottom: 5rem;
                        &:hover{
                            .arrow-link{
                                &::before{
                                    width: 12.5rem;
                                    height: 7rem;
                                    transform: translateY(50%);
                                    transition: 0.3s all ease;
                                }
                            }
                        }
                    }
                    .arrow-link{
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            background-image: url('/images/bttn-arrow-big.svg');
                            width: 10.5rem;
                            height: 5rem;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            bottom: -5rem;
                            transform: translateY(50%);
                            transition: 0.3s all ease;

                        }
                    }
                }
                .simulator-main.fama_2021{
                    padding: 0;
                    margin: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    position: relative;
                    height: 50rem;
                    display: flex;
                    background-color: $colorCorporative;


                    .simulator-entry{
                        padding-left: 10rem;
                        display: none;
                        align-self: center;
                        position: relative;
                        opacity: 0;
                        transition: 0.3s 0.2s opacity ease;

                        &.isActive{
                            opacity: 1;
                            transition: 0.3s 0.2s opacity ease;
                            display: block;
                        }
                        .simulator{
                            height: auto;
                            min-height: unset;
                            position: unset;
                            margin-top: 10rem;
                            margin-bottom: 2rem;

                            .simulator-item-title{
                                color: #fff;
                                font-family: 'Montserrat-SemiBold';
                                font-size: 2.5rem;
                                line-height: 3.3rem;
                                padding: 0;
                                padding-bottom: 2rem;
                                margin-bottom: 0.5rem;
                            }
                            .simulator-item-desc{
                                font-family: 'Montserrat-Regular';
                                font-size: 1.5rem;
                                line-height: 2.1rem;
                                color: #fff;
                                padding: 0;
                                min-height: unset;
                                p{
                                    padding-bottom: 2rem;
                                }
                            }
                            .wrap-img{
                                margin-top: -70rem;
                                position: relative;
                                top: 0;
                                left: -0.5rem;
                                width: calc(100%);
                                margin-bottom: 3.7rem;
                                max-height: 35rem;
                                aspect-ratio: 16/9;

                                img {
                                    box-shadow: 1px 1px 8px 1px #c70087;
                                    margin-left: 10px;
                                    width: calc(100% - 20px);
                                }
                            }

                        }


                    }

                }
            }
        }

        .section-title{
            margin-bottom: 4rem;

            .title{
                font-size: 3.5rem;
                text-transform: none;
                font-family: "Montserrat-SemiBold";
                font-weight: normal;
                line-height: 4.3rem;
                text-align: left;
            }
            .title-underline{
                width: 3.5rem;
                border-bottom: 4px solid;
                background: $colorCorporative;
                margin-top: 0.5rem;
                margin-bottom: 5.4rem;
            }
        }
        .text.generic{
            font-size: 1.5rem !important;
            line-height: 2.5rem !important;
        }
        .description{
            height: 30rem;
            max-height: 30rem;
            overflow: hidden;
        }

    }

}

@media (max-width: $md-width) {
    .conteiner-content.simulations{
        padding-top: 9.8rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        position: relative;
        background-color: #fff;

        .aux{
            display: none;
        }

        .limited-wrap{

            .wrap-content{
                flex-direction: column;
                justify-content: space-between;
                margin-top: 0;
                padding-bottom: 0;

                .part-left{
                    width: 100%;
                    min-width: unset;
                    padding-bottom: 5.5rem;

                    .menu-list{
                        display: none;

                        .list-item{
                            line-height: normal;
                            padding: 1rem 3rem;
                            padding-right: 0;
                            font-size: 1.9rem;

                            .list-text{
                                position: relative;
                                font-family: 'Montserrat-SemiBold';
                                font-size: 1.9rem;
                                letter-spacing: 0;
                                padding-top: 0;
                                padding-left: 0;
                                color: $gray;
                            }
                            &.isActive{

                                .list-text{
                                    position: relative;
                                    color: $colorCorporative;

                                    &::before{
                                        content: "";
                                        width: 1rem;
                                        height: 1rem;
                                        border-radius: 50%;
                                        background-color: $colorCorporative;
                                        top: 50%;
                                        transform: translateY(-50%) translateX(-25px);
                                        position: absolute;
                                        transition: 0.3s all ease;
                                    }
                                }

                            }
                        }
                    }
                }

                .part-right{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    width: calc(100% + 3rem);
                    transform: translateX(-1.5rem);

                    .part-down{
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 5rem;
                        .one{
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 5rem;
                        }
                        .two{
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            padding-bottom: 0;
                            width: fit-content;

                            &:hover{
                                .arrow-link{
                                    &::before{
                                        width: 10.5rem;
                                        height: 5rem;
                                    }
                                }
                            }
                        }
                       
                      
                        .arrow-link{
                            position: relative;
                            &::before{
                                content: '';
                                position: absolute;
                                background-image: url('/images/bttn-arrow-big.svg');
                                width: 10.5rem;
                                height: 5rem;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                bottom: unset;
                                transform: translateY(50%);
                                transition: 0.3s all ease;

                            }
                        }
                    }

                    .simulator-main.fama_2021{
                        padding: 0;
                        margin: 0;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        position: relative;
                        height: auto;
                        display: flex;
                        background-color: transparent;


                        .simulator-entry{
                            padding-left: 0;
                            display: block;
                            align-self: center;
                            position: relative;
                            opacity: 1;
                            transition: 0.3s 0.2s opacity ease;
                            background: linear-gradient(transparent 17%, $colorCorporative 17%);
                            margin-bottom: 5rem;
                            padding: 0 1.5rem;
                            overflow: hidden;
                          
                           

                            &:last-of-type{
                                margin-bottom: 0;
                            }

                            &.isActive{
                                opacity: 1;
                                transition: 0.3s 0.2s opacity ease;
                                display: block;
                            }
                            .simulator{
                                height: auto;
                                min-height: unset;
                                position: unset;
                                margin-top: 0;

                                .simulator-item-title{
                                    color: #fff;
                                    font-family: 'Montserrat-SemiBold';
                                    font-size: 2.5rem;
                                    line-height: 3.3rem;
                                    padding: 0;
                                    padding-bottom: 2rem;
                                }
                                .simulator-item-desc{
                                    font-family: 'Montserrat-Regular';
                                    font-size: 1.5rem;
                                    line-height: 2.1rem;
                                    color: #fff;
                                    padding: 0;
                                    min-height: unset;
                                    p{
                                        padding-bottom: 2rem;
                                    }
                                }
                                .wrap-img{
                                    margin-top: 0;
                                    position: relative;
                                    top: 0;
                                    left: 0;
                                    width: calc(100% - 3rem);
                                    margin: 0 auto;
                                    margin-bottom: 3rem;
                                    padding-top: 1rem;
                                }
                            }

                        }

                    }
                }
            }

            .section-title{

                .title{
                    font-size: 2.5rem;
                    line-height: 3.3rem;
                }
                .title-underline{
                    width: 3.5rem;
                    border-bottom: 2px solid;
                    margin-bottom: 5.4rem;
                }
            }

            .description{
                height: auto;
                max-height: unset;
                overflow: hidden;
                font-size: 1.6rem;
                line-height: 2.2rem;
            }

        }

    }

}
