.page_404{
    .main{
        height: 100vh;

        .wrap-content{
            margin: 0 auto;
            margin-top: unset;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
        }
        
    }

}
.page_404 .main .wrap-store form .form-control{
    height: inherit;
}
.page_404 .main .wrap-banner .wrap-content{
    margin-top: 0;
    max-width: unset;
    padding: 1.2rem;
    height: auto;
    transform: none;
}
.page_404 .main .wrap-store .wrap-btnDefault{
    max-width: unset;
    min-width: unset;
}
.page_404 .main .wrap-store .name{
    font-size: inherit;
    color: inherit;
    font-weight: normal;
    font-family: inherit;
}

.page_404 {
    padding: 0;
    max-width: unset;
}

.page_404 .wrap-content{
    max-width: 90rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.page_404 h2{
    @include font_mont(SemiBold, 3.3);
    text-align: center;
    color: $colorBlack;
    margin-top: 5rem;
    margin-bottom: 3.4rem;
}
.page_404 .wrap-num{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 auto;

    .item2{
        img{
            width: auto;
            height: calc(100% + 3rem);
            object-position: center;
            transform: translateX(-1.5rem) translateY(-1rem);
        }
    }
}

.page_404 .wrap-num img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.page_404 .wrap-btnDefault {
    max-width: 24.5rem;
    min-width: unset;
}
.wrap-btnDefault .btnDefault {
    @include font_mont(Bold, 1.5);
    padding: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: .3rem;
}

@media (max-width: $md-width) {
    .page_404 .main .wrap-content .wrap-banner .wrap-content{
        padding: 1.2rem;
        height: auto;
    }
    .page_404 .main .wrap-content{
        justify-content: space-between;
        width: 100%;
        height: 100vh;
        padding-top: 23vh;
        margin-top: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        position: relative;
        padding-bottom: 4rem;
        top: unset;
        left: 0;
        bottom: unset;
        transform: none;
    }
    .page_404 h2{
        font-size: 2.1rem;
        margin-top: 0;
        margin-bottom: 0;
        margin: 0 auto;
    }
    .page_404 .wrap-num{
        align-items: center;
        max-width: 60rem;
        top: 30%;
        position: relative;
        transform: translateY(-50%);
    }

    .page_404 .wrap-num .item{
        width: 24% !important;
        img{
            height: fit-content;
        }
    }
    .page_404 .wrap-num .item.item2{
        width: 40% !important;
        margin-right: 4.5% !important;
        img{
            width: 100%;
            transform: scale(1.2);
        }
    }
 

    .page_404 .wrap-num img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .page_404 .wrap-btnDefault {
        max-width: unset;
    }
    .wrap-btnDefault .btnDefault {
        font-size: 1.5rem;
    }
}