.section-empresa.garantee.section-limited{
    padding: 0 $paddingLateral;
}
.section-empresa.sofas.sofas-rscVerticalBlocks {
    padding-top: 0;


    .wrap-content {
        padding-top: 8.5rem;
        padding-bottom: 8.5rem;
        background-color: $colorRoseLight;
    }

    .wrap-title {
        width: 100%;
        //padding-bottom: 5.9rem;

        .title {
            text-align: center;
            font-size: 2.5rem;
            padding-right: 0;
            line-height: 3rem;
            letter-spacing: 2.21px;
            font-family: 'Montserrat-ExtraBold';
        }
    }

    .limited-wrap {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .wrap-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 $paddingLateral;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 2.9rem;
            height: 2.9rem;
            border-radius: 50%;
            background-color: $colorCorporative;
            transform: translateX(-50%);
            z-index: 1;
        }

        &:after {
            bottom: 0;
            top: unset;
            left: 50%;
        }

        .item {
            width: 100%;
            display: flex;
            position: relative;

            .item-wrapper {
                position: absolute;
                width: 50%;
                height: 100%;
                max-width: 40rem;
                pointer-events: none;

                .wrap-image {
                    height: 20rem;
                    position: absolute;

                    &.topcenter {
                        left: 50%;
                        transform: translateX(-50%);
                        top: -20rem;
                    }

                    &.bottomleft {
                        left: 0;
                        bottom: -20rem;
                    }

                    &.bottomright {
                        right: 0;
                        bottom: -20rem;
                    }

                    &.bottomcenter {
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -20rem;
                    }
                }
            }

            .item-content {
                padding-left: 2rem;
                padding-top: 4rem;
                width: 50%;
                position: relative;

                .num {
                    font-size: 6.1rem;
                    color: rgba($colorCorporative, 0.2);
                    font-family: 'Montserrat-SemiBold';
                    letter-spacing: 0;
                    top: 0;
                    position: absolute;
                    left: 0;
                }

                .wrap-title {
                    padding-bottom: 0;
                    position: relative;

                    .title {
                        text-align: left;
                        font-size: 2.5rem;
                        text-transform: uppercase;
                        line-height: 3rem;
                        letter-spacing: 2.21px;
                        font-family: 'Montserrat-Medium';
                        color: $colorCorporative;
                        padding-bottom: 0;
                        width: fit-content;

                    }
                }

                .wrap-text {
                    position: relative;

                    u {
                        color: $colorCorporative;
                    }
                }

                .wrap-btn {
                    float: left;
                    margin-top: 3rem;
                }

                .text {
                    padding-bottom: 2.6rem;
                    padding-top: 2.6rem;
                    max-width: 40rem;
                }

                .link {
                    a {
                        span {
                            text-decoration: underline;

                            &.corporative {
                                text-decoration: none;
                                color: $colorCorporative;

                                &:after {
                                    content: "";
                                    width: 1.2rem;
                                    height: 0.8rem;
                                    display: inline-block;
                                    margin-left: 1rem;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                    background-image: url(/images/arrow-pink.svg);
                                    -webkit-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
                                    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
                                }
                            }
                        }
                    }
                }

            }

            &.left {
                justify-content: flex-start;

                .item-content {
                    border-right: 1px solid $colorCorporative;
                    padding-right: 9.6rem;
                    overflow: hidden;

                    .num {
                        right: 7.6rem;
                        z-index: 1;
                    }

                    .aux-line {
                        width: auto;
                        height: 100%;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            width: 100%;
                            background-color: $colorCorporative;
                            height: 1px;
                        }
                    }

                    .wrap-title {
                        .title {
                            padding-right: 2.5rem;
                            position: relative;
                            z-index: 1;
                        }
                    }

                }

                &:first-of-type {
                    .item-content {
                        padding-top: 9rem;

                        .num {
                            top: 5rem;
                        }
                    }
                }

            }

            &.right {
                justify-content: flex-end;

                .item-wrapper {
                    left: calc(50% + 9.6rem);
                }

                .item-content {
                    border-left: 1px solid $colorCorporative;
                    transform: translateX(-1px);
                    padding-left: 9.6rem;

                    .num {
                        left: 7.6rem;
                    }

                    .wrap-title {
                        .title {
                            background-color: unset;

                            &::after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                width: 7rem;
                                background-color: $colorCorporative;
                                height: 1px;
                                right: unset;
                                left: -9.6rem;
                            }
                        }
                    }
                }

                &:first-of-type {
                    .item-content {
                        padding-top: 9rem;

                        .num {
                            top: 5rem;
                        }
                    }
                }
            }
        }
    }
}


//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .section-empresa.garantee.section-limited{
        padding: 0;
    }
    .section-empresa.sofas.sofas-rscVerticalBlocks {
        &.section-rsc-vertical-blocks {
            padding-top: 0;

            .wrap-content {
                padding: 0 $paddingLateralXs 18rem;

                .generic.text, .generic.text p, .generic {
                    color: inherit;
                    font-size: 1.5rem;
                    line-height: 2.5rem;
                }

                .wrap-title {
                    width: 100%;
                    padding-bottom: 0;

                    .title {
                        font-size: 1.7rem;
                        line-height: 3rem;
                        letter-spacing: 1.5px;
                        text-align: left;
                        width: 90%;
                        padding-bottom: 0;
                    }
                }

                .wrap-list {
                    padding: 0;
                    &:before, &:after {
                        content: none;
                    }

                    .item {
                        width: 100%;
                        display: flex;
                        margin-bottom: 5rem;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        .item-wrapper {
                            .wrap-image {
                                height: 16rem;

                                &.topcenter {
                                    top: -8rem;
                                }

                                &.bottomright {
                                    right: 0;
                                    bottom: -5rem;
                                    height: 13rem;
                                }
                            }
                        }

                        .item-content {
                            padding-left: 2rem;
                            padding-top: 4rem;
                            position: relative;
                            width: 100%;
                            padding-right: 2.5rem;

                            .num {
                                font-size: 6.1rem;
                                color: rgba($colorCorporative, 0.22);
                                font-family: 'Montserrat-SemiBold';
                                letter-spacing: 0;
                                top: 0;
                                position: absolute;
                                left: 0;
                            }

                            .wrap-title {
                                padding-bottom: 0;

                                .title {
                                    text-align: left;
                                    font-size: 2.5rem;
                                    text-transform: uppercase;
                                    line-height: 3rem;
                                    letter-spacing: 2.21px;
                                    font-family: 'Montserrat-Medium';
                                    color: $colorCorporative;
                                    padding-bottom: 0;
                                    width: 100%;
                                    background-color: transparent;

                                }
                            }

                            .text {
                                padding-bottom: 2.6rem;
                                padding-top: 2.6rem;
                                font-size: 1.5rem !important;
                            }

                            .wrap-btn {
                                display: block;
                                width: 100%;
                            }

                            .wrap-text {
                                u {
                                    font-size: 1.5rem;
                                }
                            }

                            .wrap-link {
                                width: 90%;

                                .link {
                                    &::before {
                                        height: 1.9rem;
                                        bottom: 0;
                                    }
                                }
                            }
                        }

                        &.left {
                            justify-content: flex-start;

                            .item-wrapper {
                                width: 100%;
                            }

                            .item-content {
                                border-right: none;
                                padding-right: 0;
                                overflow: hidden;
                                padding-right: 2.5rem;

                                .num {
                                    right: 7.6rem;
                                    z-index: 2;
                                }

                                .aux-line {
                                    display: none;
                                }

                                .wrap-title {
                                    .title {
                                        padding-right: 0;
                                        position: relative;
                                        z-index: 1;
                                    }
                                }

                            }

                            &:first-of-type {
                                .item-content {
                                    padding-top: 9rem;

                                    .num {
                                        top: 5rem;
                                    }
                                }
                            }
                        }

                        &.right {
                            justify-content: flex-end;

                            .item-wrapper {
                                left: auto;
                                right: 0;
                            }

                            .item-content {
                                border-left: none;
                                transform: translateX(0);
                                padding-left: 2rem;
                                padding-right: 2.5rem;

                                .num {
                                    left: 0;
                                }

                                .wrap-title {
                                    .title {
                                        background-color: unset;

                                        &::after {
                                            content: none;
                                        }
                                    }
                                }
                            }

                            &:first-of-type {
                                .item-content {
                                    padding-top: 9rem;

                                    .num {
                                        top: 5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
