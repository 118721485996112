// LOADER
.loader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $colorWhite;;
    z-index: 2000;

	&.loader-intro{	
		background-color: $colorWhite;
	}    

	.loader-wrap{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background-color: $colorWhite;
		border-radius: 50%;		
		overflow: hidden;

		width: 14rem;
		height: 10.5rem;		

		padding: 0;

		img{
			width: 100%;
			height: auto;    
            &.visible-md.visible-lg{
                display: none;
            }    
			&.visible-xs.visible-sm{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				padding: 1rem;
			}  
		}
		&:before{
			content: "";
			background-color: $colorCorporative;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			-webkit-mix-blend-mode: color;
			mix-blend-mode: color;
			z-index: 1;
		}
	}
}

// END LOADER

// DESKTOP
@media (min-width: $screen-md-min){
	.loader{
		.loader-wrap{	
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			background-color: $colorWhite;
			border-radius: 50%;		
			overflow: hidden;
			height: 18%;
			width: 22%;

			img{
				width: auto;
				&.visible-xs.visible-sm{
					display: none;
				}
				&.visible-md.visible-lg{
					display: block;
					transform: translateY(-50%) translateX(-50%);
					position: absolute;
    				margin: 0;
					top: 50%;
					left: 50%;
				}  
			}	
	
		}	
	}
}