.new-blog {   
    position: relative;
    background-color: $colorWhite;

    .aux-slider{
        margin-top: 7.9rem;

        .pasadorPost{
            width: calc(100% - 1.5rem)!important;
            margin-right: 1.5rem;

            &.swiper-container-horizontal{
                cursor: grab;
            }
        }
        .control.control-next {
            right: 0rem;
        }
        .swiper-slider{
            width: auto;
        }
    }
 
    &.detail-blog-famaliving2023{
        .authors-container.postsRelated{
            transform: none;
            top: calc(8.1rem + 8.4rem);
        
            .title{
                background-color: $colorCorporative;
            }
        }
        .aux-slider.isOne{
            .control.control-prev, 
            .control.control-next 
            {
                display: none !important;
            }
        }
    }
    &.page-blog{
        .two-columns-wrap{
            margin-bottom: 9rem;
        }
    }

    .wrap-container{
        padding-top: 0;
        padding-bottom: 0;

        &:first-of-type{
            padding-top: 16.4rem;
        }

        .blog-header{
            position: relative;
            padding-left: calc((100% - 1280px) / 2);
            margin-bottom: 4.4rem;

            .title{
                max-width: 30rem;
            }
        }
        .part-left{
            min-width: calc(42%);
            max-width: calc((1280px / 100) * 45);
            position: relative;
            padding-right: 7.6rem;
            display: flex;
            flex-direction: column;

            .wrap-blogs-form{
                position: relative;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .form-group{
                    margin-bottom: 0;
                }
            }
        }
        .blog-img{
            width: 100%;
            position: relative;
            min-height: 39rem;
            min-width: 58%;

            .img{
                //width: calc(100% + 5rem);
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                height: 100%;
            }
        }
    }
 
    .limited-wrap{
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;      
    }
    .header {
        display: flex;
        align-items: center;
    }
    .blog-description{
        margin-bottom: 5rem;
    }
    .two-columns-wrap{
        justify-content: space-between;
    }
    .wrap-lastArticles{
        margin-top: 9rem;

        .last-3-news {
            width: calc(100% + 2rem);
            margin: auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 16rem;   

            .last-3-new {
                width: calc(100% / 3);
                padding-right: 2rem;
                background-color: $colorRoseLight;
                padding-bottom: 3rem;

                .aux-item{
                    border-bottom: 5px solid #c70087;
                    width: 100%;
                    overflow: hidden;
                }

                .wrap-img {
                    position: relative;
                    padding-top: 64%;
                    transform: translateY(0);
                    transition: transform 0.5s;
                    overflow: hidden;
        
                    .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        background-color: $pink;
                        transform: scale(1);
                        transition: 0.5s transform linear;
                    }
                }
                .header,
                .date, 
                .author, 
                .title {
                    padding: 0 1rem;
                }
                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 1rem;
        
                    span {
                        font-size: 1.3rem;
                    }
        
                    .heart {
                        color: $pink;
                    }
                }
        
                .date {
                    font-size: 1.3rem;
                    color: $colorCorporative;
                    opacity: 1;
                    display: block;
                    margin-bottom: 2rem;
                    font-family: "Montserrat-Italic";
                }
        
                .title {
                    font-size: 1.5rem;
                    font-family: "Montserrat-SemiBold";
                    color: $grayDark2;
                }
                &:hover {
                    .wrap-img {
                        //transform: translateY(-1rem);
                        transition: transform 0.5s;
                        .img {
                            transform: scale(1.3);
                            transition: 0.5s transform linear;
                        }
                    }
                }
            }
        }

       .last-3-news{
        max-width: calc(100rem);
        margin: 0 auto;
       
            .last-3-new{
                width: calc((100% / 3) - 1.5rem);
                padding-right: 0;
                .header, .date{
                    margin-bottom: 0;
                }
                .title{
                    margin-top: 2rem;
                }
                .author{
                    @include font_mont(Italic, 1.3);
                    color: $grayDark2;
                    line-height: 2.1rem;
                    opacity: 1;
                    display: block;            
                }
            }
       }
       .section-title {
            width: 100%;
            margin: 0 auto;
            margin-left: 0;

            h2{
                font-size: 2.1rem;
                color: $colorCorporative;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 5.25px;
                font-family: "Montserrat-SemiBold";
            }
            .divider{
                width: 3.5rem;
                border-top: 2px solid $colorCorporative;
                margin: 2rem auto 7rem auto;
            }
        }
    }
    .authors-container {            
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
        position: -webkit-sticky;
        //right: 5rem;
        top: calc(16.5rem - 8.4rem);
        transform: translateY(8.4rem);
        width: 36.2rem;
        height: 46.6rem;
        z-index: 14;
        padding: 2rem;
        background-color: #fff4fb;

        .blog-authors {
            width: 100%;
            height: 85%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            
            &::-webkit-scrollbar:horizontal {
                height: 4px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                border: 1px solid $colorCorporative;
                background-color: $colorCorporative;
            }
            
            &::-webkit-scrollbar-track { 
                background-color: rgba($colorCorporative, 0.32); 
                border-radius: 8px; 
            }
            &::-webkit-scrollbar-track-piece  {
                background-color: rgba($colorCorporative, 0.32); 
                border-radius: 8px; 
            }
            
            &::-webkit-scrollbar-thumb:vertical {
                border-radius: 8px;
                border: 1px solid $colorCorporative;
                background-color: $colorCorporative;
            }
            /* Thin Scrollbar for firefox */
            &{
                scrollbar-color: $colorCorporative rgba($colorCorporative, 0.32);
                scrollbar-width: thin;                
            }
            .author-row {
                display: flex;
                align-items: flex-end;
                margin-top: 1.5rem;

                .img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
                & > .a {
                    width: 8.4rem;
                    height: 7.4rem;
                    display: block;
                    border: 1px solid $colorCorporative;
                    font-size: 1.3rem;
                }

                .author-info {
                    margin-left: 1.2rem;
                    width: 80%;

                    a{
                        font-size: 1.3rem;
                        font-family: 'Montserrat-Regular';
                        font-style: normal;
                    }

                    .a {
                        font-size: 1.3rem;

                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .name{
                        color: $colorCorporative;
                        font-style: oblique;
                        font-size: 1.3rem;
                        font-family: 'Montserrat-Regular';
                        transition: .2s color ease;
                        line-height: 2rem;
                        

                        &:hover{
                            color:rgba($colorCorporative, 0.8);
                            transition: .2s color ease;
                        }
                    }

                    .post{
                        color: #575252;            
                        font-style: normal;  
                        transition: .2s color ease; 

                        &:hover{
                            color:rgba(#575252, 0.8);
                            transition: .2s color ease;
                        } 
                    }

                }
            }
        }

        .title {
            height: 5rem;
            width: 100%;
            background-color: $pink;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;

            h3 {
                font-size: 1.7rem;
                color: white;
                font-weight: 600;
                text-align: center;
            }
        }
        &.postsRelated{           
            height: 44.5rem;
            padding: 1.8rem;
            >.title{
                margin-bottom: 2.8rem;
                text-transform: uppercase;
                h3{
                    font-weight: normal;
                    margin-bottom: 0;
                    font-family: 'Montserrat-SemiBold';
                    line-height: 2.5rem;
                }
            }
            .blog-authors{          
                height: auto;
                max-height: 33rem;
                overflow-y: auto;
            }
            .author-row{
                margin-top: 1.7rem;
                &:first-of-type{
                    margin-top: 0;
                }
                .pic{
                    width: 10.4rem;
                    height: 6.5rem;
                    display: block;
                    border: 1px solid $colorCorporative;
                    border: none;

                    .img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .author-info{
                    padding: 0 1.5rem;
                    margin: 0;
                    font-size: 1.1rem;
                    .name{
                        line-height: 1.9rem;
                        font-family: 'Montserrat-Regular';
                        font-size: 1.3rem;
                        border-bottom: 1px solid rgba($colorCorporative, .5);
                    }
                    .titlePost{
                        font-size: 1.3rem;
                        color: $grayDark2;
                        font-family: 'Montserrat-SemiBold';
                        //font-family: 'Montserrat-Regular';
                        background-color: transparent;
                        padding-bottom: 4px;
                       
                        transition: .2s color ease;
                      

                        &:hover{
                            color: rgba($grayDark2, .8);
                            transition: .2s color ease;
                        }
                    }
                }
            }
        }
    }

    .blog-entry-container {
        width: calc(100% - 36.2rem);  
        @include grid(2, 0, 200);

        .blog-entry {
            width: 100%;         
            margin-bottom: 4rem;
            padding-right: 4rem;

            &:nth-child(even){
                transform: translateY(8.4rem);
            }

            .blog-content {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                height: 100%;  
                
                .img {
                    display: block;                   
                    background-color: grey; // BGM del post
                }

                .block-text {
                    width: 100%;
                    background-color: #fff4fb;
                    padding: 1rem;
                    padding-bottom: 2rem;
                    position: relative;
                    flex-grow: 1;

                    .block-title {
                        @include font_mont(SemiBold, 1.5);
                        color: $grayDark2;
                        margin-top: 1rem;
                    }
        
                    .desc {
                        margin-top: 1rem;
                    }
        
                    p {
                        font-size: 1.5rem;
                    }
        
                    .author {
                        font-size: 1.3rem;
                        line-height: 2.1rem;
                        color: $colorCorporative;
                        font-style: oblique;
                    }

                    hr {
                        width: 100%;
                        border-color: rgba($colorCorporative, 0.5);
                        margin-bottom: 0;
                        border-width: .2rem;
                        border: none !important;
                    }     
                             
                }
                .info{
                    .date-info{
                        @include font_mont(Italic, 1.3);
                        color: $colorCorporative;
                        line-height: 2.1rem;
                    }
                }
                .generic.text, 
                .generic.text p, 
                .generic, 
                .generic.text{
                    @include font_mont(Regular, 1.5);
                    color: $colorBlack;
                    line-height: 2.5rem;
                    letter-spacing: 0 !important;

                    .author {
                        color: #575252;
                        font-style: oblique;
                    }
                    hr{
                        display: block;
                    }
                    table, table tr{
                        border: none;
                        border-bottom: none;
                    }
                    td{
                        background-image: none;
                    }
                }              

            }
        
            .aux-item{
                width: 100%;
                border-bottom: 5px solid $colorCorporative;
            }
            .aux-img{           
                width: 100%;
                position: relative;
                .wrap-img{
                    position: relative;
                    width: 100%;
                    padding-top: 63%;
                    overflow: hidden;
                    margin: 0 auto;
                    &:hover{
                        img {
                            width: 100%;
                            transform: scale(1.3);
                        }
                    }
                    .img {
                        transition: 0.5s all ease-in-out;
                        width: 100%;
                        height: 100%;                       
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        box-shadow: none;
                    }
                }
           
            }

        }
    }

}

.spacer {
    width: 100%;
    margin: auto;
    opacity: .4;
    margin-bottom: 6rem;   
    border-top: 1px solid $colorCorporative;
}


// DETALLE POST
.new-blog{
    .wrap-content-post{
        width: calc(100% - 36.2rem);
        margin-right: 0;
        padding-right: 2.8rem;

        .wrap-blog-content{
            display: flex;
            flex-direction: column;
        }
        
        .wrap-share{
            width: 100%;
            justify-content: flex-end;
            display: flex;
            padding-right: 1.5rem;
            margin-top: 1.7rem;
            
            .share{
                padding: 0;
                .icon{
                    padding-left: 1.7rem;
                    i{
                        font-size: 2.2rem;
                    }
                }
                a{
                    transition: .2s color ease;
                }
                a:hover{  
                    i{
                        color: $colorCorporative !important;
                        transition: .2s color ease;
                    }               
                    
                }
            }
        }
        .wrap-author{
            padding-bottom: 0.8rem;
            display: flex;
            border-bottom: 0.3px solid rgba($colorCorporative, .5);
            margin-bottom: 4.5rem;
            margin-right: 1.5rem;
            margin-top: 2.6rem;

            .wrap-aux{
                padding-top: 0.8rem;
                border-top: 0.3px solid rgba($colorCorporative, .5);
                padding-right: 5rem;
                width: 100%;

                >a{
                    width: 7rem;
                    height: 7rem;
                    position: relative;
                    border-radius: 50%;
                    margin-right: 1.3rem;
                }
            }
            .pic{
                //width: 7rem;
                //height: 7rem;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                margin-right: 1.3rem;
                border-radius: 50%;
                position: absolute;
            }
            .author-info{
                @include font_mont(Regular, 1.3);
                line-height: 2.1rem;
                color: $colorBlack;            
                font-weight: normal;
                align-self: center;

                .name{
                    @include font_mont(Italic, 1.3);
                    color: $colorCorporative;        
                    line-height: 2.1rem;                                  
                    font-weight: normal; 
                    margin-bottom: 0;
                }
            }
        }
        &.noPostRelated{
            width: 100%;
            margin-right: 0;
            .new-text{
                padding-right: 0;
            }
        }
    }
    .content.detail_new.comments{
        .comment{
            background-color: transparent;
            height: auto!important;
            padding: 0;
            padding-bottom: 3rem;
            border-bottom: solid 0.3px rgba(151, 151, 151, 0.5);
            border-radius: 0;

          
            .name{
                font-family: 'Montserrat-Medium';
                font-size: 1.5rem;
                line-height: 2.3rem;
                font-weight: normal;
                color: $colorCorporative;
            }

            .date{
               font-family: 'Montserrat-Medium';
               font-size: 1.1rem;
               line-height: 2.3rem;
               font-weight: normal;
               color: $colorBlack ;
               padding-bottom: 2.6rem;
               opacity: 1;
               
            }

            .text{
                @include font_mont(Medium, 1.5);
                color: $colorBlack;
                line-height: 2.1rem;
                font-weight: normal;
                margin-bottom: 0;
            }
            
        }
    }
    .wrap-comments-content{
        padding: 5rem;
        padding-top: 2rem;
        background-color: $colorRoseLight;
        margin-right: 0.5rem;

        .title-comments{
            @include font_mont(Bold, 2.9);
            line-height: 3.7;
            color: $colorCorporative;
            font-weight: normal;
            display: none;
            &.isSee{
                display: block;
            }
        }
        .comments{
            .new-comments{
                padding: 0;
                .form{
                    .form-control{
                        background-color: transparent;
                    }
                }
                .title{
                    line-height: 3.7;
                    font-size: 2.9rem;
                    font-family: 'Montserrat-Bold';
                    color: $colorCorporative;
                    font-weight: normal;
                    background-color: transparent;
                    padding: 0;
                    margin: 0;
                    text-transform: none;
                    margin-top: 2rem;
                }
            }
            .comments-list{
         
                .no-comments{
                    line-height: 3.7;
                    font-size: 1.9rem;
                    font-family: 'Montserrat-Bold';
                    color: $colorCorporative;
                    font-weight: normal;
                }
               
                .coment-item{
                    .comment{
                        background-color: transparent;
                        height: auto!important;
                        padding: 0;
                        padding-bottom: 3rem;
                        border-bottom: solid 0.3px rgba(151, 151, 151, 0.5);
                        border-radius: 0;
                        padding-top: 2.65rem;
                   
                     .name{
                        @include font_mont(Medium, 1.5);
                         line-height: 2.3rem;
                         font-weight: normal;
                         color: $colorCorporative;
                     }

                     .date{
                        @include font_mont(Medium, 1.3);
                        line-height: 2.1rem;
                        font-weight: normal;
                        color: $colorBlack;
                        padding-bottom: 1.9rem;
                        
                     }
                     .text{
                        @include font_mont(Medium, 1.5);
                        color: $colorBlack;
                        line-height: 2.3rem;
                        font-weight: normal;
                        margin-bottom: 0;
                     }
                     
                    }
                }
            }
            .form{
                .privacy .labelContainer{
                    margin-top: 1rem;
                }                 
              
                .buttons{
                    justify-content: flex-end;
                    //margin-top: 1.5rem;          
                    margin-left: 0 !important;
                    margin-right: 0 !important;       
               
                    .wrap-btnDefault {
                        min-width: unset;
                        .btnDefault{                       
                            font-size: 1.5rem;
                            line-height: 1.7rem;
                            padding: 1.4rem 3.8rem;
                        }
                    }
                }
                .input-box input + .label{                          
                    top: 0;
                    font-size: 1.5rem;
                    left: 1.7rem;
                    color: #575252;
                    background-color: $colorRoseLight;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                    &.left-1{
                        left: 1rem
                    }           
                  
                }
                .input-box textarea{
                    min-height: 14.7rem;
                }
                .input-box textarea + .label {                  
                    position: absolute;
                    top: 0;
                    font-size: 1.5rem;
                    left: 1.7rem;
                    color: #575252;
                    background-color: $colorRoseLight;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                    &.left-1{
                        left: 1rem
                    }                  
                }
      
                .input-box input:focus,       
                .input-box textarea:focus{
                    box-shadow: none;
                    border: 1px solid $colorBlack;
                }

                .input-box input:focus + .label, .input-box input:valid + .label,       
                .input-box textarea:focus + .label, .input-box textarea:valid + .label {
                    top: 0;                 
                    transform: translateY(-50%) scale(1);
                }
            }
        }
     }
    
    .detail_new{
        background-color: transparent;        
        
        .new-text{
            padding: 0;
            padding-right: 4.5rem;

            .text, .text *{
                line-height: 2.3rem !important;
                font-size: 1.5rem !important;
                color: $colorBlack !important;
                font-family: 'Montserrat-Regular' !important;
                font-weight: normal !important;
                background-color: transparent !important;
                padding: 0;
                b{
                    font-family: 'Montserrat-SemiBold';
                    font-weight: normal !important;
                }
                i{
                    font-family: 'Montserrat-Italic' !important;
                    font-weight: normal !important;
                }
                a{
                    color: $colorCorporative !important;
                    text-decoration: underline !important;
                    cursor: pointer;
                }
            }

            .text > *:not(br){
                padding-bottom: 2.5rem !important;
                &:empty{
                    display: none !important;
                    padding-bottom: 0 !important;
                }              
            }
            .text{
                p:empty{
                    display: none !important;
                    padding-bottom: 0 !important;
                }
            }
        }
        .photo{
            width: 100%;
            height: auto;
            border-radius: 0;
            position: relative;
            margin-bottom: 2.5rem;
            border-bottom: 6px solid $colorCorporative;

            .img-responsive{
                width: 100%;
            }

            .pull-right{
                position: absolute;
                color: $colorBlack;  
                width: 8rem;
                height: 4.4rem; 
                top: 2.1rem;
                right: 0;
                background-image: url('/images/flag.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                justify-content: center;
                align-items: center;                
                padding-left: 1rem;

                .fa{
                    font-size: 1.8rem;
                    margin-left: 0.5rem;
                }
            }
        }
        .new-header{
            .title{
                font-size: 3.1rem;
                font-family: 'Montserrat-SemiBold';
                line-height: 3.9rem;
                color: #575252;
                margin-bottom: 3.2rem;
            }
        }
    }


    .comments{
        padding-right: 0.5rem;
    }
 
}

@media (max-width: $md-width) {

    .new-blog {     
        padding-top: 9.2rem;      
        padding-left: $paddingLateralXs;
        padding-right: $paddingLateralXs;

        .blog-description{
            margin-bottom: 2.5rem;
        }

        .aux-slider{
            margin-bottom: 4.3rem;
            margin-top: 3rem;

            &.isOne{
                .swiper-wrapper{
                    .swiper-slide{
                        &:last-of-type{
                            .wrap-item{
                                margin: 0;
                            }
                        }
                        .wrap-item{
                            margin: 0;

                            .img-responsive{
                                max-height: unset;
                                width: 100%;
                            }
                        }
                    }
                }
                .pasadorPost.isOne{
                    width: calc(100% - 3rem) !important;
                    left: -1.5rem;
                }
            }

            .swiper-slider{
                width: unset;
            }
            .control{
                &.control-next {
                    right: 1rem;
                    width: 2.6rem;
                    height: 2.6rem;
                }
                &.control-prev {
                    left: 1rem;
                    width: 2.6rem;
                    height: 2.6rem;
                }
            }
        }
        .blog-entry-container{
            .blog-entry:nth-child(even){
                transform: none;
            }
        }
        &.page-blog{
            .two-columns-wrap{
                margin-bottom: 0;
            }
        }

        .form-more{
            margin-top: 9rem !important;
        }

        .wrap-container{
            padding: 0;

            &:first-of-type{
                padding-top: 0;
                padding: 0;
            }

            .blog-header{
                padding-left: 0;
                flex-direction: column;
                margin-bottom: 0;
           

                .part-left{
                    width: 100%;
                    max-width: 100%;
                    padding: 0;
                    padding-left: 0;

                    .wrap-blogs-form{
                        margin-top: 3.7rem;
                        margin-bottom: 2.8rem;

                       
                    }
                }
                .blog-img{
                    margin-top: 0;
                }
            }
            .blog-img{
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                min-height: unset;
                margin-top: 3.5rem;

                .img{
                    width: 100%;
                    height: auto;
                    aspect-ratio: 3 / 2;
                    position: relative;
                }
            }
            .form-group{
                margin-bottom: 0;
            }
        }

        .limited-wrap{
            padding-top: 0;
        }
        .header {
            justify-content: space-between;
            margin-bottom: 0;
            .long-line {
                width: 100%;
            }
            
            .header-title {             
                .title {
                    font-size: 2.5rem;
                    max-width: 20rem;
                }
            }
        }
    .wrap-lastArticles{
        .section-title{
            h2{
                font-size: 1.7rem;
                letter-spacing: 4.25px;
            }
            .divider{
                margin: 2rem auto 3rem auto;
            }
        }
        .last-3-news{
            transform: translateX(-1.5rem);
            width: calc(100% + 3rem);
            flex-wrap: nowrap;
            padding-bottom: 6rem;
            overflow-x: scroll;
    
            .last-3-new{
                width: calc(100% - 5.5rem);
                flex-shrink: 0;
                margin-right: 1.5rem;
                &:first-of-type{
                    margin-left: 1.5rem;
                }
            }
        }
    } 
        .authors-container {
            display: none;
        }
        .detail_new{
            margin-left: 0;
            margin-right: 0;

            .new-text{
                padding-right: 0;

                .new-header{
                    padding: 0;
                    margin-bottom: 1.2rem;

                    .title{
                        font-size: 2.1rem;
                        line-height: 2.7rem;
                    }
                }
                .text{
                    padding: 0;
                    p:empty{
                        display: none !important;
                        padding-bottom: 0 !important;
                    }

                }

                .photo {
                    border-bottom: 3px solid $colorCorporative;
                    .pull-right{
                        height: 3rem;
                        top: 1rem;
                        width: 6.6rem;
                    }
                }
            }
        }

        .wrap-content-post{
            width: 100%;
            margin-right: 0;
            padding-right: 0;

            .wrap-share{
                padding-right: 0;
                margin-top: 0;
            }
            .wrap-author{
                margin-right: 0;
                padding-right: 0;

                .wrap-aux{
                    width: 100%;
                    margin-right: 0;
                    padding-right: 0;
                  
                }
            }
            .wrap-comments-content{
                padding: 1.5rem;
                margin: 0;

                .detail_new.comments{
                    padding-right: 0;

                    .new-comments{
                        .title{
                            line-height: normal;
                            margin-bottom: 3.6rem;
                        }
                        .form-comment{
                            .form{
                                display: block;
                                .input-box input + .label {
                                    left: 1rem;
                                }
                            }
                            .input-box{
                                padding: 0;
                            }
                        }
                    }
                    .comments-list .no-comments {
                        font-size: 1.7rem;
                    }

                    .form{
                        .privacy{
                            .labelContainer{
                                position: relative;
                            }
                        }
                    }
                }
                .title-comments{
                    line-height: 3.6rem;
                    margin-bottom: 3.2rem;
                    margin-top: 3.2rem;
                }
            }

            .pasadorPost{
                margin-bottom: 0;
                margin-right: 0;             
            }
        }
    
        .blog-entry-container {
            @include grid(1,1);
            width: 100%;
            margin-bottom: 2.8rem;
    
            .blog-entry .aux-img .wrap-img:hover img {
                transform: unset;
            }
            .blog-entry,
            .blog-entry:first-of-type {
                width: 100%;
                margin-bottom: 3rem;
                padding-right: 0;

                .info{
                    .date-info{
                        @include font_mont(Italic, 1.3);
                        color: $colorCorporative;
                        line-height: 2.1rem;
                    }
                }
                .blog-content {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;   
                    
                    .block-text{
                        padding: 1rem;
                        padding-bottom: 2rem;
                    }              
    
                    .badge-blog {
                        display: block;
                        position: relative;
                        top: unset;
                        right: unset;
                        width: 80px;
                        border: none;
                        margin: 0 auto;
                        margin-right: 0;

                        .like-button{
                            width: auto;
                        }
                        
                        p {
                            position: relative;
                            right: 0;
                            transform: none;
                        }
                        .likes{
                            align-items: center;
                        }

                        .fa:before {
                            font: FontAwesome;
                            color: $colorCorporative;
                        }

                    }
              
                    .author{
                        color: #575252;
                    }
    
                    
    
                }
    
            }
    
           
        }
    
    }
}









