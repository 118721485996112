.geo-content{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1000;

    
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
    .wrap-content{
        .content{
            width: 39.5rem;
            //height: 37.8rem;
            height: auto;
            background-color: $colorWhite;
            position: absolute;
            padding: 3.5rem 3.2rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 767px) {
                width: 80% !important;
            }
        }
        .logo{
            width: 100%;
            height: auto;
           // padding: 0 auto;
            padding-bottom: 2.7rem;
            margin-bottom: 3.6rem;
            border-bottom: 1px solid rgba($colorCorporative, .3);
            display: flex;
            justify-content: center;

            img{
                max-width: 18rem;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .subtitle{
            @include font_lato(Black, 1.9);
            line-height: 1;
            color: $colorCorporative;
            text-align: center;
            margin-bottom: 2rem;
            
        }
        .country{
            @include font_lato(Regular, 1.3);
            line-height: 1.9rem;
            text-align: center;
            margin-bottom: 3.6rem;

            .data{
                text-transform: uppercase;
                font-family: 'Lato-Bold';
            }
        }

        .wrap-link{
            @include font_lato(Bold, 1.3);
            line-height: 1.9rem;
            text-align: center;
            margin-top: 2.2rem;

            .changeGeo{
                width: fit-content;
                position: relative;
                margin: 0 auto;

                &::before{
                    content: '';
                    width: 1.2rem;
                    height: 100%;
                    position: absolute;
                    background-image: url('/images/arrow-right-black.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    left: -2rem;
                    transform: scale(1) rotate(180deg) translateX(0);
                    transition: .3s transform linear;
                }

                &:hover{
                    &::before{
                        transform: scale(1.1) rotate(180deg) translateX(.5rem);
                        transition: .3s transform linear;
                    }
                }
            }
        }

    }
    .wrap-btnDefault{
        min-width: unset;
        width: 100%;
    //margin-bottom: 2.2rem;
        .btnDefault{
            width: 100%;
            padding: .9rem;
            letter-spacing: .2rem;
        }
    }
}