
#warranties_famaliving_2023{
    padding: 0;
    padding-top: 15.6rem;
    padding-right: 5rem;
    padding-left: 5rem;
  
    .limited-wrap{
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;

        .box-content{   
            color: $colorBlack;
            letter-spacing: 0;
            padding: 0 10.3rem;

            .title.small-line{
                @include font_mont(SemiBold, 3.5);
                letter-spacing: 0;
                line-height: normal;
                margin-bottom: 4.7rem;
                position: relative;
            }
            .small-line{
                margin-bottom: 5rem;
               
                &::before {
                    bottom: -1.4rem;
                }
            }
           
        }

        .warranty-block{
            padding: 6.9rem 10.3rem;
            margin-bottom: 7.5rem;
            position: relative;

            .warranty-subtitle{
                font-size: 2.5rem;
                color: $colorCorporative;
                margin-bottom: 2.4rem;
                margin-top: 0;
            }

            .warranty-block-left{
                min-height: unset;   

                .warranty-text{
                    @include font_mont(Regular, 1.5);
                    max-width: 100%;
                    width: 100%;
                    line-height: 2.1rem;
                    color: #575252;
                    position: relative;    

                    b{
                        @include font_mont(SemiBold, 1.5);
                    }
                  
                    .tooltip{  
                        z-index: 10 !important;
                        position: relative;
                        display: inline-block;
                        transition: color 0.3s;
                        opacity: 1;
                        font-size: 1.3rem;
                        transition: .2s all ease;
                        text-align: center;
                        margin-left: 1rem;

                        &::before{
                            content: "";
                            position: absolute;
                            width: 1.5rem;
                            height: 1.5rem;
                            border: 1px solid #575252;
                            border-radius: 50%;
                            transform: translate(-0.7rem, 0.2rem);
                            left: 0;
                            top: 0;
                        }

                        .tooltiptext {                       
                            width: 34.2rem;
                            visibility: hidden;
                            display: flex;
                            flex-direction: column;
                            background-color: rgba(0, 0, 0, 0.8);
                            color: #fff;
                            text-align: left;
                            padding: 3rem;
                            position: absolute;
                            z-index: 1;
                            top: unset;
                            left: unset;
                            margin-left: -1rem;
                            opacity: 0;
                            transform: translate(-100%, -50%);
                            transition: opacity 0.3s;

                            .firstImgTooltip{
                                max-height: 14rem;
                                margin: 0 auto;
                                margin-right: 0;
                                margin-top: 2.5rem;
                                margin-bottom: 2.5rem;
                            }
                            .arrowTooltip{
                                position: absolute;
                                top: 59%;
                                left: 47%;
                            }
                            .secondImgTooltip{
                                width: 15.4rem;
                                max-height: unset;
                            }
                        }
                        &:hover{
                            color: $colorCorporative;
                            transition: .2s all ease;

                            &::before{
                                border: 1px solid $colorCorporative;
                                transition: .2s all ease;
                            }

                            .tooltiptext{
                                visibility: visible;
                                opacity: 1;
                            }

                        }
                    }      
                 
                }
              
            }

            #garantias_famaliving2023.garantias{
                .form{
                    .input-box{
                        input{
                            height: 5rem;
                        }
                    }
                    .input-box input + .label,                    
                    .input-box textarea + .label, 
                     .select2 + .label{
                        position: absolute;
                        top: 0;
                        font-size: 1.3rem;
                        left: 1.7rem;
                        color: #575252;
                        background-color: #fff;
                        padding: 0 1.1rem;
                        pointer-events: none;
                        transform: translateY(-50%);
                        transform-origin: left;
                        white-space: nowrap;
                        margin: 0;
                        transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                    }
                    
                    .warranty-block-warranty{
                        width: calc(100% + 3.2rem);
                        margin-top: 5.9rem;

                        .input-box{
                            padding-right: 3.2rem;
                            margin-bottom: 3.8rem;
                            .label{
                                background-color: #fff;
                            }
                        }
                        .textarea{
                            .input-box{
                                margin-bottom: 0;
                            }
                        }
                        .wrap-privacy{                            
                            width: calc(100% - 3.2rem);

                            .privacy{
                                width: 100%;

                                .labelContainer{
                                    .textContainer{
                                        font-family: 'Montserrat-Medium';
                                        font-weight: normal;
                                        display: block;
            
                                        .link{
                                            color: $colorCorporative;
                                            text-decoration: none;
                                            //-left: .5rem;

                                            --border-color: #c70087;
                                            --border-width: 1px;
                                            --bottom-distance: 0px;
                                            display: inline;
                                            background-image: linear-gradient(var(--border-color),var(--border-color));
                                            background-size: 0 var(--border-width);
                                            background-repeat: no-repeat;
                                            background-position-y: bottom;
                                            transition: background-size .3s;  

                                            &:hover{
                                                background-size: 100% var(--border-width);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .wrap-rate-stars{
                            margin-bottom: 3rem;

                            .warranty-subtitle{
                                margin-bottom: 1rem;
                            }
                            .rate{
                                display: flex;                             
                                position: relative;
                                float: unset;
                                flex-direction: row-reverse;
                                justify-content: flex-end;
                                height: 3.5rem;                               
                                font-size: unset;
                                color: transparent;
                                background-color: $colorWhite;

                                &>input{
                                    z-index: -1;
                                    position: absolute;
                                }
                            }
                            .rate > label{
                                position: relative;
                                width: 3rem;
                                height: 3rem;
                                margin-left: .5rem;
                                color: transparent;
                            }
                            .rate:not(:checked) > label:before {
                                content: '';
                                background-image: url('/images/star-grey.svg');
                                width: 3rem;  
                                height: 3rem;                              
                                position: absolute;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;      
                            }
                            .rate > input:hover  ~ label:before {
                                content: '';
                                background-image: url('/images/star-orange.svg');
                                filter: brightness(.9);
                                width: 3rem;  
                                height: 3rem;
                                position: absolute;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;                              
                                
                            }
                            .rate > input:checked  ~ label:before{
                                content: '';
                                background-image: url('/images/star-orange.svg');
                                width: 3rem;  
                                height: 3rem;
                                position: absolute;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: contain;                              
                                
                            }
                        }
                        .buttons{                            
                            width: calc(100% - 3.2rem);
                            display: flex;                           
                            justify-content: flex-end;

                            .wrap-btnDefault{
                                min-width: unset;

                                .btnDefault{
                                    font-size: 1.5rem;
                                    line-height: 1.7rem;
                                    padding: 1.4rem 6.6rem;
                                }
                            }

                        }
                   
                    }
                }

            }
            &::after{
                content: '';
                background-image: url('/images/fl-circle-warranty.svg');
                width: 14rem;
                height: 14rem;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: -8rem;
                right: 4rem;
                z-index: 1;
            }
            &::before{
                content: '6';               
                width: 14rem;
                height: 14rem;
                font-family: 'Montserrat-Bold';
                font-size: 6rem;
                color: #fff;
                position: absolute;             
                top: -8rem;
                right: 4rem;
                transform: translate(6.5rem, 1.5rem);
                z-index: 2;

            }
        }
    }

}


@media (max-width: $md-width) {
  
    #warranties_famaliving_2023{
        padding: 0 $paddingLateralXs;
        padding-top: 9.2rem;
        background-color: $colorRoseLight;
        padding-bottom: 3.3rem;
    
        .limited-wrap{       
            max-width:100%;        
    
            .box-content{            
                padding: 0 $paddingLateralXs;   
                padding-right: 0;
                max-width: 50%;  

                .title.small-line{ 
                    font-size: 2.9rem;                   
                    line-height: 3.8rem;
                    margin-bottom: 4.7rem;
                }  
            }
    
            .warranty-block{
                padding: 0 $paddingLateralXs;
                padding-top: 7.6rem;
                padding-bottom: 8.5rem;
                margin-bottom: 0;
                background-color: $colorWhite;

                .warranty-subtitle{
                    font-size: 2.5rem;
                    color: $colorCorporative;
                    margin-bottom: 2.4rem;
                    margin-top: 0;
                }
    
                .warranty-block-left{
                    min-height: unset;   

                    .warranty-text{
                        @include font_mont(Regular, 1.5);
                        max-width: 100%;
                        width: 100%;
                        line-height: 2.1rem;
                        color: #575252;
                        position: relative;    
                      
                        .tooltip{
                            width: 1.5rem;
                            height: 1.5rem;
                            position: unset;
                            color: $colorBlack;
                            transition: .2s all ease;
                            border: 1px solid $colorBlack;
                            border-radius: 50%;
                         

                            &::before{
                                content: none;
                            }

                            &:hover{
                                color: $colorCorporative;
                                transition: .2s all ease;
                                border: 1px solid $colorCorporative;
                            }

                            .tooltiptext {                       
                                width: 100%;
                                transform: translate(0, 1rem);
                                left: 0;
                                margin-left: 0;
                                padding: 1.5rem;
                                z-index: 3;
                                font-size: 1.5rem;

                                .firstImgTooltip{
                                    max-height: 14rem;
                                    margin: 0 auto;
                                    margin-right: 0;
                                    margin-top: 2.5rem;
                                    margin-bottom: 2.5rem;
                                }
                                .arrowTooltip{
                                    transform: rotate(-20deg);
                                    position: absolute;
                                    top: 53%;
                                    left: 57%;
                                }
                                .secondImgTooltip{
                                    width: 70%;
                                }
                            }
                            &:hover{
                                color: $colorCorporative;
                                border: 1px solid $colorCorporative;
                                transition: .2s all ease;
                                
                                .tooltiptext{
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }  
                    }
                }
                #garantias_famaliving2023.garantias{
                    .form{
                        display: block;

                        .privacy{
                            input[type=checkbox]{
                                height: 5rem;
                                width: 3rem;
                                position: absolute;
                                z-index: 10;
                            }
                            .labelContainer{
                                position: relative;
                            }
                        }

                        .input-box input + .label,                    
                        .input-box textarea + .label, 
                        .select2 + .label{
                            position: absolute;
                            top: 0;
                            font-size: 1.3rem;
                            left: 1.7rem;
                            color: #575252;
                            background-color: #fff;
                            padding: 0 1.1rem;
                            pointer-events: none;
                            transform: translateY(-50%);
                            transform-origin: left;
                            white-space: nowrap;
                            margin: 0;
                            transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
                        }
                        
                        .warranty-block-warranty{
                            width: 100%;
                            margin-top: 5.9rem;
                            flex-direction: column;

                            .input-box{
                                padding-right: 0;
                                margin-bottom: 3.4rem;

                                .label{
                                    background-color: #fff;
                                }
                            }
                            .textarea{
                                .input-box{
                                    margin-bottom: 0;
                                }
                            }
                            .wrap-privacy{                            
                                width: 100%;                             
                            }
                            .wrap-rate-stars{                                
                                width: 100%;
                                margin-bottom: 3rem;

                                .warranty-subtitle{
                                    margin-bottom: 1rem;
                                }
                            }
                            .buttons{                            
                                width: 100%;
                                display: flex;                           
                                justify-content: flex-end;
                            }
                       
                        }
                    }
                }

                &::after{
                    content: "";
                    background-image: url(/images/fl-circle-warranty.svg);
                    width: 11.1rem;
                    height: 11.1rem;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: -5.5rem;
                    right: 0;
                    z-index: 1;
                }
                &::before{
                    @include font_mont(Bold, 5);
                    content: "6";
                    width: calc(11.1rem / 2);
                    height: 11.1rem;
                    color: #fff;
                    position: absolute;
                    top: -5.5rem;                 
                    transform: translate(-.5rem, 1.5rem);
                    z-index: 2;
                    right: 0;    
                }
            }
        }    
    }
}