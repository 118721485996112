.wrap-sity-selectes{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 4.7rem;
    padding-left: 5rem;
    padding-top: 0.5rem;

    .wrap-sity-selectes-content{
        border-bottom: 1px solid rgba(192, 61, 139, 0.5);;
        border-top: 1px solid rgba(192, 61, 139, 0.5);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: $colorWhite;
        transition: background-color .2s ease; 

        &:hover{
            background-color: $colorRoseLight;
            transition: background-color .2s ease; 
        }
    }
    .wrap-sity-selectes-name{
        @include font_mont(Medium, 1.5);
        padding-left: 1.5rem;
        text-transform: uppercase;
        color: $colorCorporative;
    }

}

.wrap-store{
    width: 47.4rem;
    height: 100vh;
    position: absolute;
    padding: 8.3rem 5.5rem 3.2rem 5.2rem;
    z-index: 5;
    top: -3.7rem;
    left: 0;
    background-color: #FFF;
    transform: translateX(-100%);
    transition: transform 0.3s linear;
    display: flex;
    flex-direction: column;

    .content-wrap-stores{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .comment-loader{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
   
    .wrap-store-content{
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        flex-grow: 1;
        width: 100%;

        &::-webkit-scrollbar {
            -webkit-appearance: none;                 
        }
        &::-webkit-scrollbar:vertical {
            width:5px;
        }
        &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
            display: none;
        } 
        &::-webkit-scrollbar:horizontal {
            padding-right: .5rem;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $colorCorporative;
            border-radius: 0 !important;                    
            border: none;
        }
        &::-webkit-scrollbar-track {
            background-color: rgba($colorCorporative, 0.5);
            border-radius: 0 !important;  
            margin-top: 2rem;
            margin-bottom: 7rem;
        }
    }

    form{
        .form-group{
            margin-top: 2.2rem;
            margin-bottom: 2.5rem;

            label{
                padding: 0 1rem;
            }
        }
        .form-control {
            border: 1px solid #575252;
            display: block;
            width: 100%;
            padding: 0.75rem;
            font-size: 1.7rem;
            font-weight: normal;
            line-height: 2.5rem;
            color: #000;
            background-color: #ffffff;
            background-clip: padding-box;
            border-radius: 0;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &.err{
                border-color: $red;
                transition: border-color 0.15s ease-in-out;
            }
        }
        input:-internal-autofill-selected{
            background-color: $colorWhite !important;
        }
    }

    .step-two{
        .box-content{
            .title{
                margin-bottom: 3.3rem;
            }
        }
        .search-store-description{
            .text.generic{
                font-size: 1.3rem;
                line-height: 2.1rem;
            }
        }
        .wrap-btnDefault{
            &:first-of-type{
                margin-bottom: 2.1rem;
            }
            .btnDefault{
                padding: 0.9rem;
                letter-spacing: .2rem;
            }
           
        }
     
    }
    .wrap-store-list-near{
        .stores-near{
            .store-near-name{
                color: $colorCorporative;
                margin-bottom: .7rem;
                .store-name{
                    color: $colorBlack;
                }
            }
        }
    }
    &.is-open{
        transform: translateX(0);
        transition: transform 0.3s linear;
    }
    .btn-close{
        position: absolute;
        top: 2rem;
        right: 2rem;
        display: block !important;
    }
    .btn-back{
        position: absolute;
        top: 2.3rem;
        left: 2.9rem;
        color: #fff;
        height: 3rem;
        max-width: 3rem;
        transition: .2s all ease-in-out;
        overflow: hidden;

        .icon, 
        .icon2{
            position: relative;
            width: 3rem;
            height: 3rem;
            background-color: $colorCorporative;
            color: #fff;
            border-radius: 50%;
   
        }
        .icon{
            z-index: 4;
            &::before{
                content: "";
                position: absolute;
                min-width: .6rem;
                height: .6rem;
                top: 50%;
                left: 50%;
                border: 0.2rem solid $colorWhite;
                border-top: 0;
                border-right: 0;
                transform: translate(-50%, -50%) rotate(45deg) scale(1);
            }
        }
        .icon2{
            margin-left: -1.5rem;
            z-index: 1;
        }
        .text{
            width: auto;
            max-width: 0;
            position: relative;
            line-height: 3rem;
            background-color: $colorCorporative;
            transition: .1s all ease-in-out;
            margin-left: -1.5rem;
            padding-left: 0;
            z-index: 3;

            &>div{
                background-color: $colorCorporative;
                opacity: 0;
                //visibility: hidden;
                transition: opacity .4s ease-in-out;
            }
        }
        &.hover{
            max-width: 9999px;
            transition:  .2s max-width ease-in-out;

            .text{
                padding-left: 1.5rem;
                max-width: 9999px;
                transition: max-width .9s ease-in-out;

                &>div{
                    opacity: 1;
                    //visibility: visible;
                    transition: opacity .4s  ease-in-out;
                }
            }
            
        }
        &.d-none{
            display: none !important;
        }
    }
    .box-content{
        .title{
            margin-bottom: 2.5rem;
            font-size: 2.3rem;

            .name{
                font-size: inherit;
                color: inherit;
            }
        }
    }
    .store-info{
        margin-bottom: 1rem;

        .text.generic{
            font-size: 1.3rem;
            line-height: 2.1rem;
        }
    }

    .store-buttons{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        grid-gap: 1.5rem;
        margin-bottom: 2.8rem;

        .wrap-btnDefault{
            min-width: 11rem;
            .btnDefault{
                @include font_mont(SemiBold, 1.3);
                padding: 0.4rem;
                text-transform: unset;
                line-height: 2.1rem;
                letter-spacing: normal;
                justify-content: center;
               
            }
        }
    }
    .store-map{
        margin-bottom: 2.5rem;
        .map-container{
            width: 100%;
            aspect-ratio: 2;
            height: 20rem;
            padding: 0;
            margin: 0;
            border-radius: 0;
        }
        #mapMenu{
            width: 100%;
            height: 100%;
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
        }
    }
    .store-schedule{
        .title{
            @include font_mont(SemiBold, 1.3);
            line-height: 2.1rem;
            margin-bottom: 0.2rem;
        }
        .store-schedule-content{
            font-size: 1.3rem;
            line-height: 2.1rem;
        }
        .saturday,
        .mondayFriday{
            .saturday-title,
            .mondayFriday-title{
                width: 40%;
                margin-right: 3rem;
            }
            .saturday-time,
            .mondayFriday-time{
                width: calc(60% - 3rem);
            }
        }
    }

    .wrap-buttons{
        padding-top: 3rem;
        border-top: 1px solid rgba(199, 0, 135, 0.5);
        margin-top: 3rem;

        .wrap-btnDefault{
            &:first-of-type{
                margin-bottom: 2.1rem;
            }
            .btnDefault{
                padding: 0.9rem;
                letter-spacing: .2rem;
            }

            &.js-seeListStore{
                margin-bottom: 2.4rem;
            }
           
        }
    }

    .autocomplete{
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        top: 100%;
        background-color: #fff;
       // padding: 1rem;
        visibility: hidden;
        z-index: -1;

        &.isActive{
            visibility: visible;
            z-index: 2;
            border: 1px solid;
            border-top: none;
        }

        .autocompletecontent{
            .optionAuto{
                padding: 1rem;
                &:focus,
                &:focus-visible{
                    background-color: $colorRoseLight;
                }
            }
        }
    }

    &.stepSeeListStore{
        padding-bottom: 0;
        .wrap-store-content{
            width: calc(100% + 3.5rem);
        }
    }

    .part-result{
        margin-right: 3rem;
        .resNoNear{
            &.err{
                color: #ff0000;
                font-family: 'Montserrat-SemiBold';
            }
        }
       .stores-near{
            padding-top: 0.3rem;
       }
       .store-near{
            @include font_mont(SemiBold, 1.5);
            padding: 1.6rem 0.9rem;
            border-bottom: 1px solid rgba($colorCorporative, .7);
            color: $colorBlack;
            position: relative;
            background-color: $colorWhite;
            transition:  background-color .2s ease-in-out;
            position: relative;

            .wrap-img{
                position: absolute;
                z-index: -1;
                visibility: hidden;
                opacity: 0;
                width: 21rem;
                bottom: unset;
                top: calc(100% - 2rem);
                right: 4rem;
                padding: 11px 12px 11px 13px;
                border-radius: 5px;
                background-color: #fff;
                box-shadow: 0 1px 4px 0 $pink;
                aspect-ratio: 2;

                .img{
                    @include object_fit(cover);
                }

                &.isVisible{
                    z-index: 2;
                    visibility: visible;
                    opacity: 1;
                }
            }
            .store-near-name,
            .store-near-address{
                padding-right: 4rem;
            }
            .store-near-address{
                @include font_mont(Regular, 1.3);
                line-height: 2.1rem;
                color: #000;
            }
        
            .store-link{
                &:before{
                    content: '';
                    background-image: url('/images/fl-arrow-rose.svg');
                    display: block;
                    height: 1.3rem;
                    width: 1.3rem;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                    background-size: contain;
                    background-repeat: no-repeat;
                    right: .9rem;
                    top: 1.6rem;
                    left: unset;
                    
                }
            }

            &:hover{
                background-color: $colorRoseLight;
                transition:  background-color .2s ease-in-out;
            }

            &.isNotVisible{
               display: none;
            }
      
       }
    }
}

    //***************************************************************************************************//
// TABLET/mobile

@media only screen and (max-width: $md-width){
    /*body {
        min-height: 100vh;
        min-height: fill-available;
        min-height: -webkit-fill-available;
    }
    html {
        height: fill-available;
        height: -webkit-fill-available;
    }*/

    .wrap-store{
        width: 100%;
        padding: 1.5rem;
        padding-top: 5.6rem;
        top: -1.5rem;
        overflow: scroll;

        .store-buttons{
            grid-gap: 1.5rem;
            grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
        }

        &.stepSeeListStore{
            padding-bottom: 0;
            height: 100vh;

            .wrap-store-content{
                width: 100%;
                &::-webkit-scrollbar-track{
                    margin-bottom: 5rem;
                }
            }
        }
        .part-result{
            margin-right: 1rem;
        }

        .btn-close{
            width: 4rem;
            height: 4rem;
            top: 0;
            right: 0;
            

            .icon:before {
                width: 1.8rem;
                height: 1.8rem;
                bottom: 0;
                top: unset;
            }
        }
        .btn-back{
            left: 1.5rem;
            height: 2.4rem;
            max-width: 2.4rem;
            
            .icon, .icon2{
                height: 2.4rem;
                width: 2.4rem;
            }
            .icon2{
                margin-left: -1.2rem;
                z-index: 1;
            }
            .text{
                line-height: 2.4rem;
                margin-left: -1.2rem;
            }
            .icon{
                &::before{
                    left: 55%;
                    border: 0.1rem solid #FFF;
                    border-top: 0;
                    border-right: 0;
                }
            }
        }
    }
    .wrap-sity-selectes{
        padding-right: 3.5rem;
        padding-left: 3.5rem;
        padding-top: 2rem;

        .wrap-sity-selectes-content{
            background-color: $colorCorporative;
            color: $colorWhite;
            border-bottom: 1px solid $colorWhite;
            border-top: 1px solid $colorWhite;
            padding-top: 1rem;
            padding-bottom: 1rem;
            
            &:hover{
                .wrap-sity-selectes-name{
                    color: $colorCorporative;
                }
            }
        }
        .wrap-sity-selectes-name{
            color: $colorWhite;
            font-size: 1.3rem;
        }
    }

    .wrap-buttons .wrap-btnDefault .btnDefault{
        font-size: 1.5rem;
    }
    .part-result .store-near .wrap-img{
        &.isVisible{
            z-index: -1 !important;
            visibility: hidden !important;
            opacity: 0 !important;
        }
    }
}

@media only screen and (max-width: 375px){
    .wrap-store{
        .wrap-buttons{
            .wrap-btnDefault{
                min-width: unset;
            }
        }
        .store-buttons{
            grid-gap: 1rem;
        }
    }
}