//for style is-active on product´s page
.app-list.view-index-page.main{
    .header_page nav.topbar{	
        .menu.menu-horizontal {      
            & > .item {    
                &.is-active{
                    border: none;

                    a:before {
                        content: '';
                        position: absolute;
                        height: 1px;
                        background-color: $colorWhite;
                        transform: scaleX(1);
                        right: 0;
                        bottom: -6px;
                        left: 0;
                        transition: transform $t-fast ease-in-out;
                    }
    
                    a:hover:before {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}
//only for home, if is home all of items opacity 1
.isHomePage.main{
    .header_page nav.topbar .menu.menu-horizontal > .item.regular{
        opacity: 1;
    }
}
.header_page{
    //***************************************************************************************************//
// NAV
    nav.topbar{
        height: 6.5rem;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 5rem;
        margin-top: 3.7rem;
        z-index: 101;
        transform: translateX(0);
        transition: all  0.3s ease-in-out;

        &.navAdapt{
            width: calc(100% - 1.5rem);
        }
        &.topOut{
            .full-level-black,
            .wrap-store{
                top: 6.5rem;
            }
        }
        //CAPA full black(open menu vertical)
        .full-level-black{
            position: relative;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: -3.7rem;
            left: 0;
            opacity: 0;
            z-index: -1;
            visibility: hidden;
            background-color: rgba(0, 0, 0, 0.5);


            &.isVisible{
                visibility: unset;
                opacity: 1;
                z-index: 1;
                transition: .2s opacity ease;

                &.isVisibleStore{
                    z-index: 4;
                    background-color: rgba(0, 0, 0, 0.7);
                }
            }

            &.isVisibleStore{
                visibility: unset;
                z-index: 4;
                opacity: 1;
                transition: .2s all ease;
                background-color: rgba(0, 0, 0, 0.7);
            }

        }


        .container-nav{
            background-color: $colorCorporative;
            padding: 0 2.2rem;

            position: inherit;
            width: calc(100% - 10rem);
            z-index: 3;

            .container-aux-nav{
                justify-content: space-between;

                .nav-part-right{
                 @include center_vertical;

                }
            }

        }

        .menu-burger{
            min-width: 2.7rem;
            display: flex;

            .item-burger{
                align-items: center;
            }
            .store-user{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: #fff;
                z-index: 1;
                margin-left: 2rem;

                .store-user-name{
                    @include font_mont(Medium, 1.5);
                    text-decoration: none;

                    --border-color: #fff;
                    --border-width: 1px;
                    --bottom-distance: 0px;
                    display: inline;
                    background-image: linear-gradient(
                        var(--border-color),
                        var(--border-color)
                    );
                    background-size: 100% var(--border-width);
                    background-repeat: no-repeat;
                    background-position-y: bottom;
                    transition: background-size 0.3s;
                    color: $colorWhite;

                    &:hover {
                        background-size: 0 var(--border-width);
                    }

                }
            }

        }
        .logo-movil{
            display: none;
            .logo{
                height: 2.6rem;
                img{
                    height: 100%;
                }
            }
        }
        .wrap-items{
            justify-content: space-between;
        }

        .ham  {
            position: relative;
            cursor: pointer;
            margin-right: 0;

            &+.hide-menu+.logo {
                height: unset;
            }

        }

        .logo {
            img {
                height: 100%;
                object-fit: contain;
            }
        }
    //***************************************************************************************************//
    // MENU HORIZONTAL
        .menu.menu-horizontal {
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            margin: 0 auto;
            transition: padding $t-fast ease-in;
            font-family: 'Montserrat-Regular';
            display: flex;
            padding-left: 0;

            & > .item {
                line-height: normal;
                font-weight: normal;
                font-size: 1.9rem;
                padding-left: 2.3rem;
                padding-right: 2.3rem;

                &.logo-item{
                    a:hover{
                        &:before{
                        content: none;
                        }
                    }
                    .logo{
                        height: calc(2.7rem * 1.2);
                        //margin-top: .4rem;

                        img{
                            height: 100%;
                        }
                    }
                    h1{
                        font-size: 0;
                        height: 100%;
                    }
                }

                a {
                    position: relative;
                    color: $colorWhite;
                    text-transform: none;
                    text-decoration: none;
                }
                h1{
                    font-size: 1.7rem;
                    margin-bottom: 0;
                    font-family: inherit;
                }
                &.is-active {
                    font-family: 'Montserrat-SemiBold';
                    border: 1px solid #fff;
                    //padding: 0.5rem 1.3rem;

                    a{
                        text-decoration: none;
                        color: $colorWhite;

                        &:before{
                            background-color: $colorWhite;
                        }
                    }
                    a:hover:before{
                        transform: scaleX(0);
                    }
                    &.logo-item{
                        border: none;
                        //padding: 0;
                        //padding-left: 1.3rem;
                        //padding-right: 1.3rem;
                    }
                }
                &.no-active, &.regular{
                    opacity: .5;
                    &:hover, &:active{
                        opacity: 1;
                    }
                }


                a:before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    background-color: $colorWhite;
                    transform: scaleX(0);
                    right: 0;
                    bottom: -6px;
                    left: 0;
                    transition: transform $t-fast ease-in-out;
                }

                a:hover:before {
                    transform: scaleX(1);
                }
            }

        }

        .btn-search  {
            width: 2.7rem;
            width: 2.7rem;
            margin-left: 2rem;
            cursor: pointer;
            position: relative;
            height: 4.7rem;

            .searchImgHover{
                opacity: 0;
                transition: .2s opacity ease-in-out;
            }

            &::before{
                content: '';
                background-color: transparent;
                position: absolute;
                width: 4.6rem;
                height: 4.6rem;
                border-radius: 50%;
                z-index: 1;
                top: 50%;
                left: -1rem;
                transform: translateY(-50%);

            }
            &:hover{
                &::before{
                    background-color: #fff;
                    transition: .2s background-color ease-in-out;
                }
                .searchImgHover{
                        opacity: 1;
                        transition: .2s opacity ease-in-out;
                        z-index: 2;
                }
                .searchImg{
                    opacity: 0;
                    transition: .2s opacity ease-in-out;
                }
            }

            img{
                @include object_fit(contain);
                left: 0;
                width: 2.7rem;
                height: 2.7rem;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
            }

            @media (max-width: #{$screen-md-max}){
                margin-left: 0;
            }

            @media (min-width: #{$screen-lg-min}){
                margin-left: 0;
            }
        }
        .close.noSeeDesk{
            display: none;
        }
    // END MENU HORIZONTAL


    //***************************************************************************************************//
    // MENU VERTICAL
        .menu-vertical {
            width: 37.4rem;
            height: 100vh;
            position: absolute;
            padding-top: 13.2rem;
            z-index: 2;
            top: -3.7rem;
            left: 0;
            background-color: $colorWhite;
            transform: translateX(-37.4rem);
            transition: transform .3s linear;
            display: flex;
            flex-direction: column;
            padding-bottom: 0;

            #languages{
                margin-bottom: 0;
                padding: 2.3rem 5rem;
                margin: 0;

                .lang-current.lang-item{
                    border-bottom: 3px solid $colorCorporative;
                    margin-right: 1.5rem;
                    padding: 0 1px 1px 2px;
                }

                #select-idioma2 {
                    .lang-item:not(:last-of-type){
                        margin-right: 1.5rem
                    }

                }
            }


            .wrap-redes{
                width: 100%;
                background-color: $colorRoseLight;
                padding: 3.1rem 5rem;
                padding-bottom: calc(3.1rem - 1.8rem);

                .redes{
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                }
                .circl{
                    position: relative;
                    width: 15%;

                    &::after{
                        content: '';
                        position: absolute;
                        width: .5rem;
                        height: .5rem;
                        border-radius: 50%;
                        background-color: $colorCorporative;
                        top: 1rem;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .red-item{
                    position: relative;
                    @include font_mont(Medium, 1.3);
                    margin-bottom: 1.8rem;
                    padding-right: 0;
                    text-align: center;

                    &:nth-child(3n){
                        margin-right: 0;
                    }
                }
            }
            .menu-vertical-box{
                width: calc(100% - 1.5rem);
                height: 100%;
                position: relative;
                overflow-y: auto;
                display: flex;
                flex-direction: column;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
                &::-webkit-scrollbar:vertical {
                    width:5px;
                }
                &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
                    display: none;
                }
                &::-webkit-scrollbar:horizontal {
                    padding-right: .5rem;
                    height: 8px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $colorCorporative;
                    border-radius: 0 !important;
                    border: none;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba($colorCorporative, 0.5);;
                    border-radius: 0 !important;
                }
            }

            & .menu-vertical-box > ul {
                list-style: none;
                margin-left: 0;
                padding-left: 5rem;
                padding-right: 4.7rem;
                padding-top: .5rem;

                .item a,
                .item p {
                    display: block;
                    color: $colorCorporative;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    padding-left: 1.5rem;
                    padding-right: 0;
                    background-color: transparent;
                    //border-bottom: 1px solid $line-gray;
                    border-bottom: 1px solid rgba(199, 0, 135, 0.5);
                    font-size: $f-size-menu-vertical;
                    font-family: 'Montserrat-Regular';
                    transition: background-color $t-fast ease-in-out;
                    text-transform: none;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    transition: .3s font-family ease;

                    &:hover {
                        background-color: transparent;
                        text-decoration: none;
                        font-family: 'Montserrat-SemiBold';
                        transition: .3s font-family ease;
                    }
                }

                .item.is-open{
                    background-color: transparent;
                    border-bottom: none;
                    &>a{
                        border-bottom: none;
                    }
                    a{
                        color: $colorWhite;
                        border-bottom: none;
                        li a:last-of-type{
                            border-bottom: 1px solid rgba($colorWhite, 0.5);
                        }
                    }
                }
                .item.top-separator{
                    border-top: 1px solid transparent;
                }

            li.item > ul {
                    list-style: none;
                    padding: 0;
                    background-color: transparent;

                    > li.item a{
                        color: $colorWhite;
                        padding-top: 1.2rem;
                        padding-bottom: 1.2rem;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        font-size: $f-size-menu-vertical;
                        font-family: 'Montserrat-Regular';
                        display: flex;
                        align-items: center;
                        text-transform: capitalize;
                        transition: .3s all;

                        &:hover {
                            text-decoration: none;
                            font-family: 'Montserrat-SemiBold';
                            transition: .3s all;

                            &:before
                            {
                                transform: translateX(0.5rem);
                            }
                        }
                    }
                }
            }
            .menu-categories-productos{
                margin-bottom: 3.4rem;
                &.mvl{
                    display: none;
                }
            }
            .menu-general{
                margin-top: 0;
                padding-left: 0;
                padding-right: 0;
                margin-left: 5rem;

                .item{
                    cursor: pointer;


                    &.is-active{
                        &>a, &>p{
                            font-family: 'Montserrat-SemiBold';
                        }
                    }
                    a, p{
                        font-size: 1.5rem;
                        font-family: 'Montserrat-Regular';
                    }
                    &.hasChild{
                        >p{
                            position: relative;
                            padding-left: 1.5rem;
                            display: block;
                            color: $colorCorporative;
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                            padding-right: 0;
                            background-color: transparent;
                            border-bottom: 1px solid rgba($colorCorporative, 0.5);
                            font-size: 1.3rem;
                            font-weight: normal;
                            transition: background-color 0.3s ease-in-out;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            transition: 0.3s font-weight ease;

                            &.isOpen{
                                font-family: 'Montserrat-SemiBold';
                                &:before{
                                    content: "-";
                                    top: 0;
                                    font-size: 1.9rem;
                                    font-family: inherit;
                                    height: 100%;
                                    color: inherit;
                                    position: absolute;
                                    left: 0;
                                    padding-right: 0;
                                    transform: translateX(0) rotate(0);
                                    transition: all 0.3s;
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                }
                            }
                        }
                        .isOpen +.sublist{
                            max-height: 9999px;
                            transition: 0.3s all;

                            >.item a{
                                border: none;
                            }

                        }
                        >p:before{
                            content: "+";
                            font-size: inherit;
                            font-family: inherit;
                            color: inherit;
                            position: absolute;
                            left: -1rem;
                            padding-right: 1.3rem;
                            transform: translateX(50%) rotate(360deg);
                            transition: 0.3s all;
                        }
                        >p +.sublist{
                            overflow: hidden;
                            max-height: 0;
                            transition: 0.3s all;
                            .item{
                                a{
                                    padding: 0.5rem 0 0.5rem 3rem;
                                    font-family: 'Montserrat-Regular';
                                    color: $colorCorporative;
                                    text-transform: none;
                                    border: none;
                                    transition: 0.3s font-family;

                                    &:hover{
                                        font-family: 'Montserrat-SemiBold';
                                        transition: 0.3s all;
                                    }
                                }
                            }
                        }

                    }
                }
                &:hover{
                    .item a,
                    .item p{
                        opacity: .6;
                        transition: .3s opacity ease;
                        &:hover{
                            opacity: 1;
                            transition: .3s opacity ease;
                        }
                    }
                }
            }

        }
    //EMD MENU VERTICAL

    //***************************************************************************************************//
    //MENU HANBURGUESA
        .ham {
            display: flex;
            align-items: center;
            height: 5rem;

            &::before{
                content: '';
                position: absolute;
                width: 4.9rem;
                height: 4.9rem;
                background-color: $colorCorporative;
                border-radius: 50%;
                transition: .3s background-color ease;
                transform: translateX(-50%);
                left: 50%;
            }

            &:hover{
                &::before{
                    background-color: $colorWhite;
                    transition: .3s background-color ease;
                }
                .item-ham,
                .item-ham:before,
                .item-ham:after{
                    background-color: $colorCorporative;
                }
            }

            div {
                position: relative;
            }

            div,
            div:before,
            div:after{
                height: 2px;
                background-color: $colorWhite;
                position: relative;
                width: 2.7rem;
                content: '';
                transition: all 0.3s ease-in-out;
            }

            div:before,
            div:after{
                position: absolute;
                width: 1.9rem;
                left: 0.4rem;
            }

            div:before{
                top: -9px;
            }
            div:after{
                bottom: -9px;
            }

            &:hover{
                div,
                div:before,
                div:after{
                    background-color: $colorWhite;
                }
            }
        }

        .hide-menu{
            position: absolute;
            left: 3.8rem;
            opacity: 0;
            z-index: -1;
            transition: 0.4s ease-in-out all;
            font-size: rem(9px);
            text-transform: uppercase;
            width: 5rem;
            line-height: 1.5;
            color: $gray-dark;
        }

        .if-open-hidden{
            opacity: 1;
            transition: 0.3s ease-in-out all;
            h1{
                font-size: inherit;
                font-family: inherit;
                font-weight: inherit;
                margin: 0;
            }
        }
        &.is-open{
            transform: translateX(0);
            .container-nav{
                z-index: 3;
                //position: relative;
            }
            .menu-vertical {
                transform: translateX(0);
                transition: transform .3s linear;
            }

            .ham{
                &:hover{
                    &::before{
                        background-color: $colorWhite;
                        transition: .3s background-color ease;
                    }

                    .item-ham:before,
                    .item-ham:after{
                        background-color: $colorCorporative;
                    }
                    .item-ham{
                        background-color: transparent;
                    }
                }
                div{
                    &:before{
                        transform: rotate(-45deg) ;
                    }
                    &:after{
                        transform: rotate(45deg) ;
                    }

                    &:before,
                    &:after{
                        width: 3.2rem;
                        transform-origin: center;
                        top: 0;
                        background-color: $colorWhite;
                        left: -.2rem;
                    }
                    &{
                        background-color: transparent;
                        //transition: background-color .2s linear;
                    }
                }
            }
            .hide-menu{
                opacity: 1;
                z-index: 1;
                transition: 0.4s 0.8s ease-in-out all;
                cursor: pointer;
            }

            .if-open-hidden{
                opacity: 0;
            }
            .full-level-black{
                visibility: unset;
                opacity: 1;
                z-index: 1;
                transition: .2s opacity ease;
            }
        }

    }
// END MENU HANBURGUESA


//***************************************************************************************************//
// TABLET

@media only screen and (max-width: $md-width){
	nav.topbar{
        height: 5.5rem;
		padding: 0 1.5rem;
		margin-top: 1.5rem;

        .close.noSeeDesk{

            width: 2.2rem;
            background-color: $colorCorporative;
            height: 5.5rem;
            position: absolute;
            opacity: 1;
            z-index: 2;

            .icon{
                background-color: $colorCorporative;
                width: 100%;
                height: 100%;
                z-index: 2;

                &:before{
                    content: '';
                    width: 2.2rem;
                    height: 2.2rem;
                    position: absolute;
                    background-image: url('/images/cancel-copy-white.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    top: 2rem;
                    left: unset;
                    right: 0;
                }
            }
        }
        .full-level-black{
            top: -1.5rem;
        }
		.container-nav{
			padding: 0 1.8rem;
            position: fixed;
            width: calc(100% - 3rem);
            z-index: 3;


            .wrap-items{
                width: 2.2rem;
                transition: .1s all ease;
                &.notVisible{
                    visibility: hidden;
                    transition: .1s all ease;
                }
            }
            .menu-burger{
                opacity: 1;
                transition: .2s 0.2s visibility ease, .2s .1s opacity ease;
                &.notVisible{
                    visibility: hidden;
                    opacity: 0;
                    transition: .1s all ease;
                }
            }

		}
		.menu.menu-horizontal {
			display: none;
		}
        .logo-movil{
            display: flex;
        }

        .btn-search{
            width: 2.3rem;
            min-width: 2.3rem;
            min-height: 2.3rem;
            margin: 0;

            &::before{
                content: none;
            }

            &:hover{
                &::before{
                    content: none;
                }
                .searchImgHover{
                        opacity: 0;
                }
                .searchImg{
                    opacity: 1;
                }
            }
            img{
                width: 100%;
                height: auto;
            }
        }
        .ham{
            &::before{
                content: none;
            }
            div{
                width: 2.7rem;
            }
            .item-ham{
                &:before,
                &:after{
                    background-color: #fff;
                }
            }
            &:hover{
                .item-ham{
                    background-color: #fff;
                    &:before,
                    &:after{
                        background-color: #fff;
                    }
                }
            }
        }
        &.is-open{
            .menu-vertical {
                opacity: 1;
                transition: opacity .1s, transform 0.3s linear;
            }
            .ham{
                &::before{
                    content: none;
                }
                div{
                    &:before{
                        width: 2.5rem;
                        left: -.5rem;
                    }
                    &:after{
                        width: 2.5rem;
                        left: -.5rem;
                    }

                }
                &:hover{
                    .item-ham{
                        &:before,
                        &:after{
                            background-color: #fff;
                        }
                    }
                }

            }

            .btn-search {
                width: 2.3rem;
                height: 2.3rem;
                background-color: $colorCorporative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 10;
            }

           .container-nav{
             position: fixed;
           }

        }
        .menu-vertical {
            background-color: $colorCorporative;
            //transform: translateX(-100vw);
            transform: translateX(calc(-100% - 3rem));
            opacity: 0;
            transition: .3s opacity .1s, transform 0.3s linear;
            position: absolute;
            margin: 1.5rem;
            width: calc(100% - 3rem);
            top: -1.5rem;
            padding-top: 5.5rem;
            height: calc(100vh - 10rem);
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: 1.5rem;
            padding-bottom: 0;


            .wrap-redes{
                width: calc(100% + $paddingLateralXs);
                padding: 1.7rem 3.5rem;
                padding-bottom: 0.7rem;


                .redes{
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    max-width: 27rem;

                    .red-item{
                        margin-bottom: 1rem;
                        padding-right: 0;

                        &:nth-child(3n){
                            margin-right: 0;
                        }
                    }
                }
            }

            .menu-vertical-box{
                width: 100%;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
                &::-webkit-scrollbar:vertical {
                    width:5px;
                }
                &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
                    display: none;
                }
                &::-webkit-scrollbar:horizontal {
                    padding-right: .5rem;
                    height: 8px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $colorWhite;
                    border-radius: 0 !important;
                    border: none;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba($colorWhite, 0.5);;
                    border-radius: 0 !important;
                }
            }
            .menu-vertical-box > ul{
                padding-right: 3.5rem;
                padding-left: 3.5rem;
                .item a,
                .item p{
                    color: $colorWhite;
                    border-bottom: 1px solid rgba($colorWhite, 0.5) !important;
                }
                .item.is-open{
                    background-color: darken($gray, 20%);
                    background-color: transparent;
                    border-bottom: none;
                    &>a{
                        border-bottom: none;
                    }
                    a{
                        color: $colorWhite;
                        border-bottom: none;
                        li a:last-of-type{
                            border-bottom: 1px solid rgba($colorWhite, 0.5);
                        }
                    }
                }
                .item.top-separator{
                    border-top: 1px solid transparent;
                }

               li.item > ul {
                    list-style: none;
                    padding: 0;
                    background-color: transparent;

                    > li.item a{
                        color: $colorWhite;
                        padding-top: 1.2rem;
                        padding-bottom: 1.2rem;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        font-size: $f-size-menu-vertical;
                        font-family: 'Montserrat-Regular';
                        display: flex;
                        align-items: center;

                        text-transform: capitalize;

                        &:before  {
                            content: none;
                        }
                    }
                }
                &.menu-general{
                    //margin-top: 3rem;
                    margin-top: 0;

                    .item a,
                    .item p{
                        color: $colorWhite !important;
                        font-size: 1.5rem  !important;
                        font-family: 'Montserrat-Regular'  !important;
                        padding-bottom: 1rem !important;
                        padding-top: 1rem !important;
                    }
                    .item{
                        .hasChild{
                            >p{
                                border-bottom: 1px solid rgba($colorWhite, 0.5);
                            }
                        }
                        &.is-active a{
                            font-family: 'Montserrat-Bold' !important;
                        }
                    }
                }

                .menu-categories-productos.mvl{
                    .item.hasChild.isOpen{
                        .itemName{
                            border-bottom: none !important;
                        }
                    }
                }
            }
            .menu-categories-productos{
                margin-bottom: 0;
                &.desk{
                    display: none;
                }
                &.mvl{
                    display: block;
                    margin-bottom: 3rem;

                    .itemName{
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    .itemName{
                        color: $colorWhite;
                        font-size: 1.5rem;
                    }
                    .sub-menu {
                        .itemName{
                            line-height: normal;
                            color: $colorWhite;
                            padding-left: calc(2.6rem + 1.1rem);
                            @include font_mont(medium, 1.3);
                            position: relative;

                            &:before{
                                content: '';
                                width: 4px;
                                height: 4px;
                                background-color: white;
                                position: absolute;
                                left: 25px;
                                top: 0.65rem;
                                border-radius: 50%;
                            }
                        }
                        .sub-item{
                            padding: 1rem 0;
                        }
                        .sub-sub-menu {
                            .sub-sub-item {
                                padding: 1rem 0;
                            }
                            .itemName{
                                line-height: normal;
                                padding-left:  calc(2.6rem + 1.1rem + 1.1rem);
                                font-family: 'Montserrat-Regular';

                                &:before{
                                    content: none;
                                }
                            }
                        }
                    }
                    .item{
                        >.itemName{
                            line-height: normal;
                            color: $colorWhite;
                            @include font_mont(Regular, 1.5);
                            padding: 1rem 0;
                            padding-left: 1.5rem;
                        }

                    }

                    .item.hasChild{
                        .itemName{
                            position: relative;
                            padding-left: 2.6rem;

                            &::before{
                                content: "+";
                                font-size: inherit;
                                font-family: inherit;
                                color: inherit;
                                position: absolute;
                                left: 0;
                                padding-right: .5rem;
                                top: 50%;
                                transform: translateX(50%) translateY(-50%) rotate(360deg);
                                transition: all .3s;
                                font-size: 2rem;
                            }
                        }

                        &.isOpen{
                            border-bottom: none !important;
                            &.visible-xs .itemName{
                                border-bottom: none !important;
                            }
                            .itemName{
                                position: relative;

                                &::before{
                                    content: "-";
                                    font-size: inherit;
                                    font-family: inherit;
                                    color: inherit;
                                    position: absolute;
                                    left: 0;
                                    padding-right: 1.3rem;
                                    transform: translateX(50%) translateY(-50%) rotate(0);
                                    transition: all .3s;
                                    font-size: 2rem;
                                    top: 50%;
                                }

                            }
                            & +.sub-menu{
                                max-height: 9999px;
                                transition: .3s all;
                                border-bottom: 1px solid rgba($colorWhite, 0.5);
                                //padding-bottom: 1.8rem;
                                padding-bottom: 0;


                            }

                        }
                        & +.sub-menu{
                            overflow: hidden;
                            max-height: 0;
                            transition: .3s all;
                        }
                    }
                }
            }
            .menu-general{
                margin-top: 0;
                padding-left: 3.5rem;
                padding-right: 3.5rem;
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 0rem;

                .item{
                    a{
                        text-transform: uppercase;
                        font-size: 1.5rem;
                    }
                }
                .item.hasChild p{
                    color: $colorWhite;
                }
                .item.hasChild{
                    .isOpen.sublist{
                        >.item{
                            >a, >p{
                                color: $colorWhite !important;
                                font-family: inherit !important;
                                padding: inherit;
                                padding-left: 1.5rem;
                                font-size: inherit;
                                font-family: inherit;
                            }
                        }
                    }
                }
            }
            #languages.languages{
                margin-top: 0;
                position: relative;
                margin-bottom: 0;
                bottom: 0;
                width: 100%;
                padding-left: 3.5rem;
                padding-bottom: 2.7rem;

                #select-idioma2{
                    .lang-item:not(:last-of-type){
                        margin-right: 1.6rem;
                    }
                }
                .lang-current.lang-item{
                    border-bottom: 3px solid $colorWhite;
                    padding: 0 1px;
                    a, .a{
                        @include font_mont(Bold, 1.3);
                    }
                }
                .lang-item{
                    margin-right: 1.9rem;
                    opacity: 1;
                    transition: .3s opacity ease;
                    &:last-of-type{
                        margin-right: 0;
                    }
                    a, .a{
                        @include font_mont(Regular, 1.3);
                        text-transform: uppercase;
                        letter-spacing: 3.42px;
                        color: $colorWhite;
                    }
                }

            }
        }
	}
}




    //MENU languages
  .languages{
        width: 100%;
        justify-content: flex-start;
        display: flex;
        padding-left: 5rem;
        padding-right: 4.7rem;
        padding-bottom: 2rem;
        padding-top: 2.3rem;
        position: relative;
        margin-bottom: 0;

        #select-idioma2{
            &:hover{
                .lang-item{
                    opacity: .6;
                    transition: .3s opacity ease;
                    &:hover{
                        opacity: 1;
                        transition: .3s all ease;
                        a{
                            color: $colorCorporative;
                            transition: .2s color ease;
                        }
                    }
                }
            }
        }
        .lang-current.lang-item{
            cursor: auto;
            pointer-events: none;
            margin-right: 1.6rem;
            a{
                @include font_mont(Bold, 1.3);
            }
        }
        .lang-item{
            margin-right: 1.6rem;
            opacity: 1;
            transition: .3s opacity ease;

            &:last-of-type{
                margin-right: 0;
            }
            a, .a{
                @include font_mont(Medium, 1.3);
                text-transform: uppercase;
                letter-spacing: 3.42px;
                color: $colorBlack;
                transition: .2s color ease;
            }
        }


    }
// END MENU languages
}

//max 991px
@media (max-width: 991px){

    .header_page nav.topbar .menu-vertical .wrap-sity-selectes{
        .store-user{
            border-top: 1px solid #FFF;
            border-bottom: 1px solid #FFF;
            //padding-top: 0.5rem;
            //padding-bottom: 0.5rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-bottom: 2rem;

            .store-user-name{
                padding-left: 1.5rem;
                color: #FFF;
                font-size: 1.5rem;
            }
            &.isSelectedStore{
                border-top: 1px solid #FFF;
                border-bottom: 1px solid #FFF;
                //padding-top: 0.5rem;
                //padding-bottom: 0.5rem;


                .store-user-name{
                    padding-left: 1.5rem;
                    color: #FFF;
                    font-size: 1.5rem;
                }
            }
        }
        .store-user.isSelectedStore +.wrap-sity-selectes-content{
            display: none !important;
        }
    }
}
