.form.form-newsletter{
    position: fixed;
    display: flex;
    bottom: 9rem;
    left: 7.5rem;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    width: 70%;
    height: auto;
    min-width: 15rem;
    max-width: 81rem;

    box-shadow: 1px 1px 20px 1px rgba(#333, 0.3);

    .err{
        display: none;
    }


    &.isOpen{
        visibility: visible;
        z-index: 103;
        opacity: 1;
    }
    .box-img{
        width: 35%;
        background-image: url('/img/img_11.png');
        //height: 22.5rem;
        min-height: 22.5rem;
        height: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba($colorCorporative, .1);
            
        }
    }
    .box-content{
        width: 65%;
        background-color: $colorCorporative;
        display: flex;
        padding: 2.8rem 1.8rem 2.8rem 3.5rem;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        flex-wrap: wrap;

        .title{
            @include font_mont(SemiBold, 2.5);
            color: $colorWhite;
            position: relative;
            align-self: flex-start;
            margin: 0 auto;
            margin-left: 0;
            padding-right: 3rem;
            font-size: 2.1rem;
            width: 100%;

            &.underline::before{
                content: '';
                position: absolute;
                width: 2rem;
                height: 3px;
                background-color: $colorWhite;
                bottom: -6px;
                left: 0;
            }
        
        }
        .wrap-input{
            width: 100%;
            margin-top: 1.5rem;
        }

        .input-box{
            width: 100%;
            height: fit-content;
            margin-right: 0.7rem;

            input{
                border: 1px solid #fff;
                color: $colorWhite;
                &:focus{
                    border: 1px solid #fff;
                }
                & +.label{
                    color: $colorWhite;
                    left: 1rem;
                    font-size: 1.9rem;
                    background-color: $colorCorporative;
                }
            }

            &.error{
                .err{
                    display: block;
                    background-color: $colorWhite;
                    color: #ff0000;
                    margin-top: .5rem;
                    padding: .5rem;
                }
            }

            
        }
        .wrap-btnDefault{
            border-color: $colorWhite;
            .btnDefault{
                background-color: $colorWhite;
                color: $colorCorporative;
            }
        }
        .well{
            @include font_mont(Medium, 1.5);
            line-height: 2.3rem;
            color: $colorWhite;
        }
    }
    .close{
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 1.8rem;
        right: 1.8rem;
        background-image: url('/images/fl-close-newsletter.svg');
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
        transform: scale(1);
        transition: .2s all ease;

        &:hover{
            transform: scale(1.1);
            transition: .2s all ease;
        }
    }
    .privacy.policy{
        width: 100%;
        margin-bottom: 0;

        .labelContainer{
            padding-top: 1.5rem;
            position: relative;

            .textContainer{
                padding-left: 1.3rem;
                color: $colorWhite;

                .link{
                    color: $colorWhite;
                }
            }
            &:before{
                width: 1.5rem;
                height: 1.5rem;
                border: 1px solid $colorWhite;
            }
            &:after{
                min-width: 0.9rem;
                height: 0.5rem;
                top: 2.4rem;
                border-color: $colorWhite;
            }
        }
       
        &.error{
            input+.labelContainer:before {
                border: 0.2rem solid $colorWhite !important;
            }
            .err{
                display: block;
                background-color: $colorWhite;
                color: #ff0000;
                margin-top: .5rem;
                padding: .5rem;
            }
        }
    }
    .wrap-btnDefault {
        top: 0.15rem;
        align-self: flex-start;
    }
}


@media (max-width: $screen-sm-max){
       
    .form.form-newsletter{
        width: calc(100% - 6.4rem);
        max-width: 100%;
        flex-direction: column;
        left: 50%;
        top: 50%;
        bottom: unset;
        transform: translateY(-50%) translateX(-50%);
        max-width: 31rem;
    
        .box-content .input-box .label{
            font-size: 1.5rem !important;
        }
        .close{
            right: 1.3rem;
            top: 1.3rem;
        }

        .box-img{
            order: 2;
            width: 100%;
        }
        .box-content{
            width: 100%;
            order: 1;
            padding: 2rem;

            .title{
                font-size: 1.9rem;
                padding-right: 2rem;
            }
            .wrap-input{
                margin-top: 2.8rem;
                &>.d-flex{
                    flex-direction: column;
                }
            }
            .wrap-btnDefault{
                margin-top: 1.5rem;
                width: 100%;

                .btnDefault{
                    width: 100%;
                    padding: calc((3.5rem - 1.7rem) / 2);
                }
            }
            .well{
                font-size: 1.3rem;
                line-height: 2.1rem;
            }
        }

        .privacy.policy{
            .labelContainer{
                position: relative;
                padding-top: 0;

                &:after{
                    top: 1.2rem;
                }
            }
        }
    }
}