.new-blog{
    &.page-blog-autor-famaliving2023{
        .authors-container{
            transform: none;
        }

        .blog-author{
            width: 100%;
            //grid-column: 1/3;
            margin-bottom: 5rem;

            .box-content{
                display: flex;
            }
            .photo-author{
                width: 25%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: top;
                }
            }
            .author-content-box{
                width: 75%;
                //width: 60%;
                //padding-right: 4rem;

                &.imgTrue{
                    //margin-left: 3rem;
                    padding-left: 3rem;
                }
            }
            .title{

            }
            .company{
                @include font_mont(Italic, 1.3);
                color: $colorCorporative;
                margin-bottom: 1rem;
            }
            .subtitle{
                @include font_mont(SemiBold, 1.3);
                color: $colorBlack;
                margin-bottom: 1rem;
            }
            .text{
                margin-top: 2rem;
            }
            .title-last-blog-box{
                @include font_mont(SemiBold, 1.3);
                color: $colorCorporative;
                margin-top: 2rem;
            }
        }

        .blog-entry-container .blog-entry:nth-child(even){
            transform: none;
        }
    }
}

@media (max-width: $md-width) {
    .new-blog{
        &.page-blog-autor-famaliving2023{
    
            .blog-author{
                margin-bottom: 0;
    
                .box-content{
                    flex-direction: column;
                }
                .photo-author{
                    width: 100%;
                    height: fit-content;
                    padding-bottom: 2rem;
                }
                .author-content-box{
                    width: 100%;
                    padding-left: 0;
    
                    &.imgTrue{
                        padding-left: 0;
                    }
                }
                .title{
    
                }
                .company{
                    @include font_mont(Italic, 1.3);
                    color: $colorCorporative;
                    margin-bottom: 1rem;
                }
                .subtitle{
                    @include font_mont(SemiBold, 1.3);
                    color: $colorBlack;
                    margin-bottom: 1rem;
                }
                .text{
                    margin-top: 2rem;
                }
                .title-last-blog-box{
                    @include font_mont(SemiBold, 1.3);
                    color: $colorCorporative;
                }
            }
    
            .blog-entry-container .blog-entry:nth-child(even){
                transform: none;
            }
        }
    }
}