.section-instagram{
    .wrap-container{
        padding: 0 $paddingLateral;
        margin-bottom: 4rem;
    }
    .hash-title{
        position: relative;
        left: 1.5rem;
    }
    .wrap-post-instagram{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 2.3rem;

        .item{
            aspect-ratio: 1;
            overflow: hidden;
            position: relative;

            .img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.item-1{
                grid-area: 1 / 1 / 3 / 3;
            }
            &.item-8{
                grid-area: 2 /5 / 7 / 7;
            }
            a{
                width: 100%;
                height: 100%;
            }
            .content-post{
                width: 100%;
                height: 100%;

            }
            .wrap-content{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                color: #fff;

                .instaVideo{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                .instagram-media{                       
                    color: #fff;                          
                }

                .img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    filter: blur(0);                    
                    transition: .3s .2s filter ease;
                }
                .instagram-info{                        
                    width: 100%;
                    height: auto;
                    top: 50%;
                    left: 0;
                    position: absolute;
                    color: #fff;
                    padding: 3.1rem;                       
                    z-index: 2;
                    opacity: 0;
                    transform: translateY(-50%);
                    transition: .2s opacity ease;

                    .text{
                        color: #fff;
                        text-align: center;                          
                        font-size: 1.5rem;
                        a{
                            color: #fff;
                        }
                    }
                   
                }
                &:before{
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba($colorCorporative, 0.4);
                    opacity: 0;
                    z-index: 1;
                    transition: .3s .2s opacity ease;
                }
                &.esVideo:after{
                    content: "";
                    width: 10%;
                    height: 10%;
                    max-width: 2.5rem;
                    max-height: 2.5rem;
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    background-image: url('/images/icon-videoInsta.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    opacity: 0;
                    z-index: 2;
                    transition: .3s .2s opacity ease;                        
                }                
            } 
            .instagram-info{
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: -1;
            }

            .instaVideo{
                width: 100%;
                height: auto;
                object-fit: cover;
            }
  
            &:hover{
                .wrap-content{
                    &:before, &.esVideo:after{
                        opacity: 1;
                        transition: .3s opacity ease;
                        z-index: 1;
                    }
                  
                    .instagram-info{
                        opacity: 1;
                        z-index: 1;
                        transition: .3s .2s opacity ease;
                    }
                    .img{
                        filter: blur(5px);                    
                        transition: .3s filter ease;
                    }
                }
            }
        }
    }

    .text.generic{
        text-align: center;
        width: 78%;
        margin: 0 auto;
        line-height: 2.5rem;
        margin-bottom: 3rem;

        b{
            font-family: 'Montserrat-Bold' !important;
        }
    }
    .subtitle{
        @include font_mont(Medium, 1.5);
        color: $colorCorporative;
        text-transform: uppercase;
        line-height: 2.5rem;
        letter-spacing: 1.5px;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 6.7rem;
        font-weight: normal;
    }
    .subtitle a b{
        color: $colorCorporative;
    }
    .box-content{
        width: 100%;
        .title{
            font-size: 5.5rem;
            width: fit-content;
            margin: 0 auto;
            margin-bottom: 3.9rem;

            &:before{
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    @media (max-width: $md-width) {   
        margin-bottom: 7.1rem;

        .hash-title{
            left: .5rem;
        }
        .wrap-container{
            padding: 0 $paddingLateralXs;
            margin-bottom: 0;
        }
        .wrap-post-instagram{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: .9rem;
    
            .item{
                aspect-ratio: 1;
                .img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
           
                &.item-1{
                    grid-area: unset;
                }
             
            }
        }
        .text.generic{
            width: 100%;
            margin: 0 auto;
            margin-bottom: 2rem;
        }
        .subtitle{
            margin-bottom: 3.6rem;
        }
        .box-content{
            width: 100%;
            .title{
                font-size: 2.9rem;
                margin-bottom: 3.1rem;
                line-height: 3.9rem;
                font-family: "Montserrat-SemiBold";
            }
        }
    }
}