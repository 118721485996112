
// FAMALIVING 2023
@font-face {
	font-family: 'Montserrat-Regular';
	src: url('../../fonts/Montserrat-Regular.ttf');
}
.regular{
	font-family: 'Montserrat-Regular';
}

@font-face {
	font-family: 'Montserrat-Italic';
	src: url('../../fonts/Montserrat-Italic.ttf');
}
.italic{
	font-family: 'Montserrat-Italic';
}

@font-face {
	font-family: 'Montserrat-Medium';
	src: url('../../fonts/Montserrat-Medium.ttf');
}
.medium{
	font-family: 'Montserrat-Medium';
}

@font-face {
	font-family: 'Montserrat-SemiBold';
	src: url('../../fonts/Montserrat-SemiBold.ttf');
}
.semiBold{
	font-family: 'Montserrat-SemiBold';
}

@font-face {
	font-family: 'Montserrat-Bold';
	src: url('../../fonts/Montserrat-Bold.ttf');
}
.bold{
	font-family: 'Montserrat-Bold';
}

@font-face {
	font-family: 'Montserrat-ExtraBold';
	src: url('../../fonts/Montserrat-ExtraBold.ttf');
}
.extraBold{
	font-family: 'Montserrat-ExtraBold';
}
@font-face {
	font-family: 'Lato-Regular';
	src: url('../../fonts/Lato-Regular.ttf');
}
.lato-regular{
	font-family: 'Lato-Regular';
}

@font-face {
	font-family: 'Lato-Bold';
	src: url('../../fonts/Lato-Bold.ttf');
}

.lato-bold{
	font-family: 'Lato-Bold';
}
@font-face {
	font-family: 'Lato-Black';
	src: url('../../fonts/Lato-Black.ttf');
}

.lato-bold{
	font-family: 'Lato-Black';
}
