
.pasadorPost{
    width: 100%;
    position: relative;
    background-color: transparent;    
    margin-right: 0.5rem;
    min-height: unset;      
    margin-bottom: 0;
    //height: 26.5rem;

    .swiper-wrapper{
        .swiper-slide{
            //padding-right: 1.5rem;             
            //width: auto !important;
            height: 100%;
            width: fit-content;

            .img-responsive{                     
                position: relative;
                top: 0;
                left: 0;                       
                object-fit: contain;
                max-height: 26.5rem;
                cursor: pointer;
            }             
        }
    }
   
       
}
.aux-slider{        
    //padding-right: .5rem;
    position: relative;

    .control {
        width: 4rem;
        height: 4rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        color: transparent;
        border: none;
        font-size: unset;
        opacity: .75;
        transition: .2s all ease-in;
        z-index: 1;
        &:hover{
            opacity: 1;
        }
        &.swiper-button-disabled{
            transition: .2s all ease-in;
            opacity: .3;
        }
    
        &.control-prev{              
            width: 4rem;
            height: 4rem;
            left: -1.5rem;
        
            &:before{
                content: "";
                background-image: url('/images/fl-right.svg');
                width: 100%;
                height: 100%;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: 0;
                left: 0;
                transform: rotate(180deg);
            }
        
        }
        &.control-next{            
            width: 4rem;
            height: 4rem;
            right: -1.5rem;
            
            &:before{
                content: "";
                background-image: url('/images/fl-right.svg');
                width: 100%;
                height: 100%;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: 0;
                left: 0;
            }
        }
    }
}



// PHOTO Full screen

.mygallerybox{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    opacity: 0;

    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;

    &.isOne{
        .swiper-button-prev,
        .swiper-button-next{
            display: none;
        }
    }
  
    &.preopen,
    &.open{
        z-index: 1040;	
    }

    &.open {
        opacity: 1;
    }

    & .swiper-container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    

    .slide-img{
      
        max-width: 90%;
        max-height: 90%;
        position: absolute;
        top: 0;
        left:0;
        right:0;
        bottom: 0;
        margin: auto;


        @media (max-width: $md-width) {
            max-width: 100%;
            max-height: 100%;
        }
      
       
    }

    .btn-close {    
        position: absolute;
        top: 5rem;
        right: 5rem;
        padding: 0;
        background-color: transparent;          
        -webkit-transition: 0.3s ease-in all;
        transition: 0.3s ease-in all;
        z-index: 1040;
        width: 3rem;
        height: 3rem;
        opacity: .75;
        &:hover{
            background-color: transparent;
        }

        &::before{
            content: "";
            position: absolute;
            background-image: url('/images/fl-close.svg');
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            //transform: rotate(45deg);
        }
        
        &:hover{
            opacity: 1;            
        }
    }

    .swiper-button-prev,
    .swiper-button-next {           
        width: 4rem;
        height: 4rem;          
    }
    .swiper-button-prev{
        left: 15px;          

        &:before{
            content: '';
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-image: url('/images/fl-right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(180deg);
        }
    }
    .swiper-button-next {
        right: 15px;

        &:before{
            content: '';
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-image: url('/images/fl-right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .swiper-button-next, 
    .swiper-container-rtl .swiper-button-prev,
    .swiper-button-prev, 
    .swiper-container-rtl .swiper-button-next  {
        background-image: none;
    }
}   

@media (max-width: $md-width) {
    .aux-slider{
        padding-right: 0;
        width: calc(100% + 3rem);          
        transform: translateX(-1.5rem);
        .pasadorPost{
            height: auto;
        }
        .pasadorPost .swiper-wrapper .swiper-slide{
            //padding-right: 0.8rem;
            padding-right: 0;
            

            &:last-of-type{
                .wrap-item{
                    margin-right: 1.5rem;
                }
            }
        }
        .swiper-wrapper{
            display: flex;
            align-items: center;

            .swiper-slide{    
                //width: 60% !important;
                                   
                .img-responsive{                       
                    height: 100%;
                    width: auto;
                    object-fit: contain;
                    //max-height: unset;   
                    max-height: 20rem;     
                }
            }
        }
    }
  
}