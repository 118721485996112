.that-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    width: 100%;
    justify-content: unset !important;
    width: 100% !important;

  }
  
  .that-col-1-24 {
    -ms-flex: 0 0 4.17%;
    -webkit-box-flex: 0;
            flex: 0 0 4.17%;
    max-width: 4.17%;
  }
  .that-col-2-24 {
    -ms-flex: 0 0 8.33%;
    -webkit-box-flex: 0;
            flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .that-col-3-24 {
    -ms-flex: 0 0 12.5%;
    -webkit-box-flex: 0;
            flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .that-col-4-24 {
    -ms-flex: 0 0 16.67%;
    -webkit-box-flex: 0;
            flex: 0 0 16.67%;
    max-width: 16.67%;
  }
  .that-col-5-24 {
    -ms-flex: 0 0 20.83%;
    -webkit-box-flex: 0;
            flex: 0 0 20.83%;
    max-width: 20.83%;
  }
  .that-col-6-24 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .that-col-7-24 {
    -ms-flex: 0 0 29.17%;
    -webkit-box-flex: 0;
            flex: 0 0 29.17%;
    max-width: 29.17%;
  }
  .that-col-8-24 {
    -ms-flex: 0 0 33.33%;
    -webkit-box-flex: 0;
            flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .that-col-9-24 {
    -ms-flex: 0 0 37.5%;
    -webkit-box-flex: 0;
            flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .that-col-10-24 {
    -ms-flex: 0 0 41.67%;
    -webkit-box-flex: 0;
            flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .that-col-11-24 {
    -ms-flex: 0 0 45.83%;
    -webkit-box-flex: 0;
            flex: 0 0 45.83%;
    max-width: 45.83%;
  }
  .that-col-12-24 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .that-col-13-24 {
    -ms-flex: 0 0 54.17%;
    -webkit-box-flex: 0;
            flex: 0 0 54.17%;
    max-width: 54.17%;
  }
  .that-col-14-24 {
    -ms-flex: 0 0 58.33%;
    -webkit-box-flex: 0;
            flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .that-col-15-24 {
    -ms-flex: 0 0 62.5%;
    -webkit-box-flex: 0;
            flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .that-col-16-24 {
    -ms-flex: 0 0 66.67%;
    -webkit-box-flex: 0;
            flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .that-col-17-24 {
    -ms-flex: 0 0 70.83%;
    -webkit-box-flex: 0;
            flex: 0 0 70.83%;
    max-width: 70.83%;
  }
  .that-col-18-24 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .that-col-19-24 {
    -ms-flex: 0 0 79.17%;
    -webkit-box-flex: 0;
            flex: 0 0 79.17%;
    max-width: 79.17%;
  }
  .that-col-20-24 {
    -ms-flex: 0 0 83.33%;
    -webkit-box-flex: 0;
            flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .that-col-21-24 {
    -ms-flex: 0 0 87.5%;
    -webkit-box-flex: 0;
            flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .that-col-22-24 {
    -ms-flex: 0 0 91.67%;
    -webkit-box-flex: 0;
            flex: 0 0 91.67%;
    max-width: 91.67%;
  }
  .that-col-23-24 {
    -ms-flex: 0 0 95.83%;
    -webkit-box-flex: 0;
            flex: 0 0 95.83%;
    max-width: 95.83%;
  }.that-col-24-24 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }

  @media (max-width: $sm-width) {
       .that-row>*{
                width: 100%;
                min-width: 100%;
                padding-left: 0 !important;
              
       }      
}

@media (max-width: $md-width) {
        .that-row.that-md-col{
                flex-direction: column !important;
        }
        .that-col-24-24-md{
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                        flex: 0 0 100%;
                max-width: 100%;
                width: 100% !important;
        } 
}

.pr-0-5{
    padding-right: calc(2.5rem / 2);    
}
.pl-0-5{
    padding-left: calc(2.5rem / 2);    
}

.m-b-2{
        margin-bottom: 2rem;
}
.m-b-3{
        margin-bottom: 3rem;
}