.container.our-services{
    margin-bottom: 10rem;
    max-width: unset;
    padding: 0;
    .aux-wrap{       
        padding-top: 11rem;
        padding-bottom: 9rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
    }
    
    .wrap-text{        
        white-space: nowrap;
        .text{
            color: #fff;
            font-size: 10.1rem;
            font-family: 'Montserrat-SemiBold';
            padding-bottom: 5.6rem;
            .light{
                font-family: 'Montserrat-Regular';                       
            }
        }     
    }

    .carousel-box{
        position: relative;    
         //width: 100%;
         //max-width: calc(996px + 100px);
         width:  calc(100% - ((100% - 1060px) / 2));
         padding-left: 3rem;
         //padding-right: 5rem;
         margin: 0 auto;
         overflow: hidden;
      

        .carousel-box-wrapper{          
            width: 100%;           
            margin: 0 auto;
            //max-width: 1280px; 

            .title{
                font-size: 4.5rem;
            }
        }    
    
        .carousel-wrapper{
            width: 100%;     
            overflow: hidden;
            width: calc(100% + 2rem);
            left: -2rem;
            overflow: hidden;
            position: relative;


            .carousel-container{
                padding-left: 2rem;
                display: flex;
                flex-wrap: nowrap;   

                .slick-list{
                    overflow: visible;
                    width: 100%;

                    .slick-slide{
                        padding-right: 2.2rem;
                        &:last-of-type{
                            padding-right: 0;
                        }
                    }
                }

                .carousel-item{            
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    //width: 33rem;
                  
                    .item{ 
                        position: relative;       
                        padding-top: 120%;        
                        margin-bottom: 2rem;     
                        margin-right: 0;   
                        overflow: hidden;      

                        .img{  
                            position: absolute; 
                            top: 0;
                            left: 0;                                    
                            width: 100%;
                            height: 100%;
                            object-fit: cover;         
                            transform: scale(1);
                            transition: 0.3s transform;                  
                        } 
                    }
                    .item-info{
                        color: $colorCorporative;
                        .title{
                            font-family: 'Montserrat-Bold';
                            font-size: 1.9rem;
                            line-height: 2.6rem;
                            margin-bottom: .7rem;
                        }
                        
                        .text{
                            color: $colorCorporative;
                            font-family: 'Montserrat-Regular';
                            font-size: 1.5rem;
                            line-height: 2.2rem;
                        }
                    }   
                    &:hover{
                        .item{
                            .img{
                                transform: scale(1.1);
                                transition: 0.3s transform;
                            }
                        }
                    }               
                }
                &>.slick-next{
                    opacity: 1;                    
                    width: 4rem;
                    height: 4rem;
                    //transform: translateY(-50%);
                    //top: 14.7rem;
                    top: 50%;
                    transform: translateY(calc((-100% - 4.6rem) / 2));
                    //right: calc((100vw - 100%) / -2 + 5rem);
                    //right: 0;
                    left: unset;
                    /* z-index: -11; */
                    right: 0.1rem;
                    z-index: 1;

                    &:before{
                        content: '';
                        background-image: url('/images/fl-right.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                    }        
                }
                &>.slick-prev{
                   // display: none !important;
                    opacity: 1;                   
                    width: 4rem;
                    height: 4rem;
                    //transform: translateY(-50%);
                    //top: 14.7rem;
                    top: 50%;
                    transform: translateY(calc((-100% - 4.6rem) / 2));
                    //left: calc((100vw - 100%) / -2 + 5rem);
                    //left: -2.5rem;
                    left: 0;
                    z-index: 1;
                    &:before{
                        content: '';
                        background-image: url('/images/fl-left.svg');
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        top: 0;
                        left: 0;
                    }        
                }
            }           
        }
    } 
}

@media (max-width: $sm-width) {
.container.our-services{
    padding-top: 0;
    margin-bottom: 6.3rem;

    .aux-wrap{
        padding-bottom: 10.2rem;
        padding-top: 5.1rem;
    }  
        .carousel-box{
            width: 100%;
            position: relative;        
            padding-left: 0;
            padding-right: 0;

            .box-content{
                padding-left: 1.5rem;

                .title{
                    font-size: 2.9rem;
                    font-family: 'Montserrat-SemiBold';
                    line-height: 3.9rem;
                }
            }

            .carousel-wrapper{
                width: 100%;      
                overflow: hidden;
                left: 0;

                .carousel-container{
                    display: flex;
                    flex-wrap: nowrap;   
                    padding-left: 2rem;         

                    .slick-list{
                        padding: 0 1.5rem !important;
                        //padding-left: 2.5rem !important;
                        padding-left: 0 !important;
                        padding-right: calc(((100% - 3rem) / 100) + 35%) !important;
                    }

                    .carousel-item{      
                        width: 26.2rem;
                        margin: 0;
                        margin-left: 0;
                        display: flex;
                        flex-direction: column;
                       
                        .item{                
                            margin-bottom: 2rem;  
                            padding-top: 110%;                     
                            .img{                                       
                                width: 100%;
                                height: 100%;
                                object-fit: cover;                             
                            } 
                        }                                        
                    
                        &:first-of-type{
                            margin-left: 0;
                        }
                        &:last-of-type{
                            //margin-right: 3rem;
                        }

                        .item-info{
                            .title{
                                font-size: 1.5rem;
                                line-height: normal;
                                margin-bottom: 0;
                            }
                        }
                        &:hover{
                            .item{
                                .img{
                                    transform: scale(1);
                                }
                            }
                        }       
                    }
                    &>.slick-next{                
                        //top: 12.5rem;
                        top: 50%;
                        //right: 1.5rem;    
                        //left: 1rem;        
                        width: 2.5rem;
                        height: 2.5rem;
                        right: 1rem;
                        left: unset;
                        display: none !important;
            
                    }
                    &>.slick-prev{                       
                        //top: 12.5rem;
                        //left: 1.5rem;             
                        width: 2.5rem;
                        height: 2.5rem; 
                        right: unset;
                        left: 1rem;
                        top: 50%;
                        display: none !important;
            
                    }
                }           
            }
        }
        .wrap-text{           
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            .text{
                font-size: 3.5rem;
                line-height: 6.3rem;
                padding-bottom: 3rem;                   
            }             
        }
    }
}

