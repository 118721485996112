.article-products{
    //padding: 0 5rem;
    margin-bottom: 10.7rem;

    &.default {
        .header-title {
            .title {
                width: 100%;
                margin-bottom: 0;
            }
        }

        .content {
            max-width: 1280px;
            width: 100%;
            margin: 0 auto;
        }
    }

    .header-title{
        width: 100%;
        .title{
            width: 56%;
            max-width: 40rem;
        }
    }

    .subtitle {
        line-height: 2.4rem;
        font-size: 1.5rem;
        color: $colorCorporative;
    }

    .content{
        max-width: $max-width-detail-store ;
        margin: 0 auto;
        width: 100%;
        position: relative;
        flex-wrap: wrap;
    }
    .part-right{
        width: 56%;
        order: 2;
    }
    .part-left{
        width: 44%;
        display: flex;
        flex-direction: column;

        padding-right: 4rem;
        order: 1;

        &>*, &>* p{
            margin-bottom: 3rem;
            padding-bottom: 0;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        .wrap-btnDefault{
            min-width: unset;
            width: fit-content;
            margin: 0 auto;
            margin-left: 0;
            margin-top: 3.5rem;

            .btnDefault{
                padding: 1.4rem 4rem;
                width: fit-content;
            }
        }
        .wrap-info{
            flex-grow: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            //max-width: 37rem;
            justify-content: space-between;

            .text.generic{
                p:first-of-type, 
                p:first-of-type *{
                    font-family: 'Montserrat-SemiBold' !important;
                }
            }
        }
    }
    .wrap-images{
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .item-img{
            overflow: hidden;
            border-radius: 0;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1);
                transition: .2s transform ease-in-out;
                object-position: top;
            }
            .wrap-item{
                position: relative;
                height: 100%;
                //cursor: pointer;

                /**
                &:hover{
                    img{
                        transform: scale(1.1);
                        transition: .2s transform ease-in-out;
                    }
                }*/
            }
            &.default{
                .wrap-item{
                    background-color: $colorRoseLight;
                    img{
                        height: auto;
                        width: 100%;
                        width: 15rem;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        left: 50%;
                        position: relative;
                        object-fit: contain;
                    }
                }
            }
        }
        .row-img{
            gap: 1.3rem;
            margin-bottom: 1.3rem;

            &:last-of-type{
                margin-bottom: 0;
            }
        }
        .row-img:nth-child(odd){
            width: 100%;
            display: flex;

            .item-img:nth-child(even){
                width: 30%;
                img{
                    aspect-ratio: unset;
                }
            }
            .item-img:nth-child(odd){
                width: 70%;
                .wrap-item{
                    aspect-ratio: 4/1.75;
                }
                img{
                    //aspect-ratio: 4/1.75;
                }
            }
        }
        .row-img:nth-child(even){
            width: 100%;
            display: flex;

            .item-img:nth-child(odd){
                width: 30%;
                img{
                    aspect-ratio: unset;
                }
            }
            .item-img:nth-child(even){
                width: 70%;
                .wrap-item{
                    aspect-ratio: 4/1.75;
                }
                img{
                    //aspect-ratio: 4/1.75;
                }
            }
        }
    }

    @media (max-width: $md-width) {

    }
}

@media (max-width: $md-width) {
    .article-products{
        margin-bottom: 7.7rem;

   
        .header-title .title{
            width: 75%;
        }
        .content{
            flex-direction: column;
        }
        .wrap-images{
            .row-img{
                gap: 0.9rem;
                margin-bottom: 0.9rem;
            }
        }

        .part-right{
            width: 100%;
        }
        .part-left{
            width: 100%;
            margin-bottom: 3.6rem;
            padding: 0;

            .wrap-btnDefault{
          
                .btnDefault{
                    padding: 1.4rem 5.3rem;
                    font-size: 1.5rem;
                }
            }
        }
    }
}
