.container.last-posts{
    padding: 0;
    max-width: unset;
    padding-bottom: 10.4rem;

    &>div{
        position: relative;
    }
    .wrap-container{
        padding-top: 0;
        padding-bottom: 0;
    }
    .box-wall{      
        position: absolute;  
        width: 100%;
        height: 65%;
        padding: 0;
        top: 30%;
    }
   .box-content{
        max-width: 1280px;
        margin: 0 auto;
        text-align: center;
        width: fit-content;

        .title{
            font-size: 4.5rem;
            margin-bottom: 6.5rem;
            color: $grayDark2;

            &::before{
                left: 50%;
                margin: 0 auto;
                bottom: -1.3rem;
                transform: translateX(-50%);
            }
        }
   }
    .line-separation{
        .line{
            background-color: #fff;
        }
    }
    .section-subtitle{
        color: #fff;
        -webkit-text-stroke: unset;
    }
    .wrap-btnDefault{
        margin-top: 5rem;
    }
    .container-blog{
        width: 100%;
        position: relative;
        margin: 0 auto;
        z-index: 1;

        .box-container-blog{
            .autor{
                margin-top: .4rem;
                font-family: 'Montserrat-Italic';
                color: #575252;
            }

            .wrap-btnDefault{

                .btnDefault{
                    padding: 1.4rem 7rem;
                }
            }
            .content-blog{
                padding: 0 5rem;

                .wrap-items{
                    width: 100%;
                    max-width: 1280px;
                    margin: 0 auto;
                    padding: 0;
                    position: relative;
                    justify-content: center;
                    flex-wrap: nowrap;

                    .wrap-item{
                        .wrap-img{
                            padding-top: 64%;
                            position: relative;

                            .img{
                                width: 100%;
                                left: 0;
                                position: absolute;
                                top: 0;
                                object-fit: cover;
                                height: 100%;
                            }
                        }
                     
                    }
                    .blog-entry{
                        width: 100%;         
                        padding-right: 4rem;
                        background-color: #fff;
                        padding: 1.8rem;
            
                        .blog-content {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: flex-end;
                            display: flex;
                            flex-direction: column;
                            height: 100%;  
                            
                            .img {
                                display: block;                   
                                background-color: grey; // BGM del post
                            }
            
                            .block-text {
                                width: 100%;
                                background-color: $colorWhite;
                                padding: 1rem;
                                padding-bottom: 2rem;
                                position: relative;
                                flex-grow: 1;
                                border-top: 5px solid $colorCorporative;
            
                                .block-title {
                                    @include font_mont(SemiBold, 1.5);
                                    color: $grayDark2;
                                    margin-top: 1rem;
                                    font-weight: normal;
                                }
                    
                                .desc {
                                    margin-top: 1rem;
                                }
                    
                                p {
                                    font-size: 1.5rem;
                                }
                    
                                .author {
                                    font-size: 1.3rem;
                                    line-height: 2.1rem;
                                    color: $colorCorporative;
                                    font-style: oblique;
                                }
            
                                hr {
                                    width: 100%;
                                    border-color: rgba($colorCorporative, 0.5);
                                    margin-bottom: 0;
                                    border-width: .2rem;
                                }                  
                            }
                            .badge-blog{
                                text-align: right;
                            }
                            .info{
                                .date-info{
                                    @include font_mont(Italic, 1.3);
                                    color: $colorCorporative;
                                    line-height: 2.1rem;
                                }
                            }
                            .generic.text, 
                            .generic.text p, 
                            .generic, 
                            .generic.text{
                                @include font_mont(Regular, 1.5);
                                color: $colorBlack;
                                line-height: 2.5rem;
                                letter-spacing: 0 !important;
            
                                .author {
                                    color: #575252;
                                    font-style: oblique;
                                }
                                hr{
                                    display: block;
                                }
                                table, table tr{
                                    border: none;
                                    border-bottom: none;
                                }
                                td{
                                    background-image: none;
                                }
                            }              
            
                        }
                    
                        .aux-item{
                            width: 100%;
                        }
                        .aux-img{           
                            width: 100%;
                            position: relative;
                            overflow: hidden;
                            .wrap-img{
                                position: relative;
                                width: 100%;
                                padding-top: 63%;
                                .img {
                                    width: 100%;
                                    height: 100%;                       
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    object-fit: cover;
                                    box-shadow: none;
                                    transform: scale(1);
                                    transition: 0.3s transform;
                                }
                            }
                       
                        }
                        &.isSecond{
                            margin: 0 1.2rem;
                        }

                        &:hover{
                            .aux-img{
                                .wrap-img{
                                    .img{
                                        transform: scale(1.1);
                                        transition: 0.3s transform;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: $md-width) {
    .container.last-posts{
        padding-bottom: 9.5rem;
        padding-top: 0;

        .box-wall {
            height: 67%;
        }
        .scrollbar-home {
            position: relative;
            margin-top: 2.2rem;
        }

        .wrap-container{
            padding: 0 1.5rem;
        }

        .box-content .title {
            font-size: 2.9rem;
            margin-bottom: 0;
        }
        .container-blog{
            .box-container-blog{
                .content-blog{
                    .wrap-items{
                        .slick-list{
                            padding: 0 !important;
                        }
                       
                    }
                }
            }
        } 
        .section-title {
            font-size: 6rem;
            color: #fff;
        }
        .line-separation{
            .line{
                background-color: #fff;
            }
        }
        .section-subtitle{
            color: #fff;
            -webkit-text-stroke: unset;
        }
        .container-blog{
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            margin-top: 0;

            .box-container-blog{
                .wrap-btnDefault{
                    .btnDefault{
                        padding: 1.2rem 6rem;
                    }
                }
                .content-blog{
                    //width: calc(100% - 3rem);
                    width: 100%;
                    padding: 2.2rem 1.5rem;
                    padding-bottom: 0;

                    .wrap-items{
                        width: 100% !important;
                        flex-wrap: nowrap;
                        padding-top: 0;
                        overflow: hidden;

                        .slick-track{
                            height: 100%;
                        }
                        .slick-slide{
                            //display: flex;
                            //flex-direction: column;

                            &>div{
                                height: 100%;
                            }
                        }
                        .blog-entry{
                            height: 100%;

                            &.isSecond{
                                margin: 0;
                            }
                        }

                        .wrap-item{
                            width: 0;
                            padding-right: 0;
                            display: none;
                            &.isFirst{
                                display: flex;
                                width: 100%;
                            }
                        }

                    }
                }
            }
        }
    }
}
