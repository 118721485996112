.article-instagram{
    margin-bottom: 9rem;

    .wrap-btnDefault{
        min-width: unset;
        max-width: 26.6rem;
    }
    .wrap-content-info {
        width: 100%;

        &.izquierda {
            flex-direction: row-reverse;

            .part-right {
                padding-left: 0;
                padding-right: 4rem;
            }
        }
    }

    .header-title{
        width: 100%;
    }
    .subtitle{
        margin-bottom: 3.4rem;
    }
    .description{
        margin-bottom: 4.5rem;

        a,span{
            font-family: 'Montserrat-SemiBold' !important;
        }
    }
    .content {
        max-width: $max-width-detail-store ;
        margin: 0 auto;
        width: 100%;
        position: relative;
        flex-wrap: wrap;

        &.big {
            max-width: 1280px ;
        }
    }
    .part-left{
        width: 30%;

        .part-img{
            position: absolute;
            bottom: 0;
            width: 30%;

            .wrap-one-img{
                .wrap-img{
                    width: 100%;
                    left: 0;
                    position: relative;
                    margin-top: 14rem;
                    opacity: .4;

                    .img-responsive{
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
    .part-right{
        width: 70%;
        padding-left: 4rem;
        display: flex;
        flex-direction: column;

        &>*, &>* p{
            margin-bottom: 3rem;
            padding-bottom: 0;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        .wrap-btnDefault{
            min-width: unset;
            width: fit-content;
            margin: 0 auto;
            margin-left: 0;
            margin-top: 3.5rem;

            .btnDefault{
                padding: 1.4rem 4rem;
                width: fit-content;
            }
        }
        .wrap-info{
            flex-grow: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            max-width: 37rem;
            justify-content: space-between;
        }
    }
    .wrap-images{
        width: 100%;
        @include grid(2, 1.6rem);
        gap: 1.6rem;
        margin-bottom: 15% !important;

        img{
            aspect-ratio: 1/1;
        }

        .item-img{
            overflow: hidden;
            &:nth-child(odd){
                transform: translateY(30%);
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .wrap-item{
                position: relative;
                height: 100%;
            }
        }

    }

}

@media (max-width: $md-width) {
    .article-instagram{
        .wrap-content-info{
            flex-direction: column;
            &.izquierda {
                flex-direction: column;
                
                .part-right {
                    padding-left: 0;
                    padding-right: 0;
                }
              
                /**
                .part-left .part-img {
                    right: unset;
                    left: -2.5rem;
                }
                .wrap-btnDefault {
                    margin: 0 auto;
                    margin-right: 0;
                }
                .description{
                    width: 80%;
                    margin: 0 auto;
                    margin-bottom: 2.5rem;
                    margin-right: 0;
                }
                .header-title.box-content{
                    margin: 0 auto;
                    margin-right: 15%;
                }
                */
            }
        }


        .description{
            margin-bottom: 2.5rem;
        }
        .part-left{
            width: 100%;
            margin-bottom: 5.9rem;

            .part-img{
                top: -1.5rem;
                min-width: 14rem;
                right: -2.5rem;
                bottom: unset;

                .wrap-one-img{
                    .wrap-img{
                        margin-top: 0;
                    }
                }
            }
        }

        .part-right{
            width: 100%;
            padding-left: 0;

            .wrap-btnDefault{
                .btnDefault{
                    padding: 1.4rem 5.3rem;
                    width: fit-content;
                }
            }
        }
        .header-title.box-content{
            width: 65%;
            .title{
                width: 100%;
            }
        }
    }
}

