.wrap-btnDefault{
    min-width: 26.6rem;
    width: auto;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: column;  
    background-color: transparent;
    border: 1px solid $colorCorporative;  
    z-index: 1;

    .btnDefault{
        text-align: center;
        background-color: $colorCorporative;
        color: $colorWhite;
        padding: 1.4rem 4rem;
        position: relative;
        font-size: 1.9rem;
        font-family: 'Montserrat-Bold';
        letter-spacing: .3rem;
        line-height: 1.7rem;
        text-transform: uppercase;           
        transform: translateX(-.3rem) translateY(-.3rem);
        transition: .3s transform linear; 
        z-index: 2;
    
        &:active, &:hover{        
            transform: translateX(0) translateY(0);
            transition: .3s all linear;   
        } 
    } 

    @media (max-width: $sm-width) {
        .btnDefault{          
            padding: 1.4rem 3.5rem;         
            //font-size: 1.7rem;
            font-size: 1.5rem;
        } 
    }
    &.loading{
        border: 1px solid #DCDCDC;        
        .btnDefault{           
            color: #DCDCDC;
            background-color: #DCDCDC;
		    position: relative;
            &:before {
                content: '';
                position: absolute;
                left: calc(50% - 1.5rem);
                top: calc(50% - 1.5rem);
                width: 3rem;
                height: 3rem;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-image: url('/images/fl-loader-rose.svg');
                transform-origin: center;
                animation: loading 0.8s infinite;
            }
        }
    }

    &.btn-mailfastsuscription{
        min-width: unset;

        .btnDefault{
            font-size: 1.5rem;
            padding: 1.2rem 2.1rem;
            line-height: normal;
            white-space: nowrap;
        }
    }
    &.white{
        .btnDefault{
            text-align: center;
            background-color: $colorWhite;
            color: $colorCorporative;
            border: 1px solid $colorCorporative;
        }
    }
    &.d-none{
        display: none;
    }
   
}
.btn-close{

    font-size: unset;
    position: relative;
    top: 0;
    right: 0;
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;

    .icon{
        width: 2.2rem;
        height: 2.2rem;

        &:before{              
            content: '';
            width: 2.2rem;
            height: 2.2rem;                  
            position: absolute;
            background-image: url('/images/fl-close.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            top: 0%;
            left: 0;
        }
    }

    
    &.white{
        .icon{
            &:before{              
                content: '';
                width: 2.2rem;
                height: 2.2rem;                  
                position: absolute;
                background-image: url('/images/fl-close-white.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                top: 0%;
                left: 0;
            }
        }
    }
    &.black{
        .icon{
            &:before{     
                background-image: url('/images/fl-close-black.svg');
            }
        }
    }
}