.wrap-stores-near{
    display: flex;
    margin-bottom: 7rem;

    .content{
        max-width: 1380px;
        margin: 0 auto;
        width: 100%;
        flex-wrap: nowrap !important;
    }
   
    .header-part-content{
        //min-width: 22rem;
        min-width: 30rem;
        padding: 0;
        width: 20%;
        padding-right: 3rem;
    }
    .wrap-address-title, 
    .wrap-title{
        @include font_mont(SemiBold, 1.5);
        color: $colorCorporative;
        line-height: 2.3rem;
        margin-bottom: .5rem;
    }

    .wrap-address{
        @include font_mont(Regular, 1.5);
        color: $colorBlack;
        line-height: 2.3rem;
        position: relative;

        &[data-title]:hover:after {
            opacity: 1;
            transition: all 0.1s ease 0.5s;
            visibility: visible;
        }
        &[data-title]:after {
            content: attr(data-title);
            @include font_mont(Regular, 1.3);
            background-color: rgba($colorBlack, .6);
            color: #fff;
            line-height: 1.4;
            position: absolute;
            padding: 3px 20px;
            box-shadow: 1px 1px 3px #222222;
            opacity: 0;
            border: none;
            z-index: 99999;
            visibility: hidden;
            border-radius: 6px;
            top: 0;
            transform: translateY(-110%);
            left: 0;
            width: fit-content;
            height: auto;
            white-space: break-spaces;
            backdrop-filter: blur(5px);
        }
        
    }

    .wrap-stores-list{
      
        &.grid-2{
                @include grid(2, 2rem);
        }
        &.grid-3{
            @include grid(3, 2rem);
        }
        &.grid-4{
            @include grid(4, 2rem);
        }

        .store-item{
            
           .wrap-img{
            aspect-ratio: 3/2;
            margin-bottom: 2.3rem;
            overflow: hidden;

                &:hover{
                    .img-responsive{
                        transform: scale(1.1);
                        transition: .2s transform ease-in-out;
                    }
                }
                .img-responsive{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: .2s transform ease-in-out;
                }
            }
        }
    }
}

//*************************************************************************movil*************************************************************************************//
@media (max-width: $md-width) {
    .wrap-stores-near{
        .content{
            flex-direction: column;
        }
        .wrap-stores-list{
            //width: auto;
            display: flex;
            overflow: auto;
            padding-bottom: 2rem;
            width: calc(100% + ($paddingLateralXs * 2));
            position: relative;
            left: calc(-1 * $paddingLateralXs);

            .store-item{
                width: calc(85% - $paddingLateralXs);
                min-width: calc(85% - $paddingLateralXs);

                &:first-of-type{
                    margin-left: $paddingLateralXs;
                }

                &:last-of-type{
                    margin-right: $paddingLateralXs;
                }

                .wrap-img{
                    &:hover{
                        .img-responsive{
                            transform: scale(1);
                            transition: unset;
                        }
                    }

                }
            }

            &.grid-2{
                display: flex;
                grid-gap: 1.6rem;
            }
            &.grid-3{
                display: flex;
                grid-gap: 1.6rem;
            }
            &.grid-4{
                display: flex;
                grid-gap: 1.6rem;
            }
        }
    }
}