.content-cookies {
    bottom: 3.7rem;
    top: unset;
    left: unset;
    right: 5rem;
    transform: unset;

    .btn{
        font-size: inherit;
    }
}

    @media (max-width: $md-width) {
        .content-cookies {
            right: 50%;
            transform: translateX(50%);

            #lcc-modal-button-accept,
            #lcc-modal-button-settings{
                padding: 0 0.5rem;
            
            }
        }
        .layout-default-famaliving{
            .cookies-text-message{
                font-size: 1.4rem !important;
                line-height: 2.1rem;
            }
        }
    }



