//FAMALIVING NEW 2023

.modal-finder
 {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    overflow-y: auto;
    color: #324b4e;
    z-index: 100;
    width: 100%;

    .wrapp {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        margin: auto;
        padding-top: 15rem;
        height: 100%;
        padding-left: 5rem;
        padding-right: 5rem;

        .non-results{
            .wrap-NoResult{
                position: relative;
                width: 100%;
                max-width: 85rem;
                margin: 0 auto;
                margin-top: 11.8rem;
                font-size: 1.5rem;
                color: #575252;
                padding: 2.4rem 4.9rem;
                background-color: $colorRoseLight;

                strong{
                    @include font_mont(SemiBold, 1.5);
                }
            }
        }

        .aux-wrap{
            position: relative;
            width: 100%;
            max-width: 85rem;
            margin: 0 auto;
            margin-top: 5.5rem;

            .text-result{
                color: $colorBlack2;
                @include font_mont(Medium, 2.1);
                line-height: 3.1rem;
                margin-bottom: 1.1rem;
                margin-top: 4.8rem;

                .title{
                    @include font_mont(Medium, 2.1);
                    margin-bottom: 0;
                }

                .text-search{
                    @include font_mont(Regular, 2.1);
                    color: $colorCorporative;
                }

            }
        }
        .title{
            margin-bottom: 4.7rem;
            @include font_mont(SemiBold, 3.5);
            line-height: normal;
            letter-spacing: 0;
            position: relative;

            .span1{
                color: $colorCorporative;
            }
        }

        .form{
            .input-box{
                input{
                    margin-left: 0;
                    border: 1px solid #575252;
                    font-size: 1.4rem;

                    &+.label{
                        text-transform: none;
                        font-size: 1.5rem;
                        margin: 0;
                        font-family: 'Montserrat-Medium';
                        font-weight: normal;
                        background-color: #fff;
                        line-height: 1.5;
                    }
                }
            }
        }


        .results{
            padding-bottom: 16rem;
            .results-title {
                @include font_mont(Bold, 1.5);
                text-transform: uppercase;
            }
            .item {
                font-size: 25px;
                padding: 15px 0;
            }

            .item .image,
            .item img.image {
            width: 90px;
            height: 60px;
            -o-object-fit: cover;
            object-fit: cover;
            margin-right: 1.5rem;
        }

        .wrap-category{
                display: flex;
                flex-wrap: wrap;
                max-width: 1280px;
                width: calc(100% + 2rem);
                margin: 0 auto;
                margin-top: 8.4rem;

            .category{
                width: calc(100% - 2rem);
                height: 4.5rem;
                background-color: $colorGrayLight;
                text-transform: uppercase;
                padding-left: 2rem;

                    .title{
                        @include font_mont(Medium, 1.7);
                        margin-bottom: 0;
                        color: $grayDark2;
                        display:flex;
                        align-items: center;
                    }

                    .num{
                        @include font_mont(Medium, 1.7);
                        color: $grayDark2;
                        text-transform: lowercase;
                        display:flex;
                        align-items: center;
                    }
            }
            .item{
                display: block;
                width: calc(100% / 4);
                padding-top: 2.8rem;
                padding-right: 2rem;

                    .wrap-img{
                        width: 100%;
                        margin-bottom: 1.4rem;
                        .wrap-img-aux{
                            width: 100%;
                            padding-top: 64%;
                            position: relative;
                            overflow: hidden;
                            background-color: $colorRoseLight;
                            &::before{
                                content: '';
                                position: absolute;
                                background-color: transparent;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                transition: .3s all ease;

                            }
                            .image{
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transform: scale(1);
                                transition: .3s transform linear;
                            }
                        }
                    }

                    .item-name{
                        @include font_mont(Regular, 1.5);
                        color: $colorBlack2;
                        line-height: 2.3rem;
                        transition: .3s all ease;
                    }
                    &:hover{
                        .wrap-img-aux::before{
                            content: '';
                            position: absolute;
                            background-color: rgba($colorGrayLight, .6);
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            transition: .3s all ease;

                        }
                        .wrap-img .wrap-img-aux .image{
                            transform: scale(1.1);
                            transition: .3s transform linear;
                        }
                        .item-name{
                            font-family: 'Montserrat-Bold';
                            transition: .3s all ease;
                        }
                    }
            }
        }

        }
    }
}

@media (max-width: $md-width) {
    .modal-finder{
        background-color: $colorWhite;
        left: 1.5rem;
        top: 7rem;
        right: 1.5rem;
        bottom: 1.5rem;
        padding-right: 0;
        padding-bottom: 1.5rem;
        padding-top: 4rem;
        overflow-y: hidden;
        overflow-x: hidden;
        margin-top: .5rem;
        z-index: 104;
        width: calc(100% - 3rem);

        .aux-box{
            overflow-y: auto;
            width: 100%;
            height: 100%;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }
            &::-webkit-scrollbar:vertical {
                width:5px;
                display: none;
            }
            &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
                display: none;
            }
            &::-webkit-scrollbar:horizontal {
                padding-right: .5rem;
                height: 8px;
                display: none;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $colorCorporative;
                border-radius: 0 !important;
                border: none;
            }
            &::-webkit-scrollbar-track {
                background-color: rgba($colorCorporative, 0.5);;
                border-radius: 0 !important;
                display: none;
            }
        }
        .wrapp{
            background-color: $colorWhite;
            padding: 1.8rem;
            padding-top: 0;

            .aux-wrap{
                margin-top: 0;

                .wrap-search{
                    .title{
                        color: $grayDark2;
                        font-size: 2.1rem;
                        margin-bottom: 2.6rem;
                    }
                    .form{
                        display: block;
                        .input-box{
                            input{
                                border: 1px solid $colorBlack;
                                color: $colorBlack;
                                height: 4.5rem;
                            }
                            input +.label{
                                color: $colorBlack;
                                background-color: $colorWhite;
                            }
                        }
                    }
                }
                .text-result{
                    margin-top: 2.9rem;
                    margin-bottom: 3.3rem;
                    .title,
                    .text-search{
                        font-size: 1.3rem;
                        line-height: 2.1rem;
                    }
                }
            }

            .non-results{
                .wrap-NoResult{
                    margin-top: 0;
                    padding: 2.8rem 2.6rem;
                    text-align: initial;
                    font-size: 1.3rem;
                    line-height: 1.9rem;
                    strong{
                        font-weight: normal;
                        font-family: 'Montserrat-Bold';
                    }
                }
            }
            .close{
                display: none;
            }
            .results{
                padding-bottom: 5rem;

                .wrap-category{
                    margin-top: 1rem;
                    width: calc(100% + 1rem);

                    .category{
                        width: calc(100% - 1rem);
                        height: auto;
                        min-height: 3.7rem;
                        padding: 1rem;
                        flex-wrap: wrap;
                        .title, .num{
                            line-height: normal;
                            font-size: 1.5rem;
                        }

                    }
                    .item{
                        width: calc(100% / 2);
                        padding-right: 1rem;
                        // padding-top: 1.8rem;
                        padding-bottom: 0;

                        img.image{
                            margin-right: 0;
                        }
                        .item-name{
                            color: $colorBlack;
                            font-size: 1.5rem;
                            line-height: 1.9rem;
                        }
                    }
                }
            }
        }
    }
    .full-level-black-search{
        position: relative;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.5);
        &.isVisible{
            visibility: unset;
            opacity: 1;
            z-index: 1;
            transition: 0.2s opacity ease;
        }
    }

}
