
#contact_famaliving2023 {
    padding: 0 $paddingLateral;
    margin-bottom: 19rem;

    .special-contact {
        .part-down {
            .wrap-contact-info{
                .email{
                    display: none;
                }
            }
        }
    }
    &.home {
        margin-bottom: 10rem;

        .limited-wrap{
            padding-top: 0;
        }
    }
    &.default {
        .content-contact-wrap-home {
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
        }

        .special-contact {
            .part-down {
                .wrap-contact-info{
                    .email{
                        display: none;
                    }
                }
                .wrap-title {
                    .title {
                        width: 100%;
                        max-width: 40rem;
                    }
                }
            }
        }
    }

    &.shops {
        .special-contact {
            .part-down {
                .wrap-title {
                    .title {
                        @include font_mont(SemiBold, 3.5);

                        &.small-line {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }

                .wrap-contact-info {
                    @include font_mont(Regular, 1.7);

                    .phone, .email {
                        display: none;
                    }
                }
            }
        }
    }

    .limited-wrap {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 16.4rem;
    }

    .special-contact {
        padding: 0;

        .part-down {
            padding: 0;
            display: flex;
            justify-content: space-between;

            .wrap-title {
                width: fit-content;
                min-width: 23rem;
                margin-bottom: 6rem;

                .title {
                    padding-right: 1.5rem;
                    margin-bottom: 3.5rem;
                    width: 65%;
                    min-width: 26rem;
                    max-width: 30rem;
                    @include font_mont(SemiBold, 3.5);

                    &.large {
                        @include font_mont(SemiBold, 4.5);
                    }
                }

                img {
                    height: 40rem;
                    width: 100%;
                    object-fit: cover;
                    margin-top: 6rem;
                }
            }

            .wrap-contact-info {
                @include font_mont(Regular, 1.5);
                line-height: 2.3rem;

                &.large {
                    @include font_mont(Regular, 2.3);
                    line-height: 4.1rem;
                }

                a {
                    color: inherit;
                    transition: .2s color;
                }

                a:hover {
                    color: $colorCorporative;
                    transition: .2s color;
                }
            }

            .wrap-content {
                width: 100%;
                background-color: #fff;
                padding: 0;
                padding-left: 3rem;
                margin-top: 2rem;
                margin-bottom: 6rem;

                img {
                    width: 100%;
                    max-height: 25rem;
                    /* height: max-content; */
                    object-fit: cover;
                    margin-bottom: 6rem;
                }

            }

            .form {
                width: calc(100% + 3.5rem);

                .input-box {
                    padding-right: 3.5rem;
                    margin-bottom: 3rem;

                    &:last-of-type {
                        margin-bottom: 2rem;
                    }
                }

                .wrap-privacy {
                    width: calc(100% - 3.5rem);
                    justify-content: space-between;
                }

                .input-box input + .label,
                .input-box textarea + .label,
                .select2 + .label {
                    position: absolute;
                    @include font_mont(Medium, 1.5);
                    top: 0;
                    left: 1.7rem;
                    color: #575252;
                    background-color: #fff;
                    padding: 0 1.1rem;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform-origin: left;
                    white-space: nowrap;
                    margin: 0;
                    transition: top 0.2s linear, left 0.2s linear,
                    transform 0.2s linear;
                }

                .form-group {
                    position: relative;
                }

                .privacy {
                    width: 60%;

                    .labelContainer {
                        position: absolute;
                        padding: 0;
                    }

                    .textContainer {
                        width: calc(100% - 3.2rem);
                        font-family: "Montserrat-Regular";
                        font-weight: normal;
                        display: block;

                        .link {
                            color: $colorCorporative;
                            text-decoration: none;

                            --border-color: $colorCorporative;
                            --border-width: 1px;
                            --bottom-distance: 0px;
                            display: inline;
                            background-image: linear-gradient(
                                    var(--border-color),
                                    var(--border-color)
                            );
                            background-size: 0 var(--border-width);
                            background-repeat: no-repeat;
                            background-position-y: bottom;
                            transition: background-size 0.3s;

                            &:hover {
                                background-size: 100% var(--border-width);
                            }
                        }
                    }

                    input + .labelContainer:after {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        height: 0.4rem;
                        left: 0.2rem;
                        min-width: 0.8rem;
                        top: .8rem;
                        border: 0.2rem solid $colorCorporative;
                        border-top: 0;
                        border-right: 0;
                        transform: rotate(-45deg) scale(0);
                        transform-origin: left top;
                        transition: all 0.2s ease 0s;
                    }

                    input + .labelContainer:before {
                        content: "";
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        min-width: 1.3rem;
                        height: 1.3rem;
                        top: 0;
                        border: 0.1rem solid $colorCorporative;
                        border-radius: 0 !important;
                        background-color: transparent;
                        transition: background-color 0.2s linear 0.1s;
                    }

                    input[type="checkbox"]:checked + .labelContainer:before {
                        background-color: transparent;
                        transition: background-color 0.2s linear 0s;
                    }

                    input[type="checkbox"]:checked + .labelContainer:after {
                        transform: translateY(-20%) rotate(-45deg) scale(1);
                        transition: all 0.2s ease 0.1s;
                    }
                }

                .wrap-btnDefault {
                    min-width: unset;

                    .btnDefault {
                        font-size: 1.5rem;
                        line-height: 1.7rem;
                        padding: 1.4rem 6.6rem;
                    }
                }
            }
        }

        .wrap-departments {
            margin-bottom: -19rem;

            .departments {
                width: 100%;
                margin: 0 auto;
                position: relative;
                display: flex;

                &:after {
                    content: "";
                    width: 0.4rem;
                    height: 5rem;
                    background-color: $colorCorporative;
                    animation: MoveUpDown 2.5s linear infinite;
                    position: absolute;
                    right: 0;
                }

                @keyframes MoveUpDown {
                    0%,
                    100% {
                        top: 0;
                    }
                    50% {
                        top: 100px;
                    }
                }

                .part-left,
                .part-right {
                    width: 50%;

                    .section-title {
                        color: #fff;

                        .title-underline {
                            width: 3.5rem;
                            border-bottom: 3px solid;
                            background: #fff;
                            margin-top: 2.5rem;
                            margin-bottom: 0;
                        }
                    }
                }

                .part-left {
                    display: flex;
                    height: 74rem;
                    align-items: center;
                    position: sticky;
                    top: calc(100vh - 74rem);
                    width: 40%;

                    &:before {
                        position: absolute;
                        content: "";
                        top: 0;
                        right: 0;
                        background-color: $colorCorporative;
                        height: 100%;
                        width: 50vw;
                    }

                    .title {
                        line-height: 7rem;
                        text-align: left;
                        margin-left: 4rem;
                        color: white;

                        img {
                            //width: 120%;
                            width: 100%;
                        }
                    }

                    .telf, .world {
                        width: 12.4rem;
                        height: 12.4rem;
                        object-fit: contain;
                    }

                    .telf {
                        position: absolute;
                        top: 8.5rem;
                        left: 0;
                    }

                    .world {
                        position: absolute;
                        right: 5rem;
                        bottom: 8.5rem;
                    }
                }

                .part-right {
                    width: 60%;
                    max-width: calc(60% - ((100% - 1280px) / 2));
                    max-height: 74rem;
                    overflow-y: auto;
                    overflow-x: hidden;
                    position: relative;
                    padding-bottom: 3rem;

                    &::-webkit-scrollbar {
                        -webkit-appearance: none;
                    }

                    &::-webkit-scrollbar-button:increment,
                    .contenedor::-webkit-scrollbar-button {
                        display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: none !important;
                        border: none;
                    }

                    .country {
                        border-top: 1px solid $colorCorporative;
                        text-transform: capitalize;
                        @include font_mont(SemiBold, 2.5);
                        margin-left: 4.3rem;
                        padding: 0 3rem 0 0;
                        width: 100%;
                        cursor: pointer;
                        transition: background-color .5s ease-in-out;
                        display: inherit;

                        &:hover {
                            background-color: transparent;
                        }

                        .nav.nav-country {
                            flex-direction: column;
                        }

                        &.collapse:last-of-type {
                            border-bottom: 1px solid $colorCorporative;
                        }

                        .item {
                            display: flex;
                            padding: 25px;
                            padding-left: 0;
                            cursor: pointer;
                            height: 8.5rem;
                            padding-left: 2.3rem;

                            h2 {
                                @include font_mont(SemiBold, 2.5);
                            }

                        }

                        &.collapse {
                            .nav.nav-country {
                                .country-content {
                                    height: 0;
                                    opacity: 0;
                                    display: none;
                                }
                            }

                            &:hover {
                                background-color: $colorRoseLight;
                            }

                        }

                        .nav.nav-country {
                            flex-direction: column;

                            .shop-item {
                                height: 5rem;
                                //line-height: 5rem;
                                font-size: 1.5rem;
                                padding-left: 2.3rem;
                                //padding-right: 3rem;
                                margin-left: 0;
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                cursor: auto;

                                &:not(.isFiltered){
                                    background-color: $colorRoseLight;
                                    transition: background-color 300ms ease-in;
                                }
                                &:not(.isFiltered):nth-child(2n){
                                    background-color: transparent;
                                    transition: background-color 300ms ease-in;
                                }
                                &.d-none{
                                    display: none;
                                }

                                &.isFiltered{
                                    background-color: $colorRoseLight;
                                    transition: background-color 300ms ease-in;
                                }
                                &.isFiltered:nth-child(2n){
                                    background-color: transparent;
                                    transition: background-color 300ms ease-in;
                                }

                                .info-shop {
                                    display: flex;
                                    width: 100%;
                                    @include font_mont(Regular, 1.5);

                                    .shop {
                                        width: 50%;

                                        &.bold {
                                            @include font_mont(SemiBold, 1.5);
                                        }
                                    }

                                    .phone {
                                        width: 40%;
                                    }

                                    .whatsapp {
                                        display: flex;
                                        align-items: center;
                                        width: 10%;
                                        justify-content: center;
                                        //margin-right: 10%;

                                        img {
                                            height: 2rem;
                                            width: 2rem;
                                        }
                                    }


                                }

                            }

                            .country-content {
                                height: auto;
                                opacity: 100%;
                                display: block;
                                position: relative;

                                .wrap-list-form {
                                    position: absolute;
                                    left: 0;
                                    //top: -7rem;
                                    top: 0;
                                }

                                ul {
                                    &.with-select {
                                        margin-top: 8rem;
                                        //height: 42rem;
                                        //overflow-y: scroll;
                                        //overflow-x: hidden;

                                        min-height: 10rem;
                                        max-height: 42rem;
                                        overflow-y: auto;
                                        overflow-x: hidden;
                                        width: calc(100% - 13px);

                                        &::-webkit-scrollbar {
                                            -webkit-appearance: none;
                                            width: 7px;
                                        }

                                        &::-webkit-scrollbar:horizontal {
                                            height: 4px;
                                        }

                                        &::-webkit-scrollbar-thumb {
                                            border-radius: 8px;
                                            border: 1px solid $colorCorporative;
                                            background-color: $colorCorporative;
                                        }

                                        &::-webkit-scrollbar-track {
                                            background-color: rgba($colorCorporative, 0.32);
                                            border-radius: 8px;
                                        }

                                        &::-webkit-scrollbar-track-piece {
                                            background-color: rgba($colorCorporative, 0.32);
                                            border-radius: 8px;
                                        }

                                        &::-webkit-scrollbar-thumb:vertical {
                                            border-radius: 8px;
                                            border: 1px solid $colorCorporative;
                                            background-color: $colorCorporative;
                                        }

                                        /* Thin Scrollbar for firefox */
                                        & {
                                            scrollbar-color: $colorCorporative rgba($colorCorporative, 0.32);
                                            scrollbar-width: thin;
                                        }

                                    }

                               
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $md-width) {
    #contact_famaliving2023 {
        padding: 0;
        padding-top: 0;
        padding-bottom: 6.5rem;
        padding-right: $paddingLateralXs;
        padding-left: $paddingLateralXs;
        margin-bottom: 0;

        .special-contact .wrap-departments{
            margin-bottom: -6.5rem;
        }

        &.home{
            margin-bottom: 0;
        }

        .limited-wrap {
            max-width: 100%;
            padding-top: 9rem;
        }

        .section-title {
            color: $colorCorporative;
            letter-spacing: 0;

            .title {
                font-size: 2.5rem;
                line-height: 3.8rem;
            }

            .title-underline {
                width: 3.5rem;
                border-bottom: 2px solid;
                background: $colorCorporative;
                margin-top: 0.5rem;
                margin-bottom: 4rem;
            }

            &.mvl {
                display: block;
            }

            &.desc {
                display: none;
            }
        }

        .special-contact {
            padding: 0;

            &.default {
                .part-down {
                    .wrap-contact-info {
                        text-align: initial;
                        font-size: 1.5rem;
                        line-height: 2.3rem;
                    }

                    .wrap-title {

                        .box-content {
                            text-align: initial;

                            .title {
                                &::before {
                                    bottom: -1.5rem;
                                    left: 0;
                                    transform: none;
                                }
                            }
                        }

                        img {
                            height: 14rem;
                            width: 100%;
                            margin-bottom: 4rem;
                            margin-top: 5rem;
                        }
                    }

                    .wrap-content {
                        padding: 0;

                        img {
                            padding: 0;
                            height: 35rem;
                            object-fit: cover;
                            width: 100%;
                            margin-top: 0;
                            max-height: unset;
                        }
                    }
                }
            }

            .part-down {
                padding: 0;
                margin-top: 0;
                flex-direction: column;

                .wrap-contact-info {
                    text-align: center;
                    font-size: 1.7rem;
                    line-height: 2.7rem;
                }

                .wrap-title {
                    width: auto;
                    min-width: auto;
                    margin: 0 auto;

                    .box-content {
                        text-align: center;
                        
                        .title {
                            padding: 0;
                            font-size: 2.9rem;
                            margin-bottom: 5.5rem;
                            width: 100%;
                            max-width: unset;

                            &::before {
                                bottom: -1.5rem;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }

                .wrap-content {
                    padding: 0;
                    margin-bottom: 0;
                }

                .form {
                    width: 100%;
                    display: block !important;
                    overflow: visible;

                    .input-box {
                        padding-right: 0;
                        margin-bottom: 2.4rem;

                        input {
                            height: 4.5rem;
                        }

                        .label {
                            left: 1rem !important;
                        }
                    }

                    .wrap-privacy {
                        width: 100%;
                        flex-direction: column;

                        .wrap-btnDefault {
                            width: 100%;
                        }
                    }

                    .input-box input + .label,
                    .input-box textarea + .label,
                    .select2 + .label {
                        font-size: 1.3rem;
                    }

                    .form-group {
                        position: relative;
                    }

                    .privacy {
                        width: fit-content;
                        margin-top: 0;
                        margin-bottom: 4.3rem;

                        .acept {
                            position: absolute;
                        }

                        .labelContainer {
                            position: relative;
                        }

                        .textContainer {
                            width: calc(100% - 2rem);
                            font-family: "Montserrat-Regular";
                            font-weight: normal;
                            display: block;

                            .link {
                                color: $colorCorporative;
                                text-decoration: none;
                            }
                        }

                        input + .labelContainer:after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            min-width: 0.7rem;
                            height: 0.3rem;
                            top: 1.1rem;
                            left: 0.2rem;
                            border: 0.2rem solid $colorCorporative;
                            border-top: 0;
                            border-right: 0;
                            transform: rotate(-45deg) scale(0);
                            transform-origin: left top;
                            transition: all 0.2s ease 0s;
                        }

                        input + .labelContainer:before {
                            content: "";
                            position: relative;
                            display: inline-block;
                            vertical-align: middle;
                            min-width: 1.2rem;
                            height: 1.2rem;
                            top: 0;
                            border: 0.1rem solid #575252;
                            border-radius: 0 !important;
                            background-color: transparent;
                            transition: background-color 0.2s linear 0.1s;
                            margin-top: 0.3rem;
                        }

                        input[type="checkbox"]:checked
                        + .labelContainer:before {
                            background-color: transparent;
                            transition: background-color 0.2s linear 0s;
                        }

                        input[type="checkbox"]:checked + .labelContainer:after {
                            transform: translateY(-20%) rotate(-45deg) scale(1);
                            transition: all 0.2s ease 0.1s;
                        }
                    }

                    .wrap-btnDefault {
                        min-width: unset;
                        margin: 0 auto;
                        margin-right: 0;

                        .btnDefault {
                            font-size: 1.5rem;
                            line-height: 1.7rem;
                            padding: 1.5rem 4.3rem;
                        }
                    }
                }
            }

            .wrap-departments {
                .departments {
                    width: 100%;
                    margin: 0 auto;
                    padding-bottom: 6.5rem;
                    flex-direction: column;

                    &:after {
                        content: none;
                    }

                    .part-left,
                    .part-right {
                        width: 100%;
                        max-width: 100%;

                        .section-title {
                            color: #fff;

                            .title-underline {
                                border-bottom: 2px solid;
                                margin-top: 1.5rem;
                            }
                        }
                    }

                    .part-left {
                        width: calc(100% + 3rem);
                        max-width: calc(100% + 3rem);
                        background-color: $colorCorporative;
                        transform: translateX(-1.5rem);
                        padding: 3.5rem;
                        position: relative;
                        top: 0;
                        height: 50rem;
                        margin-top: 5rem;

                        .title {
                            margin-left: 0;
                        }

                        .telf {
                            right: 3rem;
                            left: auto;
                            top: 2rem;
                        }

                        .telf, .world {
                            width: 9rem;
                            height: 9rem;
                            object-fit: contain;
                        }

                        .world {
                            position: absolute;
                            right: auto;
                            bottom: 3rem;
                            left: 3rem;
                        }


                    }

                    .part-right {
                        padding: 0;
                        padding-top: 3.7rem;
                        max-height: unset;

                        .department {
                            border-top: 1px solid $colorCorporative;

                            &:last-of-type {
                                border-bottom: 1px solid $colorCorporative;
                            }

                            .item {
                                display: flex;
                                padding: 1.5rem;
                                padding-left: 0;

                                .num {
                                    font-size: 1.04rem;
                                }

                                .name {
                                    font-size: 1.9rem;
                                }
                            }
                        }

                        .country {
                            margin-left: 0;
                            padding: 0;

                            .nav.nav-country {
                                flex-direction: column;
                                //padding-left: 3rem;
                                padding-left: 0;
                            }

                            &:last-of-type {
                                border-bottom: 1px solid $colorCorporative;
                            }

                            .item {
                                display: flex;
                                padding: 25px;
                                padding-left: 0;
                                cursor: pointer;
                                padding-left: 3rem;


                                h2 {
                                    @include font_mont(SemiBold, 2.5);
                                }

                            }

                            .nav.nav-country {
                                flex-direction: column;

                                .shop-item {
                                    height: auto;
                                    padding-top: 1.5rem;
                                    line-height: 3rem;
                                    padding-bottom: 1.5rem;
                                    padding-left: 3rem;

                                    .info-shop {
                                        display: flex;
                                        flex-direction: column;
                                        width: 100%;
                                        @include font_mont(Regular, 1.5);
                                        position: relative;

                                        .shop {
                                            width: 100%;

                                            &.bold {
                                                @include font_mont(SemiBold, 1.5);
                                            }
                                        }

                                        .phone {
                                            width: 100%;
                                        }

                                        .whatsapp {
                                            display: flex;
                                            align-items: center;
                                            width: 10%;
                                            justify-content: center;
                                            margin-right: 10%;

                                            img {
                                                position: absolute;
                                                top: 50%;
                                                right: 1.5rem;
                                                transform: translateY(-50%);
                                                height: 2.5rem;
                                                width: 2.5rem;
                                            }
                                        }


                                    }

                                }

                                .country-content {
                                    height: auto;
                                    opacity: 100%;
                                    display: block;
                                    position: relative;
                                    padding-bottom: 1.5rem;

                                    .wrap-list-form {
                                        position: absolute;
                                        left: 0;
                                        //top: -7rem;
                                        width: 100%;
                                    }

                                    ul {
                                        &.with-select {
                                            margin-top: 8rem;
                                            height: 42rem;
                                            overflow-y: scroll;
                                            overflow-x: hidden;
                                            width: 100%;
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}
