//Media queries breakpoints
//Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:              					480px;

// Small screen / tablet
$screen-sm-min:              					768px;

// Medium screen / desktop
$screen-md-min:              					992px;

// Large screen / wide desktop
$screen-lg-min:             	 				1200px;

// Large scree / wider desktop
$screen-lg-med:                     			1260px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              					($screen-sm-min - 1);  // 767px
$screen-sm-max:              					($screen-md-min - 1);  // 991px
$screen-md-max:              					($screen-lg-min - 1);  // 1199px

//from bootstrap
$screen-xlg-min: 1366px;

$screen-fhd-min: 1920px;

$screen-rt-min: 2560px;

$screen-rt15-min: 2880px;

$screen-uhd-min: 3840px;

$screen-4k-min: 4096px;

$screen-8k-min: 8192px;

$screen-lg-max:                     ($screen-xlg-min - 1);
$screen-xlg-max:                 ($screen-fhd-min - 1);
$screen-fhd-max:                ($screen-rt-min - 1);
$screen-rt-max:                ($screen-rt15-min - 1);
$screen-rt15-max:               ($screen-uhd-min - 1);
$screen-uhd-max:                  ($screen-4k-min - 1);
$screen-4k-max:                   ($screen-8k-min - 1);

//Container_2023

$sm-width: 768px;
$md-width: 991px;
$lg-width: 1441px;
$extra-width: 1920px;
$special-container-max-width: 1400px;
$max-width: 1280px;

$max-width-detail-store: 1080px;


//transitions
$t-fast: 0.2s;
$t-normal: 0.4s;
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);

$line-gray: rgba(#c03d8b, 0.5);
$zindex-modal:             1050 !default;

//Text normal
$defaultFontSize: 1.4rem;
$f-size-menu-vertical: 1.3rem;
$f-size-menu-horizontal: 1.5rem;

//paddings, margins
$paddingLateral: 5rem;
$paddingLateralXs: 1.5rem;





//fonts

$fontStack1: 'Montserrat-Regular', Arial, sans-serif;
$fontStack2: 'Arvo', Georgia, "Times New Roman", Times, serif;
$fontStack3: 'Roboto', verdana, arial;
