
.generic-module {
    .subtitle {
        @include font_mont(Medium, 1.5);
        text-transform: uppercase;
        letter-spacing: 1.5px;
        color: $colorCorporative;
        line-height: 2.5rem;
        margin-top: 3rem;
    }

    .title {
        @include font_mont(SemiBold, 3.5);
        margin-bottom: 4.7rem;
        line-height: normal;
        letter-spacing: 0;
        position: relative;

        &.center {
            text-align: center;
        }

        @media (max-width: $md-width) {
            @include font_mont(SemiBold, 2.5);
            padding-right: 0;
        }
    }

    .description {
        @include font_mont(Regular, 1.5);
        margin-top: 2.5rem;
        line-height: 2.3rem;

        b {
            font-family: Montserrat-SemiBold, sans-serif;
        }
    }

    .small-line:not(.sinBorder)::before {
        bottom: -1.5rem;
    }


    .generic-module-inner {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 5rem;
        padding-bottom: 5rem;
        flex-direction: column;

        &.small {
            max-width: 1060px;
        }

        &.no-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .brands {
            justify-content: space-between;
        }

        @media (max-width: $md-width) {
            &.xs-no-padding {
                padding: 0;
            }

            &.xs-no-padding-top {
                padding-top: 0;
            }

            .common-column {
                flex-direction: column;

                .title {
                    font-size: 2.5rem;
                }

                .image-left {
                    &.with-foot {
                        padding-right: 0;
                    }

                    img {
                        width: 80%;
                    }
                }

                .generic-quote {
                    @include font_mont(Medium, 1.9);
                    text-align: center;
                    line-height: 2.9rem;
                    margin-top: 6rem;

                    .generic-quote-wrapper {
                        width: 80%;

                        &:before {
                            width: 10rem;
                            height: 7rem;
                            top: -2.5rem;
                            left: -4rem;
                        }
                    }
                }
            }

            .brands {
                flex-direction: column;
            }
        }
    }

    .image-right, .image-left {
        display: flex;
        justify-content: flex-end;

        &.with-foot {
            flex-direction: column;
            padding-right: 5%;

            img {
                width: 50%;
                display: flex;
                align-self: center;
            }

            .foot-image {
                max-width: 62rem;
                margin-top: 4rem;
                @include font_mont(Regular, 1.5);
            }
        }

        img {
            width: 80%
        }
    }

    .image-left {
        justify-content: initial;
    }

    .generic-quote {
        position: relative;
        width: 100%;
        text-align: center;
        line-height: 3.9rem;
        display: flex;
        justify-content: center;
        margin-top: 9rem;
        color: $colorCorporative;
        @include font_mont(Medium, 2.7);

        .generic-quote-wrapper {
            max-width: 53rem;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                background-image: url('/images/frase.svg');
                width: 13rem;
                height: 9.5rem;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                top: -4.5rem;
                left: -5rem;
            }
        }
    }


    .wrap-btn {
        margin-top: 3rem;

        &.blue {
            .wrap-btnDefault {
                border: 1px solid #1197a5;

                .btnDefault {
                    background-color: #1197a5;
                }
            }
        }

        &.all-width {
            .wrap-btnDefault {
                width: 100%;
            }
        }

        .wrap-btnDefault {
            align-self: flex-start;
        }
    }

    .common-image-and-logo {
        .column-content {
            position: relative;

            &.with-bg {
                padding-right: 8rem;
            }
        }

        .background-column {
            position: absolute;
            width: 140%;
            height: calc(100% + 10rem);
            max-height: 56rem;
            top: -8rem;
            left: -40%;
            z-index: -1;
        }

        .icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 12.5rem;
            height: 12.5rem;
        }

        @media (max-width: $md-width) {
            flex-direction: column;

            &.with-bg {
                flex-direction: column-reverse;

                img {
                    width: 85%;
                    max-height: 31rem;
                    object-fit: cover;
                    margin-top: 3rem;
                }
            }

            .column-content {
                &.with-bg {
                    padding-left: 6rem;
                    padding-right: 3rem;

                    .title {
                        padding-top: 2rem;
                    }
                }
            }

            .subtitle {
                max-width: 27rem;
            }

            .icon {
                width: 9rem;
                height: 9rem;
            }

            .image-right {
                margin-top: 5rem;

                img {
                    width: 100%;
                }
            }

            .background-column {
                position: absolute;
                width: 95%;
                height: calc(100% + 15rem);
                max-height: unset;
                top: -3rem;
                left: 5%;
                z-index: -1;
            }

        }
    }

    .slider-absolute {
        position: relative;
        height: 28rem;
        width: 100%;
        margin-top: 5rem;
        margin-bottom: 5rem;

        .aux-slider {
            position: absolute;
            width: calc(100vw - 5rem);
            left: calc( 50% + 5rem);
            top: 50%;
            height: 100%;
            transform: translate(-50%, -50%);

            .wrap-slider {
                .slider {
                    .slick-list {
                        padding-right: 0 !important;
                    }

                    .slick-slide {
                        width: 30vw;
                        max-width: 54rem ;
                        margin-right: 3rem;
                    }

                    .slick-track {
                        left: 0;
                    }

                    .slick-disabled {
                        opacity: 1;
                    }

                    .slick-slide {
                        opacity: 1;
                    }

                    .slick-next, .slick-prev {
                        display: none !important;
                    }

                    .item {
                        margin-right: 0;

                        img {
                            //width: 30vw;
                            //max-width: 54rem;

                            width: 100%;
                            max-width: unset;
                        }
                    }
                }
            }
        }

        @media (max-width: $md-width) {
            //height: 17rem;
            width: 100%;
            height: 100%;
            margin-top: 2rem;
            margin-bottom: 2rem;

            .aux-slider {
                //width: calc(100vw - 2rem);
                //left: calc(50% + 1rem);
                left: 0;
                width: calc(100% + 1.5rem);
                top: 0;
                transform: unset;
                position: relative;

                .wrap-slider {
                    .slider {
                        .slick-slide {
                            width: 75vw;
                            margin-right: 1.5rem;
                            max-width: unset;
                        }

                        .item {
                            margin-right: 0;

                            img {
                                width: 75vw;
                            }
                        }
                    }
                }
            }
        }
    }

    .aux-slider{
        .wrap-slider{
            width: 100%;

            .aux-box{
                padding-bottom: 3.7rem;
            }

            .slider {
                .slick-list {
                    padding: 0 !important;
                    padding-right: 10rem !important;
                }

                .slick-slide {
                    opacity: 0.4;
                    transition: opacity .3s ease-in-out;

                    &.slick-active {
                        opacity: 1;
                    }
                }

                .slick-disabled {
                    opacity: 0.4;
                }

                .slick-next, .slick-prev {
                    background-image: url('/images/slider-arrow-left.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    width: 3rem;
                    height: 3rem;
                    z-index: 2;
                    transition: all .4s ease-in-out;

                    &:hover {
                        transform: translate(-1rem, -50%);
                    }

                    &:before {
                        display: none;
                    }
                }

                .slick-next {
                    background-image: url('/images/slider-arrow-right.svg');

                    &:hover {
                        transform: translate(1rem, -50%);
                    }
                }
            }

            .item{
                position: relative;
                margin-right: 2.5rem;

                .img{
                    height: 30rem;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        &.desk{
            flex-grow: 1;
        }

        @media (max-width: $md-width) {
            transform: translateX(0);
            //width: calc(100% + 2rem);
            width: calc(100% + $paddingLateralXs);

            .wrap-slider {
                .aux-box {
                    padding-bottom: 0;
                }

                .slider {
                    .slick-next, .slick-prev {
                        display: none !important;
                    }

                    .slick-next {
                        display: none !important;
                    }
                }

                .item{
                    position: relative;
                    margin-right: 1.5rem;
                    aspect-ratio: 4/3;

                    .img{
                        //height: 17rem;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }


    .brands-group {
        width: calc(100% - 2rem);
        margin-top: 5rem;
        border: 1px solid $colorCorporative;
        position: relative;
        margin-bottom: 5rem;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            background-color: $colorCorporative;
            height: 100%;
            left: 2rem;
            top: 2rem;
        }

        .brands-group-content {
            padding-top: 5rem;
            padding-left: 5rem;
            padding-right: 5rem;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            background-color: #fff4fb;

            .brands-group-content-row {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            .brand {
                max-width: 21rem;
                max-height: 16rem;
                margin-bottom: 5rem;
                object-fit: contain;
                padding: 2rem;
                width: 25%;
            }
        }

        @media (max-width: $md-width) {
            margin-top: 0;

            .brands-group-content {
                padding-top: 4rem;
                padding-left: 4rem;
                padding-right: 4rem;

                .brands-group-content-row {
                    flex-wrap: wrap;
                }

                .brand {
                    width: 50%;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .box-prices {
        display: flex;
        align-items: center;
        height: auto;
        
        .price-product {
            font-size: 3.5rem;
            color: $colorCorporative;
            white-space: nowrap;
            font-weight: bolder;
        }

        .prev-price-product {
            color: $gray;
            font-size: 2rem;
            white-space: nowrap;
            font-weight: bold;
            text-decoration: line-through;
        }
    }

}

.common-two-columns {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 16.4rem;
    flex-direction: column;

    &.no-padding {
        padding-top: 0;
    }

    &.margin-bottom {
        margin-bottom: 10rem;
    }

    .two-columns {
        display: flex;
        width: 100%;

        &.second {
            margin-top: 5rem;
            margin-bottom: 10rem;
        }

        .image-right, .image-left {
            display: flex;
            justify-content: flex-end;

            img {
                width: 80%
            }
        }

        .image-left {
            justify-content: initial;
        }
    }

    @media (max-width: $md-width) {
        padding-top: 8.5rem;

        &.margin-bottom {
            margin-bottom: 5rem;
        }

        .two-columns {
            display: flex;
            flex-direction: column;

            &.xs-reverse {
                flex-direction: column-reverse;
            }

            &.second {
                margin-top: 0;
                margin-bottom: 5rem;

                img {
                    width: 100%;
                    margin-top: 5rem;
                }
            }

            .image-right {
                margin-top: 2rem;

                img {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: $md-width) {
    .main{
        .common-two-columns{
            padding-top: 9rem;
        }
    }
}

.stores-with-tour {
    margin-bottom: 5rem;

    .common-two-columns {
        padding-top: 5rem;
        padding-bottom: 5rem;

        .title {
            margin-bottom: 4.7rem;
        }
    }

    .stores-tour {

        margin-top: 5rem;

        .input-box {
            margin-bottom: 3rem;

            &:last-of-type {
                margin-bottom: 2rem;
            }
        }

        .input-box select + .label,
        .select2 + .label{
            position: absolute;
            top: 0;
            font-size: 1.3rem;
            left: 1.7rem;
            color: #575252;
            background-color: #fff4fb;
            padding: 0 1.1rem;
            pointer-events: none;
            transform: translateY(-50%);
            transform-origin: left;
            white-space: nowrap;
            margin: 0;
            transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
            font-family: 'Montserrat-Medium';
            font-weight: normal;
        }

        .form-group {
            position: relative;
        }

        .tour {
            .tour-wrapper {
                display: flex;
                width: 100%;
                flex-direction: column;

                img {
                    width: 100%;
                    height: 35rem;
                    object-fit: cover;
                }

                .tour-content {
                    background-color: white;
                    padding: 3rem 3.5rem;
                    margin-bottom: 4rem;

                    .title {
                        @include font_mont(SemiBold, 2.5);
                        line-height: 3rem;
                        margin-bottom: 0;
                    }

                    .name {
                        @include font_mont(SemiBold, 2.5);
                        color: $colorCorporative;
                        line-height: 3rem;
                    }

                    .description {
                        @include font_mont(Regular, 1.4);
                        max-width: 50%;
                        line-height: 2.1rem;
                        margin-top: 2rem;

                        .bold {
                            @include font_mont(SemiBold, 1.4);
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $md-width) {
        .image-left {
            img {
                object-fit: cover;
                width: 100% !important;
                margin-top: 3rem;
            }
        }

        .stores-tour {
            .tour {
                .tour-wrapper {
                    img {
                        height: 37rem;
                    }

                    .tour-content {
                        .description {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

