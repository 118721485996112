//Schema
// In this case, is a variant of real corporative colour.
$colorCorporative: #c70087;
$colorRoseLight: #fff4fb;


$colorWhite: #FFF;
$colorGray: #999;
$colorGrayLight: #f5f5f5;
$colorBlack: #333;
$colorBlack2: #666;
//$gray: #f3f6f7;
$gray: #f3f3f3;
$gray-dark: #324b4e;
$grayDark2:#262626;

//Principals colours
$colorPrimary: $colorCorporative;
$colorDanger: #FF007F;
$colorSuccess: #2ECC71;
$colorInfo: $colorCorporative;
$colorWarning: gold;
$colorMuted: $colorBlack;

//Default
$colorLinkNormal: $colorBlack2;
$colorLinkHover: $colorGray;
$colorLinkActive: $colorCorporative;
$colorText: $colorMuted;
$colorText2: $colorGray;
$backgroundTextNormal: $colorWhite;

//Header schema
$colorHeaderLinkNormal: $colorGray;
$colorHeaderLinkHover: $colorBlack2;
$colorHeaderLinkActive: $colorCorporative;
$backgroundColorNavbar: $colorWhite;

//Inverse
$colorLinkNormalInverse: $colorWhite;
$colorLinkHoverInverse: $colorWhite;
$colorLinkActiveInverse: $colorCorporative;
$backgroundColorNavbarInverse: transparent;
$backgroundBody: #DCDCDC;

//error
$colorError: #ED0000;
$red: #e3342f;
$err: #e95950;

//FAMA_new_2021
$colorCorporative_fama:#1197a5;
$gray: #575252;
$line-gray: rgba(#3f6574, 0.5);
$pink:#c70087;
