.new-store{

    .box-content{
        .title{
            //margin-bottom: 4.1rem;
            margin-bottom: 2.5rem;
            @media (max-width: $md-width){
                //margin-bottom: 2.2rem;
                margin-bottom: 1.5rem;
                width: 75%;
                max-width: 40rem;
            }
        }
    }
  
    .wrap-container{
        padding: 0 $paddingLateral;
        &:first-of-type {
            padding-top: 16.4rem;
        }
        .content.primary{
            flex-wrap: wrap;
            max-width: calc(1080px + 300px);
            margin: 0 auto;
        }

        &.content-and-slider-image-with-price {
            &:first-of-type {
                padding-top: 0 !important;
            }

            padding-top: 0;
            padding: 0;
            padding-bottom: 5rem;
        }
    }
    .subtitle.text-subtitle{
        @include font_mont(Medium, 1.5);
        line-height: 2.4rem;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $colorCorporative;
        max-width: 29.6rem;
        //margin-bottom: 3.1rem;
        margin-bottom: 2rem !important;
        font-weight: normal;

        @media (max-width: $md-width){
            line-height: 2.3rem;
            margin-bottom: 2.5rem;
            width: 80%;
            max-width: 40rem;
        }
    }

   
    .wrap-sidebar{
        width: 20%;
        min-width: 30rem;
        padding: 0;
        margin-bottom: 10.6rem;

        .list-categores{
            position: sticky;
            top: 12.4rem;
            padding-right: 3rem;
            margin-bottom: 0;
        

            .list-categores-link{
                cursor: pointer;
                &.is-active{
                    .list-categores-item{
                        color: $colorCorporative;
                        background-color: $colorRoseLight;
                        transition: .2s all ease-in-out;
                        width: 100%;
                    }
                }
            }

            .list-categores-item{
                @include font_mont(Medium, 1.5);
                color: $colorBlack;
                transition: .2s color ease-in-out;
                padding: 1rem 2.8rem 1rem 2rem;
                text-align: initial;
                width: 100%;

                &.isSelected{
                    color: $colorCorporative;
                    background-color: $colorRoseLight;
                }

                .list-categores-item-content{
                    --border-color: #c70087;
                    --border-width: 1px;
                    --bottom-distance: 0px; /* you can increase this */    
                    
                    //display: inline;
                    background-image: linear-gradient(var(--border-color), var(--border-color));         
                    background-size: 0% var(--border-width);
                    background-repeat: no-repeat;
                    background-position-y: bottom;
                    transition: background-size 0.3s;         

                    white-space: pre-wrap;
                }

                &.is-active{
                    color: $colorCorporative;
                    background-color: $colorRoseLight;
                }
                &.change-store{
                    color: #1197a5;
                    text-decoration: underline;
                }
                &:hover{
                    color: $colorCorporative;
                    transition: .1s all ease-in-out;

                    .list-categores-item-content{
                        transition: .2s all ease-in-out;
                        background-size: 100% var(--border-width);
                    }
                }
            }
        }
    }
    .wrap-content{
        padding-right: 0;
        width: calc(100% - 30rem);
  
        article{
            margin-bottom: 10.7rem;
            position: relative;
            max-width: $max-width-detail-store;

            &.article-schedule{
                z-index: 2;
                margin-bottom: 8.3rem;

                .box-content .title{
                    margin-bottom: 4.7rem;
                }
            }

            &.article-ubication{
                margin-bottom: 10.7rem;
            }

            &.article-slider{
                .section{
                    *:empty{
                        display: none;
                    }
                    &:nth-child(even){
                        .section-content{ //
                            //margin-right: calc(9.8rem / 4);
                            //margin-left: 0;
                            margin-left: calc(9.8rem / 4);
                            margin-right: 0;
                        }

                    }
                    &:nth-child(odd){
                        .section-content{ //right
                            //margin-left: calc(9.8rem / 4);
                            //margin-right: 0;
                            margin-right: calc(9.8rem / 4);
                            margin-left: 0;
                        }
                    }
                    .wrap-img{
                        margin-bottom: 3.2rem;
                        margin-top: 2.5rem;
                        
                        .img-responsive{
                            display: block;
                            max-width: calc(100% / 3 * 2);
                        }
                    }
                }
                .mygallerybox.open{
                    .swiper-slid{
                        width: 100% !important;
                    }
                }
                .pasadorPost .swiper-wrapper .swiper-slide {
                    &:last-of-type{
                        &.swiper-slide-next{
                            opacity: 1;
                        }
                    }
                   
                    &.swiper-slide-next{
                        opacity: .4;
                    }
                } 
            }
        }

        .content{
            justify-content: center;
           flex-wrap: wrap;
        }
        .limited{
            margin: 0 auto;
        }
 
        .section{
            width: 50%;
            position: relative;
        }
        .section-right,
        .section-left,
        .section-content
        {
            width: calc(100% - 4.9rem);
            height: 100%;
            position: relative;
            margin: 0 auto;
        }
        .section-right{
            margin-right: calc(9.8rem / 2);
            margin-left: auto;
        }
        .section-left{
            margin-left: calc(9.8rem / 2);
        }

        .section-map{
            max-width: $max-width-detail-store;
            margin: 0 auto;
            width: 100%;

            #mapStore{
                aspect-ratio: 3/2;
            }

            .part-right{
                width: 60%;
             
                .wrap-map{
                    width: 100%;
                    aspect-ratio: 3/2;
                   box-shadow: 0px 0px 10px 0px darkgray;
                }
            }
            .part-left{
                width: 40%;
                padding-left: 4.6rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              

                .map-contact,
                .map-address{
                    .title{
                        @include font_mont(SemiBold, 1.5);
                        color: #c70087;
                        line-height: 2.3rem;
                    }
                    .name{
                        @include font_mont(SemiBold, 1.5);
                        color: $colorBlack;
                        text-transform: uppercase;
                        line-height: 2.3rem;
                    }
                    .contact{
                        margin-bottom: 4.1rem;
                    }
                    .address, .address a,
                    .contact, .contact a{
                        @include font_mont(Regular, 1.5);
                        color: $colorBlack;
                    }
                 
                    .wrap-whatsapp{
                        .whatsapp{
                            position: relative;
                            padding-left: 5rem;
                            height: 4rem;
                            &::before{
                                content: '';
                                background-image: url('/images/icon-whatsapp.svg');
                                width: 4rem;
                                height: 4rem;
                                background-size: contain;
                                background-position: center;
                                position: absolute;
                                background-repeat: no-repeat;
                                left: 0;
                            }
                        }
                        a{
                            span{
                                color: #4caf50;
                                text-decoration: none;      
                                line-height: 2rem;                     
    
                                --border-color: #4caf50;
                                --border-width: 1px;
                                --bottom-distance: 0px;
                                display: inline;
                                background-image: linear-gradient(
                                    var(--border-color),
                                    var(--border-color)
                                );
                                background-size: 100% var(--border-width);
                                background-repeat: no-repeat;
                                background-position-y: bottom;
                                transition: background-size 0.3s;
                                letter-spacing: 1.5px;
                            }
                         

                            &:hover {
                                span{
                                    background-size: 0 var(--border-width);
                                }
                                
                            }

                        }

                    }
                }
                .wrap-btnDefault{
                    min-width: unset;
                    width: fit-content;

                    .btnDefault{
                        width: fit-content;
                        padding: 1.4rem 3.7rem;
                    }
                }
                .wrap-address{
                    margin-bottom: 2.2rem;
                }
            }
        }
        .bg-rose{
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: unset;
                width: 100%;
                height: 100%;
                background-color: $colorRoseLight                

            }
        }

    }
    .wrap-slider-full{
        width: 100%;
        height: fit-content;
        margin-bottom: 5.6rem;
     
        .aux-slider.wrap-slider-content{
            max-width: $max-width-detail-store ;
            min-height: 40rem;
            margin: 0 auto;
            width: calc(100% - 3.5rem);
            
            &.isOne{
                .control.control-prev, 
                .control.control-next 
                {
                    display: none !important;
                }
            }

            &.store-detail-store-wrap{
                min-height: unset;
            }
        }
        .wrap-slider{
            padding: 0;
            width: calc(100% + 5rem);
            left: 0;

            .swiper-slide{
                margin-right: 2.2rem !important;
            }
            .img-responsive {
                max-height: 40rem;
            }
            &.slider-detail-store{
                .swiper-slide{
                    //max-height: 40rem;
                    aspect-ratio: 3/2;
                }
                .img-responsive {
                    max-height: unset;
                    max-width: fit-content;

                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                .wrap-img{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

        }
      
    }

    a.link{
        color: $colorCorporative;

        .link-down{
            width: fit-content;
            position: relative;
            padding-right: 2.2rem;
            margin-top: 1.8rem;
            
        
            &::before{
                content: '';
                position: absolute;
                color: $colorCorporative;
                width: .8rem;
                height: .8rem;
                border: 1px solid $colorCorporative;
                margin-top: 0;
                transition: .2s margin-top ease-in-out;
                transform: rotate(45deg);
                border-top: none;
                border-left: none;
                right: 0;
            }
            &:hover{
                &::before{
                    margin-top: .5rem;
                    transition: .2s margin-top ease-in-out;
                }
            }
        }
    }
    .section.bg-rose{
        height: calc(100% - 3.6rem);

        &.is-open{
            .store-schedule-content{
                position: relative;
                z-index: 1;
                max-height: 9999px;
                opacity: 1;
                transition: 1s max-height linear;
            }

            .srore-schedule-info-content{
                &::before{
                    transition: .2s transform ease-in-out;
                    margin-top: 0;
                    transform: translateX(calc(-50% - .4rem)) rotate(225deg);
                }
                
            }
           
        }
    }
    .section.bg-rose{
        .store-schedule{
            margin-bottom: 2.6rem;
            position: relative;

            &::after{
                content: '';
                position: absolute;
                height: 1px;
                background-color: $colorCorporative;
                width: 100%;
                margin-top: 1.8rem;
            }
        }
        .section-left{
            padding: 3.2rem 4rem 2.1rem 3.5rem;
            position: unset;
            margin: 0;
            width: 100%;
        }
        .store-schedule-title{
            @include font_mont(Medium, 1.7);
            letter-spacing: .5rem;
            color: $colorCorporative;
            margin-bottom: 2.1rem;
            line-height: 1.9rem;
            position: relative;
        }
        .store-schedule-schedule{
            @include font_mont(SemiBold, 1.3);
            line-height: 2.1rem;
            position: relative;
        }
        .store-schedule-address{
            @include font_mont(Regular, 1.3);
            line-height: 2.1rem;
            position: relative;

            &::after{
                content: none;
                display: block;
                height: 1.3rem;
                width: 1.3rem;
                position: absolute;
                top: 0;
                z-index: 1;
                background: url('/images/fl-arrow-rose.svg') repeat-x transparent top center;
              
                right: 1.5rem;
                top: 50%;
                left: unset;
                transform: translateY(-50%);
                
            }
        }
        .srore-schedule-info{
            @include font_mont(SemiBold, 1.3);
            position: relative;
            line-height: 2.1rem;
            color: #000;
            transition: .2s color ease-in-out;
            position: relative;
            width: fit-content;
            padding-right: 2.8rem;

            &:before{
                content: '';
                position: absolute;
                color: #000;
                width: .8rem;
                height: .8rem;
                border: 1px solid #000;
                margin-top: 0;
                transition: .4s transform ease-in-out, .2s margin-top ease-in-out;
                transform: rotate(45deg) translateX(calc(-50% - .4rem));
                border-top: none;
                border-left: none;
                right: 0;
                top: 50%;
            }

            &:hover{
                color: $colorCorporative;
                transition: .2s color ease-in-out;

                &::before{
                    margin-top: .5rem;
                    transition: .2s all ease-in-out;
                    border-color: $colorCorporative;
                }
            }
            
        }
        .store-schedule-content{
            position: absolute;
            background-color: $colorRoseLight;
            width: 100%;
            left: 0;
            padding-top: 2rem;
            height: auto;
            max-height: 0;
            opacity: 0;
            z-index: -1;


            .mondayFriday,
            .saturday{
                line-height: 2.1rem;

                .mondayFriday-title,
                .saturday-title{
                    @include font_mont(SemiBold, 1.3);
                    width: 35%;
                    min-width: 15rem;
                }
            }
        }

    }
   
}

@media (min-width: 1750px) {
    .new-store{
        .wrap-sidebar{
            width: calc(10% + 5rem);
            transform: none;
            .list-categores{
                padding-right: 3rem;

                .list-categores-item{
                    white-space: nowrap;
                    width: 100%;

                }
            }
        }
        .wrap-content{
            width: calc(100% - 30rem);
        }
    }
   
}

@media (max-width: $md-width) {
  
    .new-store{
        .wrap-container{
            &:first-of-type{
                padding-top: 10.4rem;
            }
        }

        .wrap-sidebar{
           display: none;
            transform: none;
            .list-categores{
                padding-right: 0%;

                .list-categores-item{
                    white-space: nowrap;
                    width: 100%;
                }
            }
        }
        .wrap-content{
            width: 100%;
            padding-right: 0;
        }

        .wrap-container{
            article{
                margin-bottom: 7.7rem;
                &.article-products{
                    width: 100%;
                    margin-bottom: 7.7rem;
                }
                &.article-schedule{
                    width: 100%;
                    padding: 0;
                    margin-bottom: 2.7rem;
    
                    .section{
                        width: 100%;
                    }
                    .section-right{
                        width: 100%;
                    }
                    .box-content .title{
                        margin-bottom: 3.4rem;
                    }
                }
                .section.bg-rose{
                    width: 90%;
                    right: 0;
                    margin: 0 auto;
                    margin-left: 0;
                    .srore-schedule-info:hover{
                            margin-top: 0;
                            transition: 0.2s all ease-in-out;
                            border-color: #000;
                    }
                    &:not(.is-open){
                        .srore-schedule-info{
                            color: #000;
                        }
                        .srore-schedule-info::before{
                            margin-top: 0;
                            transition: 0.2s all ease-in-out;
                            border-color: #000;
                        }
                    }
                    &.is-open{
                        .srore-schedule-info{
                            color: $colorCorporative;
                            transition: 0.2s all ease-in-out;
                        }
                        .srore-schedule-info::before{
                            margin-top: 0.5rem;
                            transition: 0.2s all ease-in-out;
                            border-color: $colorCorporative;
                        }
                    }

                    &::before{
                        width: 100%;
                        right: 0;
                    }
                    .section-left{
                        padding: 2.9rem 3.1rem 3.1rem 2.1rem;
                        width: 100%;
                        margin: 0;
                    }
                }
              
                &.article-slider{
                    margin-bottom: 7.7rem;
                    //margin-bottom: 4.5rem;
            
                    .section1{
                        flex-direction: column;
                    }
                    .section{
                        width: 100%;

                        .section-content{
                            width: 100%;
                            margin: 0 !important;
                        }
                    }
                    .wrap-slider-full{
                        margin-bottom: 3.2rem;
                        width: 100%;

                        .aux-slider.wrap-slider-content{
                            min-height: unset;
                            transform: unset;
                            width: calc(100% + 3rem);
                            left: -1.5rem;

                            .control.control-next, 
                            .control.control-prev{
                                display: none;
                            }
                            .pasadorPost .swiper-wrapper .swiper-slide {
                                width: auto;
                                max-width: 100%;
                                margin-right: 0 !important;
                                padding-left: .7rem;

                                &:first-of-type{
                                    padding-left: 1.5rem;
                                    padding-right: 0;
                                }
                                &:last-of-type{
                                   // margin-right: 1.5rem !important;
                                   margin-right: 0 !important;
                                   padding-left: .7rem;
                                   padding-right: 1.5rem;

                                    &.swiper-slide-next{
                                        opacity: 1;
                                    }
                                }
                               
                                &.swiper-slide-next{
                                    opacity: .4;
                                }


                                .img-responsive{
                                    width: 100%;
                                    //max-height: 23.5rem;
                                    //min-height: 23.5rem;
                                    object-fit: cover;
                                    max-height: unset;
                                    min-height: unset;
                                }
                                &.slider-detail-store{
                                    .img-responsive {
                                        max-width: 100%;
                                    }
                                }

                            }
                           
                        }
                    }
                 
                }

                &.article-ubication{
                    margin-bottom: 7.7rem;
                    .content{
                        flex-direction: column;
                    }
                    .part-right{
                        width: 100%;
                        margin-bottom: 5.3rem;
                    }
                    .part-left{
                        width: 100%;
                        padding: 0;

                        .map-address{
                            margin-bottom: 4.4rem;

                            .wrap-address{
                                margin-bottom: 2.5rem;
                            }

                            .address{
                                margin-bottom: 0;
                                font-size: 1.3rem;
                                line-height: 2.1rem;
                            }
                        }

                        .map-contact{
                            display: flex;
                            width: 100%;
                            justify-content: space-between;

                            .contact{
                                font-size: 1.3rem;
                                line-height: 2.1rem;
                                margin-bottom: 0;
                            }
                            .whatsapp{
                                padding-left: 4rem;
                                &::before{
                                    padding-left:0;
                                }
                                span{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        
        }
    }
}