#languages{
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 1.7rem;
   
    .lang-current{
        font-family: Montserrat-Bold;
        border-bottom: 5px solid $colorCorporative;
        padding: 0 4px 1px 4px;
        margin-right: 3.4rem;
        
    }
    #select-idioma2{
        .lang-item{
            opacity: 1;
            transition: .2s all;
        }
        .lang-item{
           margin-right: 3.4rem;
        }
        &:hover{
            .lang-item{
                opacity: .6;
                transition: .2s all;
                &:hover{
                    opacity: 1;
                    transition: .2s all;
                }
            }
        }
    }
}

@media (max-width: $md-width){
    #languages{
        .lang-current{
            margin-right: 3rem;
        }
        #select-idioma2{
            .lang-item:not(:last-of-type){
                margin-right: 3rem;
            }
            .lang-item:last-of-type{
                margin-right: 0;
            }
    }
    }
}