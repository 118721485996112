.new-store{
    .text.generic{
        p b, b{
            font-family: 'Montserrat-SemiBold' !important;
            font-weight: normal;
        }
        p{
            margin-bottom: 2.5rem;
            font-weight: normal;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    .wrap-content{
        .article-projekt{
            //width: calc(100% + 5rem);
            margin-bottom: 10.7rem;

            .arriba .content{
                .part-img{
                    bottom: 7rem;
                    &.isSlider{
                        bottom: 7rem;
                    }
                } 
            }
            .derecha .content{
                .part-img{
                    &.isSlider{
                     
                    }
                } 
            }
           
            .wrap-header{
                width: 100%;
                .header-title{
                    max-width: 38rem;
                }
                .box-content .title {
                   // margin-bottom: 1rem;
                }
                .header-part-content{
                    width: 56%;
                    max-width: calc(#{$max-width-detail-store}  / 2);
                    position: relative;
                    margin: 0 auto;
                    margin-left: 0;
                    background-color: $colorRoseLight;
                    padding: 5.8rem 5rem 3rem 4rem;
                    padding-bottom: 0;
                
                }
                .subtitle.text-subtitle{
                    margin-bottom: 0 !important;
                    //margin-top: 3.5rem;
                }
            }
            .wrap-content-info{
                width: 56%;
                //max-width: calc(#{$max-width-detail-store} / 2);
                margin: 0 auto;
                margin-left: 0;
                background-color: $colorRoseLight;
                padding: 2rem 5rem 7rem 4rem;
                
            }
            .content{
                max-width: $max-width-detail-store ;
                margin: 0 auto;
                width: 100%;
                position: relative;

                &::before{
                    content: '';
                    position: absolute;
                    background-color: $colorRoseLight;
                    width: 85%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }

            }
            .part-content{
                width: 100%;
                max-width: calc(#{$max-width-detail-store} / 2);
                position: relative;
                margin: 0 auto;
                margin-left: 0;
                background-color: $colorRoseLight;
                padding: 0;

                .description{
                    padding-bottom: 3rem;
                }

                .wrap-one-img.isImg{
                    margin-bottom: 3rem;
                }
                
            }
            .part-img{
                width: 44%;
                position: absolute;
                //right: -5rem;
                right: 0;
                bottom: 7rem;
                top: unset;
                aspect-ratio: 3/2;

                .wrap-one-img{
                    overflow: hidden;
                    position: absolute;
                    bottom: 0;
                }
                &.isSlider{
                    max-width: 36rem;
                    //margin-top: -5rem;

                    bottom: -3rem;
                    top: unset;
                
                }
                .part-slider {
                    width: 100%;
                    margin-top: 2rem;
    
                    .aux-slider{
                        .wrap-slider{
                            width: 100%;
        
                            .aux-box{
                                padding-bottom: 3.7rem;
                            }
                            .slider-slick-detail-store.slick-slider{
                                .slick-arrow{
                                    display: none !important;
                                }
                                .slick-list{
                                    padding: 0 !important;
                                    cursor: grab;                                
                                    &.dragging, &.draggable:active:hover {
                                        cursor: grabbing;
                                    }
                                    .slick-slide{                               
                                        .item{
                                            position: relative;
                                            padding-top: 120%;                                    
                                            .img{
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                //object-fit: contain;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.desk{
                            flex-grow: 1;
                        }
                    }
  
                }

            }

            .wrap-btnDefault{
                width: fit-content;

                .btnDefault{
                    padding: 1.4rem 4rem;
                }
            }
        }
    }
}

@media (max-width: $md-width) {
    .new-store{

        .wrap-content{
            .article-projekt{
                margin-bottom: 7.7rem;
                .arriba{
                    .content{
                        .part-img.isSlider{
                            position: relative;
                            bottom: 0;
                            width: 100%;
                        }
                    }
                }
                .content{
                    flex-direction: column;

                    &::before{
                        content: none;
                    }

                    .aux-slider{
                        width: 100%;
                        transform: unset;
                    }
                }

                .wrap-header{
                    width: 100%;

                    .header-part-content{
                        width: 100%;
                        max-width: 100%;
                        padding: 4rem 0 2rem 1.5rem;
                       
                        .header-title.box-content{
                            width: 50%;
                        }

                    }
                }

                .wrap-content-info{
                    width: 100%;
                    padding: 0;
                    background-color: unset;
                }
                .part-img{
                    max-width: 100%;
                    width: 100%;
                    padding: 0 $paddingLateralXs;

                    &.isSlider{
                        max-width: 100%;
                    }
                    .part-slider{
                        margin-top: -5.2rem;
                        left: unset;
                        position: relative;
                    }
                }

                .part-content{
                    max-width: 100%;
                    padding: $paddingLateralXs;
                    position: relative;
                    background-color: unset;
                    padding-bottom: 10.7rem;
                    padding-top: 0;

                    .description{
                        position: relative;
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        background-color: $colorRoseLight;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }
                }
                .wrap-btnDefault .btnDefault {
                    font-size: 1.5rem;
                    padding: 1.4rem 5.3rem;
                }
            }
        }
    }
}