.list-stores{
    .wrap-container{
        padding: $paddingLateral;
        padding-top: 0;
        padding-bottom: 3.3rem;

        &.first{
            padding-top: 16.4rem;
        }

    }
    .content{
        position: relative;
    }
    .small-line::before {
        bottom: -2rem;
    }
    .wrap-stores-country-xs{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 105;
        width: 100%;
        height: 100vh;



        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(#000, 0.5);
        }
        .wrap-countryXs {
            //width: calc(100% - 2 * (#{$paddingLateralXs}));
            width: 100%;
            height: 100%;
            //height: calc(100% - 15rem);
            background-color: $colorWhite;
            position: relative;
            top: 0;
            left: 0;
            //top: calc(15rem / 2);
            //left: 1.5rem;
            color: $colorCorporative;
            padding: $paddingLateralXs 0;

            .close{
                width: 5.5rem;
                height: 5.5rem;
                margin: 0 auto;
                margin-right: 0;
                position: relative;
                bottom: 0;

                &::before{
                    content: "";
                    position: absolute;
                    background-image: url('/images/fl-close.svg');
                    width: 2rem;
                    height: 2rem;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    //transform: rotate(45deg);
                    top: 50%;
                    transform: translate(1.5rem, -1.5rem);
                }
            }
            .country-item{
                padding-right: 2.5rem;
                padding-left: 4rem;


                .country-item-name{
                    @include font_mont(Regular, 1.5);
                    border-bottom: 1px solid rgba($colorCorporative, 0.32);
                    padding: 0.7rem 2rem;
                    text-transform: uppercase;
                }
                &:last-of-type{
                    .country-item-name{
                        border-bottom: none;
                    }
                }

                &.is-active{
                    .country-item-name{
                        @include font-mont(SemiBold, 1.5);
                    }
                }
            }
            .list-countrysXs{
                overflow-x: hidden;
                overflow-y: auto;
                height: calc(100% - 4rem);
                margin-right: 1.5rem;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 5px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                    border: 1px solid $colorCorporative;
                    background-color: $colorCorporative;
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba($colorCorporative, 0.32);
                    border-radius: 3px;
                }
                &::-webkit-scrollbar-track-piece  {
                    background-color: rgba($colorCorporative, 0.32);
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb:vertical {
                    border-radius: 3px;
                    border: 1px solid $colorCorporative;
                    background-color: $colorCorporative;
                }
                /* Thin Scrollbar for firefox */
                &{
                    scrollbar-color: $colorCorporative rgba($colorCorporative, 0.32);
                    scrollbar-width: thin;
                }
            }

        }
    }
    .changeCountry{
        @include font_mont(Regular, 1.3);
        line-height: 2.1rem;
        color: $colorCorporative;
        text-decoration: underline;
    }
    .limited-wrap{
        max-width: 1280px;
        margin: 0 auto;
    }

    .header{
        width: 50%;
    }
    .wrap-list-form{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        form.form-block{
            min-width: 33rem;
            width: 33rem;
            margin: 0 auto;
            margin-right: 0;
        }
        .form-group{
            margin-bottom: 0;
        }
    }
    .wrap-stores-country{
        width: 30%;
        min-height: calc(6.55rem * 5);
        display: block !important;

        .country-item{
            position: relative;
            background-color: $colorWhite;
            transition: .2s all ease-in-out;
            width: 100%;
            text-align: initial;

            &:hover{
                background-color: $colorRoseLight;
                transition: .2s all ease-in-out;
            }
            &::after{
                content: '';
                position: absolute;
                width: 0;
                height: 1px;
                left: 0;
                background-color: $colorRoseLight;
                top: -1px;
            }
            .country-item-name{
                border-bottom: solid 1px #e5e7e8;
                color: $pink;
                font-size: 2.5rem;
                font-family: 'Montserrat-SemiBold';
                padding: 1.7rem 1.3rem;
                text-transform: lowercase;
                margin-right: 1.65rem;
                position: relative;
                transition: .2s padding-left ease;
                pointer-events: none;

                &::first-letter{
                    text-transform: uppercase;
                }
                &::before{
                    content: '';
                    background-image: url('/images/fl-arrow-black.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    z-index: 2;
                    width: 0%;
                    left: 2.3rem;
                    top: 0;
                    transform: translateX(-50%);
                    height: 100%;
                    background-position: center;
                    transition: .2s width ease-in-out;
                }

            }


            &.is-active{
                background-color: $colorRoseLight;
                .country-item-name{
                    border: none;
                    color: $grayDark2;
                    padding-left: 7.2rem;
                    transition: .2s padding-left ease;

                    &::before{
                        width: 2rem;
                        transition: .2s width ease-in-out;
                    }
                }
                &::after{
                    width: 100%;
                }
            }
            &:first-of-type{
                &.is-active{
                    &::after{
                        width: 0;
                    }
                }
            }
        }
    }
    .wrap-form{
        position: absolute;
        top: 0rem;
        z-index: 3;
        width: calc(70% - 1rem);
        padding-top: 3rem;
        right: 1.3rem;
        padding-bottom: 2rem;
        padding-right: 2.7rem;
        background-color: $colorRoseLight;
        .region-form-input {
            @include font_mont(Regular, 1.3);
            border: 1px solid #000 !important;
            height: 3.5rem;
            width: 25rem;
            background-color: $colorRoseLight;
            padding-left: 10px;
            border-radius: 0 !important;
        }
        .region-form-label {
            @include font_mont(Regular, 1.3);
            position: absolute;
            top: -1rem;
            left: 1rem;
            z-index: 4;
            padding: 0px 5px;
            background-color: $colorRoseLight;
        }
    }
    .wrap-form form{
        position: relative;
        width: fit-content;
        margin: 0 auto;
        margin-right: 0;
    }
    .content-list-stores{
        //display: none;
        position: absolute;
        top: 0;
        //opacity: 0;
        height: auto;
        min-height: 100%;
        width: 100%;
        background-color: $colorRoseLight;
        padding: 4.2rem 5.7rem;
        padding-top: 9rem;

        .stores-item{
            //min-height: 12.3rem;
            margin-bottom: 4.5rem;

            .stores-item-img{
                width: 30%;
                overflow: hidden;

                .wrap-img{
                    aspect-ratio: 1.8;
                    overflow: hidden;

                    .img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                        transform: scale(1);
                        transition: .2s transform ease-in-out;
                    }
                }
            }
            &:hover{
                .stores-item-img .wrap-img img{
                    transform: scale(1.1);
                    transition: .2s transform ease-in-out;
                }
            }

            .stores-item-info{
                width: 70%;
                padding-left: 2.2rem;
                display: flex;
                flex-direction: column;

                .store-name{
                    @include font_mont(SemiBold, 2.1);
                    color: $colorBlack;
                    flex-grow: 1;

                    .color{
                        color: $colorCorporative;
                        text-transform: uppercase;
                    }
                }
                .store-address{
                    color: #000;
                    line-height: 2.1rem;

                    .storeDist{
                        color: darkgray;
                        margin-top: 1rem;
                    }
                }
                .store-address{
                    @include font_mont(Regular, 1.3);

                }
                .store-schedule{
                    @include font_mont(SemiBold, 1.3);
                }
            }
        }
        &.is-active{
            position: relative;
            display: flex;
            opacity: 1;
        }
    }
    .wrap-list-stores{
        width: 70%;
        position: relative;
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 1px solid $colorCorporative;
            background-color: $colorCorporative;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba($colorCorporative, 0.32);
            border-radius: 8px;
        }
        &::-webkit-scrollbar-track-piece  {
            background-color: rgba($colorCorporative, 0.32);
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb:vertical {
            border-radius: 8px;
            border: 1px solid $colorCorporative;
            background-color: $colorCorporative;
        }
        /* Thin Scrollbar for firefox */
        &{
            scrollbar-color: $colorCorporative rgba($colorCorporative, 0.32);
            scrollbar-width: thin;
        }
    }
}

@media screen and (max-width: $md-width) {

    .list-stores{
        .title.small-line{
            .in{
                display: none !important;
            }
        }
        .title.small-line.isVisible{
            .in{
                display: inline !important;
            }
        }
        .wrap-container{
            padding: $paddingLateralXs;

            &.first{
                padding-top: 10.4rem;
            }
            .header{
                width: 100%;
            }
        }
        .wrap-stores-country{
            display: none !important;
        }

        .content{
            flex-direction: column;
        }
        .wrap-list-form{
            width: 100%;

            form.form-block{
                width: 100%;
            }
        }
        .wrap-form{
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
            max-width: 70rem;
            margin-bottom: 0;
            background-color: transparent;
            padding-top: 0;
            padding-right: 0;

            .region-form-input{
                width: 100%;
                background-color: #FFF;
            }
            .region-form-label{
                background-color: #FFF;
            }
        }
        .wrap-form form{
            width: 100%;
            margin-right: unset;
            margin-left: 0;
            padding-right: 0;
        }
        .wrap-stores-country-xs{
            position: fixed !important;
            top: 0;

            .wrap-countryXs{
                .country-item{
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    width: 100%;
                    text-align: initial;

                    .js-countryXs.close{
                        width: 5.5rem;
                        height: 5.5rem;

                        &:before {
                            width: 2rem;
                            height: 2rem;
                            top: 1rem;
                            right: 2rem;
                        }
                    }
                    &.is-active{
                        .country-item-name{
                            @include font_mont(Bold, 1.5);
                            color: $colorCorporative;
                        }
                    }
                    .country-item-name{
                        @include font_mont(SemiBold, 1.5);
                        position: relative;
                        padding: 2.6rem;
                        padding-left: 3.5rem;
                        color: $grayDark2;

                        &::before{
                            content: "";
                            position: absolute;
                            background-image: url(/images/arrow-copy-2.svg);
                            background-repeat: no-repeat;
                            width: 1.3rem;
                            height: 1.3rem;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 1.5rem;
                            background-size: contain;
                            background-position-x: right;
                        }

                    }
                    .country-item-num{
                        @include font_mont(Regular, 1.3);
                        position: absolute;
                        left: 1rem;
                        transform: translateY(-25%);
                        color: $colorCorporative;
                    }
                }
            }
        }
        .wrap-list-stores{
            width: 100%;
        }
        .content-list-stores{
            position: relative;
            width: 100%;
            padding: 0;
            background-color: $colorWhite;

            .stores-item{
                flex-direction: column;
                margin-bottom: 3.5rem;

                &:hover{
                    .stores-item-img{
                        .wrap-img{
                            .img{
                                transform: scale(1);
                                transition: unset;
                            }
                        }
                    }
                }

                .stores-item-img{
                    width: 100%;

                    .wrap-img{
                        aspect-ratio: unset;
                        margin-bottom: 1.7rem;

                        .img{
                            transform: scale(1);
                            transition: unset;
                        }
                    }
                }
                .stores-item-info{
                    width: 100%;
                    padding-left: 0;
                    padding-bottom: 3.9rem;
                    border-bottom: 1px solid rgba($colorCorporative, 0.32);

                    .store-name{
                        display: flex;
                        margin-bottom: 1.5rem;
                        &>div:first-of-type{
                            font-size: 1.5rem;
                            line-height: 1;
                            align-self: flex-end;
                            display: none !important;
                        }
                        .color{
                            line-height: 1.5rem;
                            padding-left: 0;
                            align-self: flex-end;
                        }
                    }
                }
            }

        }
    }
}
