.fa.heart{
	font-size:2.1rem;
	font-weight:normal;
}
.badge-blog {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    border: none;
    
    p {
   
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        transform: none;
        justify-content: flex-end;
    }
}
.blog-entry-container{
    .badge-blog{
        .likes{
            display: flex;
            justify-content: flex-end;
            right: unset;
            .likes-count{
                padding-right: 0.5rem;
                font-size: 1.3rem !important;
            }
        }
    }
}

.likes-count, .liked{
    font-size: 1.65rem;
}

.likes a i {
    transition: .4s transform cubic-bezier(0,1.65,1,-0.6);
    transform: scale(1);
    vertical-align: middle
}

.likes a:hover i {
    transform: scale(1.4);
}

.like a i {
    transition: .4s transform cubic-bezier(0,1.65,1,-0.6);
    transform: scale(1);
    vertical-align: middle
}


.heart--red {color:red;}
.heart--white {color:white;}
.heart--pink {color: $colorCorporative;}