.home-famaliving-items{
    &.container{
        max-width: unset;
        padding: 0;
    }
    .menu-list{
        display: grid;
        grid-template-columns: repeat(3, minmax(200px, 1fr));

        .list-item{
            //aspect-ratio: 1;
            position: relative;

            &:not(.isSlider)>a{
                &:hover{
                    .img{
                        transform: scale(1.1);
                        transition: .3s transform;
                    }
                   
                }

            }

            .content-list-item{
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .img{
                aspect-ratio: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1);
                transition: .3s transform;
            }
            .home-list-text{
                @include font_mont(Medium, 2.3);
                //@include underline(#c70087, 0.4);
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                right: 0;
                left: unset;
                color: $colorCorporative;
                bottom: 2.7rem;
                background-color: #fff4fb;
                padding: 1.2rem 1.8rem 1.2rem 2.9rem;
            }
            &.isSlider{
                .content-list-item{
                    padding: 2.7rem;
                }
                .home-list-text{
                    color: #262626;
                    background-color: #fff;
                    right: 2.7rem;
                }
            }

        }
    }

    .slider-category{
        height: 100%;

        *{
            height: 100%;
        }

        .slick-list{
            padding: 0 !important;
          
            .slick-track{

            }
        }
    }
}

@media (max-width: $md-width) {
    .home-famaliving-items{
        .menu-list{
            grid-template-columns: repeat(2, minmax(100px, 1fr));
            .list-item{
                .home-list-text{
                  font-size: 1.3rem;
                  padding: .5rem 1rem .5rem 2rem;
                  bottom: 1rem;
                }
                &.isSlider{
                    order: 1 !important;
                    grid-column: 1/3;
                    .home-list-text{
                        font-size: 2rem;
                        right: 3.4rem;
                        padding: .9rem 1.4rem;
                        bottom: 2.2rem;
                    }
                    .content-list-item{
                        padding: 2.2rem 3.4rem;
                    }
                }
            }
        }
    }
}
