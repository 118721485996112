/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
    -moz-object-fit: $fit;
    -webkit-object-fit: $fit;
    -o-object-fit: $fit;
       object-fit: $fit;
    @if $position { 
      -moz-object-position: $position;
      -webkit-object-position: $position;
        -o-object-position: $position;
           object-position: $position;
  
      // Font-family use by plugin OFI (Object Fit Images)
      // https://github.com/bfred-it/object-fit-images/tree/master
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } 
    @else {
        font-family: 'object-fit: #{$fit}';
    }
}

@mixin center_vertical{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.
@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

@mixin grid($columns, $gap: null, $minmax: null){
  display: grid;
  grid-template-columns: repeat(#{$columns}, 1fr);

    @if $minmax { 
      grid-auto-rows: minmax( #{$minmax}, auto);
    }
    @if $gap { 
      grid-gap: #{$gap};
    }
}

@mixin font_mont($weight, $size){
  font-family: 'Montserrat-#{$weight}';
  font-size: #{$size}rem;
}

@mixin font_lato($weight, $size){
  font-family: 'Lato-#{$weight}';
  font-size: #{$size}rem;
}

@mixin object_fit($fit){
  object-fit: #{$fit};
  height: 100%;
  width: 100%;
} 

@mixin underline($color, $paddBott: null){
  width: auto;
  color: #{$color};
  text-align: center;
  text-decoration: none;
  --border-color: #{$color};
  --border-width: 1px;
  --bottom-distance: 0px;
  display: inline;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0 var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
  
  @if $paddBott { 
    padding-bottom: #{$paddBott}rem;
  }@else{
    padding-bottom: 0.4rem;
  }

  &:hover{
    background-size: 100% var(--border-width);
  }
}
