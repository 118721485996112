.section-empresa.garantee {
    .wrap-content {
        padding-top: 8.5rem;
        padding-bottom: 8.5rem;
        position: relative;
    }

    .wrap-title {
        width: 100%;
        //padding-bottom: 5.9rem;

        .title {
            font-size: 3.5rem;
            padding-right: 0;
            line-height: 3rem;
            letter-spacing: 0;
            font-family: 'Montserrat-Semibold';
            margin-bottom: 3.2rem;
            padding-bottom: 1rem;
        }

        .section-subtitle {
            max-width: 52rem;
            width: 42%;

            .subtitle {
                color: $colorCorporative;
                font-family: 'Montserrat-Medium';
                font-size: 1.5rem;
                letter-spacing: 1.5px;
                line-height: 2.5rem;
                margin-bottom: 4.7rem;
                text-transform: uppercase;
            }
        }
    }

    .limited-wrap {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .container-image {
        position: absolute;

        &.upper {
            bottom: 78%;
            left: calc(50% + 9.6rem);

            img {
                max-width: 42.3rem;
                width: 21vw;
            }

            .wrap-btn {
                margin-top: 5.5rem;
            }
        }

        &.down {
            left: 0;
            bottom: 0;
            height: 70%;
            display: flex;
            align-items: flex-end;

            img {
                width: 30vw;
                max-width: 45rem;
                object-fit: cover;
                height: 100%;
            }
        }
    }

    .wrap-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        position: relative;

        .item {
            &.first {
                width: 100%;
            }

            width: 55%;

            .item-content {
                margin-top: 3.5rem;

                .title {
                    text-align: left;
                    font-size: 2.5rem;
                    text-transform: uppercase;
                    line-height: 3rem;
                    letter-spacing: 2.21px;
                    font-family: "Montserrat-Medium";
                    color: $colorCorporative;
                    padding-bottom: 0;
                    width: -moz-fit-content;
                    width: fit-content;
                    margin-bottom: 1.5rem;
                }

                .wrap-text {

                }
            }
        }
    }

    @media (max-width: $md-width) {
        .wrap-content {
            position: relative;
            padding: 42rem 2rem 0;

            .container-image {
                width: 100%;

                &.upper {
                    top: -17rem;
                    left: 0;
                    display: flex;
                    justify-content: center;

                    img {
                        max-width: none;
                        width: calc(100vw - 4rem);
                        max-height: 55rem;
                        object-fit: cover;
                    }

                    .wrap-btn {
                        display: none;
                    }
                }

                &.down {
                    width: calc(100% + 4rem);
                    align-self: center;
                    position: relative;

                    img {
                        width: 100%;
                        max-width: none;
                        object-fit: cover;
                        height: 100%;
                        max-height: 45rem;
                    }
                }
            }

            .wrap-title {

                .section-subtitle {
                    max-width: initial;
                    width: 100%;

                    .subtitle {
                        margin-bottom: 0;
                    }
                }
            }

            .wrap-list {
                position: initial;

                .item {
                    width: 100%;
                }
            }

            .wrap-btn {
                margin-top: 4rem;
                margin-bottom: 4rem;
                width: 100%;
            }
        }

    }
}
