@for $i from 0 through 150 {
    .w-#{$i} {
        width: #{$i}#{"%"} !important;
    }

    .max-w-#{$i} {
        max-width: #{$i}#{"rem"} !important;
    }

    @media(max-width: $md-width) {
        .w-#{$i} {
            width: auto !important;
        }

        .xs-w-#{$i} {
            width: #{$i}#{"%"} !important;
        }
    }
}

h1, h2, h3, h4, h5{
    font-weight: normal;
}

.container,
.main{
    width: 100%;
    position: relative;

    /**ACESSABILITY*/
    
    button,
    *[type='button'],
    //input,
    //textarea,
    //select,
    input[type='checkbox'],
    .select2-container.select2-container--default
    {
        &:focus-visible{
            border: 3px solid #FFE901;
            box-shadow: 0px 0px 10px 3px #FFE901;
        }
        
    }
    a{
        &:focus-visible{
            border: 3px solid #FFE901;
            box-shadow: 0px 0px 10px 3px #FFE901;

            &>.content-list-item,
            &>.item,
            &>.block-title,
            &>.name,
            &>.post,
            &>.aux-img,
            &>.img,
            &>div,
            &>span,
            &>p,
            &>h2,
            &>.title{
                border: 3px solid #FFE901;
                box-shadow: 0px 0px 10px 3px #FFE901;
            }
            
        }
    }

  

    /**END ACESSABILITY*/
}


//height, width
.h-100{
    height: 100%;
}

//padding, margin
.p-0{
    padding: 0;
}

//flexbox

.align-items-center{
    align-items: center;
}
.justify-content-between{
    justify-content: space-between;
}

//opacity, overflow, display
.d-none{
    display: none;
}
.d-block{
    display: block;
}
.d-flex{
    display: flex;
}
.flex-column{
    flex-direction: column;
}
.stop-scroll,
.stop-scroll-store,
.stop-scroll-geo{
    overflow: hidden;
}
.space-between {
    justify-content: space-between;
}

.text {
    &.generic {
        b {
            font-family: 'Montserrat-SemiBold', sans-serif;
        }
    }
}

//width common
.wrap-container{
    padding: $paddingLateral;

    @media (max-width: $md-width) {
        //padding: 2rem;
        padding: $paddingLateralXs;
    }
}
.wrap-limited{
    max-width: $max-width;
    margin: 0 auto;
}
.wrap-limited-small{
    max-width: 996px;
    margin: 0 auto;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
    @include img-responsive;
  }

//media
.md-hidden,
.sm-visible{
    display: none !important;
}
.md-visible{
    display: inherit !important;
    &.d-none{
        display: none !important;
    }
}

//cusors
.pointer{
    cursor: pointer;
}
.wrap-redes{
    .redes{
        .red-item{
            opacity: 1;
            transition: .2s all;
        }
        &:hover{
            .red-item{
                opacity: .6;
                transition: .2s all;

                &:hover{
                    opacity: 1;
                    transition: .2s all;
                    color: $colorCorporative;

                    a{
                        transition: .2s all;
                        color: $colorCorporative;
                    }
                }
            }
        }

    }
}

.marka{
    background-image: url('/images/img_garanty/star-living.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    width: 18.7rem;
    height: 18.7rem;
    right: 0;
    top: -20rem;

    &::before{
        content: '';
        background-image: url('/images/img_garanty/check-white.svg');
        background-repeat: no-repeat;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-position: center;
        transform: scale(1.1);
    }
    &::after{
        content: "";
        background-image: url('/images/img_garanty/words-circle.svg');
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        animation: spins 16s linear infinite;
        @-moz-keyframes spins {
            100% { -moz-transform: rotate(360deg) }
        }
        @-webkit-keyframes spins {
            100% { -webkit-transform: rotate(360deg)}
        }
        @keyframes spins {
            from {
                -webkit-transform: scale(1.1) rotate(0deg);
                transform: scale(1.1) rotate(0deg);
            }
            to {
                -webkit-transform: scale(1.1) rotate(360deg);
                transform: scale(1.1) rotate(360deg);
            }
        }
    }

    @media (max-width: $md-width) {
        width: 13rem;
        height: 13rem;
        right: 0;
        top: -7rem;

        &:before {
            transform: scale(.7);
        }

        @-moz-keyframes spins {
            100% { -moz-transform: rotate(360deg) }
        }
        @-webkit-keyframes spins {
            100% { -webkit-transform: rotate(360deg)}
        }
        @keyframes spins {
            from {
                -webkit-transform: scale(.7) rotate(0deg);
                transform: scale(.7) rotate(0deg);
            }
            to {
                -webkit-transform: scale(.7) rotate(360deg);
                transform: scale(.7) rotate(360deg);
            }
        }
    }
}

.box-content{
    .title{
        @include font_mont(SemiBold, 3.5);
        margin-bottom: 4.7rem;
        line-height: normal;
        letter-spacing: 0;
        position: relative;

        @media (max-width: $md-width){
            font-size: 2.5rem;
            margin-bottom: 4rem;
        }
    }
}


.span1, .name1-1{
    color: $colorCorporative;

    &.blue {
        color: #1197a5;
    }
}

.small-line:not(.sinBorder){
    position: relative;

    &::before{
        content: '';
        position: absolute;
        width: 3.5rem;
        height: .5rem;
        background-color: $colorCorporative;
        bottom: -0.6rem;
        left: 0;
    }

    &.white {
        &::before {
            background-color: white;
        }
    }

    &.blue {
        &::before {
            background-color: #1197a5;
        }
    }

    &.center {
        &::before {
            left: 50%;
            transform: translateX(-50%);
        }

    }


    @media (max-width: $md-width) {
        position: relative;
        @include font_mont(SemiBold, 3.5);
        line-height: 3.9rem;
        font-size: 2.9rem;
        font-weight: normal !important;

        &::before{
            width: 2rem;
            height: 3px;
        }
    }

}

//text generic
.text{
    &.generic{
        @include font_mont(Regular, 1.5);
        line-height: 2.5rem;
        color: #262626;
    }

    @media (max-width: $md-width) {
        &.generic p,
        &.generic,
        &.generic *:not(i)
        {
            @include font_mont(Regular, 1.5);
            line-height: 2.5rem;
        }

    }
}

.fa{
    &.fa-envelope,
    &.fa-twitter,
    &.fa-facebook-f
    {
        color: #575252;
    }
    &.fa-twitter{
        position: relative;
        height: 2rem;
        width: 2rem;

        &::before{
            content: '';
            height: 100%;
            width: 100%;
            background-image: url(/images/X_active.svg);
            background-size: contain;
            position: absolute;
            background-repeat: no-repeat;
            bottom: -0.2rem;
        }
    }

    &.fa-twitter:hover{
        &::before {
            background-image: url(/images/X_hover.svg);
        }
    }


}

@media (max-width: $md-width) {
    .new-blog{
        .wrap-content-post .wrap-share .share {
            .icon:hover i{
                color: #575252 !important;

                &.fa.fa-twitter:hover{
                    &::before {
                        background-image: url(/images/X_active.svg);
                    }
                }
            }
         
        }
    }
}

//bg full dark

.full-bg{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.5);

    &.isVisible{
        visibility: unset;
        opacity: 1;
        z-index: 102;
        transition: 0.2s all ease;
    }
}

//animation js
.cssanimationTranslate {
	display: inline-block;
    animation-duration: 1.4s;
    animation-delay: .5s;
    animation-fill-mode: both;
    &.delay-2{
        animation-delay: .7s;
    }
    &.delay-3{
        animation-delay: .9s;
    }
}
.fadeInBottomTranslate {
    animation-name: fadeInBottomTranslate;
}

@keyframes fadeInBottomTranslate {
    from {
        opacity: 0;
        transform: translateY(5rem);
        //transition: .3s transform linear;
    }
    to {
        opacity: 1;
        transform: translateY(0);
        //transition: .7s transform linear;
    }
}

//********//

.scrollbar-home{
	justify-content: center;
	display: flex;
	margin: 0 auto;
	width: 100%;
	height: .7rem;
	z-index: 1;
	.scrollbar{
		width: 9.8rem;
        height: .7rem;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	.scrollbar-item{
		width: 2.5rem;
		height: 20%;
		background-color: $colorCorporative;

		&.isActive{
			height: 100%;
		}
	}
	@media (max-width: $sm-width) {
        height: .5rem;
        .scrollbar{
            width: 6.8rem;
            height: .5rem;
            .scrollbar-item{
                width: 1.8rem;
            }
        }

	}
}


.desktop-hidden {
    display: none !important;

    @media (max-width: $md-width) {
        display: inherit !important;
    }
}

.mobile-hidden {
    display: inherit;

    @media (max-width: $md-width) {
        display: none !important;
    }
}

//max 991px
@media (max-width: $screen-sm-max){
    .md-hidden,
    .sm-visible{
        display: inherit !important;
        &.d-none{
            display: none !important;
        }
    }
    .md-visible{
        display: none !important;
    }
}


.layout-default-famaliving{

    .lcc-label,
    .lcc-label span{
        font-family: 'Montserrat-Medium';
        font-weight: normal!important;
    }
    .pr-cookie-view-top{
        font-family: 'Montserrat-Medium';
        font-size: 1.6rem;
        .btn{
            padding: 0.4rem 0.8rem;
            display: inline-block;
            line-height: 1;
            text-align: center;
            font-weight: normal;
        }
    }
    .cookies-text-message {
        font-family: 'Montserrat-Medium';
        font-size: 1.5rem;
    }
    .content-cookies,
    .lcc-modal{
        .btn, button{
            cursor: pointer;
        }
    }
}
//Toast
.fama-toast{
    font-size: 1.5rem;
}