.famaliving-corp-text {

    .corp-text:first-of-type {
        padding-top: 16.4rem;
    }

    .container {
        padding: 5rem;
    }

    .part-up { 
        @include grid(4,1rem,18rem)
    }
}


@media (max-width: $md-width) {

    .famaliving-corp-text {
        .corp-text:first-of-type {
            padding-top: 9.2rem;
        }
    
        .container {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}