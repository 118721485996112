.header-module {

    &.center {
        display: flex;
        justify-content: center;
    }

    @media (max-width: $md-width) {
        padding: $paddingLateralXs;
    }
}

.common-header {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 16.4rem;


    .small-line:not(.sinBorder)::before {
        bottom: -1.5rem;
    }

    .subtitle {
        @include font_mont(Medium, 1.5);
        text-transform: uppercase;
        letter-spacing: 1.5px;
        color: $colorCorporative;
        margin-top: 3rem;
    }

    .description {
        @include font_mont(Regular, 1.5);
        margin-top: 2.5rem;
    }

    .header {
        display: flex;
        width: 100%;

        .blog-img {
            display: flex;
            justify-content: flex-end;

            img {
                width: 80%
            }
        }
    }

    @media (max-width: $md-width) {
        padding-top: 8.5rem;

        .header {
            display: flex;
            flex-direction: column;

            .blog-img {
                margin-top: 2rem;

                img {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: $md-width) {
    .main{
        .common-header{
            padding-top: 9rem;
        }
    }
}
