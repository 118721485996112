.new-store{
    .wrap-container{
        .article-users{
            margin-bottom: 10.7rem;

            .box-content .title{
                //margin-bottom: 1.9rem;
            }
            .subtitle.text-subtitle {
                max-width: 37rem;
            }
            .content{
                max-width: 1080px;
                margin: 0 auto;
                width: 100%;
                position: relative;
            }
            .header-part-content{
                margin: 0 auto;
                margin-left: 0;
                position: relative;
                width: 100%;

            }
            .wrap-users{
                width: 100%;
                /*
                display: flex;
                margin: 0 auto;
                margin-right: 0;
                overflow-y: hidden;
                overflow-x: auto;
                padding-bottom: 3rem;
                */
                .users3{
                    @include grid(3, 2rem);
                }
                .users2{
                    @include grid(2, 2rem);
                }

                .slider-users{
                    width: calc(100% + 2rem);
                    .slick-slide{
                        margin-right: 2.5rem;

                        &:last-of-type{
                            margin-right: 0;
                        }
    
                    }
                  
                    .slick-prev, 
                    .slick-next{
                        width: 4rem;
                        height: 4rem;
                        z-index: 1;
                        top: calc(50% - 2rem);
                       
                        &::before{
                            content: "";
                            background-image: url(/images/fl-right.svg);
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            top: 0;
                            left: 0;
                        }
                        &.slick-disabled{
                            display: none !important;
                        }
                       
                    }
                    .slick-next{
                        right: 0;
                    }
                    .slick-prev{
                        &::before{
                            background-image: url(/images/fl-left.svg);
                        }
                    }
                }
                .item-user{
           
                    .wrap-img{
                        //width: 41rem;
                        overflow: hidden;

                        .img-responsive{
                            //min-width: 41rem;
                            aspect-ratio: 3/2;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: top;
                            transform: scale(1);
                            transition: .2s transform ease-in-out;
                        }
                        /*
                        &:hover{
                            .img-responsive{
                                transform: scale(1.1);
                                transition: .2s transform ease-in-out;
                            }
                        }
                        */
                    }
                    .name{
                        @include font_mont(SemiBold, 1.5);
                        line-height: 2.3rem;
                        margin-top: 1rem;
                        color: $colorBlack;
                        position: relative;

                        .js-userMore{
                            @include font_mont(Regular, 1.3);
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding-right: 2rem;
                            color: $colorCorporative;
                            text-transform: uppercase;

                            &.is-open{
                                &::before{
                                    transition: 0.2s margin-top ease-in-out;
                                    transform: translateY(-50%) rotate(225deg);
                                }
                            }
                        }
                        .js-userMore:before{
                            content: "";
                            position: absolute;
                            color: $colorCorporative;
                            width: 0.8rem;
                            height: 0.8rem;
                            border: 1px solid $colorCorporative;
                            margin-top: 0;
                            transition: 0.2s margin-top ease-in-out;
                            transform: translateY(-50%) rotate(45deg);
                            border-top: none;
                            border-left: none;
                            right: 0;
                            top: calc(50% - .4rem);
                        }
                    }

                    .wrap-description{
                        position: absolute;
                        background-color: #fff;
                        width: 100%;
                        padding: 3.5rem;
                        padding-top: 2rem;
                        height: auto;
                        opacity: 0;
                        max-height: 0;
                        z-index: -1;
                        padding-left: 0;

                        &.is-open{
                            position: relative;
                            z-index: 1;
                            max-height: 9999px;
                            opacity: 1;
                            transition: 1s max-height linear;
                        }
                    }
                }

                /**
                &::-webkit-scrollbar {
                    -webkit-appearance: none;                 
                }
                &::-webkit-scrollbar:vertical {
                    width:5px;
                }
                &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
                    display: none;
                } 
                &::-webkit-scrollbar:horizontal {
                    padding-right: .5rem;
                    height: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $colorCorporative;
                    border-radius: 0 !important;                    
                    border: none;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba($colorCorporative, 0.5);
                    border-radius: 0 !important;  
                    margin-top: 2rem;
                    margin-bottom: 7rem;
                }
                */
            }
        }
    }
}

@media (max-width: $md-width) {
    .new-store{
        .wrap-container{
            .article-users{
                //margin-bottom: 6.7rem;
                margin-bottom: 7.7rem;

                .subtitle.text-subtitle{
                    max-width: 28rem;
                }

                .wrap-users{
                    .users2, 
                    .users3{
                        grid-gap: unset;
                        overflow: hidden;
                        overflow-x: auto;
                        width: calc(100% + 3rem);
                        position: relative;
                        left: -1.5rem;
                        column-gap: 1.6rem;
                        display: flex;
                        padding-bottom: 2rem;
                    }
                    .item-user{
                        width: calc(80% - 1.5rem);
                        min-width: calc(80% - 1.5rem);
                        position: relative;

                        .wrap-img{
                            .img-responsive{
                                aspect-ratio: 1/1;
                            }
                        }

                        .name{
                            margin-top: 1.8rem;
                        }

                        &:first-of-type{
                            margin-left: $paddingLateralXs;
                        }
                        &:last-of-type{
                            margin-right: $paddingLateralXs;
                        }
                    }
                }
            }
        }
    }
}