.new-store{
    .wrap-content{
        .article-cita{
            //width: calc(100% + 5rem);
            margin-bottom: 10.7rem;
           
            .wrap-header{
                width: 100%;
                .header-title{
                    max-width: 38rem;
                }
                .box-content .title {
                    //margin-bottom: 0;
                }
                .header-part-content{
                    width: 56%;
                    max-width: calc(#{$max-width-detail-store} / 2);
                    position: relative;
                    margin: 0 auto;
                    margin-left: 0;
                    background-color: $colorRoseLight;
                    //padding: 5.7rem 5rem 3.5rem 5.8rem;
                    padding: 5.8rem 0rem 2rem 4rem;
                    padding-bottom: 0;
                
                }
                .subtitle.text-subtitle{
                    margin-bottom: 0;
                    margin-top: 3.5rem;
                }
            }
            .wrap-content-info{
                width: 56%;
                //max-width: calc(#{$max-width-detail-store}  / 2);
                margin: 0 auto;
                margin-left: 0;
                background-color: $colorRoseLight;
                //padding: 2rem 6rem 7rem 5.8rem;
                padding: 0rem 5rem 7rem 4rem;
                
            }
            .content{
                max-width: $max-width-detail-store ;
                margin: 0 auto;
                width: 100%;
                position: relative;

                &::before{
                    content: '';
                    position: absolute;
                    background-color: $colorRoseLight;
                    width: 85%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }


            }
            .part-content{
                width: 100%;
                max-width: calc(#{$max-width-detail-store}  / 2);
                position: relative;
                margin: 0 auto;
                margin-left: 0;
                background-color: $colorRoseLight;
                padding: 0;

                .wrap-one-img.isImg{
                    margin-bottom: 3rem;
                }

                .description{
                    padding-bottom: 3rem;
                }
                .wrap-contact{
                    padding-bottom: 3rem;

                    .contact{
                        @include font_mont(Medium, 1.5);
                        line-height: 2.3rem;
                        a{
                            color: $colorCorporative;
                        }
                    }
                }
            }
            .part-img{
                width: 44%;
                position: absolute;
                //right: -5rem;
                right: 0;
                bottom: -3rem;
                aspect-ratio: 3/2;

                .wrap-one-img{
                    overflow: hidden;
                    position: absolute;
                    bottom: 0;
                }
                &.isSlider{
                    max-width: 36rem;
                    bottom: -3rem;
                    top: unset;
                }
                .part-slider {
                    width: 100%;
                    margin-top: 2rem;
    
                    .aux-slider{
                        .wrap-slider{
                            width: 100%;
        
                            .aux-box{
                                padding-bottom: 3.7rem;
                            }
                            .slider-slick-detail-store.slick-slider{
                                .slick-arrow{
                                    display: none !important;
                                }
                                .slick-list{
                                    padding: 0 !important;
                                    cursor: grab;                                
                                    &.dragging, &.draggable:active:hover {
                                        cursor: grabbing;
                                    }
                                    .slick-slide{                               
                                        .item{
                                            position: relative;
                                            padding-top: 120%;                                    
                                            .img{
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.desk{
                            flex-grow: 1;
                        }
                    }
  
                }
            }

            .wrap-btnDefault{
                width: fit-content;

                .btnDefault{
                    padding: 1.4rem 4rem;
                }
            }
        }
    }
    //CALENDLY
    .wrap-calendly{
        display: none;
        position: fixed;
        top: 50%;
        z-index: 101;
        width: 100%;
        left: 50%;
        width: 100%;
        height: 100vh;
        transform: translate(-50%, -50%);

        .wrap-calendly-widget{
            height: 100%;
        }
        .calendly-inline-widget{
            padding-left: 27rem;
            height: 100% !important;
        }
        
        &.isOpen{
            display: block;

            &::before{
                content: '';
                position: absolute;
                background-color: rgba($colorBlack, 0.6);
                width: 100%;
                height: 100vh;
                left: 0;
                top: 0;
            }
        }
        .calendly-content{
            background-color: $colorWhite;
            height: 100%;
            transform: translate(-50%, -50%);
            position: relative;
            top: 50%;
            left: 50%;
            height: 90%;
            width: 90%;
            padding: 6rem 9rem 9rem 6rem;

            .wrap-one-img.isImg{
                padding-top: 4.3rem;
                .img-responsive{
                    max-height: 6rem;
                }
            }
        }
        .wrap-info-store{
            position: absolute;
            max-width: 27rem;
            z-index: 1;

            .contact{
                line-height: 2.4rem;
 
                a{
                    @include font_mont(Medium, 1.5);
                    color: #575252;
        
                    --border-color: #575252;
                    --border-width: 1px;
                    --bottom-distance: 0px; /* you can increase this */    
                    
                    display: inline;
                    background-image: linear-gradient(var(--border-color), var(--border-color));         
                    background-size: 0% var(--border-width);
                    background-repeat: no-repeat;
                    background-position-y: bottom;
                    transition: background-size 0.3s;         
                    
                    &:hover{
                        background-size: 100% var(--border-width);
                    }
                }    
            
            }
        }
        .calendly-close {
            width: 2.3rem;
            height: 2.3rem;
            position: absolute;
            right: 3.4rem;
            top: 3.4rem;
 
            .btn-close {    
                position: relative;
                padding: 0;
                background-color: transparent;          
                -webkit-transition: 0.3s ease-in all;
                transition: 0.3s ease-in all;
                z-index: 1040;
                width: 2.3rem;
                height: 2.3rem;
                opacity: .75;

                &:hover{
                    background-color: transparent;
                }
        
                &::before{
                    content: "";
                    position: absolute;
                    background-image: url('/images/fl-close-black.svg');
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                
                &:hover{
                    opacity: 1;            
                }
            }
        }
    }
    //END CALENDLY
}

@media (max-width: $md-width) {
    .new-store{
        .wrap-content{
            .article-cita{
                //margin-bottom: 7.7rem;
                margin-bottom: 0;
                
                .content{
                    max-width: 100%;
                    &:before{
                        content: none;
                    }
                    .wrap-header{
                        background-color: $colorRoseLight;
                        width: calc(100% - 2.7rem);
                        margin: 0 auto;
                        margin-left: 0;

                        .header-part-content{
                            width: 50%;
                            min-width: 36rem;
                            padding: 4rem 0 2rem 2.7rem;
                            background-color: transparent;
                        }
                    }
                }
                .wrap-content-info{
                    width: 100%;
                    background-color: unset;
                    padding: 0;
                }
                .part-content{
                    padding: 0 2.7rem 10.7rem 2.7rem;
                    max-width: 100%;
                    width: calc(100% - 2.7rem);
                }
                .part-img{
                    position: relative;
                    left: unset;
                    width: 100%;
                    max-width: 100%;
                    padding: 0 0 0 2.7rem;
                    @media (max-width: $md-width) {
                        bottom: 7rem;
                    }

                    &.isSlider{
                        bottom: unset;
                        max-width: 100%;
                        left: unset;
                    }
                    .part-slider{
                        margin-top: -5.2rem;
                    }
                    .aux-slider {
                        width: 100%;
                        transform: unset;
                    }
                }
            }
        }

        .wrap-calendly{
            .calendly-content{
                padding: $paddingLateralXs;
                overflow-x: hidden;
                overflow-y: auto;
            }
            .wrap-info-store{
                position: relative;
            }
            .wrap-calendly-widget{
                width: 100%;
                overflow: hidden;
                position: relative
            }
            .calendly-inline-widget{
                position: relative;
                padding-left: 0;
                overflow: hidden !important;
                overflow-y: hidden !important;
                height: 100% !important;
            }
        } 
    } 
}