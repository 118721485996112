// Modals

.modal {
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zindex-modal;
    overflow-y: auto;
}

.modal-open {
 	overflow: auto;
}

.modal-header {
	padding: 20px 20px 15px 20px;
	border-bottom: 1px solid $colorBlack;

	> .close {
		margin-top: -4px;
	}
}

.modal-body {
	padding: 20px;	
}

.modal-footer {
	padding: 20px;
	border-top: 1px solid $colorBlack;
}

.modal-title {
	font-weight: 500;
	font-size: 18px;
	color: $colorBlack;
}