#legals_famaliving2023 {
    padding: 0 $paddingLateral;
    margin-bottom: 13rem;

    .limited-wrap {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 16.4rem;
    }


    .wrap-content{
        .description {
            @include font_mont(Regular, 1.5);
            line-height: 2.3rem;

            h5 {
                @include font_mont(SemiBold, 1.5);
                margin-bottom: 1.5rem;
            }
            h6 {
                @include font_mont(SemiBold, 1.4);
                margin-bottom: 1.5rem;
            }
            h2 {
                @include font_mont(SemiBold, 2.1);
                margin-bottom: 1.5rem;
                margin-top: 3rem;
            }

            &.large {
                @include font_mont(Regular, 2.3);
                line-height: 4.1rem;
            }
            p {
                @include font_mont(Regular, 1.5);
            }
            a{
                color: $colorCorporative;
                text-decoration: underline;
                transition: .2s color;
            }
            a:hover{
                color: $colorInfo;
                transition: .2s color;
            }
            table{
                td{
                    padding-right: 5rem;
                }
            }
        }
    }


    @media screen and (max-width: $md-width) {
        padding: 0 $paddingLateralXs;
        margin-bottom: 10rem;
    
        .limited-wrap {
            padding-top: 12rem;
        }
    
    }

    @media screen and (max-width: 768px) {
       
        .wrap-content{
            .description{
                table{
                    td{
                        padding-right: 1.5rem;
                    }
                }
                a {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@media (max-width: $md-width) {
    .main{
        #legals_famaliving2023 .limited-wrap,
        .conteiner-content.simulations.famaliving{
            padding-top: 9rem;
        }
    }
}
