.footer_page.footer{
    margin-top: 0;
    background-color: $colorWhite;
    color: inherit;
    padding: inherit;

    button{
        height: auto;
        padding-left: 0;
    }

    ul li a{
        line-height: inherit;
        color: inherit;
        transition: unset;
    }

    .wrap-container{
        padding-bottom: 2.8rem;
    }
    .name-item{
        text-transform: uppercase;
        margin-bottom: 1.4rem;
    }
    .has-children,
    .not-child, 
    .name-item{
        color: $grayDark2;
        @include font_mont(Medium, 1.5);
        margin-bottom: 1.1rem;

        .li-item{
    
            &:not(:last-of-type){
                margin-bottom: 1.1rem;
            }
        }
    }

    .menu-legal{
        margin-top: 4.1rem;

        .page{
            @include font_mont(Regular, 1.5);
            &:not(:last-of-type){
                margin-bottom: 1.1rem;
            }

            &:hover{
                a{
                    color: $colorCorporative;
                    transition: .2s color ease-in-out;
                }
            }
        }
    }
   
    .part-up{
        padding: 0 $paddingLateral;
        @include grid(4,1rem,10rem);
        &>.md-visible{
            display: flex !important;
        }
        .column-1{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .logo{
                img{
                    max-height: 4.2rem;
                }
            }
        }
        .li-item{
            &:hover{
                a{
                    color: $colorCorporative;
                    transition: .2s color ease-in-out;
                }
                &::before{
                    transition: .2s color ease-in-out;
                    background-color: $colorCorporative !important;
                }
            }
        }
        .column-4.md-visible{
            display: block !important;
        }
        .column-3,
        .column-4{
         
            .has-children,
            .not-child{
                @include font_mont(Medium, 1.3);
                border: none;
                padding: 0;
               
            }
            .not-child{
                margin-bottom: 0;
            }
            .li-item{
                position: relative;
                margin-bottom: 0;
                padding: 1.1rem 0;
                padding-left: .7rem;
                
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: calc(1.3rem + 1.1rem);
                    width: .5rem;
                    height: 1px;
                    background-color: $grayDark2;
                }
               
            }
        }

        .column-3.md-visible{
            display: block !important;
        }
    }  

    .part-down{
        padding: 0 $paddingLateral;
        margin-top: 3.6rem;

        @include grid(4);
        .column-1{
            grid-column: 1 / 2;
        }
        .column-2{
            grid-column: 2 / 5;
            width: calc(100% + #{$paddingLateral});
            background-color: $colorRoseLight;
            @include center_vertical;
            padding: 9px $paddingLateral 9px 1.7rem;
        }
        .redes{
            flex-wrap: wrap;
            align-items: center;
        }
       
        .red-item{
            position: relative;
            @include font_mont(Medium, 1.5);
            
            &:not(:last-of-type){
                padding-right: 3.5rem;

                &::after{
                    content: '';
                    position: absolute;
                    width: .5rem;
                    height: .5rem;
                    border-radius: 50%;
                    background-color: $colorCorporative;
                    right: calc(3.5rem / 2);
                    top: 50%;
                    transform: translate(50%, -50%); 
                }
                
            }
        }
        .item-newsletter{
            margin-left: 7.4rem;
            @include font_mont(Bold, 1.5);
            @include center_vertical;
            transition: .2s color ease-in-out;
            color: $grayDark2;
            background-color: transparent;
            cursor: pointer;
            width: auto;
            margin-top: 0;

            &:hover{
                color: $colorCorporative;
                transition: .2s color ease-in-out;
            }
        }
      
        
    }
    #languages{
        z-index: 1;
        position: relative;
    }

}


@media (max-width: $screen-sm-max){
    .footer_page.footer{
        position: relative;
        overflow: hidden;
    
        .wrap-container{
            padding: 0;
            padding-top: 4.5rem;
            padding-bottom: 3.4rem;
        }
        .name-item{
            text-transform: uppercase;
            margin-bottom: 1.4rem;
        }
        .part-up .column-4.md-visible, 
        .part-up .md-visible{
            display: none !important;
        }
        .has-children,
        .not-child, 
        .name-item{
            color: $grayDark2;
            @include font_mont(Medium, 1.5);
            //padding: 0.7rem 0;
            margin-bottom: 0;

            .li-item{
        
                &:not(:last-of-type){
                    margin-bottom: 1.1rem;
                }
            }
        }
    
        .menu-legal{
            //margin-top: 4.1rem;
            margin-top:0;
    
            .page{
                @include font_mont(Regular, 1.5);
                &:not(:last-of-type){
                    margin-bottom: 1.1rem;
                }
            }
        }
       
        .part-up{
            padding: 0 $paddingLateralXs;
            @include grid(2,4rem);
            grid-auto-rows: minmax(6rem, auto);
    
            .column-2,
            .column-3,
            .column-4{
                .has-children,
                .not-child{
                    @include font_mont(Medium, 1.5);
                    //margin-bottom: 1.4rem;
                    line-height: 2.1rem;
                    margin-bottom: 0;
                }
                .li-item{
                    position: relative;
                    margin-bottom: 0;
                    //padding: 1.1rem 0;
                    padding: 0.7rem 0;
                    //padding-left: .7rem;
                    padding-left: 0;
                    text-transform: lowercase;
                    &::first-letter{
                        text-transform: uppercase;
                    }
                    
                    &::before{
                        content: none;
                        position: absolute;
                        left: 0;
                        //top: calc(1.4rem + 1rem);
                        top: unset;
                        bottom: 1rem;
                        width: .5rem;
                        height: 1px;
                        background-color: $grayDark2;
                    }
                }
            }
            .column-2{
                .logo{
                    img{
                        max-height: 4.2rem;
                    }
                }
            }
            .column-3.md-hidden{
                flex-grow: 1;
                display: flex !important;
                flex-direction: column;
                justify-content: flex-end;

                .menu-legal{
                    font-size: 1.5rem;
                }
            }

            .column-3.md-visible{
                display: none !important;
            }
            .column-5-mvl{
                display: flex !important;
                flex-direction: column;
                height: 100%;

                .not-child{
                    margin: 0;
                    padding: 0;
                }
            }
            .column-6{
                @include font_mont(Bold, 1.5);
            }

            .item-newsletter{
                @include font_mont(Bold, 1.5);
                color: $grayDark2;
            }
        }  
    
        .part-down{
            padding: 0 $paddingLateralXs;
            @include center_vertical;

            #languages{
                margin: 0;
             
            }
            
            .section-lang{
                order: 2;
                margin: 0 auto;
                margin-top: 2.8rem;
                margin-bottom: 2.4rem;
            }
            .column-2{
                order: 1;
                grid-column: 1/5;
                width: 100%;
                padding: 2.7rem 4.1rem;
                padding-bottom: 1.7rem;
            }
            .column-1{
                order: 3;
                grid-column: 1/5;
                border-top: 1px solid $colorCorporative;
                width: calc(100% + ($paddingLateralXs * 2));
                margin-left: - $paddingLateralXs;
                padding: 1.5rem;
                text-align: center;
            }
    
            .wrap-redes{
                width: 100%;
                background-color: $colorRoseLight;
    
                .redes{
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    max-width: fit-content;
                    max-width: 28rem;
                }
                .circl{
                    position: relative;
                    width: 14%;
    
                    &::after{
                        content: '';
                        position: absolute;
                        width: .5rem;
                        height: .5rem;
                        border-radius: 50%;
                        background-color: $colorCorporative;
                        top: calc(50% - .5rem);
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .red-item{
                    position: relative;
                    @include font_mont(Medium, 1.5);
                    margin-bottom: 1rem;
                    padding-right: 0;
                    text-align: center;
    
                    &:nth-child(3n){
                        margin-right: 0;
                    }

                    &:after{
                        content: none;
                    }
                }
            }

            .item-newsletter{
                margin-left: 7.4rem;
                @include font_mont(Bold, 1.5);
                @include center_vertical;
            }
          
        }

    }
}


@media only screen and (max-width: $screen-xs-min){
    .footer_page.footer .part-down .column-2{
        padding: 2.7rem 3rem;
        padding-bottom: 1.7rem;
    }

}
