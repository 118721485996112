.wrap-banner{
    min-height: 4rem;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 $paddingLateral;
    margin-top: calc(3.7rem + 6.5rem + .5rem);
    z-index: 100;
    color: $colorWhite;
    text-align: center;
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
    @include font_mont(Regular, 1.5);

    .wrap-content{
        height: 100%;
        padding: 1.5rem;
        background-color: #7c1f5e;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    b, a{
        font-family: 'Montserrat-Bold';
        font-weight: normal;
    }
    a{
        text-decoration: underline;
    }
    *{
        color: $colorWhite !important;
    }
    p{
        margin-bottom: 0;
        font-weight: normal;
        @include font_mont(Regular, 1.5);
    }

    @media (max-width: $md-width) {
        @include font_mont(Regular, 1.3);
        margin-top: 7.5rem;
        padding: 0 $paddingLateralXs !important;

        &.isMobile{
            padding: 0 !important;
        }
        
        .wrap-content{
            padding: 1.2rem;
        }
    }

}