

.aux-wrap-seccion-slider-home{
	width: 100%;
    background-color: $colorCorporative;
	.wrap-seccion-slider-home{
		width: 100%;
		margin: 0 auto;		
	}
}
.seccion-slider-home{
	position: relative;
    padding-top: 100vh;
    width: 100%;
	.container.slider-home{
		top: 0;
		position: absolute;
		left: 0;
		height: 100%;
		.slick-list{
			padding: 0 !important;

			.slick-slide{
				&>div:first-child{
					height: 100%;
				}
				.item-slide{
					height: 100%;
				}
			}
		}
	}
}

.seccion-slider-home .scrollbar-home{
	position: absolute;
	bottom: 3.9rem;
}

.container.slider-home{
	@media screen AND (orientation: portrait) AND (max-width: $screen-sm-max){
		&.hasvideo{
			height: auto;
		}
	}

	.listpadding{
		position: absolute;
		bottom: 5vh;
		right: 100px;
		z-index: 1;
	}
}
.slider-home .volume{
	position: absolute;
	top: 50px;
	left: 50px;
	color: $colorWhite;
	text-shadow: 0 0 3px $colorBlack, 0 0 3px transparent, 0 0 3px transparent;
	font-size: 30px;
	z-index: 1;
	cursor: pointer;

	&:hover
	{
		text-shadow: 0 0 3px $colorBlack, 0 0 30px $colorWhite, 0 0 60px $colorWhite;
	}

	& .volume-icon.muted
	{
		display: none;
	}
	& .volume-icon.actived
	{
		display: block;
	}
	&.muted .volume-icon.muted
	{
		display: block;
	}
	&.muted .volume-icon.actived
	{
		display: none;
	}


}
@media (max-width: $screen-sm-max){
	.block-text	{
		.item-news-title{
			position: static;
		}
	}

	// slider principal home
	.container.slider-home	{
		padding: 0;
		margin: 0;
		height: 100vh;
		overflow: hidden;
		position: relative;
        margin-top: 0;
		margin-bottom: 0;		

		.aux-item{
			.wrap-img{    
				height: 100%;
				width: 100%;
				position: relative;
			}
		}

		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
			}
		}
		.slick-slide{
			position: relative;
			height: 100%;
			overflow: hidden;
			width: 100vw;

			
			.aux-text-item.mvl{
				z-index: 2;
				display: flex;
				flex-direction: column;
			}
			.wrap-color{
				display: none;
			}
			.section-title{
				font-size: 1.5rem;
				line-height: 2.2rem;
				letter-spacing: 5px;
				color: #fff;
				padding-bottom: 1rem;
				text-align: left;

			}
			.pasador-title{
				color: $colorWhite;
				font-weight: normal;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #fff;
				border-top: 1px solid #fff;
				font-family: 'Montserrat-ExtraBold';
				font-size: 6rem;
			}
			.pasador-text{
				font-size: 6rem;
				width: 67.5%;
				max-width: 25.3rem;
				color: #FFF;
				font-weight: normal;
				font-size: 18px;
				position: absolute;
				bottom: 11.5rem;
				left: 3.4rem;
				top: unset;
				background-color: transparent;
				padding: 0;

			}

            .slider-image,
			video{
				width: 100%;
				height: 100%;
				position: relative;
				@include object-fit(cover);
				filter: brightness(80%);
			}

		}
		.slick-prev,
		.slick-next{
			background-image: none!important;
			width: auto;
			height: auto;
			z-index: 1;

			.icon{
				font-size: 40px;
				color: $colorWhite;
				-webkit-transition: 300ms color ease-in;
				transition: 300ms color ease-in;
				text-shadow: 0px 0px 10px rgba(0,0,0,0.7);
			}

			&:hover{
				color: $colorPrimary;

				.famaliving &{
					color: $colorCorporative;
				}
			}
		}

		.slick-prev{
			left: 5px;
		}
		.slick-next{
			right: 5px;
		}

	}
}
@media (min-width: $screen-md-min) {
	.newimg	{
		height: 100%!important;
	}

	.container.home-items{
		padding-top: 0;
	}
	.container.slider-home	{
		padding: 0;
		margin: 0;	
		height: 100vh;
		overflow: hidden;
		position: relative;
	    margin-top: 0;
		max-width: unset;

		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
			}
		}
		.slick-slide{
			position: relative;
			height: 100%;
			overflow: hidden;			
			.aux-text-item.mvl{
				display: none;
				z-index: 2;

			}
			.wrap-color{				
				min-width: 21vw;
				width: 21%;
				max-width: 21%;

				&::before{
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					background-color: inherit;
				}

			}
			.section-title{
				letter-spacing: .5rem;
				color: #fff;
				font-family: 'Montserrat-Regular';
				line-height: 2.2;
				font-size: 1.5rem;
				text-align: left;
				text-transform: uppercase;
				margin: 0;
			}
			.pasador-title{
				color: $colorWhite;
				padding-top: 2rem;
				padding-bottom: 2rem;
				border-bottom: 1px solid #fff;
				border-top: 1px solid #fff;
				font-family: 'Montserrat-ExtraBold';				
				font-size: 7.5vw;
			}
			.aux-text-item{
				top: 40%;
				width: 40%;
				position: absolute;
				margin: 0 auto;			
				left: 8%;
				z-index: 3;
				@media (min-width: $sm-width) AND (max-width: 1400px){
					padding: 0 5rem;
					.pasador-text{
						.pasador-title{
							font-size: 8vw;
						}
					}
				}

			}
			.pasador-text{
				color: $colorWhite;
				font-family: $fontStack1;
				font-weight: normal;
				position: relative;				
				background-color: transparent;
				padding: 0;
				padding-left: 0;
			}

			.aux-item{
				width: 100%;
				display: flex;
				position: relative;
				justify-content: flex-end;

				.wrap-img{    
					height: 100%;
					width: 100%;
					position: relative;
				}
			}


			.slider-image,
			video{
				width: 100%;
				height: 100%;
				position: relative;
				object-fit: contain;
				@include object-fit(cover);
				filter: brightness(80%);
			}
			.hotspot-marker.box-hotspot{
				z-index: 10;
				cursor: pointer;
				&:hover{
					.hotspot-marker-img + .wrap-hotspot-info{
						display: block;
						opacity: 1;
						visibility: visible;
						transition: .5s .2s opacity ease;

					}
					.hotspot-marker-img{

						opacity: 0;
						transition: .2s opacity ease;

						&.isHover{
							opacity: 1;
							transition: .2s opacity ease;
						}

					}

				}

				.hotspot-marker-img{

					&.isHover{
						opacity: 0;
						transition: .2s opacity ease;
					}
				}
				.hotspot-marker-img + .wrap-hotspot-info{				
					display: none;
					visibility: hidden;
					opacity: 0;
					transition: .5s .2s opacity ease;

					.hotspot-content{
						display: flex;
    					flex-direction: column;
						padding: 1.4rem;
						width: 180px;
						height: auto;

						.title{
							font-size: 1.5rem;
							letter-spacing: 1.3px;
							color: inherit;
							font-family: 'Montserrat-Regular';
						}
						.text{
							color: inherit;
							padding-bottom: 2.7rem;
							font-family: 'Montserrat-Bold';
							text-transform: uppercase;
						}
						.link{
							color: inherit;
							width: 100%;
							text-align: end;
							font-size: 1.1rem;
							position: relative;
							padding-right: 2rem;

							&::after{
								content: '';
								background-image: url('/images/arrow-white-right.svg');
								background-repeat: no-repeat;
								width: 2rem;
								height: 100%;
								position: absolute;
								right: -.5rem;
								top: 0.1rem;

							}

						}
					}
					.aux-wrap{
						position: relative;
						height: 100%;
						width: 100%;
						color: #fff;
						background-color: rgba(255, 255, 255, 0.1);
						backdrop-filter: blur(5px);
					}
				}
			}

		}
		.swiper-button-prev,
		.swiper-button-next	{
			background-image: none!important;
			width: auto;
			height: auto;

			.icon{
				font-size: 40px;
				color: $colorWhite;
				-webkit-transition: 300ms color ease-in;
				transition: 300ms color ease-in;
			}

			&:hover	{
				color: $colorPrimary;
			}
		}

		.slick-arrow{
			z-index: 1;
		}

		.slick-prev	{
			transform: translateX(5rem);
    		left: 0;
			display: none;
			width: 4rem;
			height: 4rem;

			&:before{
				content: '';
				background-image: url('/images/fl-left.svg');
				width: 100%;
				height: 100%;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				top: 0;
				left: 0;
			}

		}
		.slick-next	{
			transform: translateX(-5rem);
    		right: 0;
			display: none;
			width: 4rem;
			height: 4rem;

			&:before{
				content: '';
				background-image: url('/images/fl-right.svg');
				width: 100%;
				height: 100%;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				top: 0;
				left: 0;
			}
		}
		&:hover{

			.slick-prev, .slick-next{
				display: block;
				opacity: 1;
			}

		}
	}

	.carousel-inner > .item > img, .carousel-inner > .item > a > img{
		height: 100%;
	}
}

@media (max-width: $screen-sm-max){
// slider principal home
.container.slider-home	{
	padding: 0;
	margin: 0;
	height: 100vh;
	overflow: hidden;
	position: relative;
	margin-top: 0;
	margin-bottom: 0;		

	.aux-item{
		.wrap-img{    
			height: 100%;
			width: 100%;
			position: relative;
		}
	}

	.slick-list{
		height: 100%;
		.slick-track{
			height: 100%;
		}
	}
	.slick-slide{
		position: relative;
		height: 100%;
		overflow: hidden;
		width: 100vw;
		.aux-text-item.mvl{
			z-index: 2;
			display: flex;
			flex-direction: column;
		}
		.wrap-color{
			display: none;
		}
		.section-title{
			font-size: 1.5rem;
			line-height: 2.2rem;
			letter-spacing: 5px;
			color: #fff;
			padding-bottom: 1rem;
			text-align: left;

		}
		.pasador-title{
			color: $colorWhite;
			font-weight: normal;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #fff;
			border-top: 1px solid #fff;
			font-family: 'Montserrat-ExtraBold';
			font-size: 6rem;
		}
		.pasador-text{
			font-size: 6rem;
			width: 67.5%;
			max-width: 25.3rem;
			color: #FFF;
			font-weight: normal;
			font-size: 18px;
			position: absolute;
			bottom: 11.5rem;
			left: 3.4rem;
			top: unset;
			background-color: transparent;
			padding: 0;

		}

		.slider-image,
		video{
			width: 100%;
			height: 100%;
			position: relative;
			@include object-fit(cover);
			filter: brightness(80%);
		}

	}
	.slick-prev,
	.slick-next{
		background-image: none!important;
		width: auto;
		height: auto;
		z-index: 1;

		.icon{
			font-size: 40px;
			color: $colorWhite;
			-webkit-transition: 300ms color ease-in;
			transition: 300ms color ease-in;
			text-shadow: 0px 0px 10px rgba(0,0,0,0.7);
		}

		&:hover{
			color: $colorPrimary;

			.famaliving &{
				color: $colorCorporative;
			}
		}
	}

	.slick-prev{
		left: 5px;
	}
	.slick-next{
		right: 5px;
	}

}
}
@media (max-width: $md-width) {
	.hotspot-marker-img, .hotspot-marker-img + .wrap-hotspot-info{
		display: none !important;
		visibility: hidden !important;
	}
}