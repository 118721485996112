.blockSelected{
    padding: 0 $paddingLateral;
    .subtitle{
        @include font_mont(Medium, 1.5);
        text-transform: uppercase;
        line-height: 2.5rem;
        letter-spacing: 1.5px;
        color: $colorCorporative ;
        margin-bottom: 4.7rem;
    }
    .border{
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid $colorCorporative;
        top: 2rem;
        left: 2rem;
    }
    &.format2{
        padding-bottom: 11.9rem;
   
        .wrap-content{
            display: block;

            .header-title{
                width: 100%;
            }
            .part-right{
                width: calc(58% + 12.3rem);
                padding: 8.4rem 11.4rem 8.8rem 23.5rem;
                flex-direction: column;
                float: left; 
                margin-left: -12.3rem;
            }
            .part-left{
                width: 42%;
                float: left; 
                position: relative;
                z-index: 2;

                .wrap-img{
                    margin-top: 3.2rem;
                    position: relative;
                    width: auto;
                    height: fit-content;

                    .img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        position: relative;
                        z-index: 1;
                    }
                }  
            }
    
            .wrap-btnDefault{
                width: 100%;
            }
            .title.small-line{
                font-size: 4.5rem;
            }
            .small-line{
                margin-bottom: 5rem;
               
                &::before {
                    bottom: -1.4rem;
                }
            }
        }
    }
 
    &.format3{
        
    }
    &.format4{
        
    }
    &.format5{
        
    }
    &.format6{
        
    }
    &.format7{
        
    }
    &.format8{
        
    }
    &.format9{
        padding: 0;

        .wrap-container{
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 12.9rem;
        }
        .wrap-content{
            padding: 3.6rem 9.4rem 8.9rem 9.4rem;
            margin-top: -13rem;
        }
        .box-content{
            .title {
                width: fit-content;
                margin: 0 auto;
                margin-bottom: 4.1rem;
                font-size: 5.5rem;
    
                &::before{
                    left: 50%;
                    margin: 0 auto;
                    bottom: -1.3rem;
                    transform: translateX(-50%);
                }
            }
        } 
        .text.parrafo{
            margin-bottom: 6.1rem;
            *{
                margin-bottom: 1.5rem;
            }
        }
        .text.parrafo, .text.parrafo *{
            @include font_mont(Medium, 1.5);
            line-height: 2.3rem;
            text-align: center;
        }
        .wrap-btnDefault{
            width: fit-content;
            margin: 0 auto;
            margin-bottom: -11.5rem;

            .btnDefault{
                padding: 1.7rem 11.3rem;
            }
        }

        .wrap-img .img{
            aspect-ratio: 2.5;
            object-fit: cover;
            object-position: center;
            max-height: 60rem;
            width: 100%;
            height: 100%;
        }
    }
    &.format10{
        
    }
    &.format11{
        
    }
    &.format12{
        
    }
    &.format13{
        
    }
    &.format14{
        
    }

}

@media screen and (max-width: $md-width) {
    .blockSelected{
        padding: 0 $paddingLateralXs;
        .subtitle{
            @include font_mont(Medium, 1.3);
            line-height: 2.5rem;
            letter-spacing: 1.3px;
            margin-bottom: 2.8rem;
        }
        .border{
            top: 1.5rem;
            left: 1.5rem;
        }
        &.format2{
            padding-bottom: 6.2rem;
            .wrap-content{
                .header-title{
                    width: 100%;
                }
                .part-right{
                    width: calc(100% - 1.5rem);
                    padding: 12rem 1.5rem 3.9rem 2.6rem;
                    float: unset;
                    margin: 0;
                    margin-left: 3rem;
                    margin-top: -7.7rem;
                    position: relative;
    
                    .wrap-img{
                        margin-top: 0;
                        width: calc(100% - 1.5rem);
                        //margin-left: calc(-1.5rem / 2);
                        margin-left: 0;
                    }
                }
                .part-left{
                    width: 100%;
                    float: unset; 
    
                    .wrap-img{
                        margin-top: 0;
                        width: calc(100% - 3rem);
                        margin-left: 0;
    
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            position: relative;
                            z-index: 1;
                        }
                    }  
                }
        
                .wrap-btnDefault{
                    width: 100%;
                }
                .small-line{
                    margin-bottom: 5rem;
                    &::before {
                        bottom: -1.4rem;
                    }
                }
                .title.small-line{
                    @include font_mont(SemiBold, 2.9);
                    line-height: normal;
                }
            }
        }
     
        &.format3{
            
        }
        &.format4{
            
        }
        &.format5{
            
        }
        &.format6{
            
        }
        &.format7{
            
        }
        &.format8{
            
        }
        &.format9{
            padding: 0;

            .wrap-container{
                margin-bottom: 8.3rem;
                padding: 0 1.5rem ;
            }
            .wrap-content{
                padding: 2.7rem 2.4rem 9rem 2.4rem;
                margin-top: -8rem;
            }
            .box-content{
                .title {
                    margin-bottom: 4.8rem;
                    font-size: 2.9rem;
                    line-height: 3.9rem;
                    text-align: center;
                }
            } 
            .text.parrafo{
                margin-bottom: 3.5rem;
            }
            .wrap-btnDefault{
                width: fit-content;
                margin: 0 auto;
                margin-bottom: -11.5rem;
                width: 90%;
                max-width: 27.9rem;

                .btnDefault{
                    padding: 0.9rem;
                    font-size: 1.5rem;
                    letter-spacing: .2rem;
                }
            }

            .wrap-img .img{
                aspect-ratio: 2;
            }
        }
        &.format10{
            
        }
        &.format11{
            
        }
        &.format12{
            
        }
        &.format13{
            
        }
        &.format14{
            
        }
    
    }
}