.stores-aggrupated-famaliving {
    padding: 0 $paddingLateral;

    .limited-wrap{
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
    }

    .wrap-title {
        padding-top: 16rem;
        max-width: 30rem;
    }

    .box-content .title {
        margin-bottom: 6.2rem;
        @include font_mont(SemiBold, 3.5);
        h1{
            font-size: inherit;
        }
        .span1 {
            color: $colorCorporative;
        }
        
        &.small-line:not(.sinBorder):before {
            bottom: -1rem;
        }
    }

    .wrap-shops {

        .wrap-shops-left {
            .wrap-shops-image {
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 53.2rem;
                }
            }

            .wrap-shops-blocks {
                display: flex;
                width: 100%;
                margin-top: 2rem;
                margin-bottom: 4rem;

                .wrap-shops-block {
                    width: 50%;

                    &:first-child {
                        background-color: $colorCorporative;
                        color: white;
                        margin-right: 1.5rem;
                    }

                    &:nth-child(2) {
                        background-color: #f3f6f7;
                        color: $colorCorporative;
                        margin-left: 1.5rem;
                    }

                    padding: 3rem 1.5rem;

                    .title {
                        font-family: 'Montserrat-SemiBold', sans-serif;
                        font-size: 2.1rem;
                        line-height: 2.6rem;
                        text-align: center;
                        margin-bottom: 2rem;
                        pointer-events: none;
                    }

                    .content {
                        font-family: 'Montserrat-Regular', sans-serif;
                        font-size: 1.5rem;
                        line-height: 2.3rem;
                        text-align: center;
                    }
                }
            }
        }

        .wrap-shops-right {
            .wrap-shops-list {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .shop-item {
                    //max-width: 20rem;
                    width: calc(50% - 4rem);
                    margin-left: 4rem;
                    margin-bottom: 6rem;
                    background-color: transparent;
                    position: relative;

                    &:hover {
                        .background-shop {
                            opacity: 1;
                        }
                    }

                    .shop-item-content {
                        min-height: 20rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .background-shop {
                        content: "";
                        position: absolute;
                        width: calc(100% + 4rem);
                        height: calc(100% + 4rem);
                        transform: translate(-50%, -50%);
                        z-index: -1;
                        opacity: 0;
                        background-color: #f3f6f7;
                        transition: opacity 0.5s ease-in-out;
                        left: 50%;
                        top: 50%;
                    }

                    .title {
                        font-size: 2.1rem;
                        font-family: 'Montserrat-SemiBold', sans-serif;
                        pointer-events: none;
                    }

                    .content {
                        margin-top: 3.4rem;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 1.5rem;
                    }

                    .address, .city, .phone {
                        font-size: 1.4rem;
                        line-height: 2.1rem;
                        font-family: 'Montserrat-Regular', sans-serif;
                    }

                    .btn-more-info {
                        width: 100%;
                        font-family: 'Montserrat-SemiBold', sans-serif;
                        font-size: 1.3rem;
                        line-height: 2.1rem;
                        background-color: $colorRoseLight;
                        transition: all .4s ease-in-out;
                        margin-top: .6rem;
                        position: relative;
                        padding: 1rem 5rem 1rem 1rem;

                        &:after {
                            content: "";
                            position: absolute;
                            background-image: url('/images/arrow-link.webp');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            right: 3rem;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 0.8rem;
                            height: 0.8rem;
                            transition: all .4s ease-in-out;
                        }

                        &:hover {
                            background-color: $colorCorporative;
                            color: white;

                            &:after {
                                background-image: url('/images/arrow-link-white.svg');
                            }
                        }
                    }
                }
            }
        }
    }

    .wrap-tours {
        //height: 85rem;
        position: relative;
        margin-top: 5rem;
        height: auto;
        margin-bottom: 10.4rem;
        .title{
            @include font_mont(SemiBold, 3.5);
        }

        .subtitle {
            width: 50%;
            margin-top: 2.1rem;
            font-size: 1.5rem;
            line-height: 2.3rem;
            margin-bottom: 4rem;

            b {
                font-family: 'Montserrat-Bold', sans-serif;
            }
        }

        .wrap-tours-list {
            //position: absolute;
            width: calc(100% + ((100vw - 1280px) / 2 ));
            left: 0;
            position: relative;

            .slick-slide {
                width: 35rem;
                margin-right: 3rem;
                opacity: 1;
                transition: opacity .3s ease-in-out;
            }

            .slick-track {
                margin-left: unset;
                margin-right: unset;
            }

            .slick-disabled {
                opacity: 0.4;
            }

            .slick-next, .slick-prev {
                background-image: url('/images/slider-arrow-left.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 3rem;
                height: 3rem;
                z-index: 2;
                transition: all .4s ease-in-out;

                &:hover {
                    transform: translate(-1rem, -50%);
                }

                &:before {
                    display: none;
                }
            }

            .slick-next {
                background-image: url('/images/slider-arrow-right.svg');
                right: 15px;

                &:hover {
                    transform: translate(1rem, -50%);
                }
            }

            .tour-item {
                width: 35rem;
                background-color: #f3f6f7;
                margin-right: 3rem;

                .image-container {
                    img {
                       // height: 35rem;
                        object-fit: cover;
                        width: 100%;
                        aspect-ratio: 1;
                        height: 100%;
                    }
                }

                .tour-content {
                    padding: 2.5rem 3.5rem 3rem;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    min-height: 25rem;

                    .title {
                        font-family: 'Montserrat-SemiBold', sans-serif;
                        font-size: 2.5rem;
                        line-height: 3rem;
                        flex-grow: 1;

                        .span1 {
                            color: $colorCorporative;
                        }

                        @media (max-width: $lg-width) {
                            font-size: 2rem;
                            line-height: 2.7rem;
                        }
                    }

                    .subtitle {
                        font-size: 1.3rem;
                        line-height: 2.1rem;
                        font-family: 'Montserrat-Regular', sans-serif;
                        margin-top: 1.2rem;
                        width: 100%;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (max-width: 1280px) {
        .wrap-tours .wrap-tours-list {
            width: calc(100% + 3rem);

            .tour-item{
                .tour-content{
                    min-height: 27rem;
                }
            }
        }
    }

    @media (max-width: $md-width) {
      
        .title {
            @include font_mont(SemiBold, 2.5);
            line-height: normal;
        }

        .limited-wrap {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .wrap-title {
            padding-top: 11rem;

            .title {
                font-size: 2.5rem;
            }
        }

        .wrap-shops {
            display: block !important;

            .wrap-shops-left {

                .wrap-shops-image {
                    max-height: 25rem;

                    img {
                        max-height: 25rem;
                    }
                }

                .wrap-shops-block {
                    display: none;
                }
            }

            .wrap-shops-right {
                .wrap-shops-list {
                    margin-top: 5rem;

                    .shop-item:nth-child(even) {
                        .background-shop {
                            background-color: $colorRoseLight;
                        }

                        .shop-item-content{
                            .btn-more-info{
                                background-color: #fff;
                            }
                        }

                    }

                    .shop-item {
                        max-width: unset;
                        width: 100%;
                        margin-left: 0;
                        padding-bottom: 2rem;
                        margin-bottom: 0;
                        padding-top: 2rem;
                        text-align: left;

                        &::before {
                            content: "";
                            position: absolute;
                            width: calc(100% + 4rem);
                            left: 50%;
                            bottom: 0;
                            transform: translate(-50%, -50%);
                            background-color: $colorCorporative;
                            height: 1px;
                            z-index: 1;

                        }

                        &:last-of-type{
                            &::before{
                                content: none;
                            }
                        }

                        .title {
                            &.small-line {
                                &:before {
                                    display: none;
                                }
                            }
                        }

                        .background-arrow {
                            top: 0;
                        }

                        .arrow {
                            top: 3rem !important;
                        }
                       
                        &:nth-child(odd) {
                            &:after {
                                background-color: transparent;
                            }
                        }

                        &:nth-child(even) {
                            &:after {
                                content: "";
                                position: absolute;
                                //width: 100vw;
                                width: calc(100% + 4rem);
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                background-color: $colorRoseLight;
                                height: 100%;
                                z-index: -1;
                            }
                        }

                        .shop-item-content {
                            display: none;
                        }

                        .background-shop {
                            opacity: 1;
                            background-color: transparent;
                            cursor: pointer;
                        }

                        .background-arrow {
                            content: "";
                            position: absolute;
                            //height: 6rem;
                            //top: -2rem;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            max-height: 10rem;
                            background-color: transparent;
                            left: 0;
                        }

                        &.collapse {
                            .shop-item-content {
                                display: none;
                            }

                            .arrow {
                                transform: rotate(90deg);
                            }
                        }

                        .arrow {
                            content: "";
                            background-image: url('/images/arrow-grey-simple-right.svg');
                            width: 1rem;
                            height: 1rem;
                            position: absolute;
                            top: 1rem;
                            right: 0;
                            cursor: pointer;
                            background-size: contain;
                            transform: rotate(270deg);
                            background-repeat: no-repeat;
                            z-index: 1;
                            transition: transform .3s ease-in-out;
                        }

                        .shop-item-content {
                            display: block;
                            min-height: 11rem;
                        }
                    }
                }
            }
        }

        .wrap-tours {
            //height: 75rem;
            height: auto;
            margin-bottom: 7.7rem;
            margin-top: 7rem;

            .title{
                @include font_mont(SemiBold, 2.5);
            }

            .subtitle {
                width: 100%;
            }

            .wrap-tours-list {
                //margin-top: 5rem;
                margin-top: 2.5rem;
                position: relative;
                width: 100%;
                //padding: 1.5rem;
                padding: 0;

                .aux-slider {
                    width: 100%;
                    transform: unset;
                }

                .slick-list{
                    width: calc(100% + 4rem);
                    left: -2rem;
                }
                .slick-slide {
                    max-width: unset;
                    width: 100%;
                    margin-left: 2rem;
                    margin-right: 0;
                    max-width: unset;
                }

                .slick-prev {
                    left: -5px;
                }

                .slick-next {
                    right: -5px;
                }

                .tour-item {
                    width: 70vw;
                    margin-right: 0;
                    max-width: unset;

                    .image-container {
                        img {
                            //height: 70vw;
                            height: 100%;
                            max-height: unset;
                            width: 100%;
                        }
                    }

                    .tour-content {
                        padding: 2rem;
                        min-height: 20rem;

                        .title {
                            font-size: 2.3rem;
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
}

.layout-default-famaliving:not(.detailStore-famaliving){
    .article-instagram, 
    .article-products
    {
        padding: 0 $paddingLateral;
        margin-bottom: 13.5rem;
    }
    #contact_famaliving2023.container-content.shops.contact{
        margin-bottom: 6.5rem;
        .limited-wrap{
            padding-top: 0;
        }
    }
}
@media (max-width: $md-width) {

    .layout-default-famaliving:not(.detailStore-famaliving){
        #contact_famaliving2023.container-content.shops.contact{
            margin-bottom: 3.5rem;

            .limited-wrap{
                padding-top: 0;
            }
        }
        .container-content.stores-aggrupated-famaliving{
            padding: 0;

        }
        .article-instagram, 
        .article-products
        {
            padding: 0 2rem;
            margin-bottom: 7.7rem;

        }

        .article-instagram{
            .wrap-content-info{
                &.izquierda {
                    .part-left .part-img {
                        //left: -2rem;
                        left: unset;
                        right: -2rem;
                    }
                }
            }
        }
    }
}