.new-store{
    .wrap-content{
        .article-tour{
            width: 100%;
            margin-bottom: 10.7rem;

            .content{
               .wrap-header{
                    max-width: 1080px;
                    margin: 0 auto;
                    width: 100%;
                    position: relative;

                    .box-content{
                        .title{
                            margin: 0 auto;
                            width: fit-content;
                            background-color: #fff;
                            padding: 0 4.3rem 1.8rem 4.3rem;
                            z-index: 1;
                            border-radius: 0;
                            margin-bottom: -1.8rem;
                        }
                    }
               }
               .link-tour{
                    margin: 0 auto;
                    max-width: 1080px;
                    position: relative;
                    width: 100%;

                    .wrap-img{
                        position: relative;
                        &::before{
                            content: '';            
                            background-image: url('/images/fl-amplicar-icon.svg');
                            width: 5rem;
                            height: 5rem;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0;    
                            z-index: -1;  
                            transition: .2s opacity ease-in-out;
                            background-position: center;
                        }
                        &:hover{
                            &::before{
                                opacity: 1;     
                                z-index: 1;
                                transition: .2s opacity ease-in-out;
                            }
                        }
                    }
                   
               }
               .wrap-img{
                    width: 100%;
                    height: 100%;
                    max-height: 50rem;
                    max-width: 1080px;
                    margin: 0 auto;
                    position: relative;

                    img.img-responsive{
                        width: 100%;
                        position: relative;
                        max-height: 50rem;
                        object-fit: cover;
                    }
               }
               .wrap-description{
                    width: 100%;
                    max-width: 1080px;
                    margin: 0 auto;
                    position: relative;

                    .description{
                        width: 31rem;
                        margin: 0 auto;
                        background-color: #fff;
                        padding: 2.8rem 2.5rem 1rem 2.5rem;
                        z-index: 1;
                        border-radius: 0;
                        margin-top: -5rem;
                        margin-right: 1rem;
                   }
               }
           
            }
        }
    }
}


@media (max-width: $md-width) {
    .new-store{
        .wrap-content{
            .article-tour{
                margin-bottom: 7.7rem;
                //margin-bottom: 6.1rem;
                
                .content{
                    .link-tour{
                        .wrap-img{
                            width: calc(100% + (2 * $paddingLateralXs));
                            position: relative;
                            left: calc(-1 * $paddingLateralXs);
                        }
                    } 

                    .wrap-description{
                        .description{
                            width: 100%;
                            margin-top: 0;
                            margin: 0;
                            padding: 2.5rem 0;
                            padding-bottom: 0;
                        }
                    }
                    .wrap-header .box-content .title {
                        padding: 0 4.3rem 0.7rem 4.3rem;
                    }

                    .wrap-img{
                        img.img-responsive{
                            aspect-ratio: 5/2.5;
                        }
                    }
                } 
            }
        }
    }
}


