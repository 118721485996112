.new-store{
    
    .wrap-content{
        .article-blog{
            //width: calc(100% + 5rem);
            margin-bottom: 10.7rem;
           
            .wrap-header{
                width: 100%;
                padding-right: 3.5rem;
                padding-top: 3.5rem;

                .header-title{
                    max-width: 38rem;
                }
                .box-content .title {
                    //margin-bottom: 1rem;
                    margin-bottom: 0;
                    //padding-bottom: 4.1rem;
                    padding-bottom: 2.5rem;
                }
                .header-part-content{
                    width: 56%;
                    //max-width: calc(#{$max-width-detail-store} / 2);
                    position: relative;
                    margin: 0 auto;
                    margin-left: 0;
                    //background-color: $colorRoseLight;
                    padding: 0;
                    padding-left: 7.4rem;
                    position: relative;
                    width: 44%;
                    margin: 0 auto;
                    margin-right: 0;
                
                }
                .subtitle.text-subtitle{
                    margin-bottom: 0;
                    //margin-top: 3.5rem;
                }
            }
            .wrap-content-info{
                width: 100%;
                //max-width: calc(#{$max-width-detail-store} / 2);
                margin: 0 auto;
                margin-left: 0;
                //background-color: $colorRoseLight;
                padding: 0 3.5rem 0 0;
                
            }
            .content{
                max-width: $max-width-detail-store;
                margin: 0 auto;
                width: 100%;
                position: relative;

                &:before{
                    content: '';
                    position: absolute;
                    background-color: $colorRoseLight;
                    width: 83%;
                    //height: calc(100% - 3rem);
                    height: 100%;
                    left: unset;
                    right: 0;
                    top: 0;
                    //top: -3rem;
                }
                &:first-child{
                    z-index: 2;
                }
                &:first-child:before{
                    height: 100%;
                    top: 0;
                }
                &:last-child:before{
                    top: 0;
                }

            }
            .part-right{
                width: 44%;
                position: relative;
                padding-left: 7.4rem;

                .subtitle.text-subtitle{
                    //padding-top: 3rem;
                }

                .description{
                    padding-bottom: 3rem;
                }
             
                .wrap-one-img.isImg{
                    margin-bottom: 3rem;
                }
                
            }
            .part-left{
                width: 56%;
                //max-width: calc(#{$max-width-detail-store} / 2);
                position: relative;
                margin: 0 auto;
                margin-left: 0;
                //background-color: $colorRoseLight;
                padding: 0;
                //top: 3rem;

                .part-img{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-bottom: -3rem;
                }
                

                .wrap-one-img{
                    overflow: hidden;
                    //position: absolute;
                    bottom: 0;

                    .wrap-img{
                        //aspect-ratio: 3/2;
                     

                        .img-responsive{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
                &.isSlider{
                    max-width: 36rem;
                    //margin-top: -5rem;
                }
                .part-slider {
                    width: 100%;
                    margin-top: 0;
    
                    .aux-slider{
                        .wrap-slider{
                            width: 100%;
        
                            .aux-box{
                                padding-bottom: 3.7rem;
                            }
                            .slider-slick-detail-store.slick-slider{
                                .slick-arrow{
                                    display: none !important;
                                }
                                .slick-list{
                                    padding: 0 !important;
                                    cursor: grab;                                
                                    &.dragging, &.draggable:active:hover {
                                        cursor: grabbing;
                                    }
                                    .slick-slide{                               
                                        .item{
                                            position: relative;
                                            padding-top: 120%;                                    
                                            .img{
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.desk{
                            flex-grow: 1;
                        }
                    }
  
                }

            }

            .wrap-btnDefault{
                width: fit-content;
                margin-bottom: 3rem;

                .btnDefault{
                    padding: 1.4rem 4rem;
                }
            }

            .wrap-content-posts{
                display: flex;
                margin-top: 10.8rem;

                .header-title.box-content{
                    width: 25%;

                    .title{
                        padding-right: 2.4rem;
                        font-size: 2.7rem;
                    }
                }
            }
            .last-2-posts {
                width: 75%;
                margin: auto;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
               
    
                .last-3-new {
                    width: calc(100% / 2);
                    padding-right: 2rem;
                    background-color: $colorRoseLight;
                    padding-bottom: 3rem;
                    margin-bottom: 2rem;
    
                    .aux-item{
                        border-bottom: 5px solid #c70087;
                        width: 100%;
                        overflow: hidden;
                    }
    
                    .wrap-img {
                        position: relative;
                        padding-top: 64%;
                        transform: translateY(0);
                        transition: transform 0.5s;
                        overflow: hidden;
            
                        .img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            background-color: $pink;
                            transform: scale(1);
                            transition: 0.5s transform linear;
                        }
                    }
                    .header,
                    .date, 
                    .author, 
                    .title {
                        padding: 0 1rem;
                    }
                    .header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 1rem;
            
                        span {
                            font-size: 1.3rem;
                        }
            
                        .heart {
                            color: $pink;
                        }
                    }
            
                    .date {
                        font-size: 1.3rem;
                        color: $colorCorporative;
                        opacity: 1;
                        display: block;
                        margin-bottom: 2rem;
                        font-family: "Montserrat-Italic";
                    }
            
                    .title {
                        font-size: 1.5rem;
                        font-family: "Montserrat-SemiBold";
                        color: #000;
                    }
                    &:hover {
                        .wrap-img {
                            //transform: translateY(-1rem);
                            transition: transform 0.5s;
                            .img {
                                transform: scale(1.1);
                                transition: 0.5s transform linear;
                            }
                        }
                    }
                }
            }
    
           .last-2-posts{
            //max-width: calc(100rem);
            margin: 0 auto;
           
                .last-3-new{
                    width: calc((100% / 2) - 1.5rem);
                    padding-right: 0;
                    .header, .date{
                        margin-bottom: 0;
                    }
                    .title{
                        margin-top: 2rem;
                    }
                    .author{
                        @include font_mont(Italic, 1.3);
                        color: $colorBlack;
                        line-height: 2.1rem;
                        opacity: 1;
                        display: block;            
                    }
                }
           }
        }
    }
}

@media (max-width: $md-width) {
    .new-store{
        overflow: hidden;
        .wrap-container{
            padding: 0 $paddingLateralXs;
        }
    
        .wrap-content{

            .article-blog{
                margin-bottom: 6.7rem;
                margin-top: 15rem;

                .wrap-btnDefault{
                    margin-bottom: 7.2rem;
                }
                .wrap-header{
                    padding-top: 3.8rem;

                    .header-part-content{
                        padding: 0;
                        width: 50%;
                        margin-left: calc(2 * $paddingLateralXs);

                        .box-content{
                            .title{
                                width: 100%;
                                padding-right: 0;
                                margin-bottom: 0;
                                padding-bottom: 2.2rem;
                            }
                        }

                    }
                }
                .content::before{
                    width: calc(100% - $paddingLateralXs);
                }
                .wrap-content-info{
                    flex-direction: column;
                    padding: 0 3rem 0 0;
                }
                .part-left{
                    width: 100%;

                    .part-img{
                        margin-bottom: 0;
                        margin-top: -7rem;
                    }
                }
                .part-right{
                    width: 100%;
                    padding-left: calc(3 * $paddingLateralXs);

                    .header-part-content{
                        margin-left: 0;
                        min-width: 13rem;
                        width: 60%;
                    }
                }
                .wrap-content-posts{
                    flex-direction: column;
                    margin-top: 7.7rem;

                    .header-title.box-content{
                        width: 100%;
                        .title{
                            padding-right: 0;
                            margin-bottom: 2.2rem;
                        }
                    }

                    .last-2-posts{
                        flex-wrap: nowrap;
                        overflow: auto;
                        width: calc(100% + 3rem);
                        position: relative;
                        left: -1.5rem;
                        column-gap: 1.6rem;
                        padding-bottom: 2rem;

                        .last-3-new{
                            width: calc(85% - $paddingLateralXs);
                            min-width: calc(85% - $paddingLateralXs);

                            &:first-of-type{
                                margin-left: $paddingLateralXs;
                            }

                            &:last-of-type{
                                margin-right: $paddingLateralXs;
                            }

                        }
                    }
                }


                .wrap-btnDefault{
                   
                    .btnDefault{
                        padding: 1.4rem 5.3rem;
                    }
                }
            }
        }
    }
}