
.form-group{
    width: 100%;
    position: relative;
    margin-bottom: 4.4rem;
    

    label{
        @include font_mont(SemiBold, 1.3);
        position: absolute;
        left: 2.6rem;
        top: 0;
        transform: translateY(-50%);
        background-color: $colorWhite;
        padding: 1rem;
        padding-left: 2.8rem;


        background-color: #fff;
        color: #575252;
        font-family: Montserrat-Medium;
        font-size: 1.5rem;
        left: 1.7rem;
        margin: 0;
        padding: 0 1.1rem 0 2.8rem;
        pointer-events: none;
        position: absolute; 
        top: 0;
        transform: translateY(-50%);
        transform-origin: left;
        transition: top .2s linear,left .2s linear,transform .2s linear;
        white-space: nowrap;


        &::before{
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 1.5rem;
            height: 100%;
            left: calc(1.3rem / 2);
            top: 0;
        }
        &[for='pais']::before, 
        &[for='pais-geo']::before{
            background-image: url('/images/fl-shape.svg');
        }
        &[for='lang']::before{
            background-image: url('/images/fl-lengua.svg');
        }

        &[for='blog-shops'],
        &[for='list-shops'],
        &[for='list-shopsContact']
        {
            padding-left: 1rem;
            &::before{
                content: none;
            }
        }
    }
}
