.videos-section-item {
    width: 100%;
    position: relative;
    margin-bottom: 5rem;
    .video-wrapper {
        padding-top: 56%;
        width: 100%;
        position: relative;
        .video-container {
            left: 0;
            object-fit: cover;
            width: 100%;
            position: absolute;
            top: 0;
            height: 100%;
            img {
                z-index: -1;
                position: relative;
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: 1;
                transition: 0.3s all ease;
                &.isSee {
                    z-index: 1;
                    opacity: 1;
                    transition: 0.3s all ease;
                }
            }
            iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .aux-play {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }
    .playPause.js-play:not(.isPlayd) {
        &::after {
            content: "";
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
    .playPause {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 1;
        transition: 0.2s all ease;

        &::before {
            position: absolute;
            content: "";
            width: 6.8rem;
            height: 6.8rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.9;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%)scale(1);
            transition: 0.4s transform ease-in;
            z-index: 2;
        }

        &:hover {
            &::before {
                opacity: 1;
                transform: translateY(-50%) translateX(-50%)scale(1.2);
                transition: 0.4s transform ease-in;
            }
        }
        &.play {
            display: flex;
            &::before {
                background-image: url("/images/btn-play.svg");
            }
            &.isPlayd {
                display: none;
            }
        }
        &.pause {
            display: flex;
            &::before {
                background-image: url("/images/btn-pause.svg");
            }
            &.isStoped {
                display: none;
            }
        }
        &.mouseLeave {
            opacity: 0;
            transition: 0.2s all ease;
        }
    }
}
