@charset "UTF-8";
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "~toastify-js/src/toastify.css";
@import '../vendor/font-awesome.min.css';
@import '../vendor/elegant-font.css';
@import '../vendor/swiper.min.css';
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../fonts/Montserrat-Regular.ttf");
}
.regular {
  font-family: "Montserrat-Regular";
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url("../../fonts/Montserrat-Italic.ttf");
}
.italic {
  font-family: "Montserrat-Italic";
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../fonts/Montserrat-Medium.ttf");
}
.medium {
  font-family: "Montserrat-Medium";
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../fonts/Montserrat-SemiBold.ttf");
}
.semiBold {
  font-family: "Montserrat-SemiBold";
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../fonts/Montserrat-Bold.ttf");
}
.bold {
  font-family: "Montserrat-Bold";
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("../../fonts/Montserrat-ExtraBold.ttf");
}
.extraBold {
  font-family: "Montserrat-ExtraBold";
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../../fonts/Lato-Regular.ttf");
}
.lato-regular {
  font-family: "Lato-Regular";
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../../fonts/Lato-Bold.ttf");
}
.lato-bold {
  font-family: "Lato-Bold";
}

@font-face {
  font-family: "Lato-Black";
  src: url("../../fonts/Lato-Black.ttf");
}
.lato-bold {
  font-family: "Lato-Black";
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
* {
  box-sizing: border-box;
  outline: 0;
  background: transparent none repeat scroll 0 0;
  border: 0 none;
  font-size: 100%;
  margin: 0;
  outline: 0 none;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline;
  scroll-behavior: smooth !important;
}

html {
  margin: 0;
  font-size: 10px;
}
@media (min-width: 992px) and (max-width: 1260px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 10px;
  }
}

body {
  color: #262626;
  font-family: "Montserrat-Regular";
  font-size: 1.2rem;
  line-height: normal;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
body .container {
  flex-grow: 1;
  position: relative;
  height: 100%;
  max-width: unset;
}
body.stopSrcoll {
  overflow-y: hidden !important;
}

body a {
  text-decoration: none;
  color: #333;
}
body a:hover {
  text-decoration: none;
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  margin: 0;
}

button {
  outline: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

b, strong {
  font-weight: normal;
  font-family: "Montserrat-Bold";
}

.that-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  width: 100%;
  justify-content: unset !important;
  width: 100% !important;
}

.that-col-1-24 {
  -ms-flex: 0 0 4.17%;
  -webkit-box-flex: 0;
  flex: 0 0 4.17%;
  max-width: 4.17%;
}

.that-col-2-24 {
  -ms-flex: 0 0 8.33%;
  -webkit-box-flex: 0;
  flex: 0 0 8.33%;
  max-width: 8.33%;
}

.that-col-3-24 {
  -ms-flex: 0 0 12.5%;
  -webkit-box-flex: 0;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.that-col-4-24 {
  -ms-flex: 0 0 16.67%;
  -webkit-box-flex: 0;
  flex: 0 0 16.67%;
  max-width: 16.67%;
}

.that-col-5-24 {
  -ms-flex: 0 0 20.83%;
  -webkit-box-flex: 0;
  flex: 0 0 20.83%;
  max-width: 20.83%;
}

.that-col-6-24 {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
}

.that-col-7-24 {
  -ms-flex: 0 0 29.17%;
  -webkit-box-flex: 0;
  flex: 0 0 29.17%;
  max-width: 29.17%;
}

.that-col-8-24 {
  -ms-flex: 0 0 33.33%;
  -webkit-box-flex: 0;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.that-col-9-24 {
  -ms-flex: 0 0 37.5%;
  -webkit-box-flex: 0;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.that-col-10-24 {
  -ms-flex: 0 0 41.67%;
  -webkit-box-flex: 0;
  flex: 0 0 41.67%;
  max-width: 41.67%;
}

.that-col-11-24 {
  -ms-flex: 0 0 45.83%;
  -webkit-box-flex: 0;
  flex: 0 0 45.83%;
  max-width: 45.83%;
}

.that-col-12-24 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.that-col-13-24 {
  -ms-flex: 0 0 54.17%;
  -webkit-box-flex: 0;
  flex: 0 0 54.17%;
  max-width: 54.17%;
}

.that-col-14-24 {
  -ms-flex: 0 0 58.33%;
  -webkit-box-flex: 0;
  flex: 0 0 58.33%;
  max-width: 58.33%;
}

.that-col-15-24 {
  -ms-flex: 0 0 62.5%;
  -webkit-box-flex: 0;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.that-col-16-24 {
  -ms-flex: 0 0 66.67%;
  -webkit-box-flex: 0;
  flex: 0 0 66.67%;
  max-width: 66.67%;
}

.that-col-17-24 {
  -ms-flex: 0 0 70.83%;
  -webkit-box-flex: 0;
  flex: 0 0 70.83%;
  max-width: 70.83%;
}

.that-col-18-24 {
  -ms-flex: 0 0 75%;
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
}

.that-col-19-24 {
  -ms-flex: 0 0 79.17%;
  -webkit-box-flex: 0;
  flex: 0 0 79.17%;
  max-width: 79.17%;
}

.that-col-20-24 {
  -ms-flex: 0 0 83.33%;
  -webkit-box-flex: 0;
  flex: 0 0 83.33%;
  max-width: 83.33%;
}

.that-col-21-24 {
  -ms-flex: 0 0 87.5%;
  -webkit-box-flex: 0;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.that-col-22-24 {
  -ms-flex: 0 0 91.67%;
  -webkit-box-flex: 0;
  flex: 0 0 91.67%;
  max-width: 91.67%;
}

.that-col-23-24 {
  -ms-flex: 0 0 95.83%;
  -webkit-box-flex: 0;
  flex: 0 0 95.83%;
  max-width: 95.83%;
}

.that-col-24-24 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .that-row > * {
    width: 100%;
    min-width: 100%;
    padding-left: 0 !important;
  }
}
@media (max-width: 991px) {
  .that-row.that-md-col {
    flex-direction: column !important;
  }
  .that-col-24-24-md {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100% !important;
  }
}
.pr-0-5 {
  padding-right: 1.25rem;
}

.pl-0-5 {
  padding-left: 1.25rem;
}

.m-b-2 {
  margin-bottom: 2rem;
}

.m-b-3 {
  margin-bottom: 3rem;
}

.w-0 {
  width: 0% !important;
}

.max-w-0 {
  max-width: 0rem !important;
}

@media (max-width: 991px) {
  .w-0 {
    width: auto !important;
  }
  .xs-w-0 {
    width: 0% !important;
  }
}
.w-1 {
  width: 1% !important;
}

.max-w-1 {
  max-width: 1rem !important;
}

@media (max-width: 991px) {
  .w-1 {
    width: auto !important;
  }
  .xs-w-1 {
    width: 1% !important;
  }
}
.w-2 {
  width: 2% !important;
}

.max-w-2 {
  max-width: 2rem !important;
}

@media (max-width: 991px) {
  .w-2 {
    width: auto !important;
  }
  .xs-w-2 {
    width: 2% !important;
  }
}
.w-3 {
  width: 3% !important;
}

.max-w-3 {
  max-width: 3rem !important;
}

@media (max-width: 991px) {
  .w-3 {
    width: auto !important;
  }
  .xs-w-3 {
    width: 3% !important;
  }
}
.w-4 {
  width: 4% !important;
}

.max-w-4 {
  max-width: 4rem !important;
}

@media (max-width: 991px) {
  .w-4 {
    width: auto !important;
  }
  .xs-w-4 {
    width: 4% !important;
  }
}
.w-5 {
  width: 5% !important;
}

.max-w-5 {
  max-width: 5rem !important;
}

@media (max-width: 991px) {
  .w-5 {
    width: auto !important;
  }
  .xs-w-5 {
    width: 5% !important;
  }
}
.w-6 {
  width: 6% !important;
}

.max-w-6 {
  max-width: 6rem !important;
}

@media (max-width: 991px) {
  .w-6 {
    width: auto !important;
  }
  .xs-w-6 {
    width: 6% !important;
  }
}
.w-7 {
  width: 7% !important;
}

.max-w-7 {
  max-width: 7rem !important;
}

@media (max-width: 991px) {
  .w-7 {
    width: auto !important;
  }
  .xs-w-7 {
    width: 7% !important;
  }
}
.w-8 {
  width: 8% !important;
}

.max-w-8 {
  max-width: 8rem !important;
}

@media (max-width: 991px) {
  .w-8 {
    width: auto !important;
  }
  .xs-w-8 {
    width: 8% !important;
  }
}
.w-9 {
  width: 9% !important;
}

.max-w-9 {
  max-width: 9rem !important;
}

@media (max-width: 991px) {
  .w-9 {
    width: auto !important;
  }
  .xs-w-9 {
    width: 9% !important;
  }
}
.w-10 {
  width: 10% !important;
}

.max-w-10 {
  max-width: 10rem !important;
}

@media (max-width: 991px) {
  .w-10 {
    width: auto !important;
  }
  .xs-w-10 {
    width: 10% !important;
  }
}
.w-11 {
  width: 11% !important;
}

.max-w-11 {
  max-width: 11rem !important;
}

@media (max-width: 991px) {
  .w-11 {
    width: auto !important;
  }
  .xs-w-11 {
    width: 11% !important;
  }
}
.w-12 {
  width: 12% !important;
}

.max-w-12 {
  max-width: 12rem !important;
}

@media (max-width: 991px) {
  .w-12 {
    width: auto !important;
  }
  .xs-w-12 {
    width: 12% !important;
  }
}
.w-13 {
  width: 13% !important;
}

.max-w-13 {
  max-width: 13rem !important;
}

@media (max-width: 991px) {
  .w-13 {
    width: auto !important;
  }
  .xs-w-13 {
    width: 13% !important;
  }
}
.w-14 {
  width: 14% !important;
}

.max-w-14 {
  max-width: 14rem !important;
}

@media (max-width: 991px) {
  .w-14 {
    width: auto !important;
  }
  .xs-w-14 {
    width: 14% !important;
  }
}
.w-15 {
  width: 15% !important;
}

.max-w-15 {
  max-width: 15rem !important;
}

@media (max-width: 991px) {
  .w-15 {
    width: auto !important;
  }
  .xs-w-15 {
    width: 15% !important;
  }
}
.w-16 {
  width: 16% !important;
}

.max-w-16 {
  max-width: 16rem !important;
}

@media (max-width: 991px) {
  .w-16 {
    width: auto !important;
  }
  .xs-w-16 {
    width: 16% !important;
  }
}
.w-17 {
  width: 17% !important;
}

.max-w-17 {
  max-width: 17rem !important;
}

@media (max-width: 991px) {
  .w-17 {
    width: auto !important;
  }
  .xs-w-17 {
    width: 17% !important;
  }
}
.w-18 {
  width: 18% !important;
}

.max-w-18 {
  max-width: 18rem !important;
}

@media (max-width: 991px) {
  .w-18 {
    width: auto !important;
  }
  .xs-w-18 {
    width: 18% !important;
  }
}
.w-19 {
  width: 19% !important;
}

.max-w-19 {
  max-width: 19rem !important;
}

@media (max-width: 991px) {
  .w-19 {
    width: auto !important;
  }
  .xs-w-19 {
    width: 19% !important;
  }
}
.w-20 {
  width: 20% !important;
}

.max-w-20 {
  max-width: 20rem !important;
}

@media (max-width: 991px) {
  .w-20 {
    width: auto !important;
  }
  .xs-w-20 {
    width: 20% !important;
  }
}
.w-21 {
  width: 21% !important;
}

.max-w-21 {
  max-width: 21rem !important;
}

@media (max-width: 991px) {
  .w-21 {
    width: auto !important;
  }
  .xs-w-21 {
    width: 21% !important;
  }
}
.w-22 {
  width: 22% !important;
}

.max-w-22 {
  max-width: 22rem !important;
}

@media (max-width: 991px) {
  .w-22 {
    width: auto !important;
  }
  .xs-w-22 {
    width: 22% !important;
  }
}
.w-23 {
  width: 23% !important;
}

.max-w-23 {
  max-width: 23rem !important;
}

@media (max-width: 991px) {
  .w-23 {
    width: auto !important;
  }
  .xs-w-23 {
    width: 23% !important;
  }
}
.w-24 {
  width: 24% !important;
}

.max-w-24 {
  max-width: 24rem !important;
}

@media (max-width: 991px) {
  .w-24 {
    width: auto !important;
  }
  .xs-w-24 {
    width: 24% !important;
  }
}
.w-25 {
  width: 25% !important;
}

.max-w-25 {
  max-width: 25rem !important;
}

@media (max-width: 991px) {
  .w-25 {
    width: auto !important;
  }
  .xs-w-25 {
    width: 25% !important;
  }
}
.w-26 {
  width: 26% !important;
}

.max-w-26 {
  max-width: 26rem !important;
}

@media (max-width: 991px) {
  .w-26 {
    width: auto !important;
  }
  .xs-w-26 {
    width: 26% !important;
  }
}
.w-27 {
  width: 27% !important;
}

.max-w-27 {
  max-width: 27rem !important;
}

@media (max-width: 991px) {
  .w-27 {
    width: auto !important;
  }
  .xs-w-27 {
    width: 27% !important;
  }
}
.w-28 {
  width: 28% !important;
}

.max-w-28 {
  max-width: 28rem !important;
}

@media (max-width: 991px) {
  .w-28 {
    width: auto !important;
  }
  .xs-w-28 {
    width: 28% !important;
  }
}
.w-29 {
  width: 29% !important;
}

.max-w-29 {
  max-width: 29rem !important;
}

@media (max-width: 991px) {
  .w-29 {
    width: auto !important;
  }
  .xs-w-29 {
    width: 29% !important;
  }
}
.w-30 {
  width: 30% !important;
}

.max-w-30 {
  max-width: 30rem !important;
}

@media (max-width: 991px) {
  .w-30 {
    width: auto !important;
  }
  .xs-w-30 {
    width: 30% !important;
  }
}
.w-31 {
  width: 31% !important;
}

.max-w-31 {
  max-width: 31rem !important;
}

@media (max-width: 991px) {
  .w-31 {
    width: auto !important;
  }
  .xs-w-31 {
    width: 31% !important;
  }
}
.w-32 {
  width: 32% !important;
}

.max-w-32 {
  max-width: 32rem !important;
}

@media (max-width: 991px) {
  .w-32 {
    width: auto !important;
  }
  .xs-w-32 {
    width: 32% !important;
  }
}
.w-33 {
  width: 33% !important;
}

.max-w-33 {
  max-width: 33rem !important;
}

@media (max-width: 991px) {
  .w-33 {
    width: auto !important;
  }
  .xs-w-33 {
    width: 33% !important;
  }
}
.w-34 {
  width: 34% !important;
}

.max-w-34 {
  max-width: 34rem !important;
}

@media (max-width: 991px) {
  .w-34 {
    width: auto !important;
  }
  .xs-w-34 {
    width: 34% !important;
  }
}
.w-35 {
  width: 35% !important;
}

.max-w-35 {
  max-width: 35rem !important;
}

@media (max-width: 991px) {
  .w-35 {
    width: auto !important;
  }
  .xs-w-35 {
    width: 35% !important;
  }
}
.w-36 {
  width: 36% !important;
}

.max-w-36 {
  max-width: 36rem !important;
}

@media (max-width: 991px) {
  .w-36 {
    width: auto !important;
  }
  .xs-w-36 {
    width: 36% !important;
  }
}
.w-37 {
  width: 37% !important;
}

.max-w-37 {
  max-width: 37rem !important;
}

@media (max-width: 991px) {
  .w-37 {
    width: auto !important;
  }
  .xs-w-37 {
    width: 37% !important;
  }
}
.w-38 {
  width: 38% !important;
}

.max-w-38 {
  max-width: 38rem !important;
}

@media (max-width: 991px) {
  .w-38 {
    width: auto !important;
  }
  .xs-w-38 {
    width: 38% !important;
  }
}
.w-39 {
  width: 39% !important;
}

.max-w-39 {
  max-width: 39rem !important;
}

@media (max-width: 991px) {
  .w-39 {
    width: auto !important;
  }
  .xs-w-39 {
    width: 39% !important;
  }
}
.w-40 {
  width: 40% !important;
}

.max-w-40 {
  max-width: 40rem !important;
}

@media (max-width: 991px) {
  .w-40 {
    width: auto !important;
  }
  .xs-w-40 {
    width: 40% !important;
  }
}
.w-41 {
  width: 41% !important;
}

.max-w-41 {
  max-width: 41rem !important;
}

@media (max-width: 991px) {
  .w-41 {
    width: auto !important;
  }
  .xs-w-41 {
    width: 41% !important;
  }
}
.w-42 {
  width: 42% !important;
}

.max-w-42 {
  max-width: 42rem !important;
}

@media (max-width: 991px) {
  .w-42 {
    width: auto !important;
  }
  .xs-w-42 {
    width: 42% !important;
  }
}
.w-43 {
  width: 43% !important;
}

.max-w-43 {
  max-width: 43rem !important;
}

@media (max-width: 991px) {
  .w-43 {
    width: auto !important;
  }
  .xs-w-43 {
    width: 43% !important;
  }
}
.w-44 {
  width: 44% !important;
}

.max-w-44 {
  max-width: 44rem !important;
}

@media (max-width: 991px) {
  .w-44 {
    width: auto !important;
  }
  .xs-w-44 {
    width: 44% !important;
  }
}
.w-45 {
  width: 45% !important;
}

.max-w-45 {
  max-width: 45rem !important;
}

@media (max-width: 991px) {
  .w-45 {
    width: auto !important;
  }
  .xs-w-45 {
    width: 45% !important;
  }
}
.w-46 {
  width: 46% !important;
}

.max-w-46 {
  max-width: 46rem !important;
}

@media (max-width: 991px) {
  .w-46 {
    width: auto !important;
  }
  .xs-w-46 {
    width: 46% !important;
  }
}
.w-47 {
  width: 47% !important;
}

.max-w-47 {
  max-width: 47rem !important;
}

@media (max-width: 991px) {
  .w-47 {
    width: auto !important;
  }
  .xs-w-47 {
    width: 47% !important;
  }
}
.w-48 {
  width: 48% !important;
}

.max-w-48 {
  max-width: 48rem !important;
}

@media (max-width: 991px) {
  .w-48 {
    width: auto !important;
  }
  .xs-w-48 {
    width: 48% !important;
  }
}
.w-49 {
  width: 49% !important;
}

.max-w-49 {
  max-width: 49rem !important;
}

@media (max-width: 991px) {
  .w-49 {
    width: auto !important;
  }
  .xs-w-49 {
    width: 49% !important;
  }
}
.w-50 {
  width: 50% !important;
}

.max-w-50 {
  max-width: 50rem !important;
}

@media (max-width: 991px) {
  .w-50 {
    width: auto !important;
  }
  .xs-w-50 {
    width: 50% !important;
  }
}
.w-51 {
  width: 51% !important;
}

.max-w-51 {
  max-width: 51rem !important;
}

@media (max-width: 991px) {
  .w-51 {
    width: auto !important;
  }
  .xs-w-51 {
    width: 51% !important;
  }
}
.w-52 {
  width: 52% !important;
}

.max-w-52 {
  max-width: 52rem !important;
}

@media (max-width: 991px) {
  .w-52 {
    width: auto !important;
  }
  .xs-w-52 {
    width: 52% !important;
  }
}
.w-53 {
  width: 53% !important;
}

.max-w-53 {
  max-width: 53rem !important;
}

@media (max-width: 991px) {
  .w-53 {
    width: auto !important;
  }
  .xs-w-53 {
    width: 53% !important;
  }
}
.w-54 {
  width: 54% !important;
}

.max-w-54 {
  max-width: 54rem !important;
}

@media (max-width: 991px) {
  .w-54 {
    width: auto !important;
  }
  .xs-w-54 {
    width: 54% !important;
  }
}
.w-55 {
  width: 55% !important;
}

.max-w-55 {
  max-width: 55rem !important;
}

@media (max-width: 991px) {
  .w-55 {
    width: auto !important;
  }
  .xs-w-55 {
    width: 55% !important;
  }
}
.w-56 {
  width: 56% !important;
}

.max-w-56 {
  max-width: 56rem !important;
}

@media (max-width: 991px) {
  .w-56 {
    width: auto !important;
  }
  .xs-w-56 {
    width: 56% !important;
  }
}
.w-57 {
  width: 57% !important;
}

.max-w-57 {
  max-width: 57rem !important;
}

@media (max-width: 991px) {
  .w-57 {
    width: auto !important;
  }
  .xs-w-57 {
    width: 57% !important;
  }
}
.w-58 {
  width: 58% !important;
}

.max-w-58 {
  max-width: 58rem !important;
}

@media (max-width: 991px) {
  .w-58 {
    width: auto !important;
  }
  .xs-w-58 {
    width: 58% !important;
  }
}
.w-59 {
  width: 59% !important;
}

.max-w-59 {
  max-width: 59rem !important;
}

@media (max-width: 991px) {
  .w-59 {
    width: auto !important;
  }
  .xs-w-59 {
    width: 59% !important;
  }
}
.w-60 {
  width: 60% !important;
}

.max-w-60 {
  max-width: 60rem !important;
}

@media (max-width: 991px) {
  .w-60 {
    width: auto !important;
  }
  .xs-w-60 {
    width: 60% !important;
  }
}
.w-61 {
  width: 61% !important;
}

.max-w-61 {
  max-width: 61rem !important;
}

@media (max-width: 991px) {
  .w-61 {
    width: auto !important;
  }
  .xs-w-61 {
    width: 61% !important;
  }
}
.w-62 {
  width: 62% !important;
}

.max-w-62 {
  max-width: 62rem !important;
}

@media (max-width: 991px) {
  .w-62 {
    width: auto !important;
  }
  .xs-w-62 {
    width: 62% !important;
  }
}
.w-63 {
  width: 63% !important;
}

.max-w-63 {
  max-width: 63rem !important;
}

@media (max-width: 991px) {
  .w-63 {
    width: auto !important;
  }
  .xs-w-63 {
    width: 63% !important;
  }
}
.w-64 {
  width: 64% !important;
}

.max-w-64 {
  max-width: 64rem !important;
}

@media (max-width: 991px) {
  .w-64 {
    width: auto !important;
  }
  .xs-w-64 {
    width: 64% !important;
  }
}
.w-65 {
  width: 65% !important;
}

.max-w-65 {
  max-width: 65rem !important;
}

@media (max-width: 991px) {
  .w-65 {
    width: auto !important;
  }
  .xs-w-65 {
    width: 65% !important;
  }
}
.w-66 {
  width: 66% !important;
}

.max-w-66 {
  max-width: 66rem !important;
}

@media (max-width: 991px) {
  .w-66 {
    width: auto !important;
  }
  .xs-w-66 {
    width: 66% !important;
  }
}
.w-67 {
  width: 67% !important;
}

.max-w-67 {
  max-width: 67rem !important;
}

@media (max-width: 991px) {
  .w-67 {
    width: auto !important;
  }
  .xs-w-67 {
    width: 67% !important;
  }
}
.w-68 {
  width: 68% !important;
}

.max-w-68 {
  max-width: 68rem !important;
}

@media (max-width: 991px) {
  .w-68 {
    width: auto !important;
  }
  .xs-w-68 {
    width: 68% !important;
  }
}
.w-69 {
  width: 69% !important;
}

.max-w-69 {
  max-width: 69rem !important;
}

@media (max-width: 991px) {
  .w-69 {
    width: auto !important;
  }
  .xs-w-69 {
    width: 69% !important;
  }
}
.w-70 {
  width: 70% !important;
}

.max-w-70 {
  max-width: 70rem !important;
}

@media (max-width: 991px) {
  .w-70 {
    width: auto !important;
  }
  .xs-w-70 {
    width: 70% !important;
  }
}
.w-71 {
  width: 71% !important;
}

.max-w-71 {
  max-width: 71rem !important;
}

@media (max-width: 991px) {
  .w-71 {
    width: auto !important;
  }
  .xs-w-71 {
    width: 71% !important;
  }
}
.w-72 {
  width: 72% !important;
}

.max-w-72 {
  max-width: 72rem !important;
}

@media (max-width: 991px) {
  .w-72 {
    width: auto !important;
  }
  .xs-w-72 {
    width: 72% !important;
  }
}
.w-73 {
  width: 73% !important;
}

.max-w-73 {
  max-width: 73rem !important;
}

@media (max-width: 991px) {
  .w-73 {
    width: auto !important;
  }
  .xs-w-73 {
    width: 73% !important;
  }
}
.w-74 {
  width: 74% !important;
}

.max-w-74 {
  max-width: 74rem !important;
}

@media (max-width: 991px) {
  .w-74 {
    width: auto !important;
  }
  .xs-w-74 {
    width: 74% !important;
  }
}
.w-75 {
  width: 75% !important;
}

.max-w-75 {
  max-width: 75rem !important;
}

@media (max-width: 991px) {
  .w-75 {
    width: auto !important;
  }
  .xs-w-75 {
    width: 75% !important;
  }
}
.w-76 {
  width: 76% !important;
}

.max-w-76 {
  max-width: 76rem !important;
}

@media (max-width: 991px) {
  .w-76 {
    width: auto !important;
  }
  .xs-w-76 {
    width: 76% !important;
  }
}
.w-77 {
  width: 77% !important;
}

.max-w-77 {
  max-width: 77rem !important;
}

@media (max-width: 991px) {
  .w-77 {
    width: auto !important;
  }
  .xs-w-77 {
    width: 77% !important;
  }
}
.w-78 {
  width: 78% !important;
}

.max-w-78 {
  max-width: 78rem !important;
}

@media (max-width: 991px) {
  .w-78 {
    width: auto !important;
  }
  .xs-w-78 {
    width: 78% !important;
  }
}
.w-79 {
  width: 79% !important;
}

.max-w-79 {
  max-width: 79rem !important;
}

@media (max-width: 991px) {
  .w-79 {
    width: auto !important;
  }
  .xs-w-79 {
    width: 79% !important;
  }
}
.w-80 {
  width: 80% !important;
}

.max-w-80 {
  max-width: 80rem !important;
}

@media (max-width: 991px) {
  .w-80 {
    width: auto !important;
  }
  .xs-w-80 {
    width: 80% !important;
  }
}
.w-81 {
  width: 81% !important;
}

.max-w-81 {
  max-width: 81rem !important;
}

@media (max-width: 991px) {
  .w-81 {
    width: auto !important;
  }
  .xs-w-81 {
    width: 81% !important;
  }
}
.w-82 {
  width: 82% !important;
}

.max-w-82 {
  max-width: 82rem !important;
}

@media (max-width: 991px) {
  .w-82 {
    width: auto !important;
  }
  .xs-w-82 {
    width: 82% !important;
  }
}
.w-83 {
  width: 83% !important;
}

.max-w-83 {
  max-width: 83rem !important;
}

@media (max-width: 991px) {
  .w-83 {
    width: auto !important;
  }
  .xs-w-83 {
    width: 83% !important;
  }
}
.w-84 {
  width: 84% !important;
}

.max-w-84 {
  max-width: 84rem !important;
}

@media (max-width: 991px) {
  .w-84 {
    width: auto !important;
  }
  .xs-w-84 {
    width: 84% !important;
  }
}
.w-85 {
  width: 85% !important;
}

.max-w-85 {
  max-width: 85rem !important;
}

@media (max-width: 991px) {
  .w-85 {
    width: auto !important;
  }
  .xs-w-85 {
    width: 85% !important;
  }
}
.w-86 {
  width: 86% !important;
}

.max-w-86 {
  max-width: 86rem !important;
}

@media (max-width: 991px) {
  .w-86 {
    width: auto !important;
  }
  .xs-w-86 {
    width: 86% !important;
  }
}
.w-87 {
  width: 87% !important;
}

.max-w-87 {
  max-width: 87rem !important;
}

@media (max-width: 991px) {
  .w-87 {
    width: auto !important;
  }
  .xs-w-87 {
    width: 87% !important;
  }
}
.w-88 {
  width: 88% !important;
}

.max-w-88 {
  max-width: 88rem !important;
}

@media (max-width: 991px) {
  .w-88 {
    width: auto !important;
  }
  .xs-w-88 {
    width: 88% !important;
  }
}
.w-89 {
  width: 89% !important;
}

.max-w-89 {
  max-width: 89rem !important;
}

@media (max-width: 991px) {
  .w-89 {
    width: auto !important;
  }
  .xs-w-89 {
    width: 89% !important;
  }
}
.w-90 {
  width: 90% !important;
}

.max-w-90 {
  max-width: 90rem !important;
}

@media (max-width: 991px) {
  .w-90 {
    width: auto !important;
  }
  .xs-w-90 {
    width: 90% !important;
  }
}
.w-91 {
  width: 91% !important;
}

.max-w-91 {
  max-width: 91rem !important;
}

@media (max-width: 991px) {
  .w-91 {
    width: auto !important;
  }
  .xs-w-91 {
    width: 91% !important;
  }
}
.w-92 {
  width: 92% !important;
}

.max-w-92 {
  max-width: 92rem !important;
}

@media (max-width: 991px) {
  .w-92 {
    width: auto !important;
  }
  .xs-w-92 {
    width: 92% !important;
  }
}
.w-93 {
  width: 93% !important;
}

.max-w-93 {
  max-width: 93rem !important;
}

@media (max-width: 991px) {
  .w-93 {
    width: auto !important;
  }
  .xs-w-93 {
    width: 93% !important;
  }
}
.w-94 {
  width: 94% !important;
}

.max-w-94 {
  max-width: 94rem !important;
}

@media (max-width: 991px) {
  .w-94 {
    width: auto !important;
  }
  .xs-w-94 {
    width: 94% !important;
  }
}
.w-95 {
  width: 95% !important;
}

.max-w-95 {
  max-width: 95rem !important;
}

@media (max-width: 991px) {
  .w-95 {
    width: auto !important;
  }
  .xs-w-95 {
    width: 95% !important;
  }
}
.w-96 {
  width: 96% !important;
}

.max-w-96 {
  max-width: 96rem !important;
}

@media (max-width: 991px) {
  .w-96 {
    width: auto !important;
  }
  .xs-w-96 {
    width: 96% !important;
  }
}
.w-97 {
  width: 97% !important;
}

.max-w-97 {
  max-width: 97rem !important;
}

@media (max-width: 991px) {
  .w-97 {
    width: auto !important;
  }
  .xs-w-97 {
    width: 97% !important;
  }
}
.w-98 {
  width: 98% !important;
}

.max-w-98 {
  max-width: 98rem !important;
}

@media (max-width: 991px) {
  .w-98 {
    width: auto !important;
  }
  .xs-w-98 {
    width: 98% !important;
  }
}
.w-99 {
  width: 99% !important;
}

.max-w-99 {
  max-width: 99rem !important;
}

@media (max-width: 991px) {
  .w-99 {
    width: auto !important;
  }
  .xs-w-99 {
    width: 99% !important;
  }
}
.w-100 {
  width: 100% !important;
}

.max-w-100 {
  max-width: 100rem !important;
}

@media (max-width: 991px) {
  .w-100 {
    width: auto !important;
  }
  .xs-w-100 {
    width: 100% !important;
  }
}
.w-101 {
  width: 101% !important;
}

.max-w-101 {
  max-width: 101rem !important;
}

@media (max-width: 991px) {
  .w-101 {
    width: auto !important;
  }
  .xs-w-101 {
    width: 101% !important;
  }
}
.w-102 {
  width: 102% !important;
}

.max-w-102 {
  max-width: 102rem !important;
}

@media (max-width: 991px) {
  .w-102 {
    width: auto !important;
  }
  .xs-w-102 {
    width: 102% !important;
  }
}
.w-103 {
  width: 103% !important;
}

.max-w-103 {
  max-width: 103rem !important;
}

@media (max-width: 991px) {
  .w-103 {
    width: auto !important;
  }
  .xs-w-103 {
    width: 103% !important;
  }
}
.w-104 {
  width: 104% !important;
}

.max-w-104 {
  max-width: 104rem !important;
}

@media (max-width: 991px) {
  .w-104 {
    width: auto !important;
  }
  .xs-w-104 {
    width: 104% !important;
  }
}
.w-105 {
  width: 105% !important;
}

.max-w-105 {
  max-width: 105rem !important;
}

@media (max-width: 991px) {
  .w-105 {
    width: auto !important;
  }
  .xs-w-105 {
    width: 105% !important;
  }
}
.w-106 {
  width: 106% !important;
}

.max-w-106 {
  max-width: 106rem !important;
}

@media (max-width: 991px) {
  .w-106 {
    width: auto !important;
  }
  .xs-w-106 {
    width: 106% !important;
  }
}
.w-107 {
  width: 107% !important;
}

.max-w-107 {
  max-width: 107rem !important;
}

@media (max-width: 991px) {
  .w-107 {
    width: auto !important;
  }
  .xs-w-107 {
    width: 107% !important;
  }
}
.w-108 {
  width: 108% !important;
}

.max-w-108 {
  max-width: 108rem !important;
}

@media (max-width: 991px) {
  .w-108 {
    width: auto !important;
  }
  .xs-w-108 {
    width: 108% !important;
  }
}
.w-109 {
  width: 109% !important;
}

.max-w-109 {
  max-width: 109rem !important;
}

@media (max-width: 991px) {
  .w-109 {
    width: auto !important;
  }
  .xs-w-109 {
    width: 109% !important;
  }
}
.w-110 {
  width: 110% !important;
}

.max-w-110 {
  max-width: 110rem !important;
}

@media (max-width: 991px) {
  .w-110 {
    width: auto !important;
  }
  .xs-w-110 {
    width: 110% !important;
  }
}
.w-111 {
  width: 111% !important;
}

.max-w-111 {
  max-width: 111rem !important;
}

@media (max-width: 991px) {
  .w-111 {
    width: auto !important;
  }
  .xs-w-111 {
    width: 111% !important;
  }
}
.w-112 {
  width: 112% !important;
}

.max-w-112 {
  max-width: 112rem !important;
}

@media (max-width: 991px) {
  .w-112 {
    width: auto !important;
  }
  .xs-w-112 {
    width: 112% !important;
  }
}
.w-113 {
  width: 113% !important;
}

.max-w-113 {
  max-width: 113rem !important;
}

@media (max-width: 991px) {
  .w-113 {
    width: auto !important;
  }
  .xs-w-113 {
    width: 113% !important;
  }
}
.w-114 {
  width: 114% !important;
}

.max-w-114 {
  max-width: 114rem !important;
}

@media (max-width: 991px) {
  .w-114 {
    width: auto !important;
  }
  .xs-w-114 {
    width: 114% !important;
  }
}
.w-115 {
  width: 115% !important;
}

.max-w-115 {
  max-width: 115rem !important;
}

@media (max-width: 991px) {
  .w-115 {
    width: auto !important;
  }
  .xs-w-115 {
    width: 115% !important;
  }
}
.w-116 {
  width: 116% !important;
}

.max-w-116 {
  max-width: 116rem !important;
}

@media (max-width: 991px) {
  .w-116 {
    width: auto !important;
  }
  .xs-w-116 {
    width: 116% !important;
  }
}
.w-117 {
  width: 117% !important;
}

.max-w-117 {
  max-width: 117rem !important;
}

@media (max-width: 991px) {
  .w-117 {
    width: auto !important;
  }
  .xs-w-117 {
    width: 117% !important;
  }
}
.w-118 {
  width: 118% !important;
}

.max-w-118 {
  max-width: 118rem !important;
}

@media (max-width: 991px) {
  .w-118 {
    width: auto !important;
  }
  .xs-w-118 {
    width: 118% !important;
  }
}
.w-119 {
  width: 119% !important;
}

.max-w-119 {
  max-width: 119rem !important;
}

@media (max-width: 991px) {
  .w-119 {
    width: auto !important;
  }
  .xs-w-119 {
    width: 119% !important;
  }
}
.w-120 {
  width: 120% !important;
}

.max-w-120 {
  max-width: 120rem !important;
}

@media (max-width: 991px) {
  .w-120 {
    width: auto !important;
  }
  .xs-w-120 {
    width: 120% !important;
  }
}
.w-121 {
  width: 121% !important;
}

.max-w-121 {
  max-width: 121rem !important;
}

@media (max-width: 991px) {
  .w-121 {
    width: auto !important;
  }
  .xs-w-121 {
    width: 121% !important;
  }
}
.w-122 {
  width: 122% !important;
}

.max-w-122 {
  max-width: 122rem !important;
}

@media (max-width: 991px) {
  .w-122 {
    width: auto !important;
  }
  .xs-w-122 {
    width: 122% !important;
  }
}
.w-123 {
  width: 123% !important;
}

.max-w-123 {
  max-width: 123rem !important;
}

@media (max-width: 991px) {
  .w-123 {
    width: auto !important;
  }
  .xs-w-123 {
    width: 123% !important;
  }
}
.w-124 {
  width: 124% !important;
}

.max-w-124 {
  max-width: 124rem !important;
}

@media (max-width: 991px) {
  .w-124 {
    width: auto !important;
  }
  .xs-w-124 {
    width: 124% !important;
  }
}
.w-125 {
  width: 125% !important;
}

.max-w-125 {
  max-width: 125rem !important;
}

@media (max-width: 991px) {
  .w-125 {
    width: auto !important;
  }
  .xs-w-125 {
    width: 125% !important;
  }
}
.w-126 {
  width: 126% !important;
}

.max-w-126 {
  max-width: 126rem !important;
}

@media (max-width: 991px) {
  .w-126 {
    width: auto !important;
  }
  .xs-w-126 {
    width: 126% !important;
  }
}
.w-127 {
  width: 127% !important;
}

.max-w-127 {
  max-width: 127rem !important;
}

@media (max-width: 991px) {
  .w-127 {
    width: auto !important;
  }
  .xs-w-127 {
    width: 127% !important;
  }
}
.w-128 {
  width: 128% !important;
}

.max-w-128 {
  max-width: 128rem !important;
}

@media (max-width: 991px) {
  .w-128 {
    width: auto !important;
  }
  .xs-w-128 {
    width: 128% !important;
  }
}
.w-129 {
  width: 129% !important;
}

.max-w-129 {
  max-width: 129rem !important;
}

@media (max-width: 991px) {
  .w-129 {
    width: auto !important;
  }
  .xs-w-129 {
    width: 129% !important;
  }
}
.w-130 {
  width: 130% !important;
}

.max-w-130 {
  max-width: 130rem !important;
}

@media (max-width: 991px) {
  .w-130 {
    width: auto !important;
  }
  .xs-w-130 {
    width: 130% !important;
  }
}
.w-131 {
  width: 131% !important;
}

.max-w-131 {
  max-width: 131rem !important;
}

@media (max-width: 991px) {
  .w-131 {
    width: auto !important;
  }
  .xs-w-131 {
    width: 131% !important;
  }
}
.w-132 {
  width: 132% !important;
}

.max-w-132 {
  max-width: 132rem !important;
}

@media (max-width: 991px) {
  .w-132 {
    width: auto !important;
  }
  .xs-w-132 {
    width: 132% !important;
  }
}
.w-133 {
  width: 133% !important;
}

.max-w-133 {
  max-width: 133rem !important;
}

@media (max-width: 991px) {
  .w-133 {
    width: auto !important;
  }
  .xs-w-133 {
    width: 133% !important;
  }
}
.w-134 {
  width: 134% !important;
}

.max-w-134 {
  max-width: 134rem !important;
}

@media (max-width: 991px) {
  .w-134 {
    width: auto !important;
  }
  .xs-w-134 {
    width: 134% !important;
  }
}
.w-135 {
  width: 135% !important;
}

.max-w-135 {
  max-width: 135rem !important;
}

@media (max-width: 991px) {
  .w-135 {
    width: auto !important;
  }
  .xs-w-135 {
    width: 135% !important;
  }
}
.w-136 {
  width: 136% !important;
}

.max-w-136 {
  max-width: 136rem !important;
}

@media (max-width: 991px) {
  .w-136 {
    width: auto !important;
  }
  .xs-w-136 {
    width: 136% !important;
  }
}
.w-137 {
  width: 137% !important;
}

.max-w-137 {
  max-width: 137rem !important;
}

@media (max-width: 991px) {
  .w-137 {
    width: auto !important;
  }
  .xs-w-137 {
    width: 137% !important;
  }
}
.w-138 {
  width: 138% !important;
}

.max-w-138 {
  max-width: 138rem !important;
}

@media (max-width: 991px) {
  .w-138 {
    width: auto !important;
  }
  .xs-w-138 {
    width: 138% !important;
  }
}
.w-139 {
  width: 139% !important;
}

.max-w-139 {
  max-width: 139rem !important;
}

@media (max-width: 991px) {
  .w-139 {
    width: auto !important;
  }
  .xs-w-139 {
    width: 139% !important;
  }
}
.w-140 {
  width: 140% !important;
}

.max-w-140 {
  max-width: 140rem !important;
}

@media (max-width: 991px) {
  .w-140 {
    width: auto !important;
  }
  .xs-w-140 {
    width: 140% !important;
  }
}
.w-141 {
  width: 141% !important;
}

.max-w-141 {
  max-width: 141rem !important;
}

@media (max-width: 991px) {
  .w-141 {
    width: auto !important;
  }
  .xs-w-141 {
    width: 141% !important;
  }
}
.w-142 {
  width: 142% !important;
}

.max-w-142 {
  max-width: 142rem !important;
}

@media (max-width: 991px) {
  .w-142 {
    width: auto !important;
  }
  .xs-w-142 {
    width: 142% !important;
  }
}
.w-143 {
  width: 143% !important;
}

.max-w-143 {
  max-width: 143rem !important;
}

@media (max-width: 991px) {
  .w-143 {
    width: auto !important;
  }
  .xs-w-143 {
    width: 143% !important;
  }
}
.w-144 {
  width: 144% !important;
}

.max-w-144 {
  max-width: 144rem !important;
}

@media (max-width: 991px) {
  .w-144 {
    width: auto !important;
  }
  .xs-w-144 {
    width: 144% !important;
  }
}
.w-145 {
  width: 145% !important;
}

.max-w-145 {
  max-width: 145rem !important;
}

@media (max-width: 991px) {
  .w-145 {
    width: auto !important;
  }
  .xs-w-145 {
    width: 145% !important;
  }
}
.w-146 {
  width: 146% !important;
}

.max-w-146 {
  max-width: 146rem !important;
}

@media (max-width: 991px) {
  .w-146 {
    width: auto !important;
  }
  .xs-w-146 {
    width: 146% !important;
  }
}
.w-147 {
  width: 147% !important;
}

.max-w-147 {
  max-width: 147rem !important;
}

@media (max-width: 991px) {
  .w-147 {
    width: auto !important;
  }
  .xs-w-147 {
    width: 147% !important;
  }
}
.w-148 {
  width: 148% !important;
}

.max-w-148 {
  max-width: 148rem !important;
}

@media (max-width: 991px) {
  .w-148 {
    width: auto !important;
  }
  .xs-w-148 {
    width: 148% !important;
  }
}
.w-149 {
  width: 149% !important;
}

.max-w-149 {
  max-width: 149rem !important;
}

@media (max-width: 991px) {
  .w-149 {
    width: auto !important;
  }
  .xs-w-149 {
    width: 149% !important;
  }
}
.w-150 {
  width: 150% !important;
}

.max-w-150 {
  max-width: 150rem !important;
}

@media (max-width: 991px) {
  .w-150 {
    width: auto !important;
  }
  .xs-w-150 {
    width: 150% !important;
  }
}
h1, h2, h3, h4, h5 {
  font-weight: normal;
}

.container,
.main {
  width: 100%;
  position: relative;
  /**ACESSABILITY*/
  /**END ACESSABILITY*/
}
.container button:focus-visible,
.container *[type=button]:focus-visible,
.container input[type=checkbox]:focus-visible,
.container .select2-container.select2-container--default:focus-visible,
.main button:focus-visible,
.main *[type=button]:focus-visible,
.main input[type=checkbox]:focus-visible,
.main .select2-container.select2-container--default:focus-visible {
  border: 3px solid #FFE901;
  box-shadow: 0px 0px 10px 3px #FFE901;
}
.container a:focus-visible,
.main a:focus-visible {
  border: 3px solid #FFE901;
  box-shadow: 0px 0px 10px 3px #FFE901;
}
.container a:focus-visible > .content-list-item, .container a:focus-visible > .item, .container a:focus-visible > .block-title, .container a:focus-visible > .name, .container a:focus-visible > .post, .container a:focus-visible > .aux-img, .container a:focus-visible > .img, .container a:focus-visible > div, .container a:focus-visible > span, .container a:focus-visible > p, .container a:focus-visible > h2, .container a:focus-visible > .title,
.main a:focus-visible > .content-list-item,
.main a:focus-visible > .item,
.main a:focus-visible > .block-title,
.main a:focus-visible > .name,
.main a:focus-visible > .post,
.main a:focus-visible > .aux-img,
.main a:focus-visible > .img,
.main a:focus-visible > div,
.main a:focus-visible > span,
.main a:focus-visible > p,
.main a:focus-visible > h2,
.main a:focus-visible > .title {
  border: 3px solid #FFE901;
  box-shadow: 0px 0px 10px 3px #FFE901;
}

.h-100 {
  height: 100%;
}

.p-0 {
  padding: 0;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.stop-scroll,
.stop-scroll-store,
.stop-scroll-geo {
  overflow: hidden;
}

.space-between {
  justify-content: space-between;
}

.text.generic b {
  font-family: "Montserrat-SemiBold", sans-serif;
}

.wrap-container {
  padding: 5rem;
}
@media (max-width: 991px) {
  .wrap-container {
    padding: 1.5rem;
  }
}

.wrap-limited {
  max-width: 1280px;
  margin: 0 auto;
}

.wrap-limited-small {
  max-width: 996px;
  margin: 0 auto;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.md-hidden,
.sm-visible {
  display: none !important;
}

.md-visible {
  display: inherit !important;
}
.md-visible.d-none {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.wrap-redes .redes .red-item {
  opacity: 1;
  transition: 0.2s all;
}
.wrap-redes .redes:hover .red-item {
  opacity: 0.6;
  transition: 0.2s all;
}
.wrap-redes .redes:hover .red-item:hover {
  opacity: 1;
  transition: 0.2s all;
  color: #c70087;
}
.wrap-redes .redes:hover .red-item:hover a {
  transition: 0.2s all;
  color: #c70087;
}

.marka {
  background-image: url("/images/img_garanty/star-living.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  width: 18.7rem;
  height: 18.7rem;
  right: 0;
  top: -20rem;
}
.marka::before {
  content: "";
  background-image: url("/images/img_garanty/check-white.svg");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-position: center;
  transform: scale(1.1);
}
.marka::after {
  content: "";
  background-image: url("/images/img_garanty/words-circle.svg");
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  animation: spins 16s linear infinite;
}
@-moz-keyframes spins {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spins {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spins {
  from {
    -webkit-transform: scale(1.1) rotate(0deg);
    transform: scale(1.1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1.1) rotate(360deg);
    transform: scale(1.1) rotate(360deg);
  }
}
@media (max-width: 991px) {
  .marka {
    width: 13rem;
    height: 13rem;
    right: 0;
    top: -7rem;
  }
  .marka:before {
    transform: scale(0.7);
  }
  @-moz-keyframes spins {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spins {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spins {
    from {
      -webkit-transform: scale(0.7) rotate(0deg);
      transform: scale(0.7) rotate(0deg);
    }
    to {
      -webkit-transform: scale(0.7) rotate(360deg);
      transform: scale(0.7) rotate(360deg);
    }
  }
}

.box-content .title {
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
  margin-bottom: 4.7rem;
  line-height: normal;
  letter-spacing: 0;
  position: relative;
}
@media (max-width: 991px) {
  .box-content .title {
    font-size: 2.5rem;
    margin-bottom: 4rem;
  }
}

.span1, .name1-1 {
  color: #c70087;
}
.span1.blue, .name1-1.blue {
  color: #1197a5;
}

.small-line:not(.sinBorder) {
  position: relative;
}
.small-line:not(.sinBorder)::before {
  content: "";
  position: absolute;
  width: 3.5rem;
  height: 0.5rem;
  background-color: #c70087;
  bottom: -0.6rem;
  left: 0;
}
.small-line:not(.sinBorder).white::before {
  background-color: white;
}
.small-line:not(.sinBorder).blue::before {
  background-color: #1197a5;
}
.small-line:not(.sinBorder).center::before {
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 991px) {
  .small-line:not(.sinBorder) {
    position: relative;
    font-family: "Montserrat-SemiBold";
    font-size: 3.5rem;
    line-height: 3.9rem;
    font-size: 2.9rem;
    font-weight: normal !important;
  }
  .small-line:not(.sinBorder)::before {
    width: 2rem;
    height: 3px;
  }
}

.text.generic {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #262626;
}
@media (max-width: 991px) {
  .text.generic p, .text.generic, .text.generic *:not(i) {
    font-family: "Montserrat-Regular";
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}

.fa.fa-envelope, .fa.fa-twitter, .fa.fa-facebook-f {
  color: #575252;
}
.fa.fa-twitter {
  position: relative;
  height: 2rem;
  width: 2rem;
}
.fa.fa-twitter::before {
  content: "";
  height: 100%;
  width: 100%;
  background-image: url(/images/X_active.svg);
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  bottom: -0.2rem;
}
.fa.fa-twitter:hover::before {
  background-image: url(/images/X_hover.svg);
}

@media (max-width: 991px) {
  .new-blog .wrap-content-post .wrap-share .share .icon:hover i {
    color: #575252 !important;
  }
  .new-blog .wrap-content-post .wrap-share .share .icon:hover i.fa.fa-twitter:hover::before {
    background-image: url(/images/X_active.svg);
  }
}
.full-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
.full-bg.isVisible {
  visibility: unset;
  opacity: 1;
  z-index: 102;
  transition: 0.2s all ease;
}

.cssanimationTranslate {
  display: inline-block;
  animation-duration: 1.4s;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}
.cssanimationTranslate.delay-2 {
  animation-delay: 0.7s;
}
.cssanimationTranslate.delay-3 {
  animation-delay: 0.9s;
}

.fadeInBottomTranslate {
  animation-name: fadeInBottomTranslate;
}

@keyframes fadeInBottomTranslate {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.scrollbar-home {
  justify-content: center;
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 0.7rem;
  z-index: 1;
}
.scrollbar-home .scrollbar {
  width: 9.8rem;
  height: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.scrollbar-home .scrollbar-item {
  width: 2.5rem;
  height: 20%;
  background-color: #c70087;
}
.scrollbar-home .scrollbar-item.isActive {
  height: 100%;
}
@media (max-width: 768px) {
  .scrollbar-home {
    height: 0.5rem;
  }
  .scrollbar-home .scrollbar {
    width: 6.8rem;
    height: 0.5rem;
  }
  .scrollbar-home .scrollbar .scrollbar-item {
    width: 1.8rem;
  }
}

.desktop-hidden {
  display: none !important;
}
@media (max-width: 991px) {
  .desktop-hidden {
    display: inherit !important;
  }
}

.mobile-hidden {
  display: inherit;
}
@media (max-width: 991px) {
  .mobile-hidden {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .md-hidden,
  .sm-visible {
    display: inherit !important;
  }
  .md-hidden.d-none,
  .sm-visible.d-none {
    display: none !important;
  }
  .md-visible {
    display: none !important;
  }
}
.layout-default-famaliving .lcc-label,
.layout-default-famaliving .lcc-label span {
  font-family: "Montserrat-Medium";
  font-weight: normal !important;
}
.layout-default-famaliving .pr-cookie-view-top {
  font-family: "Montserrat-Medium";
  font-size: 1.6rem;
}
.layout-default-famaliving .pr-cookie-view-top .btn {
  padding: 0.4rem 0.8rem;
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-weight: normal;
}
.layout-default-famaliving .cookies-text-message {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
}
.layout-default-famaliving .content-cookies .btn, .layout-default-famaliving .content-cookies button,
.layout-default-famaliving .lcc-modal .btn,
.layout-default-famaliving .lcc-modal button {
  cursor: pointer;
}

.fama-toast {
  font-size: 1.5rem;
}

.wrap-btnDefault {
  min-width: 26.6rem;
  width: auto;
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 1px solid #c70087;
  z-index: 1;
}
.wrap-btnDefault .btnDefault {
  text-align: center;
  background-color: #c70087;
  color: #FFF;
  padding: 1.4rem 4rem;
  position: relative;
  font-size: 1.9rem;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.3rem;
  line-height: 1.7rem;
  text-transform: uppercase;
  transform: translateX(-0.3rem) translateY(-0.3rem);
  transition: 0.3s transform linear;
  z-index: 2;
}
.wrap-btnDefault .btnDefault:active, .wrap-btnDefault .btnDefault:hover {
  transform: translateX(0) translateY(0);
  transition: 0.3s all linear;
}
@media (max-width: 768px) {
  .wrap-btnDefault .btnDefault {
    padding: 1.4rem 3.5rem;
    font-size: 1.5rem;
  }
}
.wrap-btnDefault.loading {
  border: 1px solid #DCDCDC;
}
.wrap-btnDefault.loading .btnDefault {
  color: #DCDCDC;
  background-color: #DCDCDC;
  position: relative;
}
.wrap-btnDefault.loading .btnDefault:before {
  content: "";
  position: absolute;
  left: calc(50% - 1.5rem);
  top: calc(50% - 1.5rem);
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/images/fl-loader-rose.svg");
  transform-origin: center;
  animation: loading 0.8s infinite;
}
.wrap-btnDefault.btn-mailfastsuscription {
  min-width: unset;
}
.wrap-btnDefault.btn-mailfastsuscription .btnDefault {
  font-size: 1.5rem;
  padding: 1.2rem 2.1rem;
  line-height: normal;
  white-space: nowrap;
}
.wrap-btnDefault.white .btnDefault {
  text-align: center;
  background-color: #FFF;
  color: #c70087;
  border: 1px solid #c70087;
}
.wrap-btnDefault.d-none {
  display: none;
}

.btn-close {
  font-size: unset;
  position: relative;
  top: 0;
  right: 0;
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
}
.btn-close .icon {
  width: 2.2rem;
  height: 2.2rem;
}
.btn-close .icon:before {
  content: "";
  width: 2.2rem;
  height: 2.2rem;
  position: absolute;
  background-image: url("/images/fl-close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0%;
  left: 0;
}
.btn-close.white .icon:before {
  content: "";
  width: 2.2rem;
  height: 2.2rem;
  position: absolute;
  background-image: url("/images/fl-close-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0%;
  left: 0;
}
.btn-close.black .icon:before {
  background-image: url("/images/fl-close-black.svg");
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  z-index: 2000;
}
.loader.loader-intro {
  background-color: #FFF;
}
.loader .loader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #FFF;
  border-radius: 50%;
  overflow: hidden;
  width: 14rem;
  height: 10.5rem;
  padding: 0;
}
.loader .loader-wrap img {
  width: 100%;
  height: auto;
}
.loader .loader-wrap img.visible-md.visible-lg {
  display: none;
}
.loader .loader-wrap img.visible-xs.visible-sm {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
}
.loader .loader-wrap:before {
  content: "";
  background-color: #c70087;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  -webkit-mix-blend-mode: color;
  mix-blend-mode: color;
  z-index: 1;
}

@media (min-width: 992px) {
  .loader .loader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #FFF;
    border-radius: 50%;
    overflow: hidden;
    height: 18%;
    width: 22%;
  }
  .loader .loader-wrap img {
    width: auto;
  }
  .loader .loader-wrap img.visible-xs.visible-sm {
    display: none;
  }
  .loader .loader-wrap img.visible-md.visible-lg {
    display: block;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
  }
}
.comment-loader {
  text-align: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #c70087;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.app-list.view-index-page.main .header_page nav.topbar .menu.menu-horizontal > .item.is-active {
  border: none;
}
.app-list.view-index-page.main .header_page nav.topbar .menu.menu-horizontal > .item.is-active a:before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #FFF;
  transform: scaleX(1);
  right: 0;
  bottom: -6px;
  left: 0;
  transition: transform 0.2s ease-in-out;
}
.app-list.view-index-page.main .header_page nav.topbar .menu.menu-horizontal > .item.is-active a:hover:before {
  transform: scaleX(1);
}

.isHomePage.main .header_page nav.topbar .menu.menu-horizontal > .item.regular {
  opacity: 1;
}

.header_page nav.topbar {
  height: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 5rem;
  margin-top: 3.7rem;
  z-index: 101;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}
.header_page nav.topbar.navAdapt {
  width: calc(100% - 1.5rem);
}
.header_page nav.topbar.topOut .full-level-black,
.header_page nav.topbar.topOut .wrap-store {
  top: 6.5rem;
}
.header_page nav.topbar .full-level-black {
  position: relative;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: -3.7rem;
  left: 0;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
.header_page nav.topbar .full-level-black.isVisible {
  visibility: unset;
  opacity: 1;
  z-index: 1;
  transition: 0.2s opacity ease;
}
.header_page nav.topbar .full-level-black.isVisible.isVisibleStore {
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.7);
}
.header_page nav.topbar .full-level-black.isVisibleStore {
  visibility: unset;
  z-index: 4;
  opacity: 1;
  transition: 0.2s all ease;
  background-color: rgba(0, 0, 0, 0.7);
}
.header_page nav.topbar .container-nav {
  background-color: #c70087;
  padding: 0 2.2rem;
  position: inherit;
  width: calc(100% - 10rem);
  z-index: 3;
}
.header_page nav.topbar .container-nav .container-aux-nav {
  justify-content: space-between;
}
.header_page nav.topbar .container-nav .container-aux-nav .nav-part-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_page nav.topbar .menu-burger {
  min-width: 2.7rem;
  display: flex;
}
.header_page nav.topbar .menu-burger .item-burger {
  align-items: center;
}
.header_page nav.topbar .menu-burger .store-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  z-index: 1;
  margin-left: 2rem;
}
.header_page nav.topbar .menu-burger .store-user .store-user-name {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  text-decoration: none;
  --border-color: #fff;
  --border-width: 1px;
  --bottom-distance: 0px;
  display: inline;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 100% var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
  color: #FFF;
}
.header_page nav.topbar .menu-burger .store-user .store-user-name:hover {
  background-size: 0 var(--border-width);
}
.header_page nav.topbar .logo-movil {
  display: none;
}
.header_page nav.topbar .logo-movil .logo {
  height: 2.6rem;
}
.header_page nav.topbar .logo-movil .logo img {
  height: 100%;
}
.header_page nav.topbar .wrap-items {
  justify-content: space-between;
}
.header_page nav.topbar .ham {
  position: relative;
  cursor: pointer;
  margin-right: 0;
}
.header_page nav.topbar .ham + .hide-menu + .logo {
  height: unset;
}
.header_page nav.topbar .logo img {
  height: 100%;
  object-fit: contain;
}
.header_page nav.topbar .menu.menu-horizontal {
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  transition: padding 0.2s ease-in;
  font-family: "Montserrat-Regular";
  display: flex;
  padding-left: 0;
}
.header_page nav.topbar .menu.menu-horizontal > .item {
  line-height: normal;
  font-weight: normal;
  font-size: 1.9rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
}
.header_page nav.topbar .menu.menu-horizontal > .item.logo-item a:hover:before {
  content: none;
}
.header_page nav.topbar .menu.menu-horizontal > .item.logo-item .logo {
  height: 3.24rem;
}
.header_page nav.topbar .menu.menu-horizontal > .item.logo-item .logo img {
  height: 100%;
}
.header_page nav.topbar .menu.menu-horizontal > .item.logo-item h1 {
  font-size: 0;
  height: 100%;
}
.header_page nav.topbar .menu.menu-horizontal > .item a {
  position: relative;
  color: #FFF;
  text-transform: none;
  text-decoration: none;
}
.header_page nav.topbar .menu.menu-horizontal > .item h1 {
  font-size: 1.7rem;
  margin-bottom: 0;
  font-family: inherit;
}
.header_page nav.topbar .menu.menu-horizontal > .item.is-active {
  font-family: "Montserrat-SemiBold";
  border: 1px solid #fff;
}
.header_page nav.topbar .menu.menu-horizontal > .item.is-active a {
  text-decoration: none;
  color: #FFF;
}
.header_page nav.topbar .menu.menu-horizontal > .item.is-active a:before {
  background-color: #FFF;
}
.header_page nav.topbar .menu.menu-horizontal > .item.is-active a:hover:before {
  transform: scaleX(0);
}
.header_page nav.topbar .menu.menu-horizontal > .item.is-active.logo-item {
  border: none;
}
.header_page nav.topbar .menu.menu-horizontal > .item.no-active, .header_page nav.topbar .menu.menu-horizontal > .item.regular {
  opacity: 0.5;
}
.header_page nav.topbar .menu.menu-horizontal > .item.no-active:hover, .header_page nav.topbar .menu.menu-horizontal > .item.no-active:active, .header_page nav.topbar .menu.menu-horizontal > .item.regular:hover, .header_page nav.topbar .menu.menu-horizontal > .item.regular:active {
  opacity: 1;
}
.header_page nav.topbar .menu.menu-horizontal > .item a:before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #FFF;
  transform: scaleX(0);
  right: 0;
  bottom: -6px;
  left: 0;
  transition: transform 0.2s ease-in-out;
}
.header_page nav.topbar .menu.menu-horizontal > .item a:hover:before {
  transform: scaleX(1);
}
.header_page nav.topbar .btn-search {
  width: 2.7rem;
  width: 2.7rem;
  margin-left: 2rem;
  cursor: pointer;
  position: relative;
  height: 4.7rem;
}
.header_page nav.topbar .btn-search .searchImgHover {
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}
.header_page nav.topbar .btn-search::before {
  content: "";
  background-color: transparent;
  position: absolute;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
  z-index: 1;
  top: 50%;
  left: -1rem;
  transform: translateY(-50%);
}
.header_page nav.topbar .btn-search:hover::before {
  background-color: #fff;
  transition: 0.2s background-color ease-in-out;
}
.header_page nav.topbar .btn-search:hover .searchImgHover {
  opacity: 1;
  transition: 0.2s opacity ease-in-out;
  z-index: 2;
}
.header_page nav.topbar .btn-search:hover .searchImg {
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}
.header_page nav.topbar .btn-search img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  left: 0;
  width: 2.7rem;
  height: 2.7rem;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
@media (max-width: 1199px) {
  .header_page nav.topbar .btn-search {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .header_page nav.topbar .btn-search {
    margin-left: 0;
  }
}
.header_page nav.topbar .close.noSeeDesk {
  display: none;
}
.header_page nav.topbar .menu-vertical {
  width: 37.4rem;
  height: 100vh;
  position: absolute;
  padding-top: 13.2rem;
  z-index: 2;
  top: -3.7rem;
  left: 0;
  background-color: #FFF;
  transform: translateX(-37.4rem);
  transition: transform 0.3s linear;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.header_page nav.topbar .menu-vertical #languages {
  margin-bottom: 0;
  padding: 2.3rem 5rem;
  margin: 0;
}
.header_page nav.topbar .menu-vertical #languages .lang-current.lang-item {
  border-bottom: 3px solid #c70087;
  margin-right: 1.5rem;
  padding: 0 1px 1px 2px;
}
.header_page nav.topbar .menu-vertical #languages #select-idioma2 .lang-item:not(:last-of-type) {
  margin-right: 1.5rem;
}
.header_page nav.topbar .menu-vertical .wrap-redes {
  width: 100%;
  background-color: #fff4fb;
  padding: 3.1rem 5rem;
  padding-bottom: 1.3rem;
}
.header_page nav.topbar .menu-vertical .wrap-redes .redes {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.header_page nav.topbar .menu-vertical .wrap-redes .circl {
  position: relative;
  width: 15%;
}
.header_page nav.topbar .menu-vertical .wrap-redes .circl::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #c70087;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header_page nav.topbar .menu-vertical .wrap-redes .red-item {
  position: relative;
  font-family: "Montserrat-Medium";
  font-size: 1.3rem;
  margin-bottom: 1.8rem;
  padding-right: 0;
  text-align: center;
}
.header_page nav.topbar .menu-vertical .wrap-redes .red-item:nth-child(3n) {
  margin-right: 0;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box {
  width: calc(100% - 1.5rem);
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar {
  -webkit-appearance: none;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar:vertical {
  width: 5px;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar-button:increment, .header_page nav.topbar .menu-vertical .menu-vertical-box .contenedor::-webkit-scrollbar-button {
  display: none;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar:horizontal {
  padding-right: 0.5rem;
  height: 8px;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar-thumb {
  background-color: #c70087;
  border-radius: 0 !important;
  border: none;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar-track {
  background-color: rgba(199, 0, 135, 0.5);
  border-radius: 0 !important;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul {
  list-style: none;
  margin-left: 0;
  padding-left: 5rem;
  padding-right: 4.7rem;
  padding-top: 0.5rem;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item a,
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item p {
  display: block;
  color: #c70087;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(199, 0, 135, 0.5);
  font-size: 1.3rem;
  font-family: "Montserrat-Regular";
  transition: background-color 0.2s ease-in-out;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 0.3s font-family ease;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item a:hover,
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item p:hover {
  background-color: transparent;
  text-decoration: none;
  font-family: "Montserrat-SemiBold";
  transition: 0.3s font-family ease;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open {
  background-color: transparent;
  border-bottom: none;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open > a {
  border-bottom: none;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open a {
  color: #FFF;
  border-bottom: none;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open a li a:last-of-type {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.top-separator {
  border-top: 1px solid transparent;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul li.item > ul {
  list-style: none;
  padding: 0;
  background-color: transparent;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul li.item > ul > li.item a {
  color: #FFF;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.3rem;
  font-family: "Montserrat-Regular";
  display: flex;
  align-items: center;
  text-transform: capitalize;
  transition: 0.3s all;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul li.item > ul > li.item a:hover {
  text-decoration: none;
  font-family: "Montserrat-SemiBold";
  transition: 0.3s all;
}
.header_page nav.topbar .menu-vertical .menu-vertical-box > ul li.item > ul > li.item a:hover:before {
  transform: translateX(0.5rem);
}
.header_page nav.topbar .menu-vertical .menu-categories-productos {
  margin-bottom: 3.4rem;
}
.header_page nav.topbar .menu-vertical .menu-categories-productos.mvl {
  display: none;
}
.header_page nav.topbar .menu-vertical .menu-general {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: 5rem;
}
.header_page nav.topbar .menu-vertical .menu-general .item {
  cursor: pointer;
}
.header_page nav.topbar .menu-vertical .menu-general .item.is-active > a, .header_page nav.topbar .menu-vertical .menu-general .item.is-active > p {
  font-family: "Montserrat-SemiBold";
}
.header_page nav.topbar .menu-vertical .menu-general .item a, .header_page nav.topbar .menu-vertical .menu-general .item p {
  font-size: 1.5rem;
  font-family: "Montserrat-Regular";
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild > p {
  position: relative;
  padding-left: 1.5rem;
  display: block;
  color: #c70087;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(199, 0, 135, 0.5);
  font-size: 1.3rem;
  font-weight: normal;
  transition: background-color 0.3s ease-in-out;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 0.3s font-weight ease;
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild > p.isOpen {
  font-family: "Montserrat-SemiBold";
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild > p.isOpen:before {
  content: "-";
  top: 0;
  font-size: 1.9rem;
  font-family: inherit;
  height: 100%;
  color: inherit;
  position: absolute;
  left: 0;
  padding-right: 0;
  transform: translateX(0) rotate(0);
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild .isOpen + .sublist {
  max-height: 9999px;
  transition: 0.3s all;
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild .isOpen + .sublist > .item a {
  border: none;
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild > p:before {
  content: "+";
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  position: absolute;
  left: -1rem;
  padding-right: 1.3rem;
  transform: translateX(50%) rotate(360deg);
  transition: 0.3s all;
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild > p + .sublist {
  overflow: hidden;
  max-height: 0;
  transition: 0.3s all;
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild > p + .sublist .item a {
  padding: 0.5rem 0 0.5rem 3rem;
  font-family: "Montserrat-Regular";
  color: #c70087;
  text-transform: none;
  border: none;
  transition: 0.3s font-family;
}
.header_page nav.topbar .menu-vertical .menu-general .item.hasChild > p + .sublist .item a:hover {
  font-family: "Montserrat-SemiBold";
  transition: 0.3s all;
}
.header_page nav.topbar .menu-vertical .menu-general:hover .item a,
.header_page nav.topbar .menu-vertical .menu-general:hover .item p {
  opacity: 0.6;
  transition: 0.3s opacity ease;
}
.header_page nav.topbar .menu-vertical .menu-general:hover .item a:hover,
.header_page nav.topbar .menu-vertical .menu-general:hover .item p:hover {
  opacity: 1;
  transition: 0.3s opacity ease;
}
.header_page nav.topbar .ham {
  display: flex;
  align-items: center;
  height: 5rem;
}
.header_page nav.topbar .ham::before {
  content: "";
  position: absolute;
  width: 4.9rem;
  height: 4.9rem;
  background-color: #c70087;
  border-radius: 50%;
  transition: 0.3s background-color ease;
  transform: translateX(-50%);
  left: 50%;
}
.header_page nav.topbar .ham:hover::before {
  background-color: #FFF;
  transition: 0.3s background-color ease;
}
.header_page nav.topbar .ham:hover .item-ham,
.header_page nav.topbar .ham:hover .item-ham:before,
.header_page nav.topbar .ham:hover .item-ham:after {
  background-color: #c70087;
}
.header_page nav.topbar .ham div {
  position: relative;
}
.header_page nav.topbar .ham div,
.header_page nav.topbar .ham div:before,
.header_page nav.topbar .ham div:after {
  height: 2px;
  background-color: #FFF;
  position: relative;
  width: 2.7rem;
  content: "";
  transition: all 0.3s ease-in-out;
}
.header_page nav.topbar .ham div:before,
.header_page nav.topbar .ham div:after {
  position: absolute;
  width: 1.9rem;
  left: 0.4rem;
}
.header_page nav.topbar .ham div:before {
  top: -9px;
}
.header_page nav.topbar .ham div:after {
  bottom: -9px;
}
.header_page nav.topbar .ham:hover div,
.header_page nav.topbar .ham:hover div:before,
.header_page nav.topbar .ham:hover div:after {
  background-color: #FFF;
}
.header_page nav.topbar .hide-menu {
  position: absolute;
  left: 3.8rem;
  opacity: 0;
  z-index: -1;
  transition: 0.4s ease-in-out all;
  font-size: rem(9px);
  text-transform: uppercase;
  width: 5rem;
  line-height: 1.5;
  color: #324b4e;
}
.header_page nav.topbar .if-open-hidden {
  opacity: 1;
  transition: 0.3s ease-in-out all;
}
.header_page nav.topbar .if-open-hidden h1 {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
}
.header_page nav.topbar.is-open {
  transform: translateX(0);
}
.header_page nav.topbar.is-open .container-nav {
  z-index: 3;
}
.header_page nav.topbar.is-open .menu-vertical {
  transform: translateX(0);
  transition: transform 0.3s linear;
}
.header_page nav.topbar.is-open .ham:hover::before {
  background-color: #FFF;
  transition: 0.3s background-color ease;
}
.header_page nav.topbar.is-open .ham:hover .item-ham:before,
.header_page nav.topbar.is-open .ham:hover .item-ham:after {
  background-color: #c70087;
}
.header_page nav.topbar.is-open .ham:hover .item-ham {
  background-color: transparent;
}
.header_page nav.topbar.is-open .ham div:before {
  transform: rotate(-45deg);
}
.header_page nav.topbar.is-open .ham div:after {
  transform: rotate(45deg);
}
.header_page nav.topbar.is-open .ham div:before, .header_page nav.topbar.is-open .ham div:after {
  width: 3.2rem;
  transform-origin: center;
  top: 0;
  background-color: #FFF;
  left: -0.2rem;
}
.header_page nav.topbar.is-open .ham div {
  background-color: transparent;
}
.header_page nav.topbar.is-open .hide-menu {
  opacity: 1;
  z-index: 1;
  transition: 0.4s 0.8s ease-in-out all;
  cursor: pointer;
}
.header_page nav.topbar.is-open .if-open-hidden {
  opacity: 0;
}
.header_page nav.topbar.is-open .full-level-black {
  visibility: unset;
  opacity: 1;
  z-index: 1;
  transition: 0.2s opacity ease;
}
@media only screen and (max-width: 991px) {
  .header_page nav.topbar {
    height: 5.5rem;
    padding: 0 1.5rem;
    margin-top: 1.5rem;
  }
  .header_page nav.topbar .close.noSeeDesk {
    width: 2.2rem;
    background-color: #c70087;
    height: 5.5rem;
    position: absolute;
    opacity: 1;
    z-index: 2;
  }
  .header_page nav.topbar .close.noSeeDesk .icon {
    background-color: #c70087;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .header_page nav.topbar .close.noSeeDesk .icon:before {
    content: "";
    width: 2.2rem;
    height: 2.2rem;
    position: absolute;
    background-image: url("/images/cancel-copy-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: 2rem;
    left: unset;
    right: 0;
  }
  .header_page nav.topbar .full-level-black {
    top: -1.5rem;
  }
  .header_page nav.topbar .container-nav {
    padding: 0 1.8rem;
    position: fixed;
    width: calc(100% - 3rem);
    z-index: 3;
  }
  .header_page nav.topbar .container-nav .wrap-items {
    width: 2.2rem;
    transition: 0.1s all ease;
  }
  .header_page nav.topbar .container-nav .wrap-items.notVisible {
    visibility: hidden;
    transition: 0.1s all ease;
  }
  .header_page nav.topbar .container-nav .menu-burger {
    opacity: 1;
    transition: 0.2s 0.2s visibility ease, 0.2s 0.1s opacity ease;
  }
  .header_page nav.topbar .container-nav .menu-burger.notVisible {
    visibility: hidden;
    opacity: 0;
    transition: 0.1s all ease;
  }
  .header_page nav.topbar .menu.menu-horizontal {
    display: none;
  }
  .header_page nav.topbar .logo-movil {
    display: flex;
  }
  .header_page nav.topbar .btn-search {
    width: 2.3rem;
    min-width: 2.3rem;
    min-height: 2.3rem;
    margin: 0;
  }
  .header_page nav.topbar .btn-search::before {
    content: none;
  }
  .header_page nav.topbar .btn-search:hover::before {
    content: none;
  }
  .header_page nav.topbar .btn-search:hover .searchImgHover {
    opacity: 0;
  }
  .header_page nav.topbar .btn-search:hover .searchImg {
    opacity: 1;
  }
  .header_page nav.topbar .btn-search img {
    width: 100%;
    height: auto;
  }
  .header_page nav.topbar .ham::before {
    content: none;
  }
  .header_page nav.topbar .ham div {
    width: 2.7rem;
  }
  .header_page nav.topbar .ham .item-ham:before, .header_page nav.topbar .ham .item-ham:after {
    background-color: #fff;
  }
  .header_page nav.topbar .ham:hover .item-ham {
    background-color: #fff;
  }
  .header_page nav.topbar .ham:hover .item-ham:before, .header_page nav.topbar .ham:hover .item-ham:after {
    background-color: #fff;
  }
  .header_page nav.topbar.is-open .menu-vertical {
    opacity: 1;
    transition: opacity 0.1s, transform 0.3s linear;
  }
  .header_page nav.topbar.is-open .ham::before {
    content: none;
  }
  .header_page nav.topbar.is-open .ham div:before {
    width: 2.5rem;
    left: -0.5rem;
  }
  .header_page nav.topbar.is-open .ham div:after {
    width: 2.5rem;
    left: -0.5rem;
  }
  .header_page nav.topbar.is-open .ham:hover .item-ham:before, .header_page nav.topbar.is-open .ham:hover .item-ham:after {
    background-color: #fff;
  }
  .header_page nav.topbar.is-open .btn-search {
    width: 2.3rem;
    height: 2.3rem;
    background-color: #c70087;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
  }
  .header_page nav.topbar.is-open .container-nav {
    position: fixed;
  }
  .header_page nav.topbar .menu-vertical {
    background-color: #c70087;
    transform: translateX(calc(-100% - 3rem));
    opacity: 0;
    transition: 0.3s opacity 0.1s, transform 0.3s linear;
    position: absolute;
    margin: 1.5rem;
    width: calc(100% - 3rem);
    top: -1.5rem;
    padding-top: 5.5rem;
    height: calc(100vh - 10rem);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 1.5rem;
    padding-bottom: 0;
  }
  .header_page nav.topbar .menu-vertical .wrap-redes {
    width: calc(100% + 1.5rem);
    padding: 1.7rem 3.5rem;
    padding-bottom: 0.7rem;
  }
  .header_page nav.topbar .menu-vertical .wrap-redes .redes {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 27rem;
  }
  .header_page nav.topbar .menu-vertical .wrap-redes .redes .red-item {
    margin-bottom: 1rem;
    padding-right: 0;
  }
  .header_page nav.topbar .menu-vertical .wrap-redes .redes .red-item:nth-child(3n) {
    margin-right: 0;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box {
    width: 100%;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar:vertical {
    width: 5px;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar-button:increment, .header_page nav.topbar .menu-vertical .menu-vertical-box .contenedor::-webkit-scrollbar-button {
    display: none;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar:horizontal {
    padding-right: 0.5rem;
    height: 8px;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar-thumb {
    background-color: #FFF;
    border-radius: 0 !important;
    border: none;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0 !important;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item a,
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item p {
    color: #FFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open {
    background-color: #222121;
    background-color: transparent;
    border-bottom: none;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open > a {
    border-bottom: none;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open a {
    color: #FFF;
    border-bottom: none;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.is-open a li a:last-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .item.top-separator {
    border-top: 1px solid transparent;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul li.item > ul {
    list-style: none;
    padding: 0;
    background-color: transparent;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul li.item > ul > li.item a {
    color: #FFF;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.3rem;
    font-family: "Montserrat-Regular";
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul li.item > ul > li.item a:before {
    content: none;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul.menu-general {
    margin-top: 0;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul.menu-general .item a,
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul.menu-general .item p {
    color: #FFF !important;
    font-size: 1.5rem !important;
    font-family: "Montserrat-Regular" !important;
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul.menu-general .item .hasChild > p {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul.menu-general .item.is-active a {
    font-family: "Montserrat-Bold" !important;
  }
  .header_page nav.topbar .menu-vertical .menu-vertical-box > ul .menu-categories-productos.mvl .item.hasChild.isOpen .itemName {
    border-bottom: none !important;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos {
    margin-bottom: 0;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.desk {
    display: none;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl {
    display: block;
    margin-bottom: 3rem;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .itemName {
    padding-top: 0;
    padding-bottom: 0;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .itemName {
    color: #FFF;
    font-size: 1.5rem;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .sub-menu .itemName {
    line-height: normal;
    color: #FFF;
    padding-left: 3.7rem;
    font-family: "Montserrat-medium";
    font-size: 1.3rem;
    position: relative;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .sub-menu .itemName:before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: white;
    position: absolute;
    left: 25px;
    top: 0.65rem;
    border-radius: 50%;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .sub-menu .sub-item {
    padding: 1rem 0;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .sub-menu .sub-sub-menu .sub-sub-item {
    padding: 1rem 0;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .sub-menu .sub-sub-menu .itemName {
    line-height: normal;
    padding-left: 4.8rem;
    font-family: "Montserrat-Regular";
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .sub-menu .sub-sub-menu .itemName:before {
    content: none;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item > .itemName {
    line-height: normal;
    color: #FFF;
    font-family: "Montserrat-Regular";
    font-size: 1.5rem;
    padding: 1rem 0;
    padding-left: 1.5rem;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild .itemName {
    position: relative;
    padding-left: 2.6rem;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild .itemName::before {
    content: "+";
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    position: absolute;
    left: 0;
    padding-right: 0.5rem;
    top: 50%;
    transform: translateX(50%) translateY(-50%) rotate(360deg);
    transition: all 0.3s;
    font-size: 2rem;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild.isOpen {
    border-bottom: none !important;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild.isOpen.visible-xs .itemName {
    border-bottom: none !important;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild.isOpen .itemName {
    position: relative;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild.isOpen .itemName::before {
    content: "-";
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    position: absolute;
    left: 0;
    padding-right: 1.3rem;
    transform: translateX(50%) translateY(-50%) rotate(0);
    transition: all 0.3s;
    font-size: 2rem;
    top: 50%;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild.isOpen + .sub-menu {
    max-height: 9999px;
    transition: 0.3s all;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 0;
  }
  .header_page nav.topbar .menu-vertical .menu-categories-productos.mvl .item.hasChild + .sub-menu {
    overflow: hidden;
    max-height: 0;
    transition: 0.3s all;
  }
  .header_page nav.topbar .menu-vertical .menu-general {
    margin-top: 0;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0rem;
  }
  .header_page nav.topbar .menu-vertical .menu-general .item a {
    text-transform: uppercase;
    font-size: 1.5rem;
  }
  .header_page nav.topbar .menu-vertical .menu-general .item.hasChild p {
    color: #FFF;
  }
  .header_page nav.topbar .menu-vertical .menu-general .item.hasChild .isOpen.sublist > .item > a, .header_page nav.topbar .menu-vertical .menu-general .item.hasChild .isOpen.sublist > .item > p {
    color: #FFF !important;
    font-family: inherit !important;
    padding: inherit;
    padding-left: 1.5rem;
    font-size: inherit;
    font-family: inherit;
  }
  .header_page nav.topbar .menu-vertical #languages.languages {
    margin-top: 0;
    position: relative;
    margin-bottom: 0;
    bottom: 0;
    width: 100%;
    padding-left: 3.5rem;
    padding-bottom: 2.7rem;
  }
  .header_page nav.topbar .menu-vertical #languages.languages #select-idioma2 .lang-item:not(:last-of-type) {
    margin-right: 1.6rem;
  }
  .header_page nav.topbar .menu-vertical #languages.languages .lang-current.lang-item {
    border-bottom: 3px solid #FFF;
    padding: 0 1px;
  }
  .header_page nav.topbar .menu-vertical #languages.languages .lang-current.lang-item a, .header_page nav.topbar .menu-vertical #languages.languages .lang-current.lang-item .a {
    font-family: "Montserrat-Bold";
    font-size: 1.3rem;
  }
  .header_page nav.topbar .menu-vertical #languages.languages .lang-item {
    margin-right: 1.9rem;
    opacity: 1;
    transition: 0.3s opacity ease;
  }
  .header_page nav.topbar .menu-vertical #languages.languages .lang-item:last-of-type {
    margin-right: 0;
  }
  .header_page nav.topbar .menu-vertical #languages.languages .lang-item a, .header_page nav.topbar .menu-vertical #languages.languages .lang-item .a {
    font-family: "Montserrat-Regular";
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 3.42px;
    color: #FFF;
  }
}
.header_page .languages {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  padding-left: 5rem;
  padding-right: 4.7rem;
  padding-bottom: 2rem;
  padding-top: 2.3rem;
  position: relative;
  margin-bottom: 0;
}
.header_page .languages #select-idioma2:hover .lang-item {
  opacity: 0.6;
  transition: 0.3s opacity ease;
}
.header_page .languages #select-idioma2:hover .lang-item:hover {
  opacity: 1;
  transition: 0.3s all ease;
}
.header_page .languages #select-idioma2:hover .lang-item:hover a {
  color: #c70087;
  transition: 0.2s color ease;
}
.header_page .languages .lang-current.lang-item {
  cursor: auto;
  pointer-events: none;
  margin-right: 1.6rem;
}
.header_page .languages .lang-current.lang-item a {
  font-family: "Montserrat-Bold";
  font-size: 1.3rem;
}
.header_page .languages .lang-item {
  margin-right: 1.6rem;
  opacity: 1;
  transition: 0.3s opacity ease;
}
.header_page .languages .lang-item:last-of-type {
  margin-right: 0;
}
.header_page .languages .lang-item a, .header_page .languages .lang-item .a {
  font-family: "Montserrat-Medium";
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 3.42px;
  color: #333;
  transition: 0.2s color ease;
}

@media (max-width: 991px) {
  .header_page nav.topbar .menu-vertical .wrap-sity-selectes .store-user {
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  .header_page nav.topbar .menu-vertical .wrap-sity-selectes .store-user .store-user-name {
    padding-left: 1.5rem;
    color: #FFF;
    font-size: 1.5rem;
  }
  .header_page nav.topbar .menu-vertical .wrap-sity-selectes .store-user.isSelectedStore {
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
  }
  .header_page nav.topbar .menu-vertical .wrap-sity-selectes .store-user.isSelectedStore .store-user-name {
    padding-left: 1.5rem;
    color: #FFF;
    font-size: 1.5rem;
  }
  .header_page nav.topbar .menu-vertical .wrap-sity-selectes .store-user.isSelectedStore + .wrap-sity-selectes-content {
    display: none !important;
  }
}
.wrap-sity-selectes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 4.7rem;
  padding-left: 5rem;
  padding-top: 0.5rem;
}
.wrap-sity-selectes .wrap-sity-selectes-content {
  border-bottom: 1px solid rgba(192, 61, 139, 0.5);
  border-top: 1px solid rgba(192, 61, 139, 0.5);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #FFF;
  transition: background-color 0.2s ease;
}
.wrap-sity-selectes .wrap-sity-selectes-content:hover {
  background-color: #fff4fb;
  transition: background-color 0.2s ease;
}
.wrap-sity-selectes .wrap-sity-selectes-name {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  padding-left: 1.5rem;
  text-transform: uppercase;
  color: #c70087;
}

.wrap-store {
  width: 47.4rem;
  height: 100vh;
  position: absolute;
  padding: 8.3rem 5.5rem 3.2rem 5.2rem;
  z-index: 5;
  top: -3.7rem;
  left: 0;
  background-color: #FFF;
  transform: translateX(-100%);
  transition: transform 0.3s linear;
  display: flex;
  flex-direction: column;
}
.wrap-store .content-wrap-stores {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wrap-store .comment-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrap-store .wrap-store-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
}
.wrap-store .wrap-store-content::-webkit-scrollbar {
  -webkit-appearance: none;
}
.wrap-store .wrap-store-content::-webkit-scrollbar:vertical {
  width: 5px;
}
.wrap-store .wrap-store-content::-webkit-scrollbar-button:increment, .wrap-store .wrap-store-content .contenedor::-webkit-scrollbar-button {
  display: none;
}
.wrap-store .wrap-store-content::-webkit-scrollbar:horizontal {
  padding-right: 0.5rem;
  height: 5px;
}
.wrap-store .wrap-store-content::-webkit-scrollbar-thumb {
  background-color: #c70087;
  border-radius: 0 !important;
  border: none;
}
.wrap-store .wrap-store-content::-webkit-scrollbar-track {
  background-color: rgba(199, 0, 135, 0.5);
  border-radius: 0 !important;
  margin-top: 2rem;
  margin-bottom: 7rem;
}
.wrap-store form .form-group {
  margin-top: 2.2rem;
  margin-bottom: 2.5rem;
}
.wrap-store form .form-group label {
  padding: 0 1rem;
}
.wrap-store form .form-control {
  border: 1px solid #575252;
  display: block;
  width: 100%;
  padding: 0.75rem;
  font-size: 1.7rem;
  font-weight: normal;
  line-height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.wrap-store form .form-control.err {
  border-color: #e3342f;
  transition: border-color 0.15s ease-in-out;
}
.wrap-store form input:-internal-autofill-selected {
  background-color: #FFF !important;
}
.wrap-store .step-two .box-content .title {
  margin-bottom: 3.3rem;
}
.wrap-store .step-two .search-store-description .text.generic {
  font-size: 1.3rem;
  line-height: 2.1rem;
}
.wrap-store .step-two .wrap-btnDefault:first-of-type {
  margin-bottom: 2.1rem;
}
.wrap-store .step-two .wrap-btnDefault .btnDefault {
  padding: 0.9rem;
  letter-spacing: 0.2rem;
}
.wrap-store .wrap-store-list-near .stores-near .store-near-name {
  color: #c70087;
  margin-bottom: 0.7rem;
}
.wrap-store .wrap-store-list-near .stores-near .store-near-name .store-name {
  color: #333;
}
.wrap-store.is-open {
  transform: translateX(0);
  transition: transform 0.3s linear;
}
.wrap-store .btn-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: block !important;
}
.wrap-store .btn-back {
  position: absolute;
  top: 2.3rem;
  left: 2.9rem;
  color: #fff;
  height: 3rem;
  max-width: 3rem;
  transition: 0.2s all ease-in-out;
  overflow: hidden;
}
.wrap-store .btn-back .icon,
.wrap-store .btn-back .icon2 {
  position: relative;
  width: 3rem;
  height: 3rem;
  background-color: #c70087;
  color: #fff;
  border-radius: 50%;
}
.wrap-store .btn-back .icon {
  z-index: 4;
}
.wrap-store .btn-back .icon::before {
  content: "";
  position: absolute;
  min-width: 0.6rem;
  height: 0.6rem;
  top: 50%;
  left: 50%;
  border: 0.2rem solid #FFF;
  border-top: 0;
  border-right: 0;
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}
.wrap-store .btn-back .icon2 {
  margin-left: -1.5rem;
  z-index: 1;
}
.wrap-store .btn-back .text {
  width: auto;
  max-width: 0;
  position: relative;
  line-height: 3rem;
  background-color: #c70087;
  transition: 0.1s all ease-in-out;
  margin-left: -1.5rem;
  padding-left: 0;
  z-index: 3;
}
.wrap-store .btn-back .text > div {
  background-color: #c70087;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.wrap-store .btn-back.hover {
  max-width: 9999px;
  transition: 0.2s max-width ease-in-out;
}
.wrap-store .btn-back.hover .text {
  padding-left: 1.5rem;
  max-width: 9999px;
  transition: max-width 0.9s ease-in-out;
}
.wrap-store .btn-back.hover .text > div {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
.wrap-store .btn-back.d-none {
  display: none !important;
}
.wrap-store .box-content .title {
  margin-bottom: 2.5rem;
  font-size: 2.3rem;
}
.wrap-store .box-content .title .name {
  font-size: inherit;
  color: inherit;
}
.wrap-store .store-info {
  margin-bottom: 1rem;
}
.wrap-store .store-info .text.generic {
  font-size: 1.3rem;
  line-height: 2.1rem;
}
.wrap-store .store-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 1.5rem;
  margin-bottom: 2.8rem;
}
.wrap-store .store-buttons .wrap-btnDefault {
  min-width: 11rem;
}
.wrap-store .store-buttons .wrap-btnDefault .btnDefault {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  padding: 0.4rem;
  text-transform: unset;
  line-height: 2.1rem;
  letter-spacing: normal;
  justify-content: center;
}
.wrap-store .store-map {
  margin-bottom: 2.5rem;
}
.wrap-store .store-map .map-container {
  width: 100%;
  aspect-ratio: 2;
  height: 20rem;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.wrap-store .store-map #mapMenu {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
}
.wrap-store .store-schedule .title {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  line-height: 2.1rem;
  margin-bottom: 0.2rem;
}
.wrap-store .store-schedule .store-schedule-content {
  font-size: 1.3rem;
  line-height: 2.1rem;
}
.wrap-store .store-schedule .saturday .saturday-title,
.wrap-store .store-schedule .saturday .mondayFriday-title,
.wrap-store .store-schedule .mondayFriday .saturday-title,
.wrap-store .store-schedule .mondayFriday .mondayFriday-title {
  width: 40%;
  margin-right: 3rem;
}
.wrap-store .store-schedule .saturday .saturday-time,
.wrap-store .store-schedule .saturday .mondayFriday-time,
.wrap-store .store-schedule .mondayFriday .saturday-time,
.wrap-store .store-schedule .mondayFriday .mondayFriday-time {
  width: calc(60% - 3rem);
}
.wrap-store .wrap-buttons {
  padding-top: 3rem;
  border-top: 1px solid rgba(199, 0, 135, 0.5);
  margin-top: 3rem;
}
.wrap-store .wrap-buttons .wrap-btnDefault:first-of-type {
  margin-bottom: 2.1rem;
}
.wrap-store .wrap-buttons .wrap-btnDefault .btnDefault {
  padding: 0.9rem;
  letter-spacing: 0.2rem;
}
.wrap-store .wrap-buttons .wrap-btnDefault.js-seeListStore {
  margin-bottom: 2.4rem;
}
.wrap-store .autocomplete {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  top: 100%;
  background-color: #fff;
  visibility: hidden;
  z-index: -1;
}
.wrap-store .autocomplete.isActive {
  visibility: visible;
  z-index: 2;
  border: 1px solid;
  border-top: none;
}
.wrap-store .autocomplete .autocompletecontent .optionAuto {
  padding: 1rem;
}
.wrap-store .autocomplete .autocompletecontent .optionAuto:focus, .wrap-store .autocomplete .autocompletecontent .optionAuto:focus-visible {
  background-color: #fff4fb;
}
.wrap-store.stepSeeListStore {
  padding-bottom: 0;
}
.wrap-store.stepSeeListStore .wrap-store-content {
  width: calc(100% + 3.5rem);
}
.wrap-store .part-result {
  margin-right: 3rem;
}
.wrap-store .part-result .resNoNear.err {
  color: #ff0000;
  font-family: "Montserrat-SemiBold";
}
.wrap-store .part-result .stores-near {
  padding-top: 0.3rem;
}
.wrap-store .part-result .store-near {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  padding: 1.6rem 0.9rem;
  border-bottom: 1px solid rgba(199, 0, 135, 0.7);
  color: #333;
  position: relative;
  background-color: #FFF;
  transition: background-color 0.2s ease-in-out;
  position: relative;
}
.wrap-store .part-result .store-near .wrap-img {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  width: 21rem;
  bottom: unset;
  top: calc(100% - 2rem);
  right: 4rem;
  padding: 11px 12px 11px 13px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 #c70087;
  aspect-ratio: 2;
}
.wrap-store .part-result .store-near .wrap-img .img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.wrap-store .part-result .store-near .wrap-img.isVisible {
  z-index: 2;
  visibility: visible;
  opacity: 1;
}
.wrap-store .part-result .store-near .store-near-name,
.wrap-store .part-result .store-near .store-near-address {
  padding-right: 4rem;
}
.wrap-store .part-result .store-near .store-near-address {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: #000;
}
.wrap-store .part-result .store-near .store-link:before {
  content: "";
  background-image: url("/images/fl-arrow-rose.svg");
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: 0;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0.9rem;
  top: 1.6rem;
  left: unset;
}
.wrap-store .part-result .store-near:hover {
  background-color: #fff4fb;
  transition: background-color 0.2s ease-in-out;
}
.wrap-store .part-result .store-near.isNotVisible {
  display: none;
}

@media only screen and (max-width: 991px) {
  /*body {
      min-height: 100vh;
      min-height: fill-available;
      min-height: -webkit-fill-available;
  }
  html {
      height: fill-available;
      height: -webkit-fill-available;
  }*/
  .wrap-store {
    width: 100%;
    padding: 1.5rem;
    padding-top: 5.6rem;
    top: -1.5rem;
    overflow: scroll;
  }
  .wrap-store .store-buttons {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  }
  .wrap-store.stepSeeListStore {
    padding-bottom: 0;
    height: 100vh;
  }
  .wrap-store.stepSeeListStore .wrap-store-content {
    width: 100%;
  }
  .wrap-store.stepSeeListStore .wrap-store-content::-webkit-scrollbar-track {
    margin-bottom: 5rem;
  }
  .wrap-store .part-result {
    margin-right: 1rem;
  }
  .wrap-store .btn-close {
    width: 4rem;
    height: 4rem;
    top: 0;
    right: 0;
  }
  .wrap-store .btn-close .icon:before {
    width: 1.8rem;
    height: 1.8rem;
    bottom: 0;
    top: unset;
  }
  .wrap-store .btn-back {
    left: 1.5rem;
    height: 2.4rem;
    max-width: 2.4rem;
  }
  .wrap-store .btn-back .icon, .wrap-store .btn-back .icon2 {
    height: 2.4rem;
    width: 2.4rem;
  }
  .wrap-store .btn-back .icon2 {
    margin-left: -1.2rem;
    z-index: 1;
  }
  .wrap-store .btn-back .text {
    line-height: 2.4rem;
    margin-left: -1.2rem;
  }
  .wrap-store .btn-back .icon::before {
    left: 55%;
    border: 0.1rem solid #FFF;
    border-top: 0;
    border-right: 0;
  }
  .wrap-sity-selectes {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    padding-top: 2rem;
  }
  .wrap-sity-selectes .wrap-sity-selectes-content {
    background-color: #c70087;
    color: #FFF;
    border-bottom: 1px solid #FFF;
    border-top: 1px solid #FFF;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .wrap-sity-selectes .wrap-sity-selectes-content:hover .wrap-sity-selectes-name {
    color: #c70087;
  }
  .wrap-sity-selectes .wrap-sity-selectes-name {
    color: #FFF;
    font-size: 1.3rem;
  }
  .wrap-buttons .wrap-btnDefault .btnDefault {
    font-size: 1.5rem;
  }
  .part-result .store-near .wrap-img.isVisible {
    z-index: -1 !important;
    visibility: hidden !important;
    opacity: 0 !important;
  }
}
@media only screen and (max-width: 375px) {
  .wrap-store .wrap-buttons .wrap-btnDefault {
    min-width: unset;
  }
  .wrap-store .store-buttons {
    grid-gap: 1rem;
  }
}
.footer_page.footer {
  margin-top: 0;
  background-color: #FFF;
  color: inherit;
  padding: inherit;
}
.footer_page.footer button {
  height: auto;
  padding-left: 0;
}
.footer_page.footer ul li a {
  line-height: inherit;
  color: inherit;
  transition: unset;
}
.footer_page.footer .wrap-container {
  padding-bottom: 2.8rem;
}
.footer_page.footer .name-item {
  text-transform: uppercase;
  margin-bottom: 1.4rem;
}
.footer_page.footer .has-children,
.footer_page.footer .not-child,
.footer_page.footer .name-item {
  color: #262626;
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  margin-bottom: 1.1rem;
}
.footer_page.footer .has-children .li-item:not(:last-of-type),
.footer_page.footer .not-child .li-item:not(:last-of-type),
.footer_page.footer .name-item .li-item:not(:last-of-type) {
  margin-bottom: 1.1rem;
}
.footer_page.footer .menu-legal {
  margin-top: 4.1rem;
}
.footer_page.footer .menu-legal .page {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
}
.footer_page.footer .menu-legal .page:not(:last-of-type) {
  margin-bottom: 1.1rem;
}
.footer_page.footer .menu-legal .page:hover a {
  color: #c70087;
  transition: 0.2s color ease-in-out;
}
.footer_page.footer .part-up {
  padding: 0 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(10rem, auto);
  grid-gap: 1rem;
}
.footer_page.footer .part-up > .md-visible {
  display: flex !important;
}
.footer_page.footer .part-up .column-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer_page.footer .part-up .column-1 .logo img {
  max-height: 4.2rem;
}
.footer_page.footer .part-up .li-item:hover a {
  color: #c70087;
  transition: 0.2s color ease-in-out;
}
.footer_page.footer .part-up .li-item:hover::before {
  transition: 0.2s color ease-in-out;
  background-color: #c70087 !important;
}
.footer_page.footer .part-up .column-4.md-visible {
  display: block !important;
}
.footer_page.footer .part-up .column-3 .has-children,
.footer_page.footer .part-up .column-3 .not-child,
.footer_page.footer .part-up .column-4 .has-children,
.footer_page.footer .part-up .column-4 .not-child {
  font-family: "Montserrat-Medium";
  font-size: 1.3rem;
  border: none;
  padding: 0;
}
.footer_page.footer .part-up .column-3 .not-child,
.footer_page.footer .part-up .column-4 .not-child {
  margin-bottom: 0;
}
.footer_page.footer .part-up .column-3 .li-item,
.footer_page.footer .part-up .column-4 .li-item {
  position: relative;
  margin-bottom: 0;
  padding: 1.1rem 0;
  padding-left: 0.7rem;
}
.footer_page.footer .part-up .column-3 .li-item::before,
.footer_page.footer .part-up .column-4 .li-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2.4rem;
  width: 0.5rem;
  height: 1px;
  background-color: #262626;
}
.footer_page.footer .part-up .column-3.md-visible {
  display: block !important;
}
.footer_page.footer .part-down {
  padding: 0 5rem;
  margin-top: 3.6rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.footer_page.footer .part-down .column-1 {
  grid-column: 1/2;
}
.footer_page.footer .part-down .column-2 {
  grid-column: 2/5;
  width: calc(100% + 5rem);
  background-color: #fff4fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9px 5rem 9px 1.7rem;
}
.footer_page.footer .part-down .redes {
  flex-wrap: wrap;
  align-items: center;
}
.footer_page.footer .part-down .red-item {
  position: relative;
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
}
.footer_page.footer .part-down .red-item:not(:last-of-type) {
  padding-right: 3.5rem;
}
.footer_page.footer .part-down .red-item:not(:last-of-type)::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #c70087;
  right: 1.75rem;
  top: 50%;
  transform: translate(50%, -50%);
}
.footer_page.footer .part-down .item-newsletter {
  margin-left: 7.4rem;
  font-family: "Montserrat-Bold";
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s color ease-in-out;
  color: #262626;
  background-color: transparent;
  cursor: pointer;
  width: auto;
  margin-top: 0;
}
.footer_page.footer .part-down .item-newsletter:hover {
  color: #c70087;
  transition: 0.2s color ease-in-out;
}
.footer_page.footer #languages {
  z-index: 1;
  position: relative;
}

@media (max-width: 991px) {
  .footer_page.footer {
    position: relative;
    overflow: hidden;
  }
  .footer_page.footer .wrap-container {
    padding: 0;
    padding-top: 4.5rem;
    padding-bottom: 3.4rem;
  }
  .footer_page.footer .name-item {
    text-transform: uppercase;
    margin-bottom: 1.4rem;
  }
  .footer_page.footer .part-up .column-4.md-visible,
  .footer_page.footer .part-up .md-visible {
    display: none !important;
  }
  .footer_page.footer .has-children,
  .footer_page.footer .not-child,
  .footer_page.footer .name-item {
    color: #262626;
    font-family: "Montserrat-Medium";
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  .footer_page.footer .has-children .li-item:not(:last-of-type),
  .footer_page.footer .not-child .li-item:not(:last-of-type),
  .footer_page.footer .name-item .li-item:not(:last-of-type) {
    margin-bottom: 1.1rem;
  }
  .footer_page.footer .menu-legal {
    margin-top: 0;
  }
  .footer_page.footer .menu-legal .page {
    font-family: "Montserrat-Regular";
    font-size: 1.5rem;
  }
  .footer_page.footer .menu-legal .page:not(:last-of-type) {
    margin-bottom: 1.1rem;
  }
  .footer_page.footer .part-up {
    padding: 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
    grid-auto-rows: minmax(6rem, auto);
  }
  .footer_page.footer .part-up .column-2 .has-children,
  .footer_page.footer .part-up .column-2 .not-child,
  .footer_page.footer .part-up .column-3 .has-children,
  .footer_page.footer .part-up .column-3 .not-child,
  .footer_page.footer .part-up .column-4 .has-children,
  .footer_page.footer .part-up .column-4 .not-child {
    font-family: "Montserrat-Medium";
    font-size: 1.5rem;
    line-height: 2.1rem;
    margin-bottom: 0;
  }
  .footer_page.footer .part-up .column-2 .li-item,
  .footer_page.footer .part-up .column-3 .li-item,
  .footer_page.footer .part-up .column-4 .li-item {
    position: relative;
    margin-bottom: 0;
    padding: 0.7rem 0;
    padding-left: 0;
    text-transform: lowercase;
  }
  .footer_page.footer .part-up .column-2 .li-item::first-letter,
  .footer_page.footer .part-up .column-3 .li-item::first-letter,
  .footer_page.footer .part-up .column-4 .li-item::first-letter {
    text-transform: uppercase;
  }
  .footer_page.footer .part-up .column-2 .li-item::before,
  .footer_page.footer .part-up .column-3 .li-item::before,
  .footer_page.footer .part-up .column-4 .li-item::before {
    content: none;
    position: absolute;
    left: 0;
    top: unset;
    bottom: 1rem;
    width: 0.5rem;
    height: 1px;
    background-color: #262626;
  }
  .footer_page.footer .part-up .column-2 .logo img {
    max-height: 4.2rem;
  }
  .footer_page.footer .part-up .column-3.md-hidden {
    flex-grow: 1;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
  }
  .footer_page.footer .part-up .column-3.md-hidden .menu-legal {
    font-size: 1.5rem;
  }
  .footer_page.footer .part-up .column-3.md-visible {
    display: none !important;
  }
  .footer_page.footer .part-up .column-5-mvl {
    display: flex !important;
    flex-direction: column;
    height: 100%;
  }
  .footer_page.footer .part-up .column-5-mvl .not-child {
    margin: 0;
    padding: 0;
  }
  .footer_page.footer .part-up .column-6 {
    font-family: "Montserrat-Bold";
    font-size: 1.5rem;
  }
  .footer_page.footer .part-up .item-newsletter {
    font-family: "Montserrat-Bold";
    font-size: 1.5rem;
    color: #262626;
  }
  .footer_page.footer .part-down {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer_page.footer .part-down #languages {
    margin: 0;
  }
  .footer_page.footer .part-down .section-lang {
    order: 2;
    margin: 0 auto;
    margin-top: 2.8rem;
    margin-bottom: 2.4rem;
  }
  .footer_page.footer .part-down .column-2 {
    order: 1;
    grid-column: 1/5;
    width: 100%;
    padding: 2.7rem 4.1rem;
    padding-bottom: 1.7rem;
  }
  .footer_page.footer .part-down .column-1 {
    order: 3;
    grid-column: 1/5;
    border-top: 1px solid #c70087;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    padding: 1.5rem;
    text-align: center;
  }
  .footer_page.footer .part-down .wrap-redes {
    width: 100%;
    background-color: #fff4fb;
  }
  .footer_page.footer .part-down .wrap-redes .redes {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: fit-content;
    max-width: 28rem;
  }
  .footer_page.footer .part-down .wrap-redes .circl {
    position: relative;
    width: 14%;
  }
  .footer_page.footer .part-down .wrap-redes .circl::after {
    content: "";
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #c70087;
    top: calc(50% - 0.5rem);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .footer_page.footer .part-down .wrap-redes .red-item {
    position: relative;
    font-family: "Montserrat-Medium";
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-right: 0;
    text-align: center;
  }
  .footer_page.footer .part-down .wrap-redes .red-item:nth-child(3n) {
    margin-right: 0;
  }
  .footer_page.footer .part-down .wrap-redes .red-item:after {
    content: none;
  }
  .footer_page.footer .part-down .item-newsletter {
    margin-left: 7.4rem;
    font-family: "Montserrat-Bold";
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (max-width: 480px) {
  .footer_page.footer .part-down .column-2 {
    padding: 2.7rem 3rem;
    padding-bottom: 1.7rem;
  }
}
.container.home-logos {
  background-color: #fff;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  flex-grow: unset;
  max-width: unset;
  height: auto;
}
.container.home-logos .wrap-logos-list {
  width: 100%;
  height: 5.8rem;
  border-top: 1px solid #c70087;
  border-bottom: 1px solid #c70087;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #fff4fb;
}
.container.home-logos .wrap-logos-list .logos-list {
  animation: banner 70s infinite linear;
  align-items: center;
}
.container.home-logos .wrap-logos-list .logos-list .logo {
  height: 2.3rem;
  margin-left: 4.4rem;
}
.container.home-logos .wrap-logos-list .logos-list .logo .img {
  width: auto;
  height: 100%;
}

@keyframes banner {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (max-width: 991px) {
  .container.home-logos {
    padding-top: 0;
  }
}
#languages {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 1.7rem;
}
#languages .lang-current {
  font-family: Montserrat-Bold;
  border-bottom: 5px solid #c70087;
  padding: 0 4px 1px 4px;
  margin-right: 3.4rem;
}
#languages #select-idioma2 .lang-item {
  opacity: 1;
  transition: 0.2s all;
}
#languages #select-idioma2 .lang-item {
  margin-right: 3.4rem;
}
#languages #select-idioma2:hover .lang-item {
  opacity: 0.6;
  transition: 0.2s all;
}
#languages #select-idioma2:hover .lang-item:hover {
  opacity: 1;
  transition: 0.2s all;
}

@media (max-width: 991px) {
  #languages .lang-current {
    margin-right: 3rem;
  }
  #languages #select-idioma2 .lang-item:not(:last-of-type) {
    margin-right: 3rem;
  }
  #languages #select-idioma2 .lang-item:last-of-type {
    margin-right: 0;
  }
}
#login-shops {
  position: fixed;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.95);
  width: 0;
  height: 0;
  padding: 1.5rem;
  z-index: 10;
  opacity: 0;
  border: 1px solid #1197a5;
  -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
  transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
  font-weight: normal;
  overflow: hidden;
  transform: translateX(-50%) translateY(-50%);
}
#login-shops.google_loggedin {
  height: 23.5rem !important;
}
#login-shops.in {
  top: 0;
  height: 24rem;
  width: 34.8rem;
  -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
  transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out, top 300ms ease-in-out;
  opacity: 1;
  display: block !important;
}
#login-shops .login-title {
  font-size: 1.4rem;
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
  margin-bottom: 2.3rem;
}
#login-shops .form-group {
  margin-bottom: 2rem;
}
#login-shops .form-control {
  border-radius: 0;
  border-color: #575252;
  background-color: transparent;
  display: block;
  width: 100%;
  height: 3.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#login-shops .btns {
  padding: 0 1.5rem 0;
}
#login-shops .btns .wrap-btns {
  width: 100%;
  justify-content: space-between;
}
#login-shops .btns .wrap-btnDefault {
  width: 45%;
  min-width: 45%;
}
#login-shops .btns .wrap-btnDefault .btnDefault {
  padding: 1rem;
  font-size: 1rem;
}
#login-shops .btns .wrap-employeeLogin {
  padding: 0;
  margin-top: 4.5rem;
  width: 100%;
}
#login-shops .btns .wrap-employeeLogin .employee_login {
  padding-right: 1rem;
  font-size: 1.2rem;
}
#login-shops .label {
  background-color: #fff;
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  font-weight: normal;
  color: #575252;
  margin: 0;
  top: calc(50% - 0.1rem);
  transform: translateY(-50%);
}
#login-shops input {
  box-shadow: none;
}
#login-shops input:focus {
  box-shadow: none;
  border: 1px solid #000;
}
#login-shops input:focus + .label, #login-shops input:valid + .label {
  top: 0;
  color: #000;
  transform: translateY(-50%) scale(0.8);
}

.modal-finder {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  color: #324b4e;
  z-index: 100;
  width: 100%;
}
.modal-finder .wrapp {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  padding-top: 15rem;
  height: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
}
.modal-finder .wrapp .non-results .wrap-NoResult {
  position: relative;
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;
  margin-top: 11.8rem;
  font-size: 1.5rem;
  color: #575252;
  padding: 2.4rem 4.9rem;
  background-color: #fff4fb;
}
.modal-finder .wrapp .non-results .wrap-NoResult strong {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
}
.modal-finder .wrapp .aux-wrap {
  position: relative;
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;
  margin-top: 5.5rem;
}
.modal-finder .wrapp .aux-wrap .text-result {
  color: #666;
  font-family: "Montserrat-Medium";
  font-size: 2.1rem;
  line-height: 3.1rem;
  margin-bottom: 1.1rem;
  margin-top: 4.8rem;
}
.modal-finder .wrapp .aux-wrap .text-result .title {
  font-family: "Montserrat-Medium";
  font-size: 2.1rem;
  margin-bottom: 0;
}
.modal-finder .wrapp .aux-wrap .text-result .text-search {
  font-family: "Montserrat-Regular";
  font-size: 2.1rem;
  color: #c70087;
}
.modal-finder .wrapp .title {
  margin-bottom: 4.7rem;
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
  line-height: normal;
  letter-spacing: 0;
  position: relative;
}
.modal-finder .wrapp .title .span1 {
  color: #c70087;
}
.modal-finder .wrapp .form .input-box input {
  margin-left: 0;
  border: 1px solid #575252;
  font-size: 1.4rem;
}
.modal-finder .wrapp .form .input-box input + .label {
  text-transform: none;
  font-size: 1.5rem;
  margin: 0;
  font-family: "Montserrat-Medium";
  font-weight: normal;
  background-color: #fff;
  line-height: 1.5;
}
.modal-finder .wrapp .results {
  padding-bottom: 16rem;
}
.modal-finder .wrapp .results .results-title {
  font-family: "Montserrat-Bold";
  font-size: 1.5rem;
  text-transform: uppercase;
}
.modal-finder .wrapp .results .item {
  font-size: 25px;
  padding: 15px 0;
}
.modal-finder .wrapp .results .item .image,
.modal-finder .wrapp .results .item img.image {
  width: 90px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 1.5rem;
}
.modal-finder .wrapp .results .wrap-category {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  width: calc(100% + 2rem);
  margin: 0 auto;
  margin-top: 8.4rem;
}
.modal-finder .wrapp .results .wrap-category .category {
  width: calc(100% - 2rem);
  height: 4.5rem;
  background-color: #f5f5f5;
  text-transform: uppercase;
  padding-left: 2rem;
}
.modal-finder .wrapp .results .wrap-category .category .title {
  font-family: "Montserrat-Medium";
  font-size: 1.7rem;
  margin-bottom: 0;
  color: #262626;
  display: flex;
  align-items: center;
}
.modal-finder .wrapp .results .wrap-category .category .num {
  font-family: "Montserrat-Medium";
  font-size: 1.7rem;
  color: #262626;
  text-transform: lowercase;
  display: flex;
  align-items: center;
}
.modal-finder .wrapp .results .wrap-category .item {
  display: block;
  width: 25%;
  padding-top: 2.8rem;
  padding-right: 2rem;
}
.modal-finder .wrapp .results .wrap-category .item .wrap-img {
  width: 100%;
  margin-bottom: 1.4rem;
}
.modal-finder .wrapp .results .wrap-category .item .wrap-img .wrap-img-aux {
  width: 100%;
  padding-top: 64%;
  position: relative;
  overflow: hidden;
  background-color: #fff4fb;
}
.modal-finder .wrapp .results .wrap-category .item .wrap-img .wrap-img-aux::before {
  content: "";
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s all ease;
}
.modal-finder .wrapp .results .wrap-category .item .wrap-img .wrap-img-aux .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: 0.3s transform linear;
}
.modal-finder .wrapp .results .wrap-category .item .item-name {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  color: #666;
  line-height: 2.3rem;
  transition: 0.3s all ease;
}
.modal-finder .wrapp .results .wrap-category .item:hover .wrap-img-aux::before {
  content: "";
  position: absolute;
  background-color: rgba(245, 245, 245, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s all ease;
}
.modal-finder .wrapp .results .wrap-category .item:hover .wrap-img .wrap-img-aux .image {
  transform: scale(1.1);
  transition: 0.3s transform linear;
}
.modal-finder .wrapp .results .wrap-category .item:hover .item-name {
  font-family: "Montserrat-Bold";
  transition: 0.3s all ease;
}

@media (max-width: 991px) {
  .modal-finder {
    background-color: #FFF;
    left: 1.5rem;
    top: 7rem;
    right: 1.5rem;
    bottom: 1.5rem;
    padding-right: 0;
    padding-bottom: 1.5rem;
    padding-top: 4rem;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 0.5rem;
    z-index: 104;
    width: calc(100% - 3rem);
  }
  .modal-finder .aux-box {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  .modal-finder .aux-box::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .modal-finder .aux-box::-webkit-scrollbar:vertical {
    width: 5px;
    display: none;
  }
  .modal-finder .aux-box::-webkit-scrollbar-button:increment, .modal-finder .aux-box .contenedor::-webkit-scrollbar-button {
    display: none;
  }
  .modal-finder .aux-box::-webkit-scrollbar:horizontal {
    padding-right: 0.5rem;
    height: 8px;
    display: none;
  }
  .modal-finder .aux-box::-webkit-scrollbar-thumb {
    background-color: #c70087;
    border-radius: 0 !important;
    border: none;
  }
  .modal-finder .aux-box::-webkit-scrollbar-track {
    background-color: rgba(199, 0, 135, 0.5);
    border-radius: 0 !important;
    display: none;
  }
  .modal-finder .wrapp {
    background-color: #FFF;
    padding: 1.8rem;
    padding-top: 0;
  }
  .modal-finder .wrapp .aux-wrap {
    margin-top: 0;
  }
  .modal-finder .wrapp .aux-wrap .wrap-search .title {
    color: #262626;
    font-size: 2.1rem;
    margin-bottom: 2.6rem;
  }
  .modal-finder .wrapp .aux-wrap .wrap-search .form {
    display: block;
  }
  .modal-finder .wrapp .aux-wrap .wrap-search .form .input-box input {
    border: 1px solid #333;
    color: #333;
    height: 4.5rem;
  }
  .modal-finder .wrapp .aux-wrap .wrap-search .form .input-box input + .label {
    color: #333;
    background-color: #FFF;
  }
  .modal-finder .wrapp .aux-wrap .text-result {
    margin-top: 2.9rem;
    margin-bottom: 3.3rem;
  }
  .modal-finder .wrapp .aux-wrap .text-result .title,
  .modal-finder .wrapp .aux-wrap .text-result .text-search {
    font-size: 1.3rem;
    line-height: 2.1rem;
  }
  .modal-finder .wrapp .non-results .wrap-NoResult {
    margin-top: 0;
    padding: 2.8rem 2.6rem;
    text-align: initial;
    font-size: 1.3rem;
    line-height: 1.9rem;
  }
  .modal-finder .wrapp .non-results .wrap-NoResult strong {
    font-weight: normal;
    font-family: "Montserrat-Bold";
  }
  .modal-finder .wrapp .close {
    display: none;
  }
  .modal-finder .wrapp .results {
    padding-bottom: 5rem;
  }
  .modal-finder .wrapp .results .wrap-category {
    margin-top: 1rem;
    width: calc(100% + 1rem);
  }
  .modal-finder .wrapp .results .wrap-category .category {
    width: calc(100% - 1rem);
    height: auto;
    min-height: 3.7rem;
    padding: 1rem;
    flex-wrap: wrap;
  }
  .modal-finder .wrapp .results .wrap-category .category .title, .modal-finder .wrapp .results .wrap-category .category .num {
    line-height: normal;
    font-size: 1.5rem;
  }
  .modal-finder .wrapp .results .wrap-category .item {
    width: 50%;
    padding-right: 1rem;
    padding-bottom: 0;
  }
  .modal-finder .wrapp .results .wrap-category .item img.image {
    margin-right: 0;
  }
  .modal-finder .wrapp .results .wrap-category .item .item-name {
    color: #333;
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  .full-level-black-search {
    position: relative;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .full-level-black-search.isVisible {
    visibility: unset;
    opacity: 1;
    z-index: 1;
    transition: 0.2s opacity ease;
  }
}
.form .input-box {
  position: relative;
}
.form .textarea-box, .form .input-box {
  display: block;
}
.form .textarea-box input + .label, .form .input-box input + .label, .form .textarea-box textarea + .label, .form .input-box textarea + .label {
  font-family: "Montserrat-Medium";
  font-size: 1.3rem;
  position: absolute;
  top: 0;
  left: 1.7rem;
  color: #575252;
  background-color: #f3f6f7;
  padding: 0 1.1rem;
  pointer-events: none;
  transform: translateY(50%);
  transform-origin: left;
  white-space: nowrap;
  transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
}
.form .textarea-box input:valid + .label, .form .input-box input:valid + .label, .form .textarea-box input:focus + .label, .form .input-box input:focus + .label, .form .textarea-box textarea:valid + .label, .form .input-box textarea:valid + .label, .form .textarea-box textarea:focus + .label, .form .input-box textarea:focus + .label {
  transform: translateY(-50%) scale(0.8);
}
.form .textarea-box input:focus, .form .input-box input:focus, .form .textarea-box textarea:focus, .form .input-box textarea:focus {
  border: 1px solid #575252;
}
.form .textarea-box input, .form .input-box input, .form .textarea-box select, .form .input-box select, .form .textarea-box textarea, .form .input-box textarea {
  -webkit-appearance: none;
  display: block;
  position: relative;
  width: 100%;
  height: 4.5rem;
  border-radius: 0 !important;
  border: 1px solid #575252;
  color: #575252;
  font-family: "Montserrat-Medium";
  font-size: 1.4rem;
  outline: 0;
  padding: 0 1rem;
  transition: all 0.2s linear;
  text-decoration: none;
}
.form .textarea-box input.error, .form .input-box input.error, .form .textarea-box select.error, .form .input-box select.error, .form .textarea-box textarea.error, .form .input-box textarea.error {
  border-color: #e3342f !important;
}
.form .textarea-box select, .form .input-box select {
  line-height: normal;
}
.form .textarea-box textarea, .form .input-box textarea {
  padding: 1rem;
  min-width: 100%;
  max-width: 100%;
  min-height: 15rem;
}
.form .privacy {
  position: relative;
}
.form .privacy input[type=checkbox] {
  width: auto;
  visibility: visible;
  appearance: none;
  width: 0px;
  height: 0px;
}
.form .privacy .labelContainer {
  top: 0;
  width: 100%;
  position: absolute;
  left: 0;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
}
.form .privacy .labelContainer .textContainer {
  font-family: "Montserrat-Medium";
  font-size: 1.3rem;
  width: calc(100% - 2.2rem);
  color: #575252;
  font-weight: normal;
  padding-left: 0.8rem;
}
.form .privacy .labelContainer .textContainer .link {
  text-decoration: underline;
  color: #c70087;
  font-family: "Montserrat-Medium";
  font-weight: normal;
}
.form .privacy input + .labelContainer:before {
  content: "";
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 1.3rem;
  height: 1.3rem;
  top: 0;
  border: 0.1rem solid #c70087;
  border-radius: 0 !important;
  background-color: transparent;
  transition: background-color 0.2s linear 0.1s;
}
.form .privacy input.error + .labelContainer:before {
  border: 0.1rem solid #e3342f !important;
}
.form .privacy input + .labelContainer:after {
  content: "";
  position: absolute;
  z-index: 1;
  min-width: 0.8rem;
  height: 0.4rem;
  top: 1.9rem;
  left: 0.2rem;
  border: 0.2rem solid #c70087;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg) scale(0);
  transform-origin: left top;
  transition: all 0.2s ease 0s;
}
.form .privacy input[type=checkbox]:checked + .labelContainer:before {
  background-color: transparent;
  transition: background-color 0.2s linear 0s;
}
.form .privacy input[type=checkbox]:checked + .labelContainer:after {
  transform: translateY(-20%) rotate(-45deg) scale(1);
  transition: all 0.2s ease 0.1s;
}
.form .privacy.error input + .labelContainer:before {
  border: 0.2rem solid #e95950 !important;
}
.form .privacy .err {
  width: 100%;
  font-style: normal;
  color: #e95950;
}
.form .check_cookies {
  position: relative;
}
.form .check_cookies input[type=checkbox] {
  width: auto;
  visibility: hidden;
}
.form .check_cookies .labelContainer {
  top: 0;
  width: 100%;
  left: 0;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  margin-bottom: 0.5rem;
  position: relative;
}
.form .check_cookies .labelContainer .textContainer {
  color: #575252;
  font-size: 1.3rem;
  width: calc(100% - 2.2rem);
  font-family: "Montserrat-Medium";
  font-weight: normal;
}
.form .check_cookies input + .labelContainer:before {
  content: "";
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 1.4rem;
  height: 1.4rem;
  top: 0;
  border: 0.1rem solid #c70087;
  border-radius: 0 !important;
  background-color: transparent;
  transition: background-color 0.2s linear 0.1s;
}
.form .check_cookies input + .labelContainer:after {
  content: "";
  position: absolute;
  z-index: 1;
  min-width: 1rem;
  height: 0.4rem;
  top: 0.8rem;
  left: 0.2rem;
  border: 0.1rem solid #fff;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg) scale(0);
  transform-origin: left top;
  transition: all 0.2s ease 0s;
}
.form .check_cookies input[type=checkbox]:checked + .labelContainer:before {
  background-color: #c70087;
  transition: background-color 0.2s linear 0s;
}
.form .check_cookies input[type=checkbox]:checked + .labelContainer:after {
  transform: translateY(-20%) rotate(-45deg) scale(1);
  transition: all 0.2s ease 0.1s;
}
.form .checkboxRadio-box.squared input {
  display: none;
}
.form .checkboxRadio-box.squared input[type=radio] + .inputContainer:before {
  border-radius: 0;
}
.form .checkboxRadio-box.squared input[type=radio] + .inputContainer:after {
  border-radius: 0;
}
.form .checkboxRadio-box input {
  display: none;
}
.form .checkboxRadio-box input[type=radio] + .inputContainer:before {
  content: "";
  position: absolute;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 100%;
  border: 1px solid #888;
  background-color: #fff;
  transition: border-color 0.2s linear;
}
.form .checkboxRadio-box input[type=radio] + .inputContainer:after {
  content: "";
  position: absolute;
  width: 0rem;
  height: 0rem;
  top: 0.5rem;
  left: 0.8rem;
  border-radius: 100%;
  background-color: #e3342f;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.2s linear;
}
.form .checkboxRadio-box input[type=radio] + .inputContainer:hover:before {
  border-color: #ccc;
}
.form .checkboxRadio-box input[type=radio] + .inputContainer:hover:after {
  background-color: #e9605c;
}
.form .checkboxRadio-box input[type=radio]:checked + .inputContainer:after {
  top: 0.3rem;
  left: 0.3rem;
  width: 1rem;
  height: 1rem;
}
.form .checkboxRadio-box input[type=checkbox] + .inputContainer:before {
  content: "";
  border-radius: 0;
  position: absolute;
  width: 1.4rem;
  height: 1.4rem;
  top: 0.2rem;
  border: 1px solid #888;
  background-color: transparent;
  transition: border-color 0.2s linear;
}
.form .checkboxRadio-box input[type=checkbox] + .inputContainer:after {
  content: "";
  position: absolute;
  width: 0.8rem;
  height: 0.3rem;
  top: 0.6rem;
  left: 0.3rem;
  border: 2px solid #e3342f;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg) scale(0);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.form .checkboxRadio-box input[type=checkbox] + .inputContainer:hover:before {
  border-color: #ccc;
}
.form .checkboxRadio-box input[type=checkbox] + .inputContainer:hover:after {
  border-color: #e9605c;
}
.form .checkboxRadio-box input[type=checkbox]:checked + .inputContainer:after {
  transform: rotate(-45deg) scale(1);
}
.form .checkboxRadio-box input:disabled + .inputContainer {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}
.form .checkboxRadio-box .inputContainer {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.form .checkboxRadio-box .inputContainer .textPlain {
  display: inline-block;
  padding-left: 2.4rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-family: "Montserrat-Bold";
  color: #333;
  transition: all 0.2s linear;
  letter-spacing: 0.9px;
  line-height: 1.2;
}
.form .checkboxRadio-box .inputContainer .textNormal {
  display: inline-block;
  padding-left: 2.4rem;
  font-size: 1.4rem;
  font-family: "Montserrat-Medium";
  color: #333;
  transition: all 0.2s linear;
  line-height: normal;
}
.form .checkboxRadio-box .inputContainer .highlight {
  color: #e3342f;
  font-size: 1rem;
  letter-spacing: 0;
}
.form .checkboxRadio-box .inputContainer .subtextNormal {
  display: block;
  padding-left: 2.4rem;
  font-size: 1rem;
  line-height: 1.2;
  color: #797979;
}
.form .checkboxRadio-box .inputContainer a {
  color: #e3342f;
  transition: all 0.2s linear;
}
.form .checkboxRadio-box .inputContainer a:hover {
  color: #e9605c;
}
.form .checkboxRadio-box.isHidden {
  display: none;
  transition: all 0.2s ease;
}
.form .checkboxRadio-box input {
  display: none;
}
.form .checkboxRadio-box .inputContainer {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.form .checkboxRadio-box .inputContainer .textSmall {
  display: inline-block;
  padding-left: 2.4rem;
  font-size: 1rem;
  font-family: "Montserrat-Medium";
  color: #fff;
  transition: all 0.2s linear;
  line-height: normal;
}
.form .checkboxRadio-box .inputContainer .textSmall a {
  line-height: 2.8;
  color: #fff;
}
.form .checkboxRadio-box input[type=checkbox] + .inputContainer:before {
  content: "";
  border-radius: 0;
  position: absolute;
  width: 1.4rem;
  height: 1.4rem;
  top: 0.7rem;
  background-color: #FFF;
  transition: border-color 0.2s linear;
}
.form .checkboxRadio-box input[type=checkbox] + .inputContainer:after {
  content: "";
  position: absolute;
  width: 0.8rem;
  height: 0.3rem;
  top: 1.1rem;
  left: 0.3rem;
  border: 2px solid #e2231b;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg) scale(0);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.form .checkboxRadio-box input[type=checkbox]:checked + .inputContainer:after {
  transform: rotate(-45deg) scale(1);
}
.form .err {
  color: #e3342f;
  font-size: 14px;
  margin: 0;
  position: relative;
  font-family: "Montserrat-Medium";
  padding-top: 0.5rem;
}

@media (max-width: 991px) {
  .form .privacy {
    margin-bottom: 2rem;
  }
  .form .privacy .labelContainer .textContainer {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  .form .privacy input + .labelContainer:before {
    top: 0.2rem;
  }
  .form .privacy input + .labelContainer:after {
    top: 1.9rem;
  }
  .form .input-box input, .form .textarea-box input, .form .input-box select, .form .textarea-box select, .form .input-box textarea, .form .textarea-box textarea {
    height: 3.5rem;
  }
  .form .input-box input + .label, .form .textarea-box input + .label, .form .input-box select + .label, .form .textarea-box select + .label, .form .input-box textarea + .label, .form .textarea-box textarea + .label {
    font-size: 1.5rem;
  }
}
.form.form-newsletter {
  position: fixed;
  display: flex;
  bottom: 9rem;
  left: 7.5rem;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  width: 70%;
  height: auto;
  min-width: 15rem;
  max-width: 81rem;
  box-shadow: 1px 1px 20px 1px rgba(51, 51, 51, 0.3);
}
.form.form-newsletter .err {
  display: none;
}
.form.form-newsletter.isOpen {
  visibility: visible;
  z-index: 103;
  opacity: 1;
}
.form.form-newsletter .box-img {
  width: 35%;
  background-image: url("/img/img_11.png");
  min-height: 22.5rem;
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.form.form-newsletter .box-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(199, 0, 135, 0.1);
}
.form.form-newsletter .box-content {
  width: 65%;
  background-color: #c70087;
  display: flex;
  padding: 2.8rem 1.8rem 2.8rem 3.5rem;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  flex-wrap: wrap;
}
.form.form-newsletter .box-content .title {
  font-family: "Montserrat-SemiBold";
  font-size: 2.5rem;
  color: #FFF;
  position: relative;
  align-self: flex-start;
  margin: 0 auto;
  margin-left: 0;
  padding-right: 3rem;
  font-size: 2.1rem;
  width: 100%;
}
.form.form-newsletter .box-content .title.underline::before {
  content: "";
  position: absolute;
  width: 2rem;
  height: 3px;
  background-color: #FFF;
  bottom: -6px;
  left: 0;
}
.form.form-newsletter .box-content .wrap-input {
  width: 100%;
  margin-top: 1.5rem;
}
.form.form-newsletter .box-content .input-box {
  width: 100%;
  height: fit-content;
  margin-right: 0.7rem;
}
.form.form-newsletter .box-content .input-box input {
  border: 1px solid #fff;
  color: #FFF;
}
.form.form-newsletter .box-content .input-box input:focus {
  border: 1px solid #fff;
}
.form.form-newsletter .box-content .input-box input + .label {
  color: #FFF;
  left: 1rem;
  font-size: 1.9rem;
  background-color: #c70087;
}
.form.form-newsletter .box-content .input-box.error .err {
  display: block;
  background-color: #FFF;
  color: #ff0000;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.form.form-newsletter .box-content .wrap-btnDefault {
  border-color: #FFF;
}
.form.form-newsletter .box-content .wrap-btnDefault .btnDefault {
  background-color: #FFF;
  color: #c70087;
}
.form.form-newsletter .box-content .well {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #FFF;
}
.form.form-newsletter .close {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 1.8rem;
  right: 1.8rem;
  background-image: url("/images/fl-close-newsletter.svg");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  transform: scale(1);
  transition: 0.2s all ease;
}
.form.form-newsletter .close:hover {
  transform: scale(1.1);
  transition: 0.2s all ease;
}
.form.form-newsletter .privacy.policy {
  width: 100%;
  margin-bottom: 0;
}
.form.form-newsletter .privacy.policy .labelContainer {
  padding-top: 1.5rem;
  position: relative;
}
.form.form-newsletter .privacy.policy .labelContainer .textContainer {
  padding-left: 1.3rem;
  color: #FFF;
}
.form.form-newsletter .privacy.policy .labelContainer .textContainer .link {
  color: #FFF;
}
.form.form-newsletter .privacy.policy .labelContainer:before {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #FFF;
}
.form.form-newsletter .privacy.policy .labelContainer:after {
  min-width: 0.9rem;
  height: 0.5rem;
  top: 2.4rem;
  border-color: #FFF;
}
.form.form-newsletter .privacy.policy.error input + .labelContainer:before {
  border: 0.2rem solid #FFF !important;
}
.form.form-newsletter .privacy.policy.error .err {
  display: block;
  background-color: #FFF;
  color: #ff0000;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.form.form-newsletter .wrap-btnDefault {
  top: 0.15rem;
  align-self: flex-start;
}

@media (max-width: 991px) {
  .form.form-newsletter {
    width: calc(100% - 6.4rem);
    max-width: 100%;
    flex-direction: column;
    left: 50%;
    top: 50%;
    bottom: unset;
    transform: translateY(-50%) translateX(-50%);
    max-width: 31rem;
  }
  .form.form-newsletter .box-content .input-box .label {
    font-size: 1.5rem !important;
  }
  .form.form-newsletter .close {
    right: 1.3rem;
    top: 1.3rem;
  }
  .form.form-newsletter .box-img {
    order: 2;
    width: 100%;
  }
  .form.form-newsletter .box-content {
    width: 100%;
    order: 1;
    padding: 2rem;
  }
  .form.form-newsletter .box-content .title {
    font-size: 1.9rem;
    padding-right: 2rem;
  }
  .form.form-newsletter .box-content .wrap-input {
    margin-top: 2.8rem;
  }
  .form.form-newsletter .box-content .wrap-input > .d-flex {
    flex-direction: column;
  }
  .form.form-newsletter .box-content .wrap-btnDefault {
    margin-top: 1.5rem;
    width: 100%;
  }
  .form.form-newsletter .box-content .wrap-btnDefault .btnDefault {
    width: 100%;
    padding: 0.9rem;
  }
  .form.form-newsletter .box-content .well {
    font-size: 1.3rem;
    line-height: 2.1rem;
  }
  .form.form-newsletter .privacy.policy .labelContainer {
    position: relative;
    padding-top: 0;
  }
  .form.form-newsletter .privacy.policy .labelContainer:after {
    top: 1.2rem;
  }
}
.page_404 .main {
  height: 100vh;
}
.page_404 .main .wrap-content {
  margin: 0 auto;
  margin-top: unset;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.page_404 .main .wrap-store form .form-control {
  height: inherit;
}

.page_404 .main .wrap-banner .wrap-content {
  margin-top: 0;
  max-width: unset;
  padding: 1.2rem;
  height: auto;
  transform: none;
}

.page_404 .main .wrap-store .wrap-btnDefault {
  max-width: unset;
  min-width: unset;
}

.page_404 .main .wrap-store .name {
  font-size: inherit;
  color: inherit;
  font-weight: normal;
  font-family: inherit;
}

.page_404 {
  padding: 0;
  max-width: unset;
}

.page_404 .wrap-content {
  max-width: 90rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page_404 h2 {
  font-family: "Montserrat-SemiBold";
  font-size: 3.3rem;
  text-align: center;
  color: #333;
  margin-top: 5rem;
  margin-bottom: 3.4rem;
}

.page_404 .wrap-num {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}
.page_404 .wrap-num .item2 img {
  width: auto;
  height: calc(100% + 3rem);
  object-position: center;
  transform: translateX(-1.5rem) translateY(-1rem);
}

.page_404 .wrap-num img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.page_404 .wrap-btnDefault {
  max-width: 24.5rem;
  min-width: unset;
}

.wrap-btnDefault .btnDefault {
  font-family: "Montserrat-Bold";
  font-size: 1.5rem;
  padding: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.3rem;
}

@media (max-width: 991px) {
  .page_404 .main .wrap-content .wrap-banner .wrap-content {
    padding: 1.2rem;
    height: auto;
  }
  .page_404 .main .wrap-content {
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    padding-top: 23vh;
    margin-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    padding-bottom: 4rem;
    top: unset;
    left: 0;
    bottom: unset;
    transform: none;
  }
  .page_404 h2 {
    font-size: 2.1rem;
    margin-top: 0;
    margin-bottom: 0;
    margin: 0 auto;
  }
  .page_404 .wrap-num {
    align-items: center;
    max-width: 60rem;
    top: 30%;
    position: relative;
    transform: translateY(-50%);
  }
  .page_404 .wrap-num .item {
    width: 24% !important;
  }
  .page_404 .wrap-num .item img {
    height: fit-content;
  }
  .page_404 .wrap-num .item.item2 {
    width: 40% !important;
    margin-right: 4.5% !important;
  }
  .page_404 .wrap-num .item.item2 img {
    width: 100%;
    transform: scale(1.2);
  }
  .page_404 .wrap-num img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .page_404 .wrap-btnDefault {
    max-width: unset;
  }
  .wrap-btnDefault .btnDefault {
    font-size: 1.5rem;
  }
}
.content-cookies {
  bottom: 3.7rem;
  top: unset;
  left: unset;
  right: 5rem;
  transform: unset;
}
.content-cookies .btn {
  font-size: inherit;
}

@media (max-width: 991px) {
  .content-cookies {
    right: 50%;
    transform: translateX(50%);
  }
  .content-cookies #lcc-modal-button-accept,
  .content-cookies #lcc-modal-button-settings {
    padding: 0 0.5rem;
  }
  .layout-default-famaliving .cookies-text-message {
    font-size: 1.4rem !important;
    line-height: 2.1rem;
  }
}
.geo-content {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1000;
}
.geo-content::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.geo-content .wrap-content .content {
  width: 39.5rem;
  height: auto;
  background-color: #FFF;
  position: absolute;
  padding: 3.5rem 3.2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .geo-content .wrap-content .content {
    width: 80% !important;
  }
}
.geo-content .wrap-content .logo {
  width: 100%;
  height: auto;
  padding-bottom: 2.7rem;
  margin-bottom: 3.6rem;
  border-bottom: 1px solid rgba(199, 0, 135, 0.3);
  display: flex;
  justify-content: center;
}
.geo-content .wrap-content .logo img {
  max-width: 18rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.geo-content .wrap-content .subtitle {
  font-family: "Lato-Black";
  font-size: 1.9rem;
  line-height: 1;
  color: #c70087;
  text-align: center;
  margin-bottom: 2rem;
}
.geo-content .wrap-content .country {
  font-family: "Lato-Regular";
  font-size: 1.3rem;
  line-height: 1.9rem;
  text-align: center;
  margin-bottom: 3.6rem;
}
.geo-content .wrap-content .country .data {
  text-transform: uppercase;
  font-family: "Lato-Bold";
}
.geo-content .wrap-content .wrap-link {
  font-family: "Lato-Bold";
  font-size: 1.3rem;
  line-height: 1.9rem;
  text-align: center;
  margin-top: 2.2rem;
}
.geo-content .wrap-content .wrap-link .changeGeo {
  width: fit-content;
  position: relative;
  margin: 0 auto;
}
.geo-content .wrap-content .wrap-link .changeGeo::before {
  content: "";
  width: 1.2rem;
  height: 100%;
  position: absolute;
  background-image: url("/images/arrow-right-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: -2rem;
  transform: scale(1) rotate(180deg) translateX(0);
  transition: 0.3s transform linear;
}
.geo-content .wrap-content .wrap-link .changeGeo:hover::before {
  transform: scale(1.1) rotate(180deg) translateX(0.5rem);
  transition: 0.3s transform linear;
}
.geo-content .wrap-btnDefault {
  min-width: unset;
  width: 100%;
}
.geo-content .wrap-btnDefault .btnDefault {
  width: 100%;
  padding: 0.9rem;
  letter-spacing: 0.2rem;
}

.form-group {
  width: 100%;
  position: relative;
  margin-bottom: 4.4rem;
}
.form-group label {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  position: absolute;
  left: 2.6rem;
  top: 0;
  transform: translateY(-50%);
  background-color: #FFF;
  padding: 1rem;
  padding-left: 2.8rem;
  background-color: #fff;
  color: #575252;
  font-family: Montserrat-Medium;
  font-size: 1.5rem;
  left: 1.7rem;
  margin: 0;
  padding: 0 1.1rem 0 2.8rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  transform-origin: left;
  transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
  white-space: nowrap;
}
.form-group label::before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.5rem;
  height: 100%;
  left: 0.65rem;
  top: 0;
}
.form-group label[for=pais]::before, .form-group label[for=pais-geo]::before {
  background-image: url("/images/fl-shape.svg");
}
.form-group label[for=lang]::before {
  background-image: url("/images/fl-lengua.svg");
}
.form-group label[for=blog-shops], .form-group label[for=list-shops], .form-group label[for=list-shopsContact] {
  padding-left: 1rem;
}
.form-group label[for=blog-shops]::before, .form-group label[for=list-shops]::before, .form-group label[for=list-shopsContact]::before {
  content: none;
}

.fa.heart {
  font-size: 2.1rem;
  font-weight: normal;
}

.badge-blog {
  display: block;
  position: relative;
  width: 100%;
  top: 0;
  border: none;
}
.badge-blog p {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  transform: none;
  justify-content: flex-end;
}

.blog-entry-container .badge-blog .likes {
  display: flex;
  justify-content: flex-end;
  right: unset;
}
.blog-entry-container .badge-blog .likes .likes-count {
  padding-right: 0.5rem;
  font-size: 1.3rem !important;
}

.likes-count, .liked {
  font-size: 1.65rem;
}

.likes a i {
  transition: 0.4s transform cubic-bezier(0, 1.65, 1, -0.6);
  transform: scale(1);
  vertical-align: middle;
}

.likes a:hover i {
  transform: scale(1.4);
}

.like a i {
  transition: 0.4s transform cubic-bezier(0, 1.65, 1, -0.6);
  transform: scale(1);
  vertical-align: middle;
}

.heart--red {
  color: red;
}

.heart--white {
  color: white;
}

.heart--pink {
  color: #c70087;
}

.pasadorPost {
  width: 100%;
  position: relative;
  background-color: transparent;
  margin-right: 0.5rem;
  min-height: unset;
  margin-bottom: 0;
}
.pasadorPost .swiper-wrapper .swiper-slide {
  height: 100%;
  width: fit-content;
}
.pasadorPost .swiper-wrapper .swiper-slide .img-responsive {
  position: relative;
  top: 0;
  left: 0;
  object-fit: contain;
  max-height: 26.5rem;
  cursor: pointer;
}

.aux-slider {
  position: relative;
}
.aux-slider .control {
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: transparent;
  border: none;
  font-size: unset;
  opacity: 0.75;
  transition: 0.2s all ease-in;
  z-index: 1;
}
.aux-slider .control:hover {
  opacity: 1;
}
.aux-slider .control.swiper-button-disabled {
  transition: 0.2s all ease-in;
  opacity: 0.3;
}
.aux-slider .control.control-prev {
  width: 4rem;
  height: 4rem;
  left: -1.5rem;
}
.aux-slider .control.control-prev:before {
  content: "";
  background-image: url("/images/fl-right.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}
.aux-slider .control.control-next {
  width: 4rem;
  height: 4rem;
  right: -1.5rem;
}
.aux-slider .control.control-next:before {
  content: "";
  background-image: url("/images/fl-right.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}

.mygallerybox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}
.mygallerybox.isOne .swiper-button-prev,
.mygallerybox.isOne .swiper-button-next {
  display: none;
}
.mygallerybox.preopen, .mygallerybox.open {
  z-index: 1040;
}
.mygallerybox.open {
  opacity: 1;
}
.mygallerybox .swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mygallerybox .slide-img {
  max-width: 90%;
  max-height: 90%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
@media (max-width: 991px) {
  .mygallerybox .slide-img {
    max-width: 100%;
    max-height: 100%;
  }
}
.mygallerybox .btn-close {
  position: absolute;
  top: 5rem;
  right: 5rem;
  padding: 0;
  background-color: transparent;
  -webkit-transition: 0.3s ease-in all;
  transition: 0.3s ease-in all;
  z-index: 1040;
  width: 3rem;
  height: 3rem;
  opacity: 0.75;
}
.mygallerybox .btn-close:hover {
  background-color: transparent;
}
.mygallerybox .btn-close::before {
  content: "";
  position: absolute;
  background-image: url("/images/fl-close.svg");
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.mygallerybox .btn-close:hover {
  opacity: 1;
}
.mygallerybox .swiper-button-prev,
.mygallerybox .swiper-button-next {
  width: 4rem;
  height: 4rem;
}
.mygallerybox .swiper-button-prev {
  left: 15px;
}
.mygallerybox .swiper-button-prev:before {
  content: "";
  position: absolute;
  width: 4rem;
  height: 4rem;
  background-image: url("/images/fl-right.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}
.mygallerybox .swiper-button-next {
  right: 15px;
}
.mygallerybox .swiper-button-next:before {
  content: "";
  position: absolute;
  width: 4rem;
  height: 4rem;
  background-image: url("/images/fl-right.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mygallerybox .swiper-button-next,
.mygallerybox .swiper-container-rtl .swiper-button-prev,
.mygallerybox .swiper-button-prev,
.mygallerybox .swiper-container-rtl .swiper-button-next {
  background-image: none;
}

@media (max-width: 991px) {
  .aux-slider {
    padding-right: 0;
    width: calc(100% + 3rem);
    transform: translateX(-1.5rem);
  }
  .aux-slider .pasadorPost {
    height: auto;
  }
  .aux-slider .pasadorPost .swiper-wrapper .swiper-slide {
    padding-right: 0;
  }
  .aux-slider .pasadorPost .swiper-wrapper .swiper-slide:last-of-type .wrap-item {
    margin-right: 1.5rem;
  }
  .aux-slider .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  .aux-slider .swiper-wrapper .swiper-slide .img-responsive {
    height: 100%;
    width: auto;
    object-fit: contain;
    max-height: 20rem;
  }
}
.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
}

.modal-header {
  padding: 20px 20px 15px 20px;
  border-bottom: 1px solid #333;
}
.modal-header > .close {
  margin-top: -4px;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  padding: 20px;
  border-top: 1px solid #333;
}

.modal-title {
  font-weight: 500;
  font-size: 18px;
  color: #333;
}

.videos-section-item {
  width: 100%;
  position: relative;
  margin-bottom: 5rem;
}
.videos-section-item .video-wrapper {
  padding-top: 56%;
  width: 100%;
  position: relative;
}
.videos-section-item .video-wrapper .video-container {
  left: 0;
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
}
.videos-section-item .video-wrapper .video-container img {
  z-index: -1;
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: 0.3s all ease;
}
.videos-section-item .video-wrapper .video-container img.isSee {
  z-index: 1;
  opacity: 1;
  transition: 0.3s all ease;
}
.videos-section-item .video-wrapper .video-container iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.videos-section-item .aux-play {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.videos-section-item .playPause.js-play:not(.isPlayd)::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.videos-section-item .playPause {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 1;
  transition: 0.2s all ease;
}
.videos-section-item .playPause::before {
  position: absolute;
  content: "";
  width: 6.8rem;
  height: 6.8rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.9;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(1);
  transition: 0.4s transform ease-in;
  z-index: 2;
}
.videos-section-item .playPause:hover::before {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%) scale(1.2);
  transition: 0.4s transform ease-in;
}
.videos-section-item .playPause.play {
  display: flex;
}
.videos-section-item .playPause.play::before {
  background-image: url("/images/btn-play.svg");
}
.videos-section-item .playPause.play.isPlayd {
  display: none;
}
.videos-section-item .playPause.pause {
  display: flex;
}
.videos-section-item .playPause.pause::before {
  background-image: url("/images/btn-pause.svg");
}
.videos-section-item .playPause.pause.isStoped {
  display: none;
}
.videos-section-item .playPause.mouseLeave {
  opacity: 0;
  transition: 0.2s all ease;
}

.wrap-banner {
  min-height: 4rem;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 5rem;
  margin-top: 10.7rem;
  z-index: 100;
  color: #FFF;
  text-align: center;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
}
.wrap-banner .wrap-content {
  height: 100%;
  padding: 1.5rem;
  background-color: #7c1f5e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap-banner b, .wrap-banner a {
  font-family: "Montserrat-Bold";
  font-weight: normal;
}
.wrap-banner a {
  text-decoration: underline;
}
.wrap-banner * {
  color: #FFF !important;
}
.wrap-banner p {
  margin-bottom: 0;
  font-weight: normal;
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
}
@media (max-width: 991px) {
  .wrap-banner {
    font-family: "Montserrat-Regular";
    font-size: 1.3rem;
    margin-top: 7.5rem;
    padding: 0 1.5rem !important;
  }
  .wrap-banner.isMobile {
    padding: 0 !important;
  }
  .wrap-banner .wrap-content {
    padding: 1.2rem;
  }
}

.aux-wrap-seccion-slider-home {
  width: 100%;
  background-color: #c70087;
}
.aux-wrap-seccion-slider-home .wrap-seccion-slider-home {
  width: 100%;
  margin: 0 auto;
}

.seccion-slider-home {
  position: relative;
  padding-top: 100vh;
  width: 100%;
}
.seccion-slider-home .container.slider-home {
  top: 0;
  position: absolute;
  left: 0;
  height: 100%;
}
.seccion-slider-home .container.slider-home .slick-list {
  padding: 0 !important;
}
.seccion-slider-home .container.slider-home .slick-list .slick-slide > div:first-child {
  height: 100%;
}
.seccion-slider-home .container.slider-home .slick-list .slick-slide .item-slide {
  height: 100%;
}

.seccion-slider-home .scrollbar-home {
  position: absolute;
  bottom: 3.9rem;
}

@media screen and (orientation: portrait) and (max-width: 991px) {
  .container.slider-home.hasvideo {
    height: auto;
  }
}
.container.slider-home .listpadding {
  position: absolute;
  bottom: 5vh;
  right: 100px;
  z-index: 1;
}

.slider-home .volume {
  position: absolute;
  top: 50px;
  left: 50px;
  color: #FFF;
  text-shadow: 0 0 3px #333, 0 0 3px transparent, 0 0 3px transparent;
  font-size: 30px;
  z-index: 1;
  cursor: pointer;
}
.slider-home .volume:hover {
  text-shadow: 0 0 3px #333, 0 0 30px #FFF, 0 0 60px #FFF;
}
.slider-home .volume .volume-icon.muted {
  display: none;
}
.slider-home .volume .volume-icon.actived {
  display: block;
}
.slider-home .volume.muted .volume-icon.muted {
  display: block;
}
.slider-home .volume.muted .volume-icon.actived {
  display: none;
}

@media (max-width: 991px) {
  .block-text .item-news-title {
    position: static;
  }
  .container.slider-home {
    padding: 0;
    margin: 0;
    height: 100vh;
    overflow: hidden;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container.slider-home .aux-item .wrap-img {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .container.slider-home .slick-list {
    height: 100%;
  }
  .container.slider-home .slick-list .slick-track {
    height: 100%;
  }
  .container.slider-home .slick-slide {
    position: relative;
    height: 100%;
    overflow: hidden;
    width: 100vw;
  }
  .container.slider-home .slick-slide .aux-text-item.mvl {
    z-index: 2;
    display: flex;
    flex-direction: column;
  }
  .container.slider-home .slick-slide .wrap-color {
    display: none;
  }
  .container.slider-home .slick-slide .section-title {
    font-size: 1.5rem;
    line-height: 2.2rem;
    letter-spacing: 5px;
    color: #fff;
    padding-bottom: 1rem;
    text-align: left;
  }
  .container.slider-home .slick-slide .pasador-title {
    color: #FFF;
    font-weight: normal;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    font-family: "Montserrat-ExtraBold";
    font-size: 6rem;
  }
  .container.slider-home .slick-slide .pasador-text {
    font-size: 6rem;
    width: 67.5%;
    max-width: 25.3rem;
    color: #FFF;
    font-weight: normal;
    font-size: 18px;
    position: absolute;
    bottom: 11.5rem;
    left: 3.4rem;
    top: unset;
    background-color: transparent;
    padding: 0;
  }
  .container.slider-home .slick-slide .slider-image,
  .container.slider-home .slick-slide video {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(80%);
  }
  .container.slider-home .slick-prev,
  .container.slider-home .slick-next {
    background-image: none !important;
    width: auto;
    height: auto;
    z-index: 1;
  }
  .container.slider-home .slick-prev .icon,
  .container.slider-home .slick-next .icon {
    font-size: 40px;
    color: #FFF;
    -webkit-transition: 300ms color ease-in;
    transition: 300ms color ease-in;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
  .container.slider-home .slick-prev:hover,
  .container.slider-home .slick-next:hover {
    color: #c70087;
  }
  .famaliving .container.slider-home .slick-prev:hover,
  .famaliving .container.slider-home .slick-next:hover {
    color: #c70087;
  }
  .container.slider-home .slick-prev {
    left: 5px;
  }
  .container.slider-home .slick-next {
    right: 5px;
  }
}
@media (min-width: 992px) {
  .newimg {
    height: 100% !important;
  }
  .container.home-items {
    padding-top: 0;
  }
  .container.slider-home {
    padding: 0;
    margin: 0;
    height: 100vh;
    overflow: hidden;
    position: relative;
    margin-top: 0;
    max-width: unset;
  }
  .container.slider-home .slick-list {
    height: 100%;
  }
  .container.slider-home .slick-list .slick-track {
    height: 100%;
  }
  .container.slider-home .slick-slide {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .container.slider-home .slick-slide .aux-text-item.mvl {
    display: none;
    z-index: 2;
  }
  .container.slider-home .slick-slide .wrap-color {
    min-width: 21vw;
    width: 21%;
    max-width: 21%;
  }
  .container.slider-home .slick-slide .wrap-color::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: inherit;
  }
  .container.slider-home .slick-slide .section-title {
    letter-spacing: 0.5rem;
    color: #fff;
    font-family: "Montserrat-Regular";
    line-height: 2.2;
    font-size: 1.5rem;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
  }
  .container.slider-home .slick-slide .pasador-title {
    color: #FFF;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    font-family: "Montserrat-ExtraBold";
    font-size: 7.5vw;
  }
  .container.slider-home .slick-slide .aux-text-item {
    top: 40%;
    width: 40%;
    position: absolute;
    margin: 0 auto;
    left: 8%;
    z-index: 3;
  }
}
@media (min-width: 992px) and (min-width: 768px) and (max-width: 1400px) {
  .container.slider-home .slick-slide .aux-text-item {
    padding: 0 5rem;
  }
  .container.slider-home .slick-slide .aux-text-item .pasador-text .pasador-title {
    font-size: 8vw;
  }
}
@media (min-width: 992px) {
  .container.slider-home .slick-slide .pasador-text {
    color: #FFF;
    font-family: "Montserrat-Regular", Arial, sans-serif;
    font-weight: normal;
    position: relative;
    background-color: transparent;
    padding: 0;
    padding-left: 0;
  }
  .container.slider-home .slick-slide .aux-item {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-end;
  }
  .container.slider-home .slick-slide .aux-item .wrap-img {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .container.slider-home .slick-slide .slider-image,
  .container.slider-home .slick-slide video {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(80%);
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot {
    z-index: 10;
    cursor: pointer;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot:hover .hotspot-marker-img + .wrap-hotspot-info {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: 0.5s 0.2s opacity ease;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot:hover .hotspot-marker-img {
    opacity: 0;
    transition: 0.2s opacity ease;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot:hover .hotspot-marker-img.isHover {
    opacity: 1;
    transition: 0.2s opacity ease;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img.isHover {
    opacity: 0;
    transition: 0.2s opacity ease;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img + .wrap-hotspot-info {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s 0.2s opacity ease;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img + .wrap-hotspot-info .hotspot-content {
    display: flex;
    flex-direction: column;
    padding: 1.4rem;
    width: 180px;
    height: auto;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img + .wrap-hotspot-info .hotspot-content .title {
    font-size: 1.5rem;
    letter-spacing: 1.3px;
    color: inherit;
    font-family: "Montserrat-Regular";
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img + .wrap-hotspot-info .hotspot-content .text {
    color: inherit;
    padding-bottom: 2.7rem;
    font-family: "Montserrat-Bold";
    text-transform: uppercase;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img + .wrap-hotspot-info .hotspot-content .link {
    color: inherit;
    width: 100%;
    text-align: end;
    font-size: 1.1rem;
    position: relative;
    padding-right: 2rem;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img + .wrap-hotspot-info .hotspot-content .link::after {
    content: "";
    background-image: url("/images/arrow-white-right.svg");
    background-repeat: no-repeat;
    width: 2rem;
    height: 100%;
    position: absolute;
    right: -0.5rem;
    top: 0.1rem;
  }
  .container.slider-home .slick-slide .hotspot-marker.box-hotspot .hotspot-marker-img + .wrap-hotspot-info .aux-wrap {
    position: relative;
    height: 100%;
    width: 100%;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
  }
  .container.slider-home .swiper-button-prev,
  .container.slider-home .swiper-button-next {
    background-image: none !important;
    width: auto;
    height: auto;
  }
  .container.slider-home .swiper-button-prev .icon,
  .container.slider-home .swiper-button-next .icon {
    font-size: 40px;
    color: #FFF;
    -webkit-transition: 300ms color ease-in;
    transition: 300ms color ease-in;
  }
  .container.slider-home .swiper-button-prev:hover,
  .container.slider-home .swiper-button-next:hover {
    color: #c70087;
  }
  .container.slider-home .slick-arrow {
    z-index: 1;
  }
  .container.slider-home .slick-prev {
    transform: translateX(5rem);
    left: 0;
    display: none;
    width: 4rem;
    height: 4rem;
  }
  .container.slider-home .slick-prev:before {
    content: "";
    background-image: url("/images/fl-left.svg");
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 0;
    left: 0;
  }
  .container.slider-home .slick-next {
    transform: translateX(-5rem);
    right: 0;
    display: none;
    width: 4rem;
    height: 4rem;
  }
  .container.slider-home .slick-next:before {
    content: "";
    background-image: url("/images/fl-right.svg");
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 0;
    left: 0;
  }
  .container.slider-home:hover .slick-prev, .container.slider-home:hover .slick-next {
    display: block;
    opacity: 1;
  }
  .carousel-inner > .item > img, .carousel-inner > .item > a > img {
    height: 100%;
  }
}
@media (max-width: 991px) {
  .container.slider-home {
    padding: 0;
    margin: 0;
    height: 100vh;
    overflow: hidden;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container.slider-home .aux-item .wrap-img {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .container.slider-home .slick-list {
    height: 100%;
  }
  .container.slider-home .slick-list .slick-track {
    height: 100%;
  }
  .container.slider-home .slick-slide {
    position: relative;
    height: 100%;
    overflow: hidden;
    width: 100vw;
  }
  .container.slider-home .slick-slide .aux-text-item.mvl {
    z-index: 2;
    display: flex;
    flex-direction: column;
  }
  .container.slider-home .slick-slide .wrap-color {
    display: none;
  }
  .container.slider-home .slick-slide .section-title {
    font-size: 1.5rem;
    line-height: 2.2rem;
    letter-spacing: 5px;
    color: #fff;
    padding-bottom: 1rem;
    text-align: left;
  }
  .container.slider-home .slick-slide .pasador-title {
    color: #FFF;
    font-weight: normal;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    font-family: "Montserrat-ExtraBold";
    font-size: 6rem;
  }
  .container.slider-home .slick-slide .pasador-text {
    font-size: 6rem;
    width: 67.5%;
    max-width: 25.3rem;
    color: #FFF;
    font-weight: normal;
    font-size: 18px;
    position: absolute;
    bottom: 11.5rem;
    left: 3.4rem;
    top: unset;
    background-color: transparent;
    padding: 0;
  }
  .container.slider-home .slick-slide .slider-image,
  .container.slider-home .slick-slide video {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(80%);
  }
  .container.slider-home .slick-prev,
  .container.slider-home .slick-next {
    background-image: none !important;
    width: auto;
    height: auto;
    z-index: 1;
  }
  .container.slider-home .slick-prev .icon,
  .container.slider-home .slick-next .icon {
    font-size: 40px;
    color: #FFF;
    -webkit-transition: 300ms color ease-in;
    transition: 300ms color ease-in;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
  .container.slider-home .slick-prev:hover,
  .container.slider-home .slick-next:hover {
    color: #c70087;
  }
  .famaliving .container.slider-home .slick-prev:hover,
  .famaliving .container.slider-home .slick-next:hover {
    color: #c70087;
  }
  .container.slider-home .slick-prev {
    left: 5px;
  }
  .container.slider-home .slick-next {
    right: 5px;
  }
}
@media (max-width: 991px) {
  .hotspot-marker-img, .hotspot-marker-img + .wrap-hotspot-info {
    display: none !important;
    visibility: hidden !important;
  }
}
.home-famaliving-items.container {
  max-width: unset;
  padding: 0;
}
.home-famaliving-items .menu-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
}
.home-famaliving-items .menu-list .list-item {
  position: relative;
}
.home-famaliving-items .menu-list .list-item:not(.isSlider) > a:hover .img {
  transform: scale(1.1);
  transition: 0.3s transform;
}
.home-famaliving-items .menu-list .list-item .content-list-item {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.home-famaliving-items .menu-list .list-item .img {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: 0.3s transform;
}
.home-famaliving-items .menu-list .list-item .home-list-text {
  font-family: "Montserrat-Medium";
  font-size: 2.3rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
  left: unset;
  color: #c70087;
  bottom: 2.7rem;
  background-color: #fff4fb;
  padding: 1.2rem 1.8rem 1.2rem 2.9rem;
}
.home-famaliving-items .menu-list .list-item.isSlider .content-list-item {
  padding: 2.7rem;
}
.home-famaliving-items .menu-list .list-item.isSlider .home-list-text {
  color: #262626;
  background-color: #fff;
  right: 2.7rem;
}
.home-famaliving-items .slider-category {
  height: 100%;
}
.home-famaliving-items .slider-category * {
  height: 100%;
}
.home-famaliving-items .slider-category .slick-list {
  padding: 0 !important;
}
@media (max-width: 991px) {
  .home-famaliving-items .menu-list {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
  .home-famaliving-items .menu-list .list-item .home-list-text {
    font-size: 1.3rem;
    padding: 0.5rem 1rem 0.5rem 2rem;
    bottom: 1rem;
  }
  .home-famaliving-items .menu-list .list-item.isSlider {
    order: 1 !important;
    grid-column: 1/3;
  }
  .home-famaliving-items .menu-list .list-item.isSlider .home-list-text {
    font-size: 2rem;
    right: 3.4rem;
    padding: 0.9rem 1.4rem;
    bottom: 2.2rem;
  }
  .home-famaliving-items .menu-list .list-item.isSlider .content-list-item {
    padding: 2.2rem 3.4rem;
  }
}
.new-blog {
  position: relative;
  background-color: #FFF;
}
.new-blog .aux-slider {
  margin-top: 7.9rem;
}
.new-blog .aux-slider .pasadorPost {
  width: calc(100% - 1.5rem) !important;
  margin-right: 1.5rem;
}
.new-blog .aux-slider .pasadorPost.swiper-container-horizontal {
  cursor: grab;
}
.new-blog .aux-slider .control.control-next {
  right: 0rem;
}
.new-blog .aux-slider .swiper-slider {
  width: auto;
}
.new-blog.detail-blog-famaliving2023 .authors-container.postsRelated {
  transform: none;
  top: 16.5rem;
}
.new-blog.detail-blog-famaliving2023 .authors-container.postsRelated .title {
  background-color: #c70087;
}
.new-blog.detail-blog-famaliving2023 .aux-slider.isOne .control.control-prev,
.new-blog.detail-blog-famaliving2023 .aux-slider.isOne .control.control-next {
  display: none !important;
}
.new-blog.page-blog .two-columns-wrap {
  margin-bottom: 9rem;
}
.new-blog .wrap-container {
  padding-top: 0;
  padding-bottom: 0;
}
.new-blog .wrap-container:first-of-type {
  padding-top: 16.4rem;
}
.new-blog .wrap-container .blog-header {
  position: relative;
  padding-left: calc((100% - 1280px) / 2);
  margin-bottom: 4.4rem;
}
.new-blog .wrap-container .blog-header .title {
  max-width: 30rem;
}
.new-blog .wrap-container .part-left {
  min-width: 42%;
  max-width: 576px;
  position: relative;
  padding-right: 7.6rem;
  display: flex;
  flex-direction: column;
}
.new-blog .wrap-container .part-left .wrap-blogs-form {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.new-blog .wrap-container .part-left .wrap-blogs-form .form-group {
  margin-bottom: 0;
}
.new-blog .wrap-container .blog-img {
  width: 100%;
  position: relative;
  min-height: 39rem;
  min-width: 58%;
}
.new-blog .wrap-container .blog-img .img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
}
.new-blog .limited-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
}
.new-blog .header {
  display: flex;
  align-items: center;
}
.new-blog .blog-description {
  margin-bottom: 5rem;
}
.new-blog .two-columns-wrap {
  justify-content: space-between;
}
.new-blog .wrap-lastArticles {
  margin-top: 9rem;
}
.new-blog .wrap-lastArticles .last-3-news {
  width: calc(100% + 2rem);
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 16rem;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new {
  width: 33.3333333333%;
  padding-right: 2rem;
  background-color: #fff4fb;
  padding-bottom: 3rem;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .aux-item {
  border-bottom: 5px solid #c70087;
  width: 100%;
  overflow: hidden;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .wrap-img {
  position: relative;
  padding-top: 64%;
  transform: translateY(0);
  transition: transform 0.5s;
  overflow: hidden;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .wrap-img .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #c70087;
  transform: scale(1);
  transition: 0.5s transform linear;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .header,
.new-blog .wrap-lastArticles .last-3-news .last-3-new .date,
.new-blog .wrap-lastArticles .last-3-news .last-3-new .author,
.new-blog .wrap-lastArticles .last-3-news .last-3-new .title {
  padding: 0 1rem;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .header span {
  font-size: 1.3rem;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .header .heart {
  color: #c70087;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .date {
  font-size: 1.3rem;
  color: #c70087;
  opacity: 1;
  display: block;
  margin-bottom: 2rem;
  font-family: "Montserrat-Italic";
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .title {
  font-size: 1.5rem;
  font-family: "Montserrat-SemiBold";
  color: #262626;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new:hover .wrap-img {
  transition: transform 0.5s;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new:hover .wrap-img .img {
  transform: scale(1.3);
  transition: 0.5s transform linear;
}
.new-blog .wrap-lastArticles .last-3-news {
  max-width: 100rem;
  margin: 0 auto;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new {
  width: calc(33.3333333333% - 1.5rem);
  padding-right: 0;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .header, .new-blog .wrap-lastArticles .last-3-news .last-3-new .date {
  margin-bottom: 0;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .title {
  margin-top: 2rem;
}
.new-blog .wrap-lastArticles .last-3-news .last-3-new .author {
  font-family: "Montserrat-Italic";
  font-size: 1.3rem;
  color: #262626;
  line-height: 2.1rem;
  opacity: 1;
  display: block;
}
.new-blog .wrap-lastArticles .section-title {
  width: 100%;
  margin: 0 auto;
  margin-left: 0;
}
.new-blog .wrap-lastArticles .section-title h2 {
  font-size: 2.1rem;
  color: #c70087;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5.25px;
  font-family: "Montserrat-SemiBold";
}
.new-blog .wrap-lastArticles .section-title .divider {
  width: 3.5rem;
  border-top: 2px solid #c70087;
  margin: 2rem auto 7rem auto;
}
.new-blog .authors-container {
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  position: -webkit-sticky;
  top: 8.1rem;
  transform: translateY(8.4rem);
  width: 36.2rem;
  height: 46.6rem;
  z-index: 14;
  padding: 2rem;
  background-color: #fff4fb;
}
.new-blog .authors-container .blog-authors {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  /* Thin Scrollbar for firefox */
}
.new-blog .authors-container .blog-authors::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.new-blog .authors-container .blog-authors::-webkit-scrollbar:horizontal {
  height: 4px;
}
.new-blog .authors-container .blog-authors::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
.new-blog .authors-container .blog-authors::-webkit-scrollbar-track {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 8px;
}
.new-blog .authors-container .blog-authors::-webkit-scrollbar-track-piece {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 8px;
}
.new-blog .authors-container .blog-authors::-webkit-scrollbar-thumb:vertical {
  border-radius: 8px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
.new-blog .authors-container .blog-authors {
  scrollbar-color: #c70087 rgba(199, 0, 135, 0.32);
  scrollbar-width: thin;
}
.new-blog .authors-container .blog-authors .author-row {
  display: flex;
  align-items: flex-end;
  margin-top: 1.5rem;
}
.new-blog .authors-container .blog-authors .author-row .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new-blog .authors-container .blog-authors .author-row > .a {
  width: 8.4rem;
  height: 7.4rem;
  display: block;
  border: 1px solid #c70087;
  font-size: 1.3rem;
}
.new-blog .authors-container .blog-authors .author-row .author-info {
  margin-left: 1.2rem;
  width: 80%;
}
.new-blog .authors-container .blog-authors .author-row .author-info a {
  font-size: 1.3rem;
  font-family: "Montserrat-Regular";
  font-style: normal;
}
.new-blog .authors-container .blog-authors .author-row .author-info .a {
  font-size: 1.3rem;
}
.new-blog .authors-container .blog-authors .author-row .author-info .a .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new-blog .authors-container .blog-authors .author-row .author-info .name {
  color: #c70087;
  font-style: oblique;
  font-size: 1.3rem;
  font-family: "Montserrat-Regular";
  transition: 0.2s color ease;
  line-height: 2rem;
}
.new-blog .authors-container .blog-authors .author-row .author-info .name:hover {
  color: rgba(199, 0, 135, 0.8);
  transition: 0.2s color ease;
}
.new-blog .authors-container .blog-authors .author-row .author-info .post {
  color: #575252;
  font-style: normal;
  transition: 0.2s color ease;
}
.new-blog .authors-container .blog-authors .author-row .author-info .post:hover {
  color: rgba(87, 82, 82, 0.8);
  transition: 0.2s color ease;
}
.new-blog .authors-container .title {
  height: 5rem;
  width: 100%;
  background-color: #c70087;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.new-blog .authors-container .title h3 {
  font-size: 1.7rem;
  color: white;
  font-weight: 600;
  text-align: center;
}
.new-blog .authors-container.postsRelated {
  height: 44.5rem;
  padding: 1.8rem;
}
.new-blog .authors-container.postsRelated > .title {
  margin-bottom: 2.8rem;
  text-transform: uppercase;
}
.new-blog .authors-container.postsRelated > .title h3 {
  font-weight: normal;
  margin-bottom: 0;
  font-family: "Montserrat-SemiBold";
  line-height: 2.5rem;
}
.new-blog .authors-container.postsRelated .blog-authors {
  height: auto;
  max-height: 33rem;
  overflow-y: auto;
}
.new-blog .authors-container.postsRelated .author-row {
  margin-top: 1.7rem;
}
.new-blog .authors-container.postsRelated .author-row:first-of-type {
  margin-top: 0;
}
.new-blog .authors-container.postsRelated .author-row .pic {
  width: 10.4rem;
  height: 6.5rem;
  display: block;
  border: 1px solid #c70087;
  border: none;
}
.new-blog .authors-container.postsRelated .author-row .pic .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new-blog .authors-container.postsRelated .author-row .author-info {
  padding: 0 1.5rem;
  margin: 0;
  font-size: 1.1rem;
}
.new-blog .authors-container.postsRelated .author-row .author-info .name {
  line-height: 1.9rem;
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  border-bottom: 1px solid rgba(199, 0, 135, 0.5);
}
.new-blog .authors-container.postsRelated .author-row .author-info .titlePost {
  font-size: 1.3rem;
  color: #262626;
  font-family: "Montserrat-SemiBold";
  background-color: transparent;
  padding-bottom: 4px;
  transition: 0.2s color ease;
}
.new-blog .authors-container.postsRelated .author-row .author-info .titlePost:hover {
  color: rgba(38, 38, 38, 0.8);
  transition: 0.2s color ease;
}
.new-blog .blog-entry-container {
  width: calc(100% - 36.2rem);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(200, auto);
  grid-gap: 0;
}
.new-blog .blog-entry-container .blog-entry {
  width: 100%;
  margin-bottom: 4rem;
  padding-right: 4rem;
}
.new-blog .blog-entry-container .blog-entry:nth-child(even) {
  transform: translateY(8.4rem);
}
.new-blog .blog-entry-container .blog-entry .blog-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.new-blog .blog-entry-container .blog-entry .blog-content .img {
  display: block;
  background-color: grey;
}
.new-blog .blog-entry-container .blog-entry .blog-content .block-text {
  width: 100%;
  background-color: #fff4fb;
  padding: 1rem;
  padding-bottom: 2rem;
  position: relative;
  flex-grow: 1;
}
.new-blog .blog-entry-container .blog-entry .blog-content .block-text .block-title {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  color: #262626;
  margin-top: 1rem;
}
.new-blog .blog-entry-container .blog-entry .blog-content .block-text .desc {
  margin-top: 1rem;
}
.new-blog .blog-entry-container .blog-entry .blog-content .block-text p {
  font-size: 1.5rem;
}
.new-blog .blog-entry-container .blog-entry .blog-content .block-text .author {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: #c70087;
  font-style: oblique;
}
.new-blog .blog-entry-container .blog-entry .blog-content .block-text hr {
  width: 100%;
  border-color: rgba(199, 0, 135, 0.5);
  margin-bottom: 0;
  border-width: 0.2rem;
  border: none !important;
}
.new-blog .blog-entry-container .blog-entry .blog-content .info .date-info {
  font-family: "Montserrat-Italic";
  font-size: 1.3rem;
  color: #c70087;
  line-height: 2.1rem;
}
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text p,
.new-blog .blog-entry-container .blog-entry .blog-content .generic,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  color: #333;
  line-height: 2.5rem;
  letter-spacing: 0 !important;
}
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text .author,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text p .author,
.new-blog .blog-entry-container .blog-entry .blog-content .generic .author,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text .author {
  color: #575252;
  font-style: oblique;
}
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text hr,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text p hr,
.new-blog .blog-entry-container .blog-entry .blog-content .generic hr,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text hr {
  display: block;
}
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text table, .new-blog .blog-entry-container .blog-entry .blog-content .generic.text table tr,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text p table,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text p table tr,
.new-blog .blog-entry-container .blog-entry .blog-content .generic table,
.new-blog .blog-entry-container .blog-entry .blog-content .generic table tr,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text table,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text table tr {
  border: none;
  border-bottom: none;
}
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text td,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text p td,
.new-blog .blog-entry-container .blog-entry .blog-content .generic td,
.new-blog .blog-entry-container .blog-entry .blog-content .generic.text td {
  background-image: none;
}
.new-blog .blog-entry-container .blog-entry .aux-item {
  width: 100%;
  border-bottom: 5px solid #c70087;
}
.new-blog .blog-entry-container .blog-entry .aux-img {
  width: 100%;
  position: relative;
}
.new-blog .blog-entry-container .blog-entry .aux-img .wrap-img {
  position: relative;
  width: 100%;
  padding-top: 63%;
  overflow: hidden;
  margin: 0 auto;
}
.new-blog .blog-entry-container .blog-entry .aux-img .wrap-img:hover img {
  width: 100%;
  transform: scale(1.3);
}
.new-blog .blog-entry-container .blog-entry .aux-img .wrap-img .img {
  transition: 0.5s all ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  box-shadow: none;
}

.spacer {
  width: 100%;
  margin: auto;
  opacity: 0.4;
  margin-bottom: 6rem;
  border-top: 1px solid #c70087;
}

.new-blog .wrap-content-post {
  width: calc(100% - 36.2rem);
  margin-right: 0;
  padding-right: 2.8rem;
}
.new-blog .wrap-content-post .wrap-blog-content {
  display: flex;
  flex-direction: column;
}
.new-blog .wrap-content-post .wrap-share {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  padding-right: 1.5rem;
  margin-top: 1.7rem;
}
.new-blog .wrap-content-post .wrap-share .share {
  padding: 0;
}
.new-blog .wrap-content-post .wrap-share .share .icon {
  padding-left: 1.7rem;
}
.new-blog .wrap-content-post .wrap-share .share .icon i {
  font-size: 2.2rem;
}
.new-blog .wrap-content-post .wrap-share .share a {
  transition: 0.2s color ease;
}
.new-blog .wrap-content-post .wrap-share .share a:hover i {
  color: #c70087 !important;
  transition: 0.2s color ease;
}
.new-blog .wrap-content-post .wrap-author {
  padding-bottom: 0.8rem;
  display: flex;
  border-bottom: 0.3px solid rgba(199, 0, 135, 0.5);
  margin-bottom: 4.5rem;
  margin-right: 1.5rem;
  margin-top: 2.6rem;
}
.new-blog .wrap-content-post .wrap-author .wrap-aux {
  padding-top: 0.8rem;
  border-top: 0.3px solid rgba(199, 0, 135, 0.5);
  padding-right: 5rem;
  width: 100%;
}
.new-blog .wrap-content-post .wrap-author .wrap-aux > a {
  width: 7rem;
  height: 7rem;
  position: relative;
  border-radius: 50%;
  margin-right: 1.3rem;
}
.new-blog .wrap-content-post .wrap-author .pic {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin-right: 1.3rem;
  border-radius: 50%;
  position: absolute;
}
.new-blog .wrap-content-post .wrap-author .author-info {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: #333;
  font-weight: normal;
  align-self: center;
}
.new-blog .wrap-content-post .wrap-author .author-info .name {
  font-family: "Montserrat-Italic";
  font-size: 1.3rem;
  color: #c70087;
  line-height: 2.1rem;
  font-weight: normal;
  margin-bottom: 0;
}
.new-blog .wrap-content-post.noPostRelated {
  width: 100%;
  margin-right: 0;
}
.new-blog .wrap-content-post.noPostRelated .new-text {
  padding-right: 0;
}
.new-blog .content.detail_new.comments .comment {
  background-color: transparent;
  height: auto !important;
  padding: 0;
  padding-bottom: 3rem;
  border-bottom: solid 0.3px rgba(151, 151, 151, 0.5);
  border-radius: 0;
}
.new-blog .content.detail_new.comments .comment .name {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  line-height: 2.3rem;
  font-weight: normal;
  color: #c70087;
}
.new-blog .content.detail_new.comments .comment .date {
  font-family: "Montserrat-Medium";
  font-size: 1.1rem;
  line-height: 2.3rem;
  font-weight: normal;
  color: #333;
  padding-bottom: 2.6rem;
  opacity: 1;
}
.new-blog .content.detail_new.comments .comment .text {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  color: #333;
  line-height: 2.1rem;
  font-weight: normal;
  margin-bottom: 0;
}
.new-blog .wrap-comments-content {
  padding: 5rem;
  padding-top: 2rem;
  background-color: #fff4fb;
  margin-right: 0.5rem;
}
.new-blog .wrap-comments-content .title-comments {
  font-family: "Montserrat-Bold";
  font-size: 2.9rem;
  line-height: 3.7;
  color: #c70087;
  font-weight: normal;
  display: none;
}
.new-blog .wrap-comments-content .title-comments.isSee {
  display: block;
}
.new-blog .wrap-comments-content .comments .new-comments {
  padding: 0;
}
.new-blog .wrap-comments-content .comments .new-comments .form .form-control {
  background-color: transparent;
}
.new-blog .wrap-comments-content .comments .new-comments .title {
  line-height: 3.7;
  font-size: 2.9rem;
  font-family: "Montserrat-Bold";
  color: #c70087;
  font-weight: normal;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-transform: none;
  margin-top: 2rem;
}
.new-blog .wrap-comments-content .comments .comments-list .no-comments {
  line-height: 3.7;
  font-size: 1.9rem;
  font-family: "Montserrat-Bold";
  color: #c70087;
  font-weight: normal;
}
.new-blog .wrap-comments-content .comments .comments-list .coment-item .comment {
  background-color: transparent;
  height: auto !important;
  padding: 0;
  padding-bottom: 3rem;
  border-bottom: solid 0.3px rgba(151, 151, 151, 0.5);
  border-radius: 0;
  padding-top: 2.65rem;
}
.new-blog .wrap-comments-content .comments .comments-list .coment-item .comment .name {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  line-height: 2.3rem;
  font-weight: normal;
  color: #c70087;
}
.new-blog .wrap-comments-content .comments .comments-list .coment-item .comment .date {
  font-family: "Montserrat-Medium";
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: normal;
  color: #333;
  padding-bottom: 1.9rem;
}
.new-blog .wrap-comments-content .comments .comments-list .coment-item .comment .text {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  color: #333;
  line-height: 2.3rem;
  font-weight: normal;
  margin-bottom: 0;
}
.new-blog .wrap-comments-content .comments .form .privacy .labelContainer {
  margin-top: 1rem;
}
.new-blog .wrap-comments-content .comments .form .buttons {
  justify-content: flex-end;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.new-blog .wrap-comments-content .comments .form .buttons .wrap-btnDefault {
  min-width: unset;
}
.new-blog .wrap-comments-content .comments .form .buttons .wrap-btnDefault .btnDefault {
  font-size: 1.5rem;
  line-height: 1.7rem;
  padding: 1.4rem 3.8rem;
}
.new-blog .wrap-comments-content .comments .form .input-box input + .label {
  top: 0;
  font-size: 1.5rem;
  left: 1.7rem;
  color: #575252;
  background-color: #fff4fb;
  padding: 0 1.1rem;
  pointer-events: none;
  transform: translateY(-50%);
  transform-origin: left;
  white-space: nowrap;
  margin: 0;
  transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
}
.new-blog .wrap-comments-content .comments .form .input-box input + .label.left-1 {
  left: 1rem;
}
.new-blog .wrap-comments-content .comments .form .input-box textarea {
  min-height: 14.7rem;
}
.new-blog .wrap-comments-content .comments .form .input-box textarea + .label {
  position: absolute;
  top: 0;
  font-size: 1.5rem;
  left: 1.7rem;
  color: #575252;
  background-color: #fff4fb;
  padding: 0 1.1rem;
  pointer-events: none;
  transform: translateY(-50%);
  transform-origin: left;
  white-space: nowrap;
  margin: 0;
  transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
}
.new-blog .wrap-comments-content .comments .form .input-box textarea + .label.left-1 {
  left: 1rem;
}
.new-blog .wrap-comments-content .comments .form .input-box input:focus,
.new-blog .wrap-comments-content .comments .form .input-box textarea:focus {
  box-shadow: none;
  border: 1px solid #333;
}
.new-blog .wrap-comments-content .comments .form .input-box input:focus + .label, .new-blog .wrap-comments-content .comments .form .input-box input:valid + .label,
.new-blog .wrap-comments-content .comments .form .input-box textarea:focus + .label, .new-blog .wrap-comments-content .comments .form .input-box textarea:valid + .label {
  top: 0;
  transform: translateY(-50%) scale(1);
}
.new-blog .detail_new {
  background-color: transparent;
}
.new-blog .detail_new .new-text {
  padding: 0;
  padding-right: 4.5rem;
}
.new-blog .detail_new .new-text .text, .new-blog .detail_new .new-text .text * {
  line-height: 2.3rem !important;
  font-size: 1.5rem !important;
  color: #333 !important;
  font-family: "Montserrat-Regular" !important;
  font-weight: normal !important;
  background-color: transparent !important;
  padding: 0;
}
.new-blog .detail_new .new-text .text b, .new-blog .detail_new .new-text .text * b {
  font-family: "Montserrat-SemiBold";
  font-weight: normal !important;
}
.new-blog .detail_new .new-text .text i, .new-blog .detail_new .new-text .text * i {
  font-family: "Montserrat-Italic" !important;
  font-weight: normal !important;
}
.new-blog .detail_new .new-text .text a, .new-blog .detail_new .new-text .text * a {
  color: #c70087 !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.new-blog .detail_new .new-text .text > *:not(br) {
  padding-bottom: 2.5rem !important;
}
.new-blog .detail_new .new-text .text > *:not(br):empty {
  display: none !important;
  padding-bottom: 0 !important;
}
.new-blog .detail_new .new-text .text p:empty {
  display: none !important;
  padding-bottom: 0 !important;
}
.new-blog .detail_new .photo {
  width: 100%;
  height: auto;
  border-radius: 0;
  position: relative;
  margin-bottom: 2.5rem;
  border-bottom: 6px solid #c70087;
}
.new-blog .detail_new .photo .img-responsive {
  width: 100%;
}
.new-blog .detail_new .photo .pull-right {
  position: absolute;
  color: #333;
  width: 8rem;
  height: 4.4rem;
  top: 2.1rem;
  right: 0;
  background-image: url("/images/flag.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
}
.new-blog .detail_new .photo .pull-right .fa {
  font-size: 1.8rem;
  margin-left: 0.5rem;
}
.new-blog .detail_new .new-header .title {
  font-size: 3.1rem;
  font-family: "Montserrat-SemiBold";
  line-height: 3.9rem;
  color: #575252;
  margin-bottom: 3.2rem;
}
.new-blog .comments {
  padding-right: 0.5rem;
}

@media (max-width: 991px) {
  .new-blog {
    padding-top: 9.2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .new-blog .blog-description {
    margin-bottom: 2.5rem;
  }
  .new-blog .aux-slider {
    margin-bottom: 4.3rem;
    margin-top: 3rem;
  }
  .new-blog .aux-slider.isOne .swiper-wrapper .swiper-slide:last-of-type .wrap-item {
    margin: 0;
  }
  .new-blog .aux-slider.isOne .swiper-wrapper .swiper-slide .wrap-item {
    margin: 0;
  }
  .new-blog .aux-slider.isOne .swiper-wrapper .swiper-slide .wrap-item .img-responsive {
    max-height: unset;
    width: 100%;
  }
  .new-blog .aux-slider.isOne .pasadorPost.isOne {
    width: calc(100% - 3rem) !important;
    left: -1.5rem;
  }
  .new-blog .aux-slider .swiper-slider {
    width: unset;
  }
  .new-blog .aux-slider .control.control-next {
    right: 1rem;
    width: 2.6rem;
    height: 2.6rem;
  }
  .new-blog .aux-slider .control.control-prev {
    left: 1rem;
    width: 2.6rem;
    height: 2.6rem;
  }
  .new-blog .blog-entry-container .blog-entry:nth-child(even) {
    transform: none;
  }
  .new-blog.page-blog .two-columns-wrap {
    margin-bottom: 0;
  }
  .new-blog .form-more {
    margin-top: 9rem !important;
  }
  .new-blog .wrap-container {
    padding: 0;
  }
  .new-blog .wrap-container:first-of-type {
    padding-top: 0;
    padding: 0;
  }
  .new-blog .wrap-container .blog-header {
    padding-left: 0;
    flex-direction: column;
    margin-bottom: 0;
  }
  .new-blog .wrap-container .blog-header .part-left {
    width: 100%;
    max-width: 100%;
    padding: 0;
    padding-left: 0;
  }
  .new-blog .wrap-container .blog-header .part-left .wrap-blogs-form {
    margin-top: 3.7rem;
    margin-bottom: 2.8rem;
  }
  .new-blog .wrap-container .blog-header .blog-img {
    margin-top: 0;
  }
  .new-blog .wrap-container .blog-img {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: unset;
    margin-top: 3.5rem;
  }
  .new-blog .wrap-container .blog-img .img {
    width: 100%;
    height: auto;
    aspect-ratio: 3/2;
    position: relative;
  }
  .new-blog .wrap-container .form-group {
    margin-bottom: 0;
  }
  .new-blog .limited-wrap {
    padding-top: 0;
  }
  .new-blog .header {
    justify-content: space-between;
    margin-bottom: 0;
  }
  .new-blog .header .long-line {
    width: 100%;
  }
  .new-blog .header .header-title .title {
    font-size: 2.5rem;
    max-width: 20rem;
  }
  .new-blog .wrap-lastArticles .section-title h2 {
    font-size: 1.7rem;
    letter-spacing: 4.25px;
  }
  .new-blog .wrap-lastArticles .section-title .divider {
    margin: 2rem auto 3rem auto;
  }
  .new-blog .wrap-lastArticles .last-3-news {
    transform: translateX(-1.5rem);
    width: calc(100% + 3rem);
    flex-wrap: nowrap;
    padding-bottom: 6rem;
    overflow-x: scroll;
  }
  .new-blog .wrap-lastArticles .last-3-news .last-3-new {
    width: calc(100% - 5.5rem);
    flex-shrink: 0;
    margin-right: 1.5rem;
  }
  .new-blog .wrap-lastArticles .last-3-news .last-3-new:first-of-type {
    margin-left: 1.5rem;
  }
  .new-blog .authors-container {
    display: none;
  }
  .new-blog .detail_new {
    margin-left: 0;
    margin-right: 0;
  }
  .new-blog .detail_new .new-text {
    padding-right: 0;
  }
  .new-blog .detail_new .new-text .new-header {
    padding: 0;
    margin-bottom: 1.2rem;
  }
  .new-blog .detail_new .new-text .new-header .title {
    font-size: 2.1rem;
    line-height: 2.7rem;
  }
  .new-blog .detail_new .new-text .text {
    padding: 0;
  }
  .new-blog .detail_new .new-text .text p:empty {
    display: none !important;
    padding-bottom: 0 !important;
  }
  .new-blog .detail_new .new-text .photo {
    border-bottom: 3px solid #c70087;
  }
  .new-blog .detail_new .new-text .photo .pull-right {
    height: 3rem;
    top: 1rem;
    width: 6.6rem;
  }
  .new-blog .wrap-content-post {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
  .new-blog .wrap-content-post .wrap-share {
    padding-right: 0;
    margin-top: 0;
  }
  .new-blog .wrap-content-post .wrap-author {
    margin-right: 0;
    padding-right: 0;
  }
  .new-blog .wrap-content-post .wrap-author .wrap-aux {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
  .new-blog .wrap-content-post .wrap-comments-content {
    padding: 1.5rem;
    margin: 0;
  }
  .new-blog .wrap-content-post .wrap-comments-content .detail_new.comments {
    padding-right: 0;
  }
  .new-blog .wrap-content-post .wrap-comments-content .detail_new.comments .new-comments .title {
    line-height: normal;
    margin-bottom: 3.6rem;
  }
  .new-blog .wrap-content-post .wrap-comments-content .detail_new.comments .new-comments .form-comment .form {
    display: block;
  }
  .new-blog .wrap-content-post .wrap-comments-content .detail_new.comments .new-comments .form-comment .form .input-box input + .label {
    left: 1rem;
  }
  .new-blog .wrap-content-post .wrap-comments-content .detail_new.comments .new-comments .form-comment .input-box {
    padding: 0;
  }
  .new-blog .wrap-content-post .wrap-comments-content .detail_new.comments .comments-list .no-comments {
    font-size: 1.7rem;
  }
  .new-blog .wrap-content-post .wrap-comments-content .detail_new.comments .form .privacy .labelContainer {
    position: relative;
  }
  .new-blog .wrap-content-post .wrap-comments-content .title-comments {
    line-height: 3.6rem;
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;
  }
  .new-blog .wrap-content-post .pasadorPost {
    margin-bottom: 0;
    margin-right: 0;
  }
  .new-blog .blog-entry-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1;
    width: 100%;
    margin-bottom: 2.8rem;
  }
  .new-blog .blog-entry-container .blog-entry .aux-img .wrap-img:hover img {
    transform: unset;
  }
  .new-blog .blog-entry-container .blog-entry,
  .new-blog .blog-entry-container .blog-entry:first-of-type {
    width: 100%;
    margin-bottom: 3rem;
    padding-right: 0;
  }
  .new-blog .blog-entry-container .blog-entry .info .date-info,
  .new-blog .blog-entry-container .blog-entry:first-of-type .info .date-info {
    font-family: "Montserrat-Italic";
    font-size: 1.3rem;
    color: #c70087;
    line-height: 2.1rem;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content .block-text,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content .block-text {
    padding: 1rem;
    padding-bottom: 2rem;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content .badge-blog,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content .badge-blog {
    display: block;
    position: relative;
    top: unset;
    right: unset;
    width: 80px;
    border: none;
    margin: 0 auto;
    margin-right: 0;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content .badge-blog .like-button,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content .badge-blog .like-button {
    width: auto;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content .badge-blog p,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content .badge-blog p {
    position: relative;
    right: 0;
    transform: none;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content .badge-blog .likes,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content .badge-blog .likes {
    align-items: center;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content .badge-blog .fa:before,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content .badge-blog .fa:before {
    font: FontAwesome;
    color: #c70087;
  }
  .new-blog .blog-entry-container .blog-entry .blog-content .author,
  .new-blog .blog-entry-container .blog-entry:first-of-type .blog-content .author {
    color: #575252;
  }
}
.new-blog.page-blog-autor-famaliving2023 .authors-container {
  transform: none;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author {
  width: 100%;
  margin-bottom: 5rem;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .box-content {
  display: flex;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .photo-author {
  width: 25%;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .photo-author img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .author-content-box {
  width: 75%;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .author-content-box.imgTrue {
  padding-left: 3rem;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .company {
  font-family: "Montserrat-Italic";
  font-size: 1.3rem;
  color: #c70087;
  margin-bottom: 1rem;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .subtitle {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .text {
  margin-top: 2rem;
}
.new-blog.page-blog-autor-famaliving2023 .blog-author .title-last-blog-box {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  color: #c70087;
  margin-top: 2rem;
}
.new-blog.page-blog-autor-famaliving2023 .blog-entry-container .blog-entry:nth-child(even) {
  transform: none;
}

@media (max-width: 991px) {
  .new-blog.page-blog-autor-famaliving2023 .blog-author {
    margin-bottom: 0;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .box-content {
    flex-direction: column;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .photo-author {
    width: 100%;
    height: fit-content;
    padding-bottom: 2rem;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .author-content-box {
    width: 100%;
    padding-left: 0;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .author-content-box.imgTrue {
    padding-left: 0;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .company {
    font-family: "Montserrat-Italic";
    font-size: 1.3rem;
    color: #c70087;
    margin-bottom: 1rem;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .subtitle {
    font-family: "Montserrat-SemiBold";
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 1rem;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .text {
    margin-top: 2rem;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-author .title-last-blog-box {
    font-family: "Montserrat-SemiBold";
    font-size: 1.3rem;
    color: #c70087;
  }
  .new-blog.page-blog-autor-famaliving2023 .blog-entry-container .blog-entry:nth-child(even) {
    transform: none;
  }
}
.container-shops {
  height: 100%;
  padding: 5rem;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  max-width: calc(1280px + 10rem);
  margin: 0 auto;
}
.container-shops .content-map {
  width: calc(100% - 40rem);
  position: relative;
  display: flex;
  flex-direction: column;
}
.container-shops .content-map .title {
  font-family: "Montserrat-SemiBold";
  font-size: 4.5rem;
  margin-left: 7rem;
  margin-bottom: 5.1rem;
  padding-top: 4rem;
  line-height: normal;
  letter-spacing: 0;
  position: relative;
}
@media (max-width: 991px) {
  .container-shops .content-map .title {
    font-size: 2.5rem;
    margin-bottom: 2.8rem;
  }
}
.container-shops .content-map .span1 {
  color: #c70087;
}
.container-shops .map-container {
  width: calc(100% - 7rem);
  aspect-ratio: 3/1.7;
  z-index: 10;
}
.container-shops .title.title-map {
  font-size: 4.1rem;
  color: #f5f5f5;
  font-family: "Montserrat-SemiBold";
  padding: 0;
  margin-top: 0;
  line-height: 5.5rem;
  position: relative;
  margin-bottom: 5.4rem;
  margin-left: 5rem;
}
.container-shops .title.title-map::before {
  content: "";
  background-image: url("/images/here-icon-blue.svg");
  width: 2.4rem;
  height: 100%;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  left: -5rem;
  top: 0;
}
.container-shops .wrap-full-background {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.2s opacity ease;
}
.container-shops .wrap-full-background .wrap-imgActive {
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 0 auto;
}
.container-shops .wrap-full-background .imgActive {
  position: relative;
  margin: 0 auto;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  object-fit: contain;
  height: 60%;
}
.container-shops .wrap-full-background .close {
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
}
.container-shops .wrap-full-background .close:hover {
  transition: transform 0.2s ease-in-out;
  transform: scale(1.1);
}
.container-shops .wrap-full-background .close::before {
  content: "";
  position: absolute;
  background-image: url("/images/fl-plus-active.svg");
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(45deg);
}
.container-shops .wrap-full-background.isOpen {
  z-index: 101;
  visibility: visible;
  opacity: 1;
  transition: 0.2s opacity ease;
}

.shop-map {
  width: 100%;
  height: auto;
  display: flex;
}
@media screen and (max-width: 991px) {
  .shop-map {
    flex-direction: column;
  }
}

.wrap-info-stores .filter-c {
  width: 40rem;
  height: 100%;
  padding-right: 0;
}
.wrap-info-stores .map-container-info {
  width: calc(100% - 47rem);
  height: 100%;
  margin-left: 7rem;
  margin-top: 3.7rem;
  font-size: 1.7rem;
  line-height: 2.3rem;
  color: #000;
  font-family: "Montserrat-Regular";
  /**
    *  Progress Bar
    */
  /**
  *  END Progress Bar
  */
}
.wrap-info-stores .map-container-info .wrap-content-shop {
  width: 100%;
  max-width: 86.5rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-down {
  flex-direction: column;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .direction .nameStory {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
}
@media (min-width: 768px) and (max-width: calc(991px + 1px)) {
  .wrap-info-stores .map-container-info .wrap-content-shop .part-up {
    flex-direction: row !important;
  }
  .wrap-info-stores .map-container-info .wrap-content-shop .part-up .direction, .wrap-info-stores .map-container-info .wrap-content-shop .part-up .contact {
    width: 50% !important;
  }
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up, .wrap-info-stores .map-container-info .wrap-content-shop .part-down {
  width: 100%;
  flex-wrap: wrap;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up > div, .wrap-info-stores .map-container-info .wrap-content-shop .part-down > div {
  width: 33.3333333333%;
  min-width: 23.5rem;
  padding-right: 1.5rem;
  margin-bottom: 4.5rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .web, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .web {
  width: calc(100% - 1.5rem);
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .web a, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .web a {
  text-decoration: none;
  --border-color: #575252;
  --border-width: 1px;
  --bottom-distance: 0px;
  display: inline;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0 var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .web a:hover, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .web a:hover {
  background-size: 100% var(--border-width);
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .aux, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .aux {
  flex-grow: 1;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .aux p, .wrap-info-stores .map-container-info .wrap-content-shop .part-up .aux p span, .wrap-info-stores .map-container-info .wrap-content-shop .part-up .aux p a, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .aux p, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .aux p span, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .aux p a {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #575252;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .aux p.title, .wrap-info-stores .map-container-info .wrap-content-shop .part-up .aux p span.title, .wrap-info-stores .map-container-info .wrap-content-shop .part-up .aux p a.title, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .aux p.title, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .aux p span.title, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .aux p a.title {
  font-family: "Montserrat-SemiBold";
  color: #c70087;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .wrap-btnDefault, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .wrap-btnDefault {
  min-width: 21.2rem;
  margin-top: 2.8rem;
  align-self: flex-start;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .wrap-btnDefault .btnDefault, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .wrap-btnDefault .btnDefault {
  padding: 1.4rem 1.4rem;
  font-size: 1.5rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .part-redes .red, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .part-redes .red {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #575252;
  position: relative;
  padding-left: 3rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .part-redes .red.facebook, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .part-redes .red.facebook {
  margin-bottom: 1rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .part-redes .red.facebook:after, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .part-redes .red.facebook:after {
  content: "";
  position: absolute;
  background-image: url("/images/fl-map-facebook.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .part-redes .red.instagram:after, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .part-redes .red.instagram:after {
  content: "";
  position: absolute;
  background-image: url("/images/fl-map-instagram.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .contact .aux, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .contact .aux {
  order: 1;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .contact .part-redes, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .contact .part-redes {
  order: 2;
  margin-top: 1.5rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .contact .wrap-btnDefault, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .contact .wrap-btnDefault {
  order: 3;
  left: 0.5rem;
}
@media (min-width: calc(991px + 1px)) and (max-width: 1280px) {
  .wrap-info-stores .map-container-info .wrap-content-shop .part-up .contact .wrap-btnDefault, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .contact .wrap-btnDefault {
    left: 0;
  }
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear {
  height: auto;
  overflow: auto;
  order: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 66.6666666667%;
  min-width: 45.4rem;
  padding-right: 0;
  column-gap: 1.5rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear::-webkit-scrollbar, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear::-webkit-scrollbar:horizontal, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear::-webkit-scrollbar:horizontal {
  height: 4px;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear::-webkit-scrollbar-thumb, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid #1197a5;
  background-color: #1197a5;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear::-webkit-scrollbar-track, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear::-webkit-scrollbar-track {
  background-color: rgba(17, 151, 165, 0.32);
  border-radius: 8px;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear .store, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear .store {
  font-weight: normal;
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  color: #575252;
  line-height: 2.1rem;
  text-transform: capitalize;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear .store .storeName, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear .store .storeName {
  text-transform: uppercase;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear .store .storeName .linkStore, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear .store .storeName .linkStore {
  color: #c70087;
  font-family: "Montserrat-Regular";
  transition: all 0.2s linear;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear .store .storeName .linkStore:hover, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear .store .storeName .linkStore:hover {
  color: #c70087;
  font-family: "Montserrat-SemiBold";
  transition: all 0.2s linear;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear .store:last-of-type .linksStores, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear .store:last-of-type .linksStores {
  margin-bottom: 0;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear .store .linksStores, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear .store .linksStores {
  margin-bottom: 1rem;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear .store .linksStores a, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear .store .linksStores a {
  font-family: "Montserrat-SemiBold";
  text-decoration: underline;
}
.wrap-info-stores .map-container-info .wrap-content-shop .part-up .title, .wrap-info-stores .map-container-info .wrap-content-shop .part-down .title {
  font-size: 1.9rem;
  font-family: "MONTSERRAT-BOLD";
  color: #575252;
  padding-bottom: 1rem;
  line-height: 2.7rem;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #fff;
  order: 1;
  margin-bottom: 0;
}
.wrap-info-stores .map-container-info .wrap-slider {
  width: 100%;
  margin-top: 5.2rem;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop {
  width: calc(100% + 5rem);
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .item-img {
  width: 30vw;
  max-width: 50rem;
  min-width: 37.3rem;
  margin-right: 1.7rem;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .item-img .img {
  width: 100%;
  padding-top: 56%;
  position: relative;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .item-img .img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .slick-arrow {
  z-index: 1;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .slick-prev {
  left: 1.5rem;
  display: none;
  width: 4rem;
  height: 4rem;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .slick-prev:before {
  content: "";
  background-image: url("/images/fl-left.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .slick-next {
  right: 1.5rem;
  display: none;
  width: 4rem;
  height: 4rem;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop .slick-next:before {
  content: "";
  background-image: url("/images/fl-right.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop:hover .slick-prev, .wrap-info-stores .map-container-info .wrap-slider .slider-shop:hover .slick-next {
  display: block;
}
.wrap-info-stores .map-container-info .wrap-slider .slider-shop:hover .slick-prev, .wrap-info-stores .map-container-info .wrap-slider .slider-shop:hover .slick-next {
  opacity: 1;
}
.wrap-info-stores .map-container-info .wrap-progress {
  width: 100%;
  max-width: 1280px;
  padding-top: 2.8rem;
}
.wrap-info-stores .map-container-info .wrap-progress .progress {
  display: block;
  width: 100%;
  height: 5px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(199, 0, 135, 0.5);
  background-image: linear-gradient(to right, #c70087, #c70087);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out;
}
.wrap-info-stores .map-container-info .wrap-progress .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.content.shops {
  padding: 12px;
}
.content.shops > .row {
  margin: 0 -6px;
}
@media screen and (min-width: 768px) {
  .content.shops > .row {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .content.shops .col {
    padding: 0 6px;
  }
}
@media screen and (max-width: 991px) {
  .content.shops .col {
    padding: 0;
  }
}

.shops-filter {
  padding: 0px 0px 0px 0px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.shops-filter .wrap-img {
  width: 41%;
  margin: 0 auto;
  margin-bottom: 5.1rem;
}
.shops-filter .wrap-img .img {
  object-position: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.shops-filter .show-filter {
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .shops-filter .show-filter {
    padding: 0;
    display: block;
    float: right;
    transform-origin: center center;
    transform: rotateX(0);
    -webkit-transition: all 0.5s ease; /* Safari */
    transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
  }
}
.shops-filter .show-filter.open {
  transform: rotateX(180deg);
}
.shops-filter .form-block {
  padding: 0;
  left: 0 !important;
}
@media screen and (min-width: 768px) {
  .shops-filter .form-block {
    position: relative;
    left: 0;
    right: 0px !important;
    bottom: 0 !important;
    top: unset !important;
    display: block !important;
  }
}
@media screen and (max-width: 767px) {
  .shops-filter .form-block {
    display: block;
    position: relative;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    top: auto !important;
  }
}
.shops-filter .form-block .form-control {
  text-transform: capitalize;
}
@media screen and (max-width: 991px) {
  .shops-filter .form-block label {
    margin-top: 0px;
  }
}
@media screen and (min-width: 768px) {
  .shops-filter .form-block label {
    margin-top: 20px;
  }
}
.shops-filter .form-block .loading-results {
  background-image: url("/css/assets/images/ajax-loader.gif");
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
}
.shops-filter .form-block .form-group {
  position: relative;
}
.shops-filter .form-block .form-group label[for=pais]::before {
  content: none;
}
.shops-filter .form-block .form-group.buttons {
  margin-bottom: 6rem;
  position: relative;
  bottom: 0;
  width: 100%;
  right: 0px;
  padding: 0;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .shops-filter .form-block .form-group.buttons {
    margin-top: 0;
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .shops-filter .form-block .form-group.buttons {
    margin-bottom: 0px;
  }
}
.shops-filter .form-block .form-group.buttons button {
  margin-top: 15px;
}
@media screen and (max-width: 1365px) {
  .shops-filter .form-block .form-group.buttons button {
    font-size: 1vw;
  }
}
@media screen and (max-width: 761px) {
  .shops-filter .form-block .form-group.buttons button {
    font-size: 18px;
  }
}
@media screen and (min-width: 1366px) {
  .shops-filter .form-block .form-group.buttons button {
    font-size: 18px;
  }
}

.shops-filter .select2-container--default .select2-selection--single {
  border: 1px solid #ccc;
}

#pais.err + .select2.select2-container .select2-selection--single,
#pais-geo.err + .select2.select2-container .select2-selection--single {
  border: 1px solid red !important;
}

#provincia_container.err .select2-container--default .select2-selection--single {
  border: 1px solid red !important;
}

.map-container #map {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
}

.map-container {
  margin-left: 7rem;
  padding-right: 0;
}
@media screen and (max-width: 991px) {
  .map-container {
    height: 100vh;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) {
  .map-container {
    height: 100%;
    overflow: hidden;
    width: calc(100% - 47rem);
    min-width: unset;
    box-shadow: 0px 0px 20px 0px darkgray;
    border-radius: 10px;
  }
}

@media screen and (min-width: 992px) {
  .filter-c {
    height: auto;
    padding-left: 0;
    width: 40rem;
  }
}

form.form-block {
  position: relative;
}

.form-search-map {
  width: 100%;
  height: 100%;
  position: relative;
}
.form-search-map .wrap-btnDefault {
  width: 100%;
  min-width: unset;
  margin: 0 auto;
  margin-right: 0;
  margin-top: 0;
}

.container-shops .form-search-map input, .container-shops .form-search-map select {
  border-radius: 0 !important;
}
.container-shops .form-search-map .form-group {
  margin-bottom: 6rem;
}
.container-shops .form-search-map .form-group .form-control {
  border: 1px solid #575252;
  display: block;
  width: 100%;
  height: inherit;
  padding: 0.75rem;
  font-size: 1.7rem;
  font-weight: normal;
  line-height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.container-shops .form-search-map .form-group .form-control + .paisLabel {
  margin-top: 0 !important;
  left: 3rem;
  margin: 0;
  position: absolute;
  top: 0;
  padding: 0 1rem;
  transform: translateY(-50%);
  background-color: #fff;
  font-size: 1.3rem;
  font-family: "Montserrat-Medium";
  font-weight: normal;
  z-index: 1;
}
.container-shops .select2.select2-container.select2-container--default + .paisLabel {
  margin-top: 0 !important;
  left: 3rem;
  margin: 0;
  position: absolute;
  top: 0;
  padding: 0 1rem;
  transform: translateY(-50%);
  background-color: #fff;
  font-size: 1.3rem;
  font-family: "Montserrat-Medium";
  font-weight: normal;
  z-index: 1;
}
.container-shops .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #575252;
  line-height: 28px;
  position: absolute;
  background-color: #fff;
  padding-right: 1rem;
}
.container-shops .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #575252 transparent transparent transparent;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  height: 0;
  left: 50%;
  margin-left: -30px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.container-shops .shops-filter .select2-container--default .select2-selection--single {
  border: 1px solid #575252;
}
.container-shops .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #575252 transparent;
  border-width: 0 7px 7px 7px;
}
.container-shops .select2-container .select2-dropdown {
  margin-top: -1px;
  border-color: #000 !important;
  border-radius: 0;
  font-size: 1.7rem;
  font-family: "Montserrat-Regular";
  line-height: 2.3rem;
  letter-spacing: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  font-size: 1.7rem;
  font-family: "Montserrat-Regular";
  line-height: 2.3rem;
  letter-spacing: 0;
}

.select2-container #select2-pais-results li:first-of-type {
  padding-top: 0px;
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .container-shops {
    padding: 6.3rem 1.5rem;
  }
  .container-shops .content-map {
    width: 100%;
  }
  .container-shops .map-header-mvl .wrap-img {
    width: auto;
    padding-right: 3.3rem;
    max-width: 10rem;
    margin-left: 0;
    margin-bottom: 4.1rem;
  }
  .container-shops .map-header-mvl .box-content {
    width: 100%;
    padding-top: 1rem;
  }
  .container-shops .title.title-map {
    font-size: 2.5rem;
    margin-bottom: 4rem;
  }
  .container-shops .form-search-map .form-group {
    margin-bottom: 3.8rem;
  }
  .container-shops .form-search-map .form-block {
    font-size: 1.7rem;
    line-height: 2.3rem;
    letter-spacing: 0;
  }
  .container-shops .form-search-map .wrap-btnDefault {
    margin-top: 0;
  }
  .container-shops .map-container {
    width: calc(100% + 3rem);
    height: 100%;
    min-height: unset;
    margin-left: 0;
    box-shadow: 0px 0px 15px 0px darkgrey;
    transform: translateX(-1.5rem);
    aspect-ratio: 1.4;
  }
  .container-shops .shop-map {
    height: auto;
  }
  .container-shops .wrap-info-stores .filter-c {
    display: none;
  }
  .container-shops .wrap-info-stores .map-container-info {
    width: 100%;
    margin-left: 0;
    margin-top: 3.3rem;
    font-family: "Montserrat-Regular";
    font-size: 1.5rem;
    line-height: 2.3rem;
  }
  .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-up, .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-down {
    flex-direction: column;
  }
  .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-up > div, .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-down > div {
    margin-top: 4.8rem;
    width: 100%;
    padding-right: 0;
    margin-bottom: 0;
  }
  .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-up .storyNear, .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-down .storyNear {
    order: 2;
    margin-top: 0;
    min-width: unset;
    column-gap: 2rem;
  }
  .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-up .store, .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-down .store {
    order: 1;
  }
  .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-up .store .storeName, .container-shops .wrap-info-stores .map-container-info .wrap-content-shop .part-down .store .storeName {
    width: 100%;
  }
  .container-shops .wrap-info-stores .map-container-info .wrap-slider {
    display: none;
  }
  .select2-results__option {
    padding: 1rem !important;
    user-select: none;
    -webkit-user-select: none;
  }
  .form-group.buttons .select2.select2-container {
    margin-bottom: 3.9rem;
  }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    height: 5rem;
    padding: 1rem;
    font-size: 1.7rem;
    line-height: 2.3rem;
    letter-spacing: 0;
  }
  .container-shops .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 1.7rem;
    line-height: 2.3rem;
    letter-spacing: 0;
  }
  .select2-container.select2-container--default.select2-container--open {
    left: unset !important;
  }
  .select2-container.select2-container--default.select2-container--open .select2-dropdown {
    left: 1.5rem;
  }
  .geo-content .select2-container.select2-container--default.select2-container--open {
    left: unset !important;
  }
  .geo-content .select2-container.select2-container--default.select2-container--open .select2-dropdown {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .geo-content .select2-container--default .select2-results > .select2-results__options,
  .geo-content .select2-container--default .select2-search--dropdown .select2-search__field {
    font-size: 1.3rem;
    line-height: 2.1rem;
  }
  .geo-content .select2-container--default .select2-results__option {
    padding: 0.5rem 1rem;
  }
  .geo-content .select2-container--default .select2-search--dropdown .select2-search__field {
    height: 4rem;
  }
}
.cluster {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
}
.cluster img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.cluster > div {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cluster > div span {
  align-items: center;
  color: #fff;
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
}

@media screen and (min-width: 991px) {
  .shops-filter .form-block .form-group.buttons {
    margin-bottom: 0;
  }
  .shops-filter .form-block .form-group.buttons .select2-container.select2-container--default {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 991px) {
  .shops-filter .form-block .form-group.buttons {
    margin-bottom: 4.3rem;
    position: relative;
  }
  .filter-c {
    height: auto;
  }
}
.blockSelected {
  padding: 0 5rem;
}
.blockSelected .subtitle {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 2.5rem;
  letter-spacing: 1.5px;
  color: #c70087;
  margin-bottom: 4.7rem;
}
.blockSelected .border {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #c70087;
  top: 2rem;
  left: 2rem;
}
.blockSelected.format2 {
  padding-bottom: 11.9rem;
}
.blockSelected.format2 .wrap-content {
  display: block;
}
.blockSelected.format2 .wrap-content .header-title {
  width: 100%;
}
.blockSelected.format2 .wrap-content .part-right {
  width: calc(58% + 12.3rem);
  padding: 8.4rem 11.4rem 8.8rem 23.5rem;
  flex-direction: column;
  float: left;
  margin-left: -12.3rem;
}
.blockSelected.format2 .wrap-content .part-left {
  width: 42%;
  float: left;
  position: relative;
  z-index: 2;
}
.blockSelected.format2 .wrap-content .part-left .wrap-img {
  margin-top: 3.2rem;
  position: relative;
  width: auto;
  height: fit-content;
}
.blockSelected.format2 .wrap-content .part-left .wrap-img .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
}
.blockSelected.format2 .wrap-content .wrap-btnDefault {
  width: 100%;
}
.blockSelected.format2 .wrap-content .title.small-line {
  font-size: 4.5rem;
}
.blockSelected.format2 .wrap-content .small-line {
  margin-bottom: 5rem;
}
.blockSelected.format2 .wrap-content .small-line::before {
  bottom: -1.4rem;
}
.blockSelected.format9 {
  padding: 0;
}
.blockSelected.format9 .wrap-container {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 12.9rem;
}
.blockSelected.format9 .wrap-content {
  padding: 3.6rem 9.4rem 8.9rem 9.4rem;
  margin-top: -13rem;
}
.blockSelected.format9 .box-content .title {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 4.1rem;
  font-size: 5.5rem;
}
.blockSelected.format9 .box-content .title::before {
  left: 50%;
  margin: 0 auto;
  bottom: -1.3rem;
  transform: translateX(-50%);
}
.blockSelected.format9 .text.parrafo {
  margin-bottom: 6.1rem;
}
.blockSelected.format9 .text.parrafo * {
  margin-bottom: 1.5rem;
}
.blockSelected.format9 .text.parrafo, .blockSelected.format9 .text.parrafo * {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  line-height: 2.3rem;
  text-align: center;
}
.blockSelected.format9 .wrap-btnDefault {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: -11.5rem;
}
.blockSelected.format9 .wrap-btnDefault .btnDefault {
  padding: 1.7rem 11.3rem;
}
.blockSelected.format9 .wrap-img .img {
  aspect-ratio: 2.5;
  object-fit: cover;
  object-position: center;
  max-height: 60rem;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 991px) {
  .blockSelected {
    padding: 0 1.5rem;
  }
  .blockSelected .subtitle {
    font-family: "Montserrat-Medium";
    font-size: 1.3rem;
    line-height: 2.5rem;
    letter-spacing: 1.3px;
    margin-bottom: 2.8rem;
  }
  .blockSelected .border {
    top: 1.5rem;
    left: 1.5rem;
  }
  .blockSelected.format2 {
    padding-bottom: 6.2rem;
  }
  .blockSelected.format2 .wrap-content .header-title {
    width: 100%;
  }
  .blockSelected.format2 .wrap-content .part-right {
    width: calc(100% - 1.5rem);
    padding: 12rem 1.5rem 3.9rem 2.6rem;
    float: unset;
    margin: 0;
    margin-left: 3rem;
    margin-top: -7.7rem;
    position: relative;
  }
  .blockSelected.format2 .wrap-content .part-right .wrap-img {
    margin-top: 0;
    width: calc(100% - 1.5rem);
    margin-left: 0;
  }
  .blockSelected.format2 .wrap-content .part-left {
    width: 100%;
    float: unset;
  }
  .blockSelected.format2 .wrap-content .part-left .wrap-img {
    margin-top: 0;
    width: calc(100% - 3rem);
    margin-left: 0;
  }
  .blockSelected.format2 .wrap-content .part-left .wrap-img .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1;
  }
  .blockSelected.format2 .wrap-content .wrap-btnDefault {
    width: 100%;
  }
  .blockSelected.format2 .wrap-content .small-line {
    margin-bottom: 5rem;
  }
  .blockSelected.format2 .wrap-content .small-line::before {
    bottom: -1.4rem;
  }
  .blockSelected.format2 .wrap-content .title.small-line {
    font-family: "Montserrat-SemiBold";
    font-size: 2.9rem;
    line-height: normal;
  }
  .blockSelected.format9 {
    padding: 0;
  }
  .blockSelected.format9 .wrap-container {
    margin-bottom: 8.3rem;
    padding: 0 1.5rem;
  }
  .blockSelected.format9 .wrap-content {
    padding: 2.7rem 2.4rem 9rem 2.4rem;
    margin-top: -8rem;
  }
  .blockSelected.format9 .box-content .title {
    margin-bottom: 4.8rem;
    font-size: 2.9rem;
    line-height: 3.9rem;
    text-align: center;
  }
  .blockSelected.format9 .text.parrafo {
    margin-bottom: 3.5rem;
  }
  .blockSelected.format9 .wrap-btnDefault {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: -11.5rem;
    width: 90%;
    max-width: 27.9rem;
  }
  .blockSelected.format9 .wrap-btnDefault .btnDefault {
    padding: 0.9rem;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
  }
  .blockSelected.format9 .wrap-img .img {
    aspect-ratio: 2;
  }
}
.container.our-services {
  margin-bottom: 10rem;
  max-width: unset;
  padding: 0;
}
.container.our-services .aux-wrap {
  padding-top: 11rem;
  padding-bottom: 9rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}
.container.our-services .wrap-text {
  white-space: nowrap;
}
.container.our-services .wrap-text .text {
  color: #fff;
  font-size: 10.1rem;
  font-family: "Montserrat-SemiBold";
  padding-bottom: 5.6rem;
}
.container.our-services .wrap-text .text .light {
  font-family: "Montserrat-Regular";
}
.container.our-services .carousel-box {
  position: relative;
  width: calc(100% - (100% - 1060px) / 2);
  padding-left: 3rem;
  margin: 0 auto;
  overflow: hidden;
}
.container.our-services .carousel-box .carousel-box-wrapper {
  width: 100%;
  margin: 0 auto;
}
.container.our-services .carousel-box .carousel-box-wrapper .title {
  font-size: 4.5rem;
}
.container.our-services .carousel-box .carousel-wrapper {
  width: 100%;
  overflow: hidden;
  width: calc(100% + 2rem);
  left: -2rem;
  overflow: hidden;
  position: relative;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container {
  padding-left: 2rem;
  display: flex;
  flex-wrap: nowrap;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .slick-list {
  overflow: visible;
  width: 100%;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .slick-list .slick-slide {
  padding-right: 2.2rem;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .slick-list .slick-slide:last-of-type {
  padding-right: 0;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item {
  position: relative;
  padding-top: 120%;
  margin-bottom: 2rem;
  margin-right: 0;
  overflow: hidden;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: 0.3s transform;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item-info {
  color: #c70087;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item-info .title {
  font-family: "Montserrat-Bold";
  font-size: 1.9rem;
  line-height: 2.6rem;
  margin-bottom: 0.7rem;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item-info .text {
  color: #c70087;
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  line-height: 2.2rem;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item:hover .item .img {
  transform: scale(1.1);
  transition: 0.3s transform;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container > .slick-next {
  opacity: 1;
  width: 4rem;
  height: 4rem;
  top: 50%;
  transform: translateY(calc((-100% - 4.6rem) / 2));
  left: unset;
  /* z-index: -11; */
  right: 0.1rem;
  z-index: 1;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container > .slick-next:before {
  content: "";
  background-image: url("/images/fl-right.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container > .slick-prev {
  opacity: 1;
  width: 4rem;
  height: 4rem;
  top: 50%;
  transform: translateY(calc((-100% - 4.6rem) / 2));
  left: 0;
  z-index: 1;
}
.container.our-services .carousel-box .carousel-wrapper .carousel-container > .slick-prev:before {
  content: "";
  background-image: url("/images/fl-left.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .container.our-services {
    padding-top: 0;
    margin-bottom: 6.3rem;
  }
  .container.our-services .aux-wrap {
    padding-bottom: 10.2rem;
    padding-top: 5.1rem;
  }
  .container.our-services .carousel-box {
    width: 100%;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .container.our-services .carousel-box .box-content {
    padding-left: 1.5rem;
  }
  .container.our-services .carousel-box .box-content .title {
    font-size: 2.9rem;
    font-family: "Montserrat-SemiBold";
    line-height: 3.9rem;
  }
  .container.our-services .carousel-box .carousel-wrapper {
    width: 100%;
    overflow: hidden;
    left: 0;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 2rem;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container .slick-list {
    padding: 0 1.5rem !important;
    padding-left: 0 !important;
    padding-right: calc((100% - 3rem) / 100 + 35%) !important;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item {
    width: 26.2rem;
    margin: 0;
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item {
    margin-bottom: 2rem;
    padding-top: 110%;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item:first-of-type {
    margin-left: 0;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item .item-info .title {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 0;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container .carousel-item:hover .item .img {
    transform: scale(1);
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container > .slick-next {
    top: 50%;
    width: 2.5rem;
    height: 2.5rem;
    right: 1rem;
    left: unset;
    display: none !important;
  }
  .container.our-services .carousel-box .carousel-wrapper .carousel-container > .slick-prev {
    width: 2.5rem;
    height: 2.5rem;
    right: unset;
    left: 1rem;
    top: 50%;
    display: none !important;
  }
  .container.our-services .wrap-text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .container.our-services .wrap-text .text {
    font-size: 3.5rem;
    line-height: 6.3rem;
    padding-bottom: 3rem;
  }
}
.container.slider-simulations {
  background-color: #FFF;
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;
  padding-top: 8.3rem;
  padding-bottom: 5.4rem;
  margin-bottom: 9.9rem;
  max-width: unset;
}
.container.slider-simulations:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: calc(50% + (50% - 5rem - ((100% - 1280px) / 2 - 5rem)) / 2);
  height: 100%;
  background-color: #fff4fb;
}
.container.slider-simulations .text.generic {
  z-index: 1;
  position: relative;
}
.container.slider-simulations .text.generic > p:not(:last-of-type) {
  padding-bottom: 1.5rem;
}
.container.slider-simulations .wrap-btn {
  flex-grow: 1;
  justify-content: flex-end;
}
.container.slider-simulations .wrap-btnDefault {
  margin-top: 5.3rem;
  align-self: flex-start;
}
.container.slider-simulations .box-content .title {
  font-size: 4.5rem;
}
.container.slider-simulations .section-simulations-wrap {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 0;
}
.container.slider-simulations .slider-home-simulations-list {
  width: 100%;
}
.container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text {
  padding: 0 6.2rem;
  padding-right: 0;
}
.container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text .section-title, .container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text .section-subtitle {
  text-align: left;
  text-transform: capitalize;
  margin: 0;
}
.container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text .section-title {
  font-size: 6rem;
  margin: 0;
}
.container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text .line-separation {
  justify-content: flex-start;
}
.container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text .section-subtitle {
  text-transform: uppercase;
}
.container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .slider-simulation-item, .container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text {
  width: 50%;
}
.container.slider-simulations .slider-home-simulations-list .slick-dotted.slick-slider {
  margin-bottom: 0;
}
.container.slider-simulations .slider-home-simulations-list .slick-next {
  opacity: 1;
  right: 0;
  width: 4rem;
  height: 4rem;
}
.container.slider-simulations .slider-home-simulations-list .slick-next:before {
  content: "";
  background-image: url("/images/right.svg");
  width: 4rem;
  height: 4rem;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}
.container.slider-simulations .slider-home-simulations-list .slick-prev {
  opacity: 1;
  left: 0;
  width: 4rem;
  height: 4rem;
}
.container.slider-simulations .slider-home-simulations-list .slick-prev:before {
  content: "";
  background-image: url("/images/left.svg");
  width: 4rem;
  height: 4rem;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}
.container.slider-simulations .slider-type-simulation.slider .slick-list {
  padding: 0 !important;
}
.container.slider-simulations .slider-type-simulation.slider .slick-list .slick-slide .item {
  margin-right: 0;
  position: relative;
  padding-top: 60%;
}
.container.slider-simulations .slider-type-simulation.slider .slick-list .slick-slide .item .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots {
  bottom: 0;
  height: 5rem;
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li {
  width: 5rem;
  height: 5rem;
  opacity: 0.8;
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li button {
  border: 2px solid #fff;
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li.slick-active {
  opacity: 1;
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li.slick-active button {
  border: 3px solid #fff;
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li.slick-active button,
.container.slider-simulations .slider-type-simulation.slider .slick-dots li:hover button {
  opacity: 1;
  transform: scale(1, 1);
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li button {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  transition: all 0.5s ease;
  transform: scale(0.75, 0.75);
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li button img {
  display: block;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container.slider-simulations .slider-type-simulation.slider .slick-dots li button:before {
  display: block;
  position: relative;
  content: "";
  width: 100%;
  padding-top: 100%;
  height: auto;
}

@media (max-width: 991px) {
  .container.slider-simulations {
    padding-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 5.1rem;
    padding-bottom: 6.2rem;
    margin-bottom: 6.2rem;
  }
  .container.slider-simulations .text.generic {
    text-align: center;
  }
  .container.slider-simulations::before {
    width: 100%;
  }
  .container.slider-simulations .box-content .title {
    font-size: 2.9rem;
    text-align: center;
  }
  .container.slider-simulations .small-line:not(.sinBorder):before {
    left: 50%;
    transform: translateX(-50%);
  }
  .container.slider-simulations .wrap-btnDefault {
    margin: 0 auto;
    margin-top: 4.2rem;
  }
  .container.slider-simulations .wrap-btnDefault .btnDefault {
    padding: 0.9rem;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
  }
  .container.slider-simulations .section-simulations-wrap {
    padding: 0;
  }
  .container.slider-simulations .section-simulations-wrap .slick-arrow {
    transform: translateY(-1000%);
  }
  .container.slider-simulations .section-simulations-wrap .wrap-text {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
  }
  .container.slider-simulations .slider-home-simulations-list {
    width: 100%;
    max-width: 100%;
  }
  .container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item {
    flex-direction: column;
  }
  .container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .slider-simulation-item,
  .container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .wrap-text {
    width: 100%;
    padding: 0;
    margin-top: 3.3rem;
  }
  .container.slider-simulations .slider-home-simulations-list .wrap-slider-simulation-item .slider-simulation-item {
    margin-top: 0;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-list {
    padding: 0 !important;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-list .slick-slide .item {
    margin-right: 0;
    position: relative;
    padding-top: 60%;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-list .slick-slide .item .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots {
    bottom: 0;
    height: 3.5rem;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li button {
    border: 2px solid #fff;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li.slick-active {
    opacity: 1;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li.slick-active button {
    border: 3px solid #fff;
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li.slick-active button,
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li:hover button {
    opacity: 1;
    transform: scale(1, 1);
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li button {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    transition: all 0.5s ease;
    transform: scale(0.75, 0.75);
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li button img {
    display: block;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .container.slider-simulations .slider-type-simulation.slider .slick-dots li button:before {
    display: block;
    position: relative;
    content: "";
    width: 100%;
    padding-top: 100%;
    height: auto;
  }
}
.container.last-posts {
  padding: 0;
  max-width: unset;
  padding-bottom: 10.4rem;
}
.container.last-posts > div {
  position: relative;
}
.container.last-posts .wrap-container {
  padding-top: 0;
  padding-bottom: 0;
}
.container.last-posts .box-wall {
  position: absolute;
  width: 100%;
  height: 65%;
  padding: 0;
  top: 30%;
}
.container.last-posts .box-content {
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
  width: fit-content;
}
.container.last-posts .box-content .title {
  font-size: 4.5rem;
  margin-bottom: 6.5rem;
  color: #262626;
}
.container.last-posts .box-content .title::before {
  left: 50%;
  margin: 0 auto;
  bottom: -1.3rem;
  transform: translateX(-50%);
}
.container.last-posts .line-separation .line {
  background-color: #fff;
}
.container.last-posts .section-subtitle {
  color: #fff;
  -webkit-text-stroke: unset;
}
.container.last-posts .wrap-btnDefault {
  margin-top: 5rem;
}
.container.last-posts .container-blog {
  width: 100%;
  position: relative;
  margin: 0 auto;
  z-index: 1;
}
.container.last-posts .container-blog .box-container-blog .autor {
  margin-top: 0.4rem;
  font-family: "Montserrat-Italic";
  color: #575252;
}
.container.last-posts .container-blog .box-container-blog .wrap-btnDefault .btnDefault {
  padding: 1.4rem 7rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog {
  padding: 0 5rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  justify-content: center;
  flex-wrap: nowrap;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .wrap-item .wrap-img {
  padding-top: 64%;
  position: relative;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .wrap-item .wrap-img .img {
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
  object-fit: cover;
  height: 100%;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry {
  width: 100%;
  padding-right: 4rem;
  background-color: #fff;
  padding: 1.8rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .img {
  display: block;
  background-color: grey;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .block-text {
  width: 100%;
  background-color: #FFF;
  padding: 1rem;
  padding-bottom: 2rem;
  position: relative;
  flex-grow: 1;
  border-top: 5px solid #c70087;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .block-text .block-title {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  color: #262626;
  margin-top: 1rem;
  font-weight: normal;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .block-text .desc {
  margin-top: 1rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .block-text p {
  font-size: 1.5rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .block-text .author {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: #c70087;
  font-style: oblique;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .block-text hr {
  width: 100%;
  border-color: rgba(199, 0, 135, 0.5);
  margin-bottom: 0;
  border-width: 0.2rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .badge-blog {
  text-align: right;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .info .date-info {
  font-family: "Montserrat-Italic";
  font-size: 1.3rem;
  color: #c70087;
  line-height: 2.1rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text p,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  color: #333;
  line-height: 2.5rem;
  letter-spacing: 0 !important;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text .author,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text p .author,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic .author,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text .author {
  color: #575252;
  font-style: oblique;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text hr,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text p hr,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic hr,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text hr {
  display: block;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text table, .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text table tr,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text p table,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text p table tr,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic table,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic table tr,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text table,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text table tr {
  border: none;
  border-bottom: none;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text td,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text p td,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic td,
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .blog-content .generic.text td {
  background-image: none;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .aux-item {
  width: 100%;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .aux-img {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .aux-img .wrap-img {
  position: relative;
  width: 100%;
  padding-top: 63%;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry .aux-img .wrap-img .img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  box-shadow: none;
  transform: scale(1);
  transition: 0.3s transform;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry.isSecond {
  margin: 0 1.2rem;
}
.container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry:hover .aux-img .wrap-img .img {
  transform: scale(1.1);
  transition: 0.3s transform;
}

@media (max-width: 991px) {
  .container.last-posts {
    padding-bottom: 9.5rem;
    padding-top: 0;
  }
  .container.last-posts .box-wall {
    height: 67%;
  }
  .container.last-posts .scrollbar-home {
    position: relative;
    margin-top: 2.2rem;
  }
  .container.last-posts .wrap-container {
    padding: 0 1.5rem;
  }
  .container.last-posts .box-content .title {
    font-size: 2.9rem;
    margin-bottom: 0;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .slick-list {
    padding: 0 !important;
  }
  .container.last-posts .section-title {
    font-size: 6rem;
    color: #fff;
  }
  .container.last-posts .line-separation .line {
    background-color: #fff;
  }
  .container.last-posts .section-subtitle {
    color: #fff;
    -webkit-text-stroke: unset;
  }
  .container.last-posts .container-blog {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 0;
  }
  .container.last-posts .container-blog .box-container-blog .wrap-btnDefault .btnDefault {
    padding: 1.2rem 6rem;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog {
    width: 100%;
    padding: 2.2rem 1.5rem;
    padding-bottom: 0;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items {
    width: 100% !important;
    flex-wrap: nowrap;
    padding-top: 0;
    overflow: hidden;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .slick-track {
    height: 100%;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .slick-slide > div {
    height: 100%;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry {
    height: 100%;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .blog-entry.isSecond {
    margin: 0;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .wrap-item {
    width: 0;
    padding-right: 0;
    display: none;
  }
  .container.last-posts .container-blog .box-container-blog .content-blog .wrap-items .wrap-item.isFirst {
    display: flex;
    width: 100%;
  }
}
#contact_famaliving2023 {
  padding: 0 5rem;
  margin-bottom: 19rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-contact-info .email {
  display: none;
}
#contact_famaliving2023.home {
  margin-bottom: 10rem;
}
#contact_famaliving2023.home .limited-wrap {
  padding-top: 0;
}
#contact_famaliving2023.default .content-contact-wrap-home {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
#contact_famaliving2023.default .special-contact .part-down .wrap-contact-info .email {
  display: none;
}
#contact_famaliving2023.default .special-contact .part-down .wrap-title .title {
  width: 100%;
  max-width: 40rem;
}
#contact_famaliving2023.shops .special-contact .part-down .wrap-title .title {
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
}
#contact_famaliving2023.shops .special-contact .part-down .wrap-title .title.small-line:before {
  display: none;
}
#contact_famaliving2023.shops .special-contact .part-down .wrap-contact-info {
  font-family: "Montserrat-Regular";
  font-size: 1.7rem;
}
#contact_famaliving2023.shops .special-contact .part-down .wrap-contact-info .phone, #contact_famaliving2023.shops .special-contact .part-down .wrap-contact-info .email {
  display: none;
}
#contact_famaliving2023 .limited-wrap {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 16.4rem;
}
#contact_famaliving2023 .special-contact {
  padding: 0;
}
#contact_famaliving2023 .special-contact .part-down {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
#contact_famaliving2023 .special-contact .part-down .wrap-title {
  width: fit-content;
  min-width: 23rem;
  margin-bottom: 6rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-title .title {
  padding-right: 1.5rem;
  margin-bottom: 3.5rem;
  width: 65%;
  min-width: 26rem;
  max-width: 30rem;
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-title .title.large {
  font-family: "Montserrat-SemiBold";
  font-size: 4.5rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-title img {
  height: 40rem;
  width: 100%;
  object-fit: cover;
  margin-top: 6rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-contact-info {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  line-height: 2.3rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-contact-info.large {
  font-family: "Montserrat-Regular";
  font-size: 2.3rem;
  line-height: 4.1rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-contact-info a {
  color: inherit;
  transition: 0.2s color;
}
#contact_famaliving2023 .special-contact .part-down .wrap-contact-info a:hover {
  color: #c70087;
  transition: 0.2s color;
}
#contact_famaliving2023 .special-contact .part-down .wrap-content {
  width: 100%;
  background-color: #fff;
  padding: 0;
  padding-left: 3rem;
  margin-top: 2rem;
  margin-bottom: 6rem;
}
#contact_famaliving2023 .special-contact .part-down .wrap-content img {
  width: 100%;
  max-height: 25rem;
  /* height: max-content; */
  object-fit: cover;
  margin-bottom: 6rem;
}
#contact_famaliving2023 .special-contact .part-down .form {
  width: calc(100% + 3.5rem);
}
#contact_famaliving2023 .special-contact .part-down .form .input-box {
  padding-right: 3.5rem;
  margin-bottom: 3rem;
}
#contact_famaliving2023 .special-contact .part-down .form .input-box:last-of-type {
  margin-bottom: 2rem;
}
#contact_famaliving2023 .special-contact .part-down .form .wrap-privacy {
  width: calc(100% - 3.5rem);
  justify-content: space-between;
}
#contact_famaliving2023 .special-contact .part-down .form .input-box input + .label,
#contact_famaliving2023 .special-contact .part-down .form .input-box textarea + .label,
#contact_famaliving2023 .special-contact .part-down .form .select2 + .label {
  position: absolute;
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  top: 0;
  left: 1.7rem;
  color: #575252;
  background-color: #fff;
  padding: 0 1.1rem;
  pointer-events: none;
  transform: translateY(-50%);
  transform-origin: left;
  white-space: nowrap;
  margin: 0;
  transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
}
#contact_famaliving2023 .special-contact .part-down .form .form-group {
  position: relative;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy {
  width: 60%;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy .labelContainer {
  position: absolute;
  padding: 0;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy .textContainer {
  width: calc(100% - 3.2rem);
  font-family: "Montserrat-Regular";
  font-weight: normal;
  display: block;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy .textContainer .link {
  color: #c70087;
  text-decoration: none;
  --border-color: $colorCorporative;
  --border-width: 1px;
  --bottom-distance: 0px;
  display: inline;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0 var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy .textContainer .link:hover {
  background-size: 100% var(--border-width);
}
#contact_famaliving2023 .special-contact .part-down .form .privacy input + .labelContainer:after {
  content: "";
  position: absolute;
  z-index: 1;
  height: 0.4rem;
  left: 0.2rem;
  min-width: 0.8rem;
  top: 0.8rem;
  border: 0.2rem solid #c70087;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg) scale(0);
  transform-origin: left top;
  transition: all 0.2s ease 0s;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy input + .labelContainer:before {
  content: "";
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 1.3rem;
  height: 1.3rem;
  top: 0;
  border: 0.1rem solid #c70087;
  border-radius: 0 !important;
  background-color: transparent;
  transition: background-color 0.2s linear 0.1s;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy input[type=checkbox]:checked + .labelContainer:before {
  background-color: transparent;
  transition: background-color 0.2s linear 0s;
}
#contact_famaliving2023 .special-contact .part-down .form .privacy input[type=checkbox]:checked + .labelContainer:after {
  transform: translateY(-20%) rotate(-45deg) scale(1);
  transition: all 0.2s ease 0.1s;
}
#contact_famaliving2023 .special-contact .part-down .form .wrap-btnDefault {
  min-width: unset;
}
#contact_famaliving2023 .special-contact .part-down .form .wrap-btnDefault .btnDefault {
  font-size: 1.5rem;
  line-height: 1.7rem;
  padding: 1.4rem 6.6rem;
}
#contact_famaliving2023 .special-contact .wrap-departments {
  margin-bottom: -19rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments:after {
  content: "";
  width: 0.4rem;
  height: 5rem;
  background-color: #c70087;
  animation: MoveUpDown 2.5s linear infinite;
  position: absolute;
  right: 0;
}
@keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 100px;
  }
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left,
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right {
  width: 50%;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .section-title,
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .section-title {
  color: #fff;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .section-title .title-underline,
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .section-title .title-underline {
  width: 3.5rem;
  border-bottom: 3px solid;
  background: #fff;
  margin-top: 2.5rem;
  margin-bottom: 0;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left {
  display: flex;
  height: 74rem;
  align-items: center;
  position: sticky;
  top: calc(100vh - 74rem);
  width: 40%;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background-color: #c70087;
  height: 100%;
  width: 50vw;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .title {
  line-height: 7rem;
  text-align: left;
  margin-left: 4rem;
  color: white;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .title img {
  width: 100%;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .telf, #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .world {
  width: 12.4rem;
  height: 12.4rem;
  object-fit: contain;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .telf {
  position: absolute;
  top: 8.5rem;
  left: 0;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .world {
  position: absolute;
  right: 5rem;
  bottom: 8.5rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right {
  width: 60%;
  max-width: calc(60% - (100% - 1280px) / 2);
  max-height: 74rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 3rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right::-webkit-scrollbar {
  -webkit-appearance: none;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right::-webkit-scrollbar-button:increment,
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .contenedor::-webkit-scrollbar-button {
  display: none;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right::-webkit-scrollbar-thumb {
  border-radius: none !important;
  border: none;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country {
  border-top: 1px solid #c70087;
  text-transform: capitalize;
  font-family: "Montserrat-SemiBold";
  font-size: 2.5rem;
  margin-left: 4.3rem;
  padding: 0 3rem 0 0;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
  display: inherit;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country:hover {
  background-color: transparent;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country {
  flex-direction: column;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country.collapse:last-of-type {
  border-bottom: 1px solid #c70087;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .item {
  display: flex;
  padding: 25px;
  padding-left: 0;
  cursor: pointer;
  height: 8.5rem;
  padding-left: 2.3rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .item h2 {
  font-family: "Montserrat-SemiBold";
  font-size: 2.5rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country.collapse .nav.nav-country .country-content {
  height: 0;
  opacity: 0;
  display: none;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country.collapse:hover {
  background-color: #fff4fb;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country {
  flex-direction: column;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item {
  height: 5rem;
  font-size: 1.5rem;
  padding-left: 2.3rem;
  margin-left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: auto;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item:not(.isFiltered) {
  background-color: #fff4fb;
  transition: background-color 300ms ease-in;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item:not(.isFiltered):nth-child(2n) {
  background-color: transparent;
  transition: background-color 300ms ease-in;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item.d-none {
  display: none;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item.isFiltered {
  background-color: #fff4fb;
  transition: background-color 300ms ease-in;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item.isFiltered:nth-child(2n) {
  background-color: transparent;
  transition: background-color 300ms ease-in;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop {
  display: flex;
  width: 100%;
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .shop {
  width: 50%;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .shop.bold {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .phone {
  width: 40%;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .whatsapp {
  display: flex;
  align-items: center;
  width: 10%;
  justify-content: center;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .whatsapp img {
  height: 2rem;
  width: 2rem;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content {
  height: auto;
  opacity: 100%;
  display: block;
  position: relative;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content .wrap-list-form {
  position: absolute;
  left: 0;
  top: 0;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select {
  margin-top: 8rem;
  min-height: 10rem;
  max-height: 42rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 13px);
  /* Thin Scrollbar for firefox */
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select::-webkit-scrollbar:horizontal {
  height: 4px;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select::-webkit-scrollbar-track {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 8px;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select::-webkit-scrollbar-track-piece {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 8px;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select::-webkit-scrollbar-thumb:vertical {
  border-radius: 8px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
#contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select {
  scrollbar-color: #c70087 rgba(199, 0, 135, 0.32);
  scrollbar-width: thin;
}

@media (max-width: 991px) {
  #contact_famaliving2023 {
    padding: 0;
    padding-top: 0;
    padding-bottom: 6.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  #contact_famaliving2023 .special-contact .wrap-departments {
    margin-bottom: -6.5rem;
  }
  #contact_famaliving2023.home {
    margin-bottom: 0;
  }
  #contact_famaliving2023 .limited-wrap {
    max-width: 100%;
    padding-top: 9rem;
  }
  #contact_famaliving2023 .section-title {
    color: #c70087;
    letter-spacing: 0;
  }
  #contact_famaliving2023 .section-title .title {
    font-size: 2.5rem;
    line-height: 3.8rem;
  }
  #contact_famaliving2023 .section-title .title-underline {
    width: 3.5rem;
    border-bottom: 2px solid;
    background: #c70087;
    margin-top: 0.5rem;
    margin-bottom: 4rem;
  }
  #contact_famaliving2023 .section-title.mvl {
    display: block;
  }
  #contact_famaliving2023 .section-title.desc {
    display: none;
  }
  #contact_famaliving2023 .special-contact {
    padding: 0;
  }
  #contact_famaliving2023 .special-contact.default .part-down .wrap-contact-info {
    text-align: initial;
    font-size: 1.5rem;
    line-height: 2.3rem;
  }
  #contact_famaliving2023 .special-contact.default .part-down .wrap-title .box-content {
    text-align: initial;
  }
  #contact_famaliving2023 .special-contact.default .part-down .wrap-title .box-content .title::before {
    bottom: -1.5rem;
    left: 0;
    transform: none;
  }
  #contact_famaliving2023 .special-contact.default .part-down .wrap-title img {
    height: 14rem;
    width: 100%;
    margin-bottom: 4rem;
    margin-top: 5rem;
  }
  #contact_famaliving2023 .special-contact.default .part-down .wrap-content {
    padding: 0;
  }
  #contact_famaliving2023 .special-contact.default .part-down .wrap-content img {
    padding: 0;
    height: 35rem;
    object-fit: cover;
    width: 100%;
    margin-top: 0;
    max-height: unset;
  }
  #contact_famaliving2023 .special-contact .part-down {
    padding: 0;
    margin-top: 0;
    flex-direction: column;
  }
  #contact_famaliving2023 .special-contact .part-down .wrap-contact-info {
    text-align: center;
    font-size: 1.7rem;
    line-height: 2.7rem;
  }
  #contact_famaliving2023 .special-contact .part-down .wrap-title {
    width: auto;
    min-width: auto;
    margin: 0 auto;
  }
  #contact_famaliving2023 .special-contact .part-down .wrap-title .box-content {
    text-align: center;
  }
  #contact_famaliving2023 .special-contact .part-down .wrap-title .box-content .title {
    padding: 0;
    font-size: 2.9rem;
    margin-bottom: 5.5rem;
    width: 100%;
    max-width: unset;
  }
  #contact_famaliving2023 .special-contact .part-down .wrap-title .box-content .title::before {
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  #contact_famaliving2023 .special-contact .part-down .wrap-content {
    padding: 0;
    margin-bottom: 0;
  }
  #contact_famaliving2023 .special-contact .part-down .form {
    width: 100%;
    display: block !important;
    overflow: visible;
  }
  #contact_famaliving2023 .special-contact .part-down .form .input-box {
    padding-right: 0;
    margin-bottom: 2.4rem;
  }
  #contact_famaliving2023 .special-contact .part-down .form .input-box input {
    height: 4.5rem;
  }
  #contact_famaliving2023 .special-contact .part-down .form .input-box .label {
    left: 1rem !important;
  }
  #contact_famaliving2023 .special-contact .part-down .form .wrap-privacy {
    width: 100%;
    flex-direction: column;
  }
  #contact_famaliving2023 .special-contact .part-down .form .wrap-privacy .wrap-btnDefault {
    width: 100%;
  }
  #contact_famaliving2023 .special-contact .part-down .form .input-box input + .label,
  #contact_famaliving2023 .special-contact .part-down .form .input-box textarea + .label,
  #contact_famaliving2023 .special-contact .part-down .form .select2 + .label {
    font-size: 1.3rem;
  }
  #contact_famaliving2023 .special-contact .part-down .form .form-group {
    position: relative;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy {
    width: fit-content;
    margin-top: 0;
    margin-bottom: 4.3rem;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy .acept {
    position: absolute;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy .labelContainer {
    position: relative;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy .textContainer {
    width: calc(100% - 2rem);
    font-family: "Montserrat-Regular";
    font-weight: normal;
    display: block;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy .textContainer .link {
    color: #c70087;
    text-decoration: none;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy input + .labelContainer:after {
    content: "";
    position: absolute;
    z-index: 1;
    min-width: 0.7rem;
    height: 0.3rem;
    top: 1.1rem;
    left: 0.2rem;
    border: 0.2rem solid #c70087;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg) scale(0);
    transform-origin: left top;
    transition: all 0.2s ease 0s;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy input + .labelContainer:before {
    content: "";
    position: relative;
    display: inline-block;
    vertical-align: middle;
    min-width: 1.2rem;
    height: 1.2rem;
    top: 0;
    border: 0.1rem solid #575252;
    border-radius: 0 !important;
    background-color: transparent;
    transition: background-color 0.2s linear 0.1s;
    margin-top: 0.3rem;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy input[type=checkbox]:checked + .labelContainer:before {
    background-color: transparent;
    transition: background-color 0.2s linear 0s;
  }
  #contact_famaliving2023 .special-contact .part-down .form .privacy input[type=checkbox]:checked + .labelContainer:after {
    transform: translateY(-20%) rotate(-45deg) scale(1);
    transition: all 0.2s ease 0.1s;
  }
  #contact_famaliving2023 .special-contact .part-down .form .wrap-btnDefault {
    min-width: unset;
    margin: 0 auto;
    margin-right: 0;
  }
  #contact_famaliving2023 .special-contact .part-down .form .wrap-btnDefault .btnDefault {
    font-size: 1.5rem;
    line-height: 1.7rem;
    padding: 1.5rem 4.3rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 6.5rem;
    flex-direction: column;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments:after {
    content: none;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left,
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right {
    width: 100%;
    max-width: 100%;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .section-title,
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .section-title {
    color: #fff;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .section-title .title-underline,
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .section-title .title-underline {
    border-bottom: 2px solid;
    margin-top: 1.5rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left {
    width: calc(100% + 3rem);
    max-width: calc(100% + 3rem);
    background-color: #c70087;
    transform: translateX(-1.5rem);
    padding: 3.5rem;
    position: relative;
    top: 0;
    height: 50rem;
    margin-top: 5rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .title {
    margin-left: 0;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .telf {
    right: 3rem;
    left: auto;
    top: 2rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .telf, #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .world {
    width: 9rem;
    height: 9rem;
    object-fit: contain;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-left .world {
    position: absolute;
    right: auto;
    bottom: 3rem;
    left: 3rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right {
    padding: 0;
    padding-top: 3.7rem;
    max-height: unset;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .department {
    border-top: 1px solid #c70087;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .department:last-of-type {
    border-bottom: 1px solid #c70087;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .department .item {
    display: flex;
    padding: 1.5rem;
    padding-left: 0;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .department .item .num {
    font-size: 1.04rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .department .item .name {
    font-size: 1.9rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country {
    margin-left: 0;
    padding: 0;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country {
    flex-direction: column;
    padding-left: 0;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country:last-of-type {
    border-bottom: 1px solid #c70087;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .item {
    display: flex;
    padding: 25px;
    padding-left: 0;
    cursor: pointer;
    padding-left: 3rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .item h2 {
    font-family: "Montserrat-SemiBold";
    font-size: 2.5rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country {
    flex-direction: column;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item {
    height: auto;
    padding-top: 1.5rem;
    line-height: 3rem;
    padding-bottom: 1.5rem;
    padding-left: 3rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Montserrat-Regular";
    font-size: 1.5rem;
    position: relative;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .shop {
    width: 100%;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .shop.bold {
    font-family: "Montserrat-SemiBold";
    font-size: 1.5rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .phone {
    width: 100%;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .whatsapp {
    display: flex;
    align-items: center;
    width: 10%;
    justify-content: center;
    margin-right: 10%;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .shop-item .info-shop .whatsapp img {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    height: 2.5rem;
    width: 2.5rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content {
    height: auto;
    opacity: 100%;
    display: block;
    position: relative;
    padding-bottom: 1.5rem;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content .wrap-list-form {
    position: absolute;
    left: 0;
    width: 100%;
  }
  #contact_famaliving2023 .special-contact .wrap-departments .departments .part-right .country .nav.nav-country .country-content ul.with-select {
    margin-top: 8rem;
    height: 42rem;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
}
.section-instagram .wrap-container {
  padding: 0 5rem;
  margin-bottom: 4rem;
}
.section-instagram .hash-title {
  position: relative;
  left: 1.5rem;
}
.section-instagram .wrap-post-instagram {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2.3rem;
}
.section-instagram .wrap-post-instagram .item {
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;
}
.section-instagram .wrap-post-instagram .item .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-instagram .wrap-post-instagram .item.item-1 {
  grid-area: 1/1/3/3;
}
.section-instagram .wrap-post-instagram .item.item-8 {
  grid-area: 2/5/7/7;
}
.section-instagram .wrap-post-instagram .item a {
  width: 100%;
  height: 100%;
}
.section-instagram .wrap-post-instagram .item .content-post {
  width: 100%;
  height: 100%;
}
.section-instagram .wrap-post-instagram .item .wrap-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  color: #fff;
}
.section-instagram .wrap-post-instagram .item .wrap-content .instaVideo {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.section-instagram .wrap-post-instagram .item .wrap-content .instagram-media {
  color: #fff;
}
.section-instagram .wrap-post-instagram .item .wrap-content .img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: blur(0);
  transition: 0.3s 0.2s filter ease;
}
.section-instagram .wrap-post-instagram .item .wrap-content .instagram-info {
  width: 100%;
  height: auto;
  top: 50%;
  left: 0;
  position: absolute;
  color: #fff;
  padding: 3.1rem;
  z-index: 2;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.2s opacity ease;
}
.section-instagram .wrap-post-instagram .item .wrap-content .instagram-info .text {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
}
.section-instagram .wrap-post-instagram .item .wrap-content .instagram-info .text a {
  color: #fff;
}
.section-instagram .wrap-post-instagram .item .wrap-content:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(199, 0, 135, 0.4);
  opacity: 0;
  z-index: 1;
  transition: 0.3s 0.2s opacity ease;
}
.section-instagram .wrap-post-instagram .item .wrap-content.esVideo:after {
  content: "";
  width: 10%;
  height: 10%;
  max-width: 2.5rem;
  max-height: 2.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-image: url("/images/icon-videoInsta.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  z-index: 2;
  transition: 0.3s 0.2s opacity ease;
}
.section-instagram .wrap-post-instagram .item .instagram-info {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}
.section-instagram .wrap-post-instagram .item .instaVideo {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.section-instagram .wrap-post-instagram .item:hover .wrap-content:before, .section-instagram .wrap-post-instagram .item:hover .wrap-content.esVideo:after {
  opacity: 1;
  transition: 0.3s opacity ease;
  z-index: 1;
}
.section-instagram .wrap-post-instagram .item:hover .wrap-content .instagram-info {
  opacity: 1;
  z-index: 1;
  transition: 0.3s 0.2s opacity ease;
}
.section-instagram .wrap-post-instagram .item:hover .wrap-content .img {
  filter: blur(5px);
  transition: 0.3s filter ease;
}
.section-instagram .text.generic {
  text-align: center;
  width: 78%;
  margin: 0 auto;
  line-height: 2.5rem;
  margin-bottom: 3rem;
}
.section-instagram .text.generic b {
  font-family: "Montserrat-Bold" !important;
}
.section-instagram .subtitle {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  color: #c70087;
  text-transform: uppercase;
  line-height: 2.5rem;
  letter-spacing: 1.5px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 6.7rem;
  font-weight: normal;
}
.section-instagram .subtitle a b {
  color: #c70087;
}
.section-instagram .box-content {
  width: 100%;
}
.section-instagram .box-content .title {
  font-size: 5.5rem;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 3.9rem;
}
.section-instagram .box-content .title:before {
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 991px) {
  .section-instagram {
    margin-bottom: 7.1rem;
  }
  .section-instagram .hash-title {
    left: 0.5rem;
  }
  .section-instagram .wrap-container {
    padding: 0 1.5rem;
    margin-bottom: 0;
  }
  .section-instagram .wrap-post-instagram {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 0.9rem;
  }
  .section-instagram .wrap-post-instagram .item {
    aspect-ratio: 1;
  }
  .section-instagram .wrap-post-instagram .item .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .section-instagram .wrap-post-instagram .item.item-1 {
    grid-area: unset;
  }
  .section-instagram .text.generic {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .section-instagram .subtitle {
    margin-bottom: 3.6rem;
  }
  .section-instagram .box-content {
    width: 100%;
  }
  .section-instagram .box-content .title {
    font-size: 2.9rem;
    margin-bottom: 3.1rem;
    line-height: 3.9rem;
    font-family: "Montserrat-SemiBold";
  }
}

.list-stores .wrap-container {
  padding: 5rem;
  padding-top: 0;
  padding-bottom: 3.3rem;
}
.list-stores .wrap-container.first {
  padding-top: 16.4rem;
}
.list-stores .content {
  position: relative;
}
.list-stores .small-line::before {
  bottom: -2rem;
}
.list-stores .wrap-stores-country-xs {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  width: 100%;
  height: 100vh;
}
.list-stores .wrap-stores-country-xs:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.list-stores .wrap-stores-country-xs .wrap-countryXs {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  position: relative;
  top: 0;
  left: 0;
  color: #c70087;
  padding: 1.5rem 0;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .close {
  width: 5.5rem;
  height: 5.5rem;
  margin: 0 auto;
  margin-right: 0;
  position: relative;
  bottom: 0;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .close::before {
  content: "";
  position: absolute;
  background-image: url("/images/fl-close.svg");
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
  transform: translate(1.5rem, -1.5rem);
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .country-item {
  padding-right: 2.5rem;
  padding-left: 4rem;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .country-item .country-item-name {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  border-bottom: 1px solid rgba(199, 0, 135, 0.32);
  padding: 0.7rem 2rem;
  text-transform: uppercase;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .country-item:last-of-type .country-item-name {
  border-bottom: none;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .country-item.is-active .country-item-name {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 4rem);
  margin-right: 1.5rem;
  /* Thin Scrollbar for firefox */
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs::-webkit-scrollbar:horizontal {
  height: 4px;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs::-webkit-scrollbar-thumb {
  border-radius: 3px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs::-webkit-scrollbar-track {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 3px;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs::-webkit-scrollbar-track-piece {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 3px;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs::-webkit-scrollbar-thumb:vertical {
  border-radius: 3px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
.list-stores .wrap-stores-country-xs .wrap-countryXs .list-countrysXs {
  scrollbar-color: #c70087 rgba(199, 0, 135, 0.32);
  scrollbar-width: thin;
}
.list-stores .changeCountry {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: #c70087;
  text-decoration: underline;
}
.list-stores .limited-wrap {
  max-width: 1280px;
  margin: 0 auto;
}
.list-stores .header {
  width: 50%;
}
.list-stores .wrap-list-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.list-stores .wrap-list-form form.form-block {
  min-width: 33rem;
  width: 33rem;
  margin: 0 auto;
  margin-right: 0;
}
.list-stores .wrap-list-form .form-group {
  margin-bottom: 0;
}
.list-stores .wrap-stores-country {
  width: 30%;
  min-height: 32.75rem;
  display: block !important;
}
.list-stores .wrap-stores-country .country-item {
  position: relative;
  background-color: #FFF;
  transition: 0.2s all ease-in-out;
  width: 100%;
  text-align: initial;
}
.list-stores .wrap-stores-country .country-item:hover {
  background-color: #fff4fb;
  transition: 0.2s all ease-in-out;
}
.list-stores .wrap-stores-country .country-item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  left: 0;
  background-color: #fff4fb;
  top: -1px;
}
.list-stores .wrap-stores-country .country-item .country-item-name {
  border-bottom: solid 1px #e5e7e8;
  color: #c70087;
  font-size: 2.5rem;
  font-family: "Montserrat-SemiBold";
  padding: 1.7rem 1.3rem;
  text-transform: lowercase;
  margin-right: 1.65rem;
  position: relative;
  transition: 0.2s padding-left ease;
  pointer-events: none;
}
.list-stores .wrap-stores-country .country-item .country-item-name::first-letter {
  text-transform: uppercase;
}
.list-stores .wrap-stores-country .country-item .country-item-name::before {
  content: "";
  background-image: url("/images/fl-arrow-black.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 2;
  width: 0%;
  left: 2.3rem;
  top: 0;
  transform: translateX(-50%);
  height: 100%;
  background-position: center;
  transition: 0.2s width ease-in-out;
}
.list-stores .wrap-stores-country .country-item.is-active {
  background-color: #fff4fb;
}
.list-stores .wrap-stores-country .country-item.is-active .country-item-name {
  border: none;
  color: #262626;
  padding-left: 7.2rem;
  transition: 0.2s padding-left ease;
}
.list-stores .wrap-stores-country .country-item.is-active .country-item-name::before {
  width: 2rem;
  transition: 0.2s width ease-in-out;
}
.list-stores .wrap-stores-country .country-item.is-active::after {
  width: 100%;
}
.list-stores .wrap-stores-country .country-item:first-of-type.is-active::after {
  width: 0;
}
.list-stores .wrap-form {
  position: absolute;
  top: 0rem;
  z-index: 3;
  width: calc(70% - 1rem);
  padding-top: 3rem;
  right: 1.3rem;
  padding-bottom: 2rem;
  padding-right: 2.7rem;
  background-color: #fff4fb;
}
.list-stores .wrap-form .region-form-input {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  border: 1px solid #000 !important;
  height: 3.5rem;
  width: 25rem;
  background-color: #fff4fb;
  padding-left: 10px;
  border-radius: 0 !important;
}
.list-stores .wrap-form .region-form-label {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  position: absolute;
  top: -1rem;
  left: 1rem;
  z-index: 4;
  padding: 0px 5px;
  background-color: #fff4fb;
}
.list-stores .wrap-form form {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-right: 0;
}
.list-stores .content-list-stores {
  position: absolute;
  top: 0;
  height: auto;
  min-height: 100%;
  width: 100%;
  background-color: #fff4fb;
  padding: 4.2rem 5.7rem;
  padding-top: 9rem;
}
.list-stores .content-list-stores .stores-item {
  margin-bottom: 4.5rem;
}
.list-stores .content-list-stores .stores-item .stores-item-img {
  width: 30%;
  overflow: hidden;
}
.list-stores .content-list-stores .stores-item .stores-item-img .wrap-img {
  aspect-ratio: 1.8;
  overflow: hidden;
}
.list-stores .content-list-stores .stores-item .stores-item-img .wrap-img .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transform: scale(1);
  transition: 0.2s transform ease-in-out;
}
.list-stores .content-list-stores .stores-item:hover .stores-item-img .wrap-img img {
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}
.list-stores .content-list-stores .stores-item .stores-item-info {
  width: 70%;
  padding-left: 2.2rem;
  display: flex;
  flex-direction: column;
}
.list-stores .content-list-stores .stores-item .stores-item-info .store-name {
  font-family: "Montserrat-SemiBold";
  font-size: 2.1rem;
  color: #333;
  flex-grow: 1;
}
.list-stores .content-list-stores .stores-item .stores-item-info .store-name .color {
  color: #c70087;
  text-transform: uppercase;
}
.list-stores .content-list-stores .stores-item .stores-item-info .store-address {
  color: #000;
  line-height: 2.1rem;
}
.list-stores .content-list-stores .stores-item .stores-item-info .store-address .storeDist {
  color: darkgray;
  margin-top: 1rem;
}
.list-stores .content-list-stores .stores-item .stores-item-info .store-address {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
}
.list-stores .content-list-stores .stores-item .stores-item-info .store-schedule {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
}
.list-stores .content-list-stores.is-active {
  position: relative;
  display: flex;
  opacity: 1;
}
.list-stores .wrap-list-stores {
  width: 70%;
  position: relative;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  /* Thin Scrollbar for firefox */
}
.list-stores .wrap-list-stores::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.list-stores .wrap-list-stores::-webkit-scrollbar:horizontal {
  height: 4px;
}
.list-stores .wrap-list-stores::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
.list-stores .wrap-list-stores::-webkit-scrollbar-track {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 8px;
}
.list-stores .wrap-list-stores::-webkit-scrollbar-track-piece {
  background-color: rgba(199, 0, 135, 0.32);
  border-radius: 8px;
}
.list-stores .wrap-list-stores::-webkit-scrollbar-thumb:vertical {
  border-radius: 8px;
  border: 1px solid #c70087;
  background-color: #c70087;
}
.list-stores .wrap-list-stores {
  scrollbar-color: #c70087 rgba(199, 0, 135, 0.32);
  scrollbar-width: thin;
}

@media screen and (max-width: 991px) {
  .list-stores .title.small-line .in {
    display: none !important;
  }
  .list-stores .title.small-line.isVisible .in {
    display: inline !important;
  }
  .list-stores .wrap-container {
    padding: 1.5rem;
  }
  .list-stores .wrap-container.first {
    padding-top: 10.4rem;
  }
  .list-stores .wrap-container .header {
    width: 100%;
  }
  .list-stores .wrap-stores-country {
    display: none !important;
  }
  .list-stores .content {
    flex-direction: column;
  }
  .list-stores .wrap-list-form {
    width: 100%;
  }
  .list-stores .wrap-list-form form.form-block {
    width: 100%;
  }
  .list-stores .wrap-form {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 70rem;
    margin-bottom: 0;
    background-color: transparent;
    padding-top: 0;
    padding-right: 0;
  }
  .list-stores .wrap-form .region-form-input {
    width: 100%;
    background-color: #FFF;
  }
  .list-stores .wrap-form .region-form-label {
    background-color: #FFF;
  }
  .list-stores .wrap-form form {
    width: 100%;
    margin-right: unset;
    margin-left: 0;
    padding-right: 0;
  }
  .list-stores .wrap-stores-country-xs {
    position: fixed !important;
    top: 0;
  }
  .list-stores .wrap-stores-country-xs .wrap-countryXs .country-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    text-align: initial;
  }
  .list-stores .wrap-stores-country-xs .wrap-countryXs .country-item .js-countryXs.close {
    width: 5.5rem;
    height: 5.5rem;
  }
  .list-stores .wrap-stores-country-xs .wrap-countryXs .country-item .js-countryXs.close:before {
    width: 2rem;
    height: 2rem;
    top: 1rem;
    right: 2rem;
  }
  .list-stores .wrap-stores-country-xs .wrap-countryXs .country-item.is-active .country-item-name {
    font-family: "Montserrat-Bold";
    font-size: 1.5rem;
    color: #c70087;
  }
  .list-stores .wrap-stores-country-xs .wrap-countryXs .country-item .country-item-name {
    font-family: "Montserrat-SemiBold";
    font-size: 1.5rem;
    position: relative;
    padding: 2.6rem;
    padding-left: 3.5rem;
    color: #262626;
  }
  .list-stores .wrap-stores-country-xs .wrap-countryXs .country-item .country-item-name::before {
    content: "";
    position: absolute;
    background-image: url(/images/arrow-copy-2.svg);
    background-repeat: no-repeat;
    width: 1.3rem;
    height: 1.3rem;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    background-size: contain;
    background-position-x: right;
  }
  .list-stores .wrap-stores-country-xs .wrap-countryXs .country-item .country-item-num {
    font-family: "Montserrat-Regular";
    font-size: 1.3rem;
    position: absolute;
    left: 1rem;
    transform: translateY(-25%);
    color: #c70087;
  }
  .list-stores .wrap-list-stores {
    width: 100%;
  }
  .list-stores .content-list-stores {
    position: relative;
    width: 100%;
    padding: 0;
    background-color: #FFF;
  }
  .list-stores .content-list-stores .stores-item {
    flex-direction: column;
    margin-bottom: 3.5rem;
  }
  .list-stores .content-list-stores .stores-item:hover .stores-item-img .wrap-img .img {
    transform: scale(1);
    transition: unset;
  }
  .list-stores .content-list-stores .stores-item .stores-item-img {
    width: 100%;
  }
  .list-stores .content-list-stores .stores-item .stores-item-img .wrap-img {
    aspect-ratio: unset;
    margin-bottom: 1.7rem;
  }
  .list-stores .content-list-stores .stores-item .stores-item-img .wrap-img .img {
    transform: scale(1);
    transition: unset;
  }
  .list-stores .content-list-stores .stores-item .stores-item-info {
    width: 100%;
    padding-left: 0;
    padding-bottom: 3.9rem;
    border-bottom: 1px solid rgba(199, 0, 135, 0.32);
  }
  .list-stores .content-list-stores .stores-item .stores-item-info .store-name {
    display: flex;
    margin-bottom: 1.5rem;
  }
  .list-stores .content-list-stores .stores-item .stores-item-info .store-name > div:first-of-type {
    font-size: 1.5rem;
    line-height: 1;
    align-self: flex-end;
    display: none !important;
  }
  .list-stores .content-list-stores .stores-item .stores-item-info .store-name .color {
    line-height: 1.5rem;
    padding-left: 0;
    align-self: flex-end;
  }
}
.wrap-stores-near {
  display: flex;
  margin-bottom: 7rem;
}
.wrap-stores-near .content {
  max-width: 1380px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: nowrap !important;
}
.wrap-stores-near .header-part-content {
  min-width: 30rem;
  padding: 0;
  width: 20%;
  padding-right: 3rem;
}
.wrap-stores-near .wrap-address-title,
.wrap-stores-near .wrap-title {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  color: #c70087;
  line-height: 2.3rem;
  margin-bottom: 0.5rem;
}
.wrap-stores-near .wrap-address {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  color: #333;
  line-height: 2.3rem;
  position: relative;
}
.wrap-stores-near .wrap-address[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
.wrap-stores-near .wrap-address[data-title]:after {
  content: attr(data-title);
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  background-color: rgba(51, 51, 51, 0.6);
  color: #fff;
  line-height: 1.4;
  position: absolute;
  padding: 3px 20px;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: none;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
  top: 0;
  transform: translateY(-110%);
  left: 0;
  width: fit-content;
  height: auto;
  white-space: break-spaces;
  backdrop-filter: blur(5px);
}
.wrap-stores-near .wrap-stores-list.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.wrap-stores-near .wrap-stores-list.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
.wrap-stores-near .wrap-stores-list.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}
.wrap-stores-near .wrap-stores-list .store-item .wrap-img {
  aspect-ratio: 3/2;
  margin-bottom: 2.3rem;
  overflow: hidden;
}
.wrap-stores-near .wrap-stores-list .store-item .wrap-img:hover .img-responsive {
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}
.wrap-stores-near .wrap-stores-list .store-item .wrap-img .img-responsive {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: 0.2s transform ease-in-out;
}

@media (max-width: 991px) {
  .wrap-stores-near .content {
    flex-direction: column;
  }
  .wrap-stores-near .wrap-stores-list {
    display: flex;
    overflow: auto;
    padding-bottom: 2rem;
    width: calc(100% + 3rem);
    position: relative;
    left: -1.5rem;
  }
  .wrap-stores-near .wrap-stores-list .store-item {
    width: calc(85% - 1.5rem);
    min-width: calc(85% - 1.5rem);
  }
  .wrap-stores-near .wrap-stores-list .store-item:first-of-type {
    margin-left: 1.5rem;
  }
  .wrap-stores-near .wrap-stores-list .store-item:last-of-type {
    margin-right: 1.5rem;
  }
  .wrap-stores-near .wrap-stores-list .store-item .wrap-img:hover .img-responsive {
    transform: scale(1);
    transition: unset;
  }
  .wrap-stores-near .wrap-stores-list.grid-2 {
    display: flex;
    grid-gap: 1.6rem;
  }
  .wrap-stores-near .wrap-stores-list.grid-3 {
    display: flex;
    grid-gap: 1.6rem;
  }
  .wrap-stores-near .wrap-stores-list.grid-4 {
    display: flex;
    grid-gap: 1.6rem;
  }
}
#legals_famaliving2023 {
  padding: 0 5rem;
  margin-bottom: 13rem;
}
#legals_famaliving2023 .limited-wrap {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 16.4rem;
}
#legals_famaliving2023 .wrap-content .description {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  line-height: 2.3rem;
}
#legals_famaliving2023 .wrap-content .description h5 {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
#legals_famaliving2023 .wrap-content .description h6 {
  font-family: "Montserrat-SemiBold";
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}
#legals_famaliving2023 .wrap-content .description h2 {
  font-family: "Montserrat-SemiBold";
  font-size: 2.1rem;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
}
#legals_famaliving2023 .wrap-content .description.large {
  font-family: "Montserrat-Regular";
  font-size: 2.3rem;
  line-height: 4.1rem;
}
#legals_famaliving2023 .wrap-content .description p {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
}
#legals_famaliving2023 .wrap-content .description a {
  color: #c70087;
  text-decoration: underline;
  transition: 0.2s color;
}
#legals_famaliving2023 .wrap-content .description a:hover {
  color: #c70087;
  transition: 0.2s color;
}
#legals_famaliving2023 .wrap-content .description table td {
  padding-right: 5rem;
}
@media screen and (max-width: 991px) {
  #legals_famaliving2023 {
    padding: 0 1.5rem;
    margin-bottom: 10rem;
  }
  #legals_famaliving2023 .limited-wrap {
    padding-top: 12rem;
  }
}
@media screen and (max-width: 768px) {
  #legals_famaliving2023 .wrap-content .description table td {
    padding-right: 1.5rem;
  }
  #legals_famaliving2023 .wrap-content .description a {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 991px) {
  .main #legals_famaliving2023 .limited-wrap,
  .main .conteiner-content.simulations.famaliving {
    padding-top: 9rem;
  }
}
.conteiner-content.simulations {
  padding-top: 16.4rem;
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;
}
.conteiner-content.simulations .aux {
  height: 60rem;
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 0.9rem - 41%);
  background-color: #c70087;
}
.conteiner-content.simulations .limited-wrap {
  width: 100%;
  max-width: 1280px !important;
  margin: 0 auto;
}
.conteiner-content.simulations .limited-wrap .wrap-content {
  justify-content: space-between;
  margin-top: 5rem;
  padding-bottom: 10rem;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-left {
  width: 33%;
  min-width: 32rem;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list {
  border-bottom: 1px solid #c70087;
  border-top: 1px solid #c70087;
  padding: 3rem 0.5rem;
  margin: 5rem 0;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item {
  line-height: normal;
  padding: 1rem 3rem;
  padding-right: 0;
  font-size: 1.9rem;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item .list-text {
  position: relative;
  font-family: "Montserrat-SemiBold";
  font-size: 1.9rem;
  letter-spacing: 0;
  padding-top: 0;
  padding-left: 0;
  color: #575252;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item.isActive .list-text {
  position: relative;
  color: #c70087;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item.isActive .list-text::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #c70087;
  top: 50%;
  transform: translateY(-50%) translateX(-25px);
  position: absolute;
  transition: 0.3s all ease;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right {
  width: 59%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: auto;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down {
  display: flex;
  flex-direction: column;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .two {
  position: absolute;
  bottom: 5rem;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .two:hover .arrow-link::before {
  width: 12.5rem;
  height: 7rem;
  transform: translateY(50%);
  transition: 0.3s all ease;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .arrow-link {
  position: relative;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .arrow-link::before {
  content: "";
  position: absolute;
  background-image: url("/images/bttn-arrow-big.svg");
  width: 10.5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: -5rem;
  transform: translateY(50%);
  transition: 0.3s all ease;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 {
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 50rem;
  display: flex;
  background-color: #c70087;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry {
  padding-left: 10rem;
  display: none;
  align-self: center;
  position: relative;
  opacity: 0;
  transition: 0.3s 0.2s opacity ease;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry.isActive {
  opacity: 1;
  transition: 0.3s 0.2s opacity ease;
  display: block;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator {
  height: auto;
  min-height: unset;
  position: unset;
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .simulator-item-title {
  color: #fff;
  font-family: "Montserrat-SemiBold";
  font-size: 2.5rem;
  line-height: 3.3rem;
  padding: 0;
  padding-bottom: 2rem;
  margin-bottom: 0.5rem;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .simulator-item-desc {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #fff;
  padding: 0;
  min-height: unset;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .simulator-item-desc p {
  padding-bottom: 2rem;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .wrap-img {
  margin-top: -70rem;
  position: relative;
  top: 0;
  left: -0.5rem;
  width: 100%;
  margin-bottom: 3.7rem;
  max-height: 35rem;
  aspect-ratio: 16/9;
}
.conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .wrap-img img {
  box-shadow: 1px 1px 8px 1px #c70087;
  margin-left: 10px;
  width: calc(100% - 20px);
}
.conteiner-content.simulations .limited-wrap .section-title {
  margin-bottom: 4rem;
}
.conteiner-content.simulations .limited-wrap .section-title .title {
  font-size: 3.5rem;
  text-transform: none;
  font-family: "Montserrat-SemiBold";
  font-weight: normal;
  line-height: 4.3rem;
  text-align: left;
}
.conteiner-content.simulations .limited-wrap .section-title .title-underline {
  width: 3.5rem;
  border-bottom: 4px solid;
  background: #c70087;
  margin-top: 0.5rem;
  margin-bottom: 5.4rem;
}
.conteiner-content.simulations .limited-wrap .text.generic {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}
.conteiner-content.simulations .limited-wrap .description {
  height: 30rem;
  max-height: 30rem;
  overflow: hidden;
}

@media (max-width: 991px) {
  .conteiner-content.simulations {
    padding-top: 9.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    background-color: #fff;
  }
  .conteiner-content.simulations .aux {
    display: none;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content {
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
    padding-bottom: 0;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-left {
    width: 100%;
    min-width: unset;
    padding-bottom: 5.5rem;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list {
    display: none;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item {
    line-height: normal;
    padding: 1rem 3rem;
    padding-right: 0;
    font-size: 1.9rem;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item .list-text {
    position: relative;
    font-family: "Montserrat-SemiBold";
    font-size: 1.9rem;
    letter-spacing: 0;
    padding-top: 0;
    padding-left: 0;
    color: #575252;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item.isActive .list-text {
    position: relative;
    color: #c70087;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-left .menu-list .list-item.isActive .list-text::before {
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #c70087;
    top: 50%;
    transform: translateY(-50%) translateX(-25px);
    position: absolute;
    transition: 0.3s all ease;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    width: calc(100% + 3rem);
    transform: translateX(-1.5rem);
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .one {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .two {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 0;
    width: fit-content;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .two:hover .arrow-link::before {
    width: 10.5rem;
    height: 5rem;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .arrow-link {
    position: relative;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .part-down .arrow-link::before {
    content: "";
    position: absolute;
    background-image: url("/images/bttn-arrow-big.svg");
    width: 10.5rem;
    height: 5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: unset;
    transform: translateY(50%);
    transition: 0.3s all ease;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 {
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: auto;
    display: flex;
    background-color: transparent;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry {
    padding-left: 0;
    display: block;
    align-self: center;
    position: relative;
    opacity: 1;
    transition: 0.3s 0.2s opacity ease;
    background: linear-gradient(transparent 17%, #c70087 17%);
    margin-bottom: 5rem;
    padding: 0 1.5rem;
    overflow: hidden;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry:last-of-type {
    margin-bottom: 0;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry.isActive {
    opacity: 1;
    transition: 0.3s 0.2s opacity ease;
    display: block;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator {
    height: auto;
    min-height: unset;
    position: unset;
    margin-top: 0;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .simulator-item-title {
    color: #fff;
    font-family: "Montserrat-SemiBold";
    font-size: 2.5rem;
    line-height: 3.3rem;
    padding: 0;
    padding-bottom: 2rem;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .simulator-item-desc {
    font-family: "Montserrat-Regular";
    font-size: 1.5rem;
    line-height: 2.1rem;
    color: #fff;
    padding: 0;
    min-height: unset;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .simulator-item-desc p {
    padding-bottom: 2rem;
  }
  .conteiner-content.simulations .limited-wrap .wrap-content .part-right .simulator-main.fama_2021 .simulator-entry .simulator .wrap-img {
    margin-top: 0;
    position: relative;
    top: 0;
    left: 0;
    width: calc(100% - 3rem);
    margin: 0 auto;
    margin-bottom: 3rem;
    padding-top: 1rem;
  }
  .conteiner-content.simulations .limited-wrap .section-title .title {
    font-size: 2.5rem;
    line-height: 3.3rem;
  }
  .conteiner-content.simulations .limited-wrap .section-title .title-underline {
    width: 3.5rem;
    border-bottom: 2px solid;
    margin-bottom: 5.4rem;
  }
  .conteiner-content.simulations .limited-wrap .description {
    height: auto;
    max-height: unset;
    overflow: hidden;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}
.header-module.center {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .header-module {
    padding: 1.5rem;
  }
}

.common-header {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 16.4rem;
}
.common-header .small-line:not(.sinBorder)::before {
  bottom: -1.5rem;
}
.common-header .subtitle {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #c70087;
  margin-top: 3rem;
}
.common-header .description {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  margin-top: 2.5rem;
}
.common-header .header {
  display: flex;
  width: 100%;
}
.common-header .header .blog-img {
  display: flex;
  justify-content: flex-end;
}
.common-header .header .blog-img img {
  width: 80%;
}
@media (max-width: 991px) {
  .common-header {
    padding-top: 8.5rem;
  }
  .common-header .header {
    display: flex;
    flex-direction: column;
  }
  .common-header .header .blog-img {
    margin-top: 2rem;
  }
  .common-header .header .blog-img img {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .main .common-header {
    padding-top: 9rem;
  }
}
.section-empresa.garantee.section-limited {
  padding: 0 5rem;
}

.section-empresa.sofas.sofas-rscVerticalBlocks {
  padding-top: 0;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-content {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
  background-color: #fff4fb;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-title {
  width: 100%;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-title .title {
  text-align: center;
  font-size: 2.5rem;
  padding-right: 0;
  line-height: 3rem;
  letter-spacing: 2.21px;
  font-family: "Montserrat-ExtraBold";
}
.section-empresa.sofas.sofas-rscVerticalBlocks .limited-wrap {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 5rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list:before, .section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 2.9rem;
  height: 2.9rem;
  border-radius: 50%;
  background-color: #c70087;
  transform: translateX(-50%);
  z-index: 1;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list:after {
  bottom: 0;
  top: unset;
  left: 50%;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item {
  width: 100%;
  display: flex;
  position: relative;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-wrapper {
  position: absolute;
  width: 50%;
  height: 100%;
  max-width: 40rem;
  pointer-events: none;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-wrapper .wrap-image {
  height: 20rem;
  position: absolute;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-wrapper .wrap-image.topcenter {
  left: 50%;
  transform: translateX(-50%);
  top: -20rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-wrapper .wrap-image.bottomleft {
  left: 0;
  bottom: -20rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-wrapper .wrap-image.bottomright {
  right: 0;
  bottom: -20rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-wrapper .wrap-image.bottomcenter {
  left: 50%;
  transform: translateX(-50%);
  bottom: -20rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content {
  padding-left: 2rem;
  padding-top: 4rem;
  width: 50%;
  position: relative;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .num {
  font-size: 6.1rem;
  color: rgba(199, 0, 135, 0.2);
  font-family: "Montserrat-SemiBold";
  letter-spacing: 0;
  top: 0;
  position: absolute;
  left: 0;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .wrap-title {
  padding-bottom: 0;
  position: relative;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .wrap-title .title {
  text-align: left;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 3rem;
  letter-spacing: 2.21px;
  font-family: "Montserrat-Medium";
  color: #c70087;
  padding-bottom: 0;
  width: fit-content;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .wrap-text {
  position: relative;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .wrap-text u {
  color: #c70087;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .wrap-btn {
  float: left;
  margin-top: 3rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .text {
  padding-bottom: 2.6rem;
  padding-top: 2.6rem;
  max-width: 40rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .link a span {
  text-decoration: underline;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .link a span.corporative {
  text-decoration: none;
  color: #c70087;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item .item-content .link a span.corporative:after {
  content: "";
  width: 1.2rem;
  height: 0.8rem;
  display: inline-block;
  margin-left: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(/images/arrow-pink.svg);
  -webkit-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left {
  justify-content: flex-start;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left .item-content {
  border-right: 1px solid #c70087;
  padding-right: 9.6rem;
  overflow: hidden;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left .item-content .num {
  right: 7.6rem;
  z-index: 1;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left .item-content .aux-line {
  width: auto;
  height: 100%;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left .item-content .aux-line::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  background-color: #c70087;
  height: 1px;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left .item-content .wrap-title .title {
  padding-right: 2.5rem;
  position: relative;
  z-index: 1;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left:first-of-type .item-content {
  padding-top: 9rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.left:first-of-type .item-content .num {
  top: 5rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right {
  justify-content: flex-end;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right .item-wrapper {
  left: calc(50% + 9.6rem);
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right .item-content {
  border-left: 1px solid #c70087;
  transform: translateX(-1px);
  padding-left: 9.6rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right .item-content .num {
  left: 7.6rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right .item-content .wrap-title .title {
  background-color: unset;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right .item-content .wrap-title .title::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 7rem;
  background-color: #c70087;
  height: 1px;
  right: unset;
  left: -9.6rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right:first-of-type .item-content {
  padding-top: 9rem;
}
.section-empresa.sofas.sofas-rscVerticalBlocks .wrap-list .item.right:first-of-type .item-content .num {
  top: 5rem;
}

@media (max-width: 991px) {
  .section-empresa.garantee.section-limited {
    padding: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks {
    padding-top: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content {
    padding: 0 1.5rem 18rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .generic.text, .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .generic.text p, .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .generic {
    color: inherit;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-title {
    width: 100%;
    padding-bottom: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-title .title {
    font-size: 1.7rem;
    line-height: 3rem;
    letter-spacing: 1.5px;
    text-align: left;
    width: 90%;
    padding-bottom: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list {
    padding: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list:before, .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list:after {
    content: none;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item {
    width: 100%;
    display: flex;
    margin-bottom: 5rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item:last-of-type {
    margin-bottom: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-wrapper .wrap-image {
    height: 16rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-wrapper .wrap-image.topcenter {
    top: -8rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-wrapper .wrap-image.bottomright {
    right: 0;
    bottom: -5rem;
    height: 13rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content {
    padding-left: 2rem;
    padding-top: 4rem;
    position: relative;
    width: 100%;
    padding-right: 2.5rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .num {
    font-size: 6.1rem;
    color: rgba(199, 0, 135, 0.22);
    font-family: "Montserrat-SemiBold";
    letter-spacing: 0;
    top: 0;
    position: absolute;
    left: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .wrap-title {
    padding-bottom: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .wrap-title .title {
    text-align: left;
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 3rem;
    letter-spacing: 2.21px;
    font-family: "Montserrat-Medium";
    color: #c70087;
    padding-bottom: 0;
    width: 100%;
    background-color: transparent;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .text {
    padding-bottom: 2.6rem;
    padding-top: 2.6rem;
    font-size: 1.5rem !important;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .wrap-btn {
    display: block;
    width: 100%;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .wrap-text u {
    font-size: 1.5rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .wrap-link {
    width: 90%;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item .item-content .wrap-link .link::before {
    height: 1.9rem;
    bottom: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left {
    justify-content: flex-start;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left .item-wrapper {
    width: 100%;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left .item-content {
    border-right: none;
    padding-right: 0;
    overflow: hidden;
    padding-right: 2.5rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left .item-content .num {
    right: 7.6rem;
    z-index: 2;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left .item-content .aux-line {
    display: none;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left .item-content .wrap-title .title {
    padding-right: 0;
    position: relative;
    z-index: 1;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left:first-of-type .item-content {
    padding-top: 9rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.left:first-of-type .item-content .num {
    top: 5rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right {
    justify-content: flex-end;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right .item-wrapper {
    left: auto;
    right: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right .item-content {
    border-left: none;
    transform: translateX(0);
    padding-left: 2rem;
    padding-right: 2.5rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right .item-content .num {
    left: 0;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right .item-content .wrap-title .title {
    background-color: unset;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right .item-content .wrap-title .title::after {
    content: none;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right:first-of-type .item-content {
    padding-top: 9rem;
  }
  .section-empresa.sofas.sofas-rscVerticalBlocks.section-rsc-vertical-blocks .wrap-content .wrap-list .item.right:first-of-type .item-content .num {
    top: 5rem;
  }
}
.section-empresa.garantee .wrap-content {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
  position: relative;
}
.section-empresa.garantee .wrap-title {
  width: 100%;
}
.section-empresa.garantee .wrap-title .title {
  font-size: 3.5rem;
  padding-right: 0;
  line-height: 3rem;
  letter-spacing: 0;
  font-family: "Montserrat-Semibold";
  margin-bottom: 3.2rem;
  padding-bottom: 1rem;
}
.section-empresa.garantee .wrap-title .section-subtitle {
  max-width: 52rem;
  width: 42%;
}
.section-empresa.garantee .wrap-title .section-subtitle .subtitle {
  color: #c70087;
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  line-height: 2.5rem;
  margin-bottom: 4.7rem;
  text-transform: uppercase;
}
.section-empresa.garantee .limited-wrap {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.section-empresa.garantee .container-image {
  position: absolute;
}
.section-empresa.garantee .container-image.upper {
  bottom: 78%;
  left: calc(50% + 9.6rem);
}
.section-empresa.garantee .container-image.upper img {
  max-width: 42.3rem;
  width: 21vw;
}
.section-empresa.garantee .container-image.upper .wrap-btn {
  margin-top: 5.5rem;
}
.section-empresa.garantee .container-image.down {
  left: 0;
  bottom: 0;
  height: 70%;
  display: flex;
  align-items: flex-end;
}
.section-empresa.garantee .container-image.down img {
  width: 30vw;
  max-width: 45rem;
  object-fit: cover;
  height: 100%;
}
.section-empresa.garantee .wrap-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  position: relative;
}
.section-empresa.garantee .wrap-list .item {
  width: 55%;
}
.section-empresa.garantee .wrap-list .item.first {
  width: 100%;
}
.section-empresa.garantee .wrap-list .item .item-content {
  margin-top: 3.5rem;
}
.section-empresa.garantee .wrap-list .item .item-content .title {
  text-align: left;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 3rem;
  letter-spacing: 2.21px;
  font-family: "Montserrat-Medium";
  color: #c70087;
  padding-bottom: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1.5rem;
}
@media (max-width: 991px) {
  .section-empresa.garantee .wrap-content {
    position: relative;
    padding: 42rem 2rem 0;
  }
  .section-empresa.garantee .wrap-content .container-image {
    width: 100%;
  }
  .section-empresa.garantee .wrap-content .container-image.upper {
    top: -17rem;
    left: 0;
    display: flex;
    justify-content: center;
  }
  .section-empresa.garantee .wrap-content .container-image.upper img {
    max-width: none;
    width: calc(100vw - 4rem);
    max-height: 55rem;
    object-fit: cover;
  }
  .section-empresa.garantee .wrap-content .container-image.upper .wrap-btn {
    display: none;
  }
  .section-empresa.garantee .wrap-content .container-image.down {
    width: calc(100% + 4rem);
    align-self: center;
    position: relative;
  }
  .section-empresa.garantee .wrap-content .container-image.down img {
    width: 100%;
    max-width: none;
    object-fit: cover;
    height: 100%;
    max-height: 45rem;
  }
  .section-empresa.garantee .wrap-content .wrap-title .section-subtitle {
    max-width: initial;
    width: 100%;
  }
  .section-empresa.garantee .wrap-content .wrap-title .section-subtitle .subtitle {
    margin-bottom: 0;
  }
  .section-empresa.garantee .wrap-content .wrap-list {
    position: initial;
  }
  .section-empresa.garantee .wrap-content .wrap-list .item {
    width: 100%;
  }
  .section-empresa.garantee .wrap-content .wrap-btn {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 100%;
  }
}

#warranties_famaliving_2023 {
  padding: 0;
  padding-top: 15.6rem;
  padding-right: 5rem;
  padding-left: 5rem;
}
#warranties_famaliving_2023 .limited-wrap {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
#warranties_famaliving_2023 .limited-wrap .box-content {
  color: #333;
  letter-spacing: 0;
  padding: 0 10.3rem;
}
#warranties_famaliving_2023 .limited-wrap .box-content .title.small-line {
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 4.7rem;
  position: relative;
}
#warranties_famaliving_2023 .limited-wrap .box-content .small-line {
  margin-bottom: 5rem;
}
#warranties_famaliving_2023 .limited-wrap .box-content .small-line::before {
  bottom: -1.4rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block {
  padding: 6.9rem 10.3rem;
  margin-bottom: 7.5rem;
  position: relative;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-subtitle {
  font-size: 2.5rem;
  color: #c70087;
  margin-bottom: 2.4rem;
  margin-top: 0;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left {
  min-height: unset;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  max-width: 100%;
  width: 100%;
  line-height: 2.1rem;
  color: #575252;
  position: relative;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text b {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip {
  z-index: 10 !important;
  position: relative;
  display: inline-block;
  transition: color 0.3s;
  opacity: 1;
  font-size: 1.3rem;
  transition: 0.2s all ease;
  text-align: center;
  margin-left: 1rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip::before {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #575252;
  border-radius: 50%;
  transform: translate(-0.7rem, 0.2rem);
  left: 0;
  top: 0;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext {
  width: 34.2rem;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: left;
  padding: 3rem;
  position: absolute;
  z-index: 1;
  top: unset;
  left: unset;
  margin-left: -1rem;
  opacity: 0;
  transform: translate(-100%, -50%);
  transition: opacity 0.3s;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext .firstImgTooltip {
  max-height: 14rem;
  margin: 0 auto;
  margin-right: 0;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext .arrowTooltip {
  position: absolute;
  top: 59%;
  left: 47%;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext .secondImgTooltip {
  width: 15.4rem;
  max-height: unset;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip:hover {
  color: #c70087;
  transition: 0.2s all ease;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip:hover::before {
  border: 1px solid #c70087;
  transition: 0.2s all ease;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .input-box input {
  height: 5rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .input-box input + .label,
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .input-box textarea + .label,
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .select2 + .label {
  position: absolute;
  top: 0;
  font-size: 1.3rem;
  left: 1.7rem;
  color: #575252;
  background-color: #fff;
  padding: 0 1.1rem;
  pointer-events: none;
  transform: translateY(-50%);
  transform-origin: left;
  white-space: nowrap;
  margin: 0;
  transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty {
  width: calc(100% + 3.2rem);
  margin-top: 5.9rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .input-box {
  padding-right: 3.2rem;
  margin-bottom: 3.8rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .input-box .label {
  background-color: #fff;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .textarea .input-box {
  margin-bottom: 0;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-privacy {
  width: calc(100% - 3.2rem);
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-privacy .privacy {
  width: 100%;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-privacy .privacy .labelContainer .textContainer {
  font-family: "Montserrat-Medium";
  font-weight: normal;
  display: block;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-privacy .privacy .labelContainer .textContainer .link {
  color: #c70087;
  text-decoration: none;
  --border-color: #c70087;
  --border-width: 1px;
  --bottom-distance: 0px;
  display: inline;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0 var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-privacy .privacy .labelContainer .textContainer .link:hover {
  background-size: 100% var(--border-width);
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars {
  margin-bottom: 3rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .warranty-subtitle {
  margin-bottom: 1rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .rate {
  display: flex;
  position: relative;
  float: unset;
  flex-direction: row-reverse;
  justify-content: flex-end;
  height: 3.5rem;
  font-size: unset;
  color: transparent;
  background-color: #FFF;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .rate > input {
  z-index: -1;
  position: absolute;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .rate > label {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  color: transparent;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .rate:not(:checked) > label:before {
  content: "";
  background-image: url("/images/star-grey.svg");
  width: 3rem;
  height: 3rem;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .rate > input:hover ~ label:before {
  content: "";
  background-image: url("/images/star-orange.svg");
  filter: brightness(0.9);
  width: 3rem;
  height: 3rem;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .rate > input:checked ~ label:before {
  content: "";
  background-image: url("/images/star-orange.svg");
  width: 3rem;
  height: 3rem;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .buttons {
  width: calc(100% - 3.2rem);
  display: flex;
  justify-content: flex-end;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .buttons .wrap-btnDefault {
  min-width: unset;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .buttons .wrap-btnDefault .btnDefault {
  font-size: 1.5rem;
  line-height: 1.7rem;
  padding: 1.4rem 6.6rem;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block::after {
  content: "";
  background-image: url("/images/fl-circle-warranty.svg");
  width: 14rem;
  height: 14rem;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: -8rem;
  right: 4rem;
  z-index: 1;
}
#warranties_famaliving_2023 .limited-wrap .warranty-block::before {
  content: "6";
  width: 14rem;
  height: 14rem;
  font-family: "Montserrat-Bold";
  font-size: 6rem;
  color: #fff;
  position: absolute;
  top: -8rem;
  right: 4rem;
  transform: translate(6.5rem, 1.5rem);
  z-index: 2;
}

@media (max-width: 991px) {
  #warranties_famaliving_2023 {
    padding: 0 1.5rem;
    padding-top: 9.2rem;
    background-color: #fff4fb;
    padding-bottom: 3.3rem;
  }
  #warranties_famaliving_2023 .limited-wrap {
    max-width: 100%;
  }
  #warranties_famaliving_2023 .limited-wrap .box-content {
    padding: 0 1.5rem;
    padding-right: 0;
    max-width: 50%;
  }
  #warranties_famaliving_2023 .limited-wrap .box-content .title.small-line {
    font-size: 2.9rem;
    line-height: 3.8rem;
    margin-bottom: 4.7rem;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block {
    padding: 0 1.5rem;
    padding-top: 7.6rem;
    padding-bottom: 8.5rem;
    margin-bottom: 0;
    background-color: #FFF;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-subtitle {
    font-size: 2.5rem;
    color: #c70087;
    margin-bottom: 2.4rem;
    margin-top: 0;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left {
    min-height: unset;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text {
    font-family: "Montserrat-Regular";
    font-size: 1.5rem;
    max-width: 100%;
    width: 100%;
    line-height: 2.1rem;
    color: #575252;
    position: relative;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip {
    width: 1.5rem;
    height: 1.5rem;
    position: unset;
    color: #333;
    transition: 0.2s all ease;
    border: 1px solid #333;
    border-radius: 50%;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip::before {
    content: none;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip:hover {
    color: #c70087;
    transition: 0.2s all ease;
    border: 1px solid #c70087;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext {
    width: 100%;
    transform: translate(0, 1rem);
    left: 0;
    margin-left: 0;
    padding: 1.5rem;
    z-index: 3;
    font-size: 1.5rem;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext .firstImgTooltip {
    max-height: 14rem;
    margin: 0 auto;
    margin-right: 0;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext .arrowTooltip {
    transform: rotate(-20deg);
    position: absolute;
    top: 53%;
    left: 57%;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip .tooltiptext .secondImgTooltip {
    width: 70%;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip:hover {
    color: #c70087;
    border: 1px solid #c70087;
    transition: 0.2s all ease;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block .warranty-block-left .warranty-text .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form {
    display: block;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .privacy input[type=checkbox] {
    height: 5rem;
    width: 3rem;
    position: absolute;
    z-index: 10;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .privacy .labelContainer {
    position: relative;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .input-box input + .label,
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .input-box textarea + .label,
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .select2 + .label {
    position: absolute;
    top: 0;
    font-size: 1.3rem;
    left: 1.7rem;
    color: #575252;
    background-color: #fff;
    padding: 0 1.1rem;
    pointer-events: none;
    transform: translateY(-50%);
    transform-origin: left;
    white-space: nowrap;
    margin: 0;
    transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty {
    width: 100%;
    margin-top: 5.9rem;
    flex-direction: column;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .input-box {
    padding-right: 0;
    margin-bottom: 3.4rem;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .input-box .label {
    background-color: #fff;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .textarea .input-box {
    margin-bottom: 0;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-privacy {
    width: 100%;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars {
    width: 100%;
    margin-bottom: 3rem;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .wrap-rate-stars .warranty-subtitle {
    margin-bottom: 1rem;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block #garantias_famaliving2023.garantias .form .warranty-block-warranty .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block::after {
    content: "";
    background-image: url(/images/fl-circle-warranty.svg);
    width: 11.1rem;
    height: 11.1rem;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: -5.5rem;
    right: 0;
    z-index: 1;
  }
  #warranties_famaliving_2023 .limited-wrap .warranty-block::before {
    font-family: "Montserrat-Bold";
    font-size: 5rem;
    content: "6";
    width: 5.55rem;
    height: 11.1rem;
    color: #fff;
    position: absolute;
    top: -5.5rem;
    transform: translate(-0.5rem, 1.5rem);
    z-index: 2;
    right: 0;
  }
}
.famaliving-corp-text .corp-text:first-of-type {
  padding-top: 16.4rem;
}
.famaliving-corp-text .container {
  padding: 5rem;
}
.famaliving-corp-text .part-up {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(18rem, auto);
  grid-gap: 1rem;
}

@media (max-width: 991px) {
  .famaliving-corp-text .corp-text:first-of-type {
    padding-top: 9.2rem;
  }
  .famaliving-corp-text .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.new-store .box-content .title {
  margin-bottom: 2.5rem;
}
@media (max-width: 991px) {
  .new-store .box-content .title {
    margin-bottom: 1.5rem;
    width: 75%;
    max-width: 40rem;
  }
}
.new-store .wrap-container {
  padding: 0 5rem;
}
.new-store .wrap-container:first-of-type {
  padding-top: 16.4rem;
}
.new-store .wrap-container .content.primary {
  flex-wrap: wrap;
  max-width: 1380px;
  margin: 0 auto;
}
.new-store .wrap-container.content-and-slider-image-with-price {
  padding-top: 0;
  padding: 0;
  padding-bottom: 5rem;
}
.new-store .wrap-container.content-and-slider-image-with-price:first-of-type {
  padding-top: 0 !important;
}
.new-store .subtitle.text-subtitle {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  line-height: 2.4rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #c70087;
  max-width: 29.6rem;
  margin-bottom: 2rem !important;
  font-weight: normal;
}
@media (max-width: 991px) {
  .new-store .subtitle.text-subtitle {
    line-height: 2.3rem;
    margin-bottom: 2.5rem;
    width: 80%;
    max-width: 40rem;
  }
}
.new-store .wrap-sidebar {
  width: 20%;
  min-width: 30rem;
  padding: 0;
  margin-bottom: 10.6rem;
}
.new-store .wrap-sidebar .list-categores {
  position: sticky;
  top: 12.4rem;
  padding-right: 3rem;
  margin-bottom: 0;
}
.new-store .wrap-sidebar .list-categores .list-categores-link {
  cursor: pointer;
}
.new-store .wrap-sidebar .list-categores .list-categores-link.is-active .list-categores-item {
  color: #c70087;
  background-color: #fff4fb;
  transition: 0.2s all ease-in-out;
  width: 100%;
}
.new-store .wrap-sidebar .list-categores .list-categores-item {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  color: #333;
  transition: 0.2s color ease-in-out;
  padding: 1rem 2.8rem 1rem 2rem;
  text-align: initial;
  width: 100%;
}
.new-store .wrap-sidebar .list-categores .list-categores-item.isSelected {
  color: #c70087;
  background-color: #fff4fb;
}
.new-store .wrap-sidebar .list-categores .list-categores-item .list-categores-item-content {
  --border-color: #c70087;
  --border-width: 1px;
  --bottom-distance: 0px; /* you can increase this */
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0% var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
  white-space: pre-wrap;
}
.new-store .wrap-sidebar .list-categores .list-categores-item.is-active {
  color: #c70087;
  background-color: #fff4fb;
}
.new-store .wrap-sidebar .list-categores .list-categores-item.change-store {
  color: #1197a5;
  text-decoration: underline;
}
.new-store .wrap-sidebar .list-categores .list-categores-item:hover {
  color: #c70087;
  transition: 0.1s all ease-in-out;
}
.new-store .wrap-sidebar .list-categores .list-categores-item:hover .list-categores-item-content {
  transition: 0.2s all ease-in-out;
  background-size: 100% var(--border-width);
}
.new-store .wrap-content {
  padding-right: 0;
  width: calc(100% - 30rem);
}
.new-store .wrap-content article {
  margin-bottom: 10.7rem;
  position: relative;
  max-width: 1080px;
}
.new-store .wrap-content article.article-schedule {
  z-index: 2;
  margin-bottom: 8.3rem;
}
.new-store .wrap-content article.article-schedule .box-content .title {
  margin-bottom: 4.7rem;
}
.new-store .wrap-content article.article-ubication {
  margin-bottom: 10.7rem;
}
.new-store .wrap-content article.article-slider .section *:empty {
  display: none;
}
.new-store .wrap-content article.article-slider .section:nth-child(even) .section-content {
  margin-left: 2.45rem;
  margin-right: 0;
}
.new-store .wrap-content article.article-slider .section:nth-child(odd) .section-content {
  margin-right: 2.45rem;
  margin-left: 0;
}
.new-store .wrap-content article.article-slider .section .wrap-img {
  margin-bottom: 3.2rem;
  margin-top: 2.5rem;
}
.new-store .wrap-content article.article-slider .section .wrap-img .img-responsive {
  display: block;
  max-width: 66.6666666667%;
}
.new-store .wrap-content article.article-slider .mygallerybox.open .swiper-slid {
  width: 100% !important;
}
.new-store .wrap-content article.article-slider .pasadorPost .swiper-wrapper .swiper-slide:last-of-type.swiper-slide-next {
  opacity: 1;
}
.new-store .wrap-content article.article-slider .pasadorPost .swiper-wrapper .swiper-slide.swiper-slide-next {
  opacity: 0.4;
}
.new-store .wrap-content .content {
  justify-content: center;
  flex-wrap: wrap;
}
.new-store .wrap-content .limited {
  margin: 0 auto;
}
.new-store .wrap-content .section {
  width: 50%;
  position: relative;
}
.new-store .wrap-content .section-right,
.new-store .wrap-content .section-left,
.new-store .wrap-content .section-content {
  width: calc(100% - 4.9rem);
  height: 100%;
  position: relative;
  margin: 0 auto;
}
.new-store .wrap-content .section-right {
  margin-right: 4.9rem;
  margin-left: auto;
}
.new-store .wrap-content .section-left {
  margin-left: 4.9rem;
}
.new-store .wrap-content .section-map {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
}
.new-store .wrap-content .section-map #mapStore {
  aspect-ratio: 3/2;
}
.new-store .wrap-content .section-map .part-right {
  width: 60%;
}
.new-store .wrap-content .section-map .part-right .wrap-map {
  width: 100%;
  aspect-ratio: 3/2;
  box-shadow: 0px 0px 10px 0px darkgray;
}
.new-store .wrap-content .section-map .part-left {
  width: 40%;
  padding-left: 4.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.new-store .wrap-content .section-map .part-left .map-contact .title,
.new-store .wrap-content .section-map .part-left .map-address .title {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  color: #c70087;
  line-height: 2.3rem;
}
.new-store .wrap-content .section-map .part-left .map-contact .name,
.new-store .wrap-content .section-map .part-left .map-address .name {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  color: #333;
  text-transform: uppercase;
  line-height: 2.3rem;
}
.new-store .wrap-content .section-map .part-left .map-contact .contact,
.new-store .wrap-content .section-map .part-left .map-address .contact {
  margin-bottom: 4.1rem;
}
.new-store .wrap-content .section-map .part-left .map-contact .address, .new-store .wrap-content .section-map .part-left .map-contact .address a,
.new-store .wrap-content .section-map .part-left .map-contact .contact, .new-store .wrap-content .section-map .part-left .map-contact .contact a,
.new-store .wrap-content .section-map .part-left .map-address .address,
.new-store .wrap-content .section-map .part-left .map-address .address a,
.new-store .wrap-content .section-map .part-left .map-address .contact,
.new-store .wrap-content .section-map .part-left .map-address .contact a {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  color: #333;
}
.new-store .wrap-content .section-map .part-left .map-contact .wrap-whatsapp .whatsapp,
.new-store .wrap-content .section-map .part-left .map-address .wrap-whatsapp .whatsapp {
  position: relative;
  padding-left: 5rem;
  height: 4rem;
}
.new-store .wrap-content .section-map .part-left .map-contact .wrap-whatsapp .whatsapp::before,
.new-store .wrap-content .section-map .part-left .map-address .wrap-whatsapp .whatsapp::before {
  content: "";
  background-image: url("/images/icon-whatsapp.svg");
  width: 4rem;
  height: 4rem;
  background-size: contain;
  background-position: center;
  position: absolute;
  background-repeat: no-repeat;
  left: 0;
}
.new-store .wrap-content .section-map .part-left .map-contact .wrap-whatsapp a span,
.new-store .wrap-content .section-map .part-left .map-address .wrap-whatsapp a span {
  color: #4caf50;
  text-decoration: none;
  line-height: 2rem;
  --border-color: #4caf50;
  --border-width: 1px;
  --bottom-distance: 0px;
  display: inline;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 100% var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
  letter-spacing: 1.5px;
}
.new-store .wrap-content .section-map .part-left .map-contact .wrap-whatsapp a:hover span,
.new-store .wrap-content .section-map .part-left .map-address .wrap-whatsapp a:hover span {
  background-size: 0 var(--border-width);
}
.new-store .wrap-content .section-map .part-left .wrap-btnDefault {
  min-width: unset;
  width: fit-content;
}
.new-store .wrap-content .section-map .part-left .wrap-btnDefault .btnDefault {
  width: fit-content;
  padding: 1.4rem 3.7rem;
}
.new-store .wrap-content .section-map .part-left .wrap-address {
  margin-bottom: 2.2rem;
}
.new-store .wrap-content .bg-rose::before {
  content: "";
  position: absolute;
  top: 0;
  left: unset;
  width: 100%;
  height: 100%;
  background-color: #fff4fb;
}
.new-store .wrap-slider-full {
  width: 100%;
  height: fit-content;
  margin-bottom: 5.6rem;
}
.new-store .wrap-slider-full .aux-slider.wrap-slider-content {
  max-width: 1080px;
  min-height: 40rem;
  margin: 0 auto;
  width: calc(100% - 3.5rem);
}
.new-store .wrap-slider-full .aux-slider.wrap-slider-content.isOne .control.control-prev,
.new-store .wrap-slider-full .aux-slider.wrap-slider-content.isOne .control.control-next {
  display: none !important;
}
.new-store .wrap-slider-full .aux-slider.wrap-slider-content.store-detail-store-wrap {
  min-height: unset;
}
.new-store .wrap-slider-full .wrap-slider {
  padding: 0;
  width: calc(100% + 5rem);
  left: 0;
}
.new-store .wrap-slider-full .wrap-slider .swiper-slide {
  margin-right: 2.2rem !important;
}
.new-store .wrap-slider-full .wrap-slider .img-responsive {
  max-height: 40rem;
}
.new-store .wrap-slider-full .wrap-slider.slider-detail-store .swiper-slide {
  aspect-ratio: 3/2;
}
.new-store .wrap-slider-full .wrap-slider.slider-detail-store .img-responsive {
  max-height: unset;
  max-width: fit-content;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.new-store .wrap-slider-full .wrap-slider.slider-detail-store .wrap-img {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-store a.link {
  color: #c70087;
}
.new-store a.link .link-down {
  width: fit-content;
  position: relative;
  padding-right: 2.2rem;
  margin-top: 1.8rem;
}
.new-store a.link .link-down::before {
  content: "";
  position: absolute;
  color: #c70087;
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid #c70087;
  margin-top: 0;
  transition: 0.2s margin-top ease-in-out;
  transform: rotate(45deg);
  border-top: none;
  border-left: none;
  right: 0;
}
.new-store a.link .link-down:hover::before {
  margin-top: 0.5rem;
  transition: 0.2s margin-top ease-in-out;
}
.new-store .section.bg-rose {
  height: calc(100% - 3.6rem);
}
.new-store .section.bg-rose.is-open .store-schedule-content {
  position: relative;
  z-index: 1;
  max-height: 9999px;
  opacity: 1;
  transition: 1s max-height linear;
}
.new-store .section.bg-rose.is-open .srore-schedule-info-content::before {
  transition: 0.2s transform ease-in-out;
  margin-top: 0;
  transform: translateX(calc(-50% - 0.4rem)) rotate(225deg);
}
.new-store .section.bg-rose .store-schedule {
  margin-bottom: 2.6rem;
  position: relative;
}
.new-store .section.bg-rose .store-schedule::after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #c70087;
  width: 100%;
  margin-top: 1.8rem;
}
.new-store .section.bg-rose .section-left {
  padding: 3.2rem 4rem 2.1rem 3.5rem;
  position: unset;
  margin: 0;
  width: 100%;
}
.new-store .section.bg-rose .store-schedule-title {
  font-family: "Montserrat-Medium";
  font-size: 1.7rem;
  letter-spacing: 0.5rem;
  color: #c70087;
  margin-bottom: 2.1rem;
  line-height: 1.9rem;
  position: relative;
}
.new-store .section.bg-rose .store-schedule-schedule {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  line-height: 2.1rem;
  position: relative;
}
.new-store .section.bg-rose .store-schedule-address {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  line-height: 2.1rem;
  position: relative;
}
.new-store .section.bg-rose .store-schedule-address::after {
  content: none;
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: 0;
  z-index: 1;
  background: url("/images/fl-arrow-rose.svg") repeat-x transparent top center;
  right: 1.5rem;
  top: 50%;
  left: unset;
  transform: translateY(-50%);
}
.new-store .section.bg-rose .srore-schedule-info {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  position: relative;
  line-height: 2.1rem;
  color: #000;
  transition: 0.2s color ease-in-out;
  position: relative;
  width: fit-content;
  padding-right: 2.8rem;
}
.new-store .section.bg-rose .srore-schedule-info:before {
  content: "";
  position: absolute;
  color: #000;
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid #000;
  margin-top: 0;
  transition: 0.4s transform ease-in-out, 0.2s margin-top ease-in-out;
  transform: rotate(45deg) translateX(calc(-50% - 0.4rem));
  border-top: none;
  border-left: none;
  right: 0;
  top: 50%;
}
.new-store .section.bg-rose .srore-schedule-info:hover {
  color: #c70087;
  transition: 0.2s color ease-in-out;
}
.new-store .section.bg-rose .srore-schedule-info:hover::before {
  margin-top: 0.5rem;
  transition: 0.2s all ease-in-out;
  border-color: #c70087;
}
.new-store .section.bg-rose .store-schedule-content {
  position: absolute;
  background-color: #fff4fb;
  width: 100%;
  left: 0;
  padding-top: 2rem;
  height: auto;
  max-height: 0;
  opacity: 0;
  z-index: -1;
}
.new-store .section.bg-rose .store-schedule-content .mondayFriday,
.new-store .section.bg-rose .store-schedule-content .saturday {
  line-height: 2.1rem;
}
.new-store .section.bg-rose .store-schedule-content .mondayFriday .mondayFriday-title,
.new-store .section.bg-rose .store-schedule-content .mondayFriday .saturday-title,
.new-store .section.bg-rose .store-schedule-content .saturday .mondayFriday-title,
.new-store .section.bg-rose .store-schedule-content .saturday .saturday-title {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  width: 35%;
  min-width: 15rem;
}

@media (min-width: 1750px) {
  .new-store .wrap-sidebar {
    width: calc(10% + 5rem);
    transform: none;
  }
  .new-store .wrap-sidebar .list-categores {
    padding-right: 3rem;
  }
  .new-store .wrap-sidebar .list-categores .list-categores-item {
    white-space: nowrap;
    width: 100%;
  }
  .new-store .wrap-content {
    width: calc(100% - 30rem);
  }
}
@media (max-width: 991px) {
  .new-store .wrap-container:first-of-type {
    padding-top: 10.4rem;
  }
  .new-store .wrap-sidebar {
    display: none;
    transform: none;
  }
  .new-store .wrap-sidebar .list-categores {
    padding-right: 0%;
  }
  .new-store .wrap-sidebar .list-categores .list-categores-item {
    white-space: nowrap;
    width: 100%;
  }
  .new-store .wrap-content {
    width: 100%;
    padding-right: 0;
  }
  .new-store .wrap-container article {
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-container article.article-products {
    width: 100%;
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-container article.article-schedule {
    width: 100%;
    padding: 0;
    margin-bottom: 2.7rem;
  }
  .new-store .wrap-container article.article-schedule .section {
    width: 100%;
  }
  .new-store .wrap-container article.article-schedule .section-right {
    width: 100%;
  }
  .new-store .wrap-container article.article-schedule .box-content .title {
    margin-bottom: 3.4rem;
  }
  .new-store .wrap-container article .section.bg-rose {
    width: 90%;
    right: 0;
    margin: 0 auto;
    margin-left: 0;
  }
  .new-store .wrap-container article .section.bg-rose .srore-schedule-info:hover {
    margin-top: 0;
    transition: 0.2s all ease-in-out;
    border-color: #000;
  }
  .new-store .wrap-container article .section.bg-rose:not(.is-open) .srore-schedule-info {
    color: #000;
  }
  .new-store .wrap-container article .section.bg-rose:not(.is-open) .srore-schedule-info::before {
    margin-top: 0;
    transition: 0.2s all ease-in-out;
    border-color: #000;
  }
  .new-store .wrap-container article .section.bg-rose.is-open .srore-schedule-info {
    color: #c70087;
    transition: 0.2s all ease-in-out;
  }
  .new-store .wrap-container article .section.bg-rose.is-open .srore-schedule-info::before {
    margin-top: 0.5rem;
    transition: 0.2s all ease-in-out;
    border-color: #c70087;
  }
  .new-store .wrap-container article .section.bg-rose::before {
    width: 100%;
    right: 0;
  }
  .new-store .wrap-container article .section.bg-rose .section-left {
    padding: 2.9rem 3.1rem 3.1rem 2.1rem;
    width: 100%;
    margin: 0;
  }
  .new-store .wrap-container article.article-slider {
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-container article.article-slider .section1 {
    flex-direction: column;
  }
  .new-store .wrap-container article.article-slider .section {
    width: 100%;
  }
  .new-store .wrap-container article.article-slider .section .section-content {
    width: 100%;
    margin: 0 !important;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full {
    margin-bottom: 3.2rem;
    width: 100%;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content {
    min-height: unset;
    transform: unset;
    width: calc(100% + 3rem);
    left: -1.5rem;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .control.control-next,
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .control.control-prev {
    display: none;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .pasadorPost .swiper-wrapper .swiper-slide {
    width: auto;
    max-width: 100%;
    margin-right: 0 !important;
    padding-left: 0.7rem;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .pasadorPost .swiper-wrapper .swiper-slide:first-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .pasadorPost .swiper-wrapper .swiper-slide:last-of-type {
    margin-right: 0 !important;
    padding-left: 0.7rem;
    padding-right: 1.5rem;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .pasadorPost .swiper-wrapper .swiper-slide:last-of-type.swiper-slide-next {
    opacity: 1;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .pasadorPost .swiper-wrapper .swiper-slide.swiper-slide-next {
    opacity: 0.4;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .pasadorPost .swiper-wrapper .swiper-slide .img-responsive {
    width: 100%;
    object-fit: cover;
    max-height: unset;
    min-height: unset;
  }
  .new-store .wrap-container article.article-slider .wrap-slider-full .aux-slider.wrap-slider-content .pasadorPost .swiper-wrapper .swiper-slide.slider-detail-store .img-responsive {
    max-width: 100%;
  }
  .new-store .wrap-container article.article-ubication {
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-container article.article-ubication .content {
    flex-direction: column;
  }
  .new-store .wrap-container article.article-ubication .part-right {
    width: 100%;
    margin-bottom: 5.3rem;
  }
  .new-store .wrap-container article.article-ubication .part-left {
    width: 100%;
    padding: 0;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-address {
    margin-bottom: 4.4rem;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-address .wrap-address {
    margin-bottom: 2.5rem;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-address .address {
    margin-bottom: 0;
    font-size: 1.3rem;
    line-height: 2.1rem;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-contact {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-contact .contact {
    font-size: 1.3rem;
    line-height: 2.1rem;
    margin-bottom: 0;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-contact .whatsapp {
    padding-left: 4rem;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-contact .whatsapp::before {
    padding-left: 0;
  }
  .new-store .wrap-container article.article-ubication .part-left .map-contact .whatsapp span {
    display: none;
  }
}
.new-store .wrap-content .article-finance .subtitle.text-subtitle {
  max-width: unset;
}
.new-store .wrap-content .article-finance .content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
}
.new-store .wrap-content .article-finance .part-content {
  width: 44%;
  padding-left: 4rem;
}
.new-store .wrap-content .article-finance .part-content .wrap-one-img .wrap-img {
  margin-bottom: 2.5rem;
}
.new-store .wrap-content .article-finance .part-slider {
  width: 56%;
  /**
                  .aux-slider.wrap-slider-content{
                      width: 100%;
                      max-width: 100%;
                      .control{
                          display: none;
                      }

                      .wrap-img{

                          padding-bottom: 120%;
                          position: relative;
                          overflow: hidden;

                      }
                      .pasadorPost .swiper-wrapper .swiper-slide .img-responsive{

                          position: absolute;
                          top: 0;
                          left: 0;
                          max-height: unset;
                          width: 100%;
                          -o-object-fit: cover;
                          object-fit: cover;
                          max-width: unset;
                          height: 100%;

                      }
                      .wrap-slider.slider-detail-store-finance{
                          width: 100%;
                          max-height: unset;
                          .swiper-wrapper {
                              .swiper-slide{
                                  width: 100%;
                              }
                          }

                      }
                  }
                  */
}
.new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider {
  width: 100%;
}
.new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider .aux-box {
  padding-bottom: 3.7rem;
}
.new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-arrow {
  display: none !important;
}
.new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list {
  padding: 0 !important;
  cursor: grab;
}
.new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.dragging, .new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.draggable:active:hover {
  cursor: grabbing;
}
.new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item {
  position: relative;
  padding-top: 120%;
  width: 100%;
}
.new-store .wrap-content .article-finance .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new-store .wrap-content .article-finance .part-slider .aux-slider.desk {
  flex-grow: 1;
}

@media (max-width: 991px) {
  .new-store .wrap-content .article-finance {
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-content .article-finance .content {
    flex-direction: column;
  }
  .new-store .wrap-content .article-finance .part-slider {
    order: 2;
    width: 100%;
  }
  .new-store .wrap-content .article-finance .part-slider .aux-slider {
    width: 100%;
    transform: unset;
  }
  .new-store .wrap-content .article-finance .part-content {
    order: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .new-store .wrap-content .article-finance .part-content .wrap-one-img {
    order: 4;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
}
.new-store .wrap-content .article-cita {
  margin-bottom: 10.7rem;
}
.new-store .wrap-content .article-cita .wrap-header {
  width: 100%;
}
.new-store .wrap-content .article-cita .wrap-header .header-title {
  max-width: 38rem;
}
.new-store .wrap-content .article-cita .wrap-header .header-part-content {
  width: 56%;
  max-width: calc(1080px / 2);
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  background-color: #fff4fb;
  padding: 5.8rem 0rem 2rem 4rem;
  padding-bottom: 0;
}
.new-store .wrap-content .article-cita .wrap-header .subtitle.text-subtitle {
  margin-bottom: 0;
  margin-top: 3.5rem;
}
.new-store .wrap-content .article-cita .wrap-content-info {
  width: 56%;
  margin: 0 auto;
  margin-left: 0;
  background-color: #fff4fb;
  padding: 0rem 5rem 7rem 4rem;
}
.new-store .wrap-content .article-cita .content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.new-store .wrap-content .article-cita .content::before {
  content: "";
  position: absolute;
  background-color: #fff4fb;
  width: 85%;
  height: 100%;
  left: 0;
  top: 0;
}
.new-store .wrap-content .article-cita .part-content {
  width: 100%;
  max-width: calc(1080px / 2);
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  background-color: #fff4fb;
  padding: 0;
}
.new-store .wrap-content .article-cita .part-content .wrap-one-img.isImg {
  margin-bottom: 3rem;
}
.new-store .wrap-content .article-cita .part-content .description {
  padding-bottom: 3rem;
}
.new-store .wrap-content .article-cita .part-content .wrap-contact {
  padding-bottom: 3rem;
}
.new-store .wrap-content .article-cita .part-content .wrap-contact .contact {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  line-height: 2.3rem;
}
.new-store .wrap-content .article-cita .part-content .wrap-contact .contact a {
  color: #c70087;
}
.new-store .wrap-content .article-cita .part-img {
  width: 44%;
  position: absolute;
  right: 0;
  bottom: -3rem;
  aspect-ratio: 3/2;
}
.new-store .wrap-content .article-cita .part-img .wrap-one-img {
  overflow: hidden;
  position: absolute;
  bottom: 0;
}
.new-store .wrap-content .article-cita .part-img.isSlider {
  max-width: 36rem;
  bottom: -3rem;
  top: unset;
}
.new-store .wrap-content .article-cita .part-img .part-slider {
  width: 100%;
  margin-top: 2rem;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider {
  width: 100%;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider .aux-box {
  padding-bottom: 3.7rem;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-arrow {
  display: none !important;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list {
  padding: 0 !important;
  cursor: grab;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.dragging, .new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.draggable:active:hover {
  cursor: grabbing;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item {
  position: relative;
  padding-top: 120%;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new-store .wrap-content .article-cita .part-img .part-slider .aux-slider.desk {
  flex-grow: 1;
}
.new-store .wrap-content .article-cita .wrap-btnDefault {
  width: fit-content;
}
.new-store .wrap-content .article-cita .wrap-btnDefault .btnDefault {
  padding: 1.4rem 4rem;
}
.new-store .wrap-calendly {
  display: none;
  position: fixed;
  top: 50%;
  z-index: 101;
  width: 100%;
  left: 50%;
  width: 100%;
  height: 100vh;
  transform: translate(-50%, -50%);
}
.new-store .wrap-calendly .wrap-calendly-widget {
  height: 100%;
}
.new-store .wrap-calendly .calendly-inline-widget {
  padding-left: 27rem;
  height: 100% !important;
}
.new-store .wrap-calendly.isOpen {
  display: block;
}
.new-store .wrap-calendly.isOpen::before {
  content: "";
  position: absolute;
  background-color: rgba(51, 51, 51, 0.6);
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
}
.new-store .wrap-calendly .calendly-content {
  background-color: #FFF;
  height: 100%;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
  height: 90%;
  width: 90%;
  padding: 6rem 9rem 9rem 6rem;
}
.new-store .wrap-calendly .calendly-content .wrap-one-img.isImg {
  padding-top: 4.3rem;
}
.new-store .wrap-calendly .calendly-content .wrap-one-img.isImg .img-responsive {
  max-height: 6rem;
}
.new-store .wrap-calendly .wrap-info-store {
  position: absolute;
  max-width: 27rem;
  z-index: 1;
}
.new-store .wrap-calendly .wrap-info-store .contact {
  line-height: 2.4rem;
}
.new-store .wrap-calendly .wrap-info-store .contact a {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  color: #575252;
  --border-color: #575252;
  --border-width: 1px;
  --bottom-distance: 0px; /* you can increase this */
  display: inline;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0% var(--border-width);
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: background-size 0.3s;
}
.new-store .wrap-calendly .wrap-info-store .contact a:hover {
  background-size: 100% var(--border-width);
}
.new-store .wrap-calendly .calendly-close {
  width: 2.3rem;
  height: 2.3rem;
  position: absolute;
  right: 3.4rem;
  top: 3.4rem;
}
.new-store .wrap-calendly .calendly-close .btn-close {
  position: relative;
  padding: 0;
  background-color: transparent;
  -webkit-transition: 0.3s ease-in all;
  transition: 0.3s ease-in all;
  z-index: 1040;
  width: 2.3rem;
  height: 2.3rem;
  opacity: 0.75;
}
.new-store .wrap-calendly .calendly-close .btn-close:hover {
  background-color: transparent;
}
.new-store .wrap-calendly .calendly-close .btn-close::before {
  content: "";
  position: absolute;
  background-image: url("/images/fl-close-black.svg");
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.new-store .wrap-calendly .calendly-close .btn-close:hover {
  opacity: 1;
}

@media (max-width: 991px) {
  .new-store .wrap-content .article-cita {
    margin-bottom: 0;
  }
  .new-store .wrap-content .article-cita .content {
    max-width: 100%;
  }
  .new-store .wrap-content .article-cita .content:before {
    content: none;
  }
  .new-store .wrap-content .article-cita .content .wrap-header {
    background-color: #fff4fb;
    width: calc(100% - 2.7rem);
    margin: 0 auto;
    margin-left: 0;
  }
  .new-store .wrap-content .article-cita .content .wrap-header .header-part-content {
    width: 50%;
    min-width: 36rem;
    padding: 4rem 0 2rem 2.7rem;
    background-color: transparent;
  }
  .new-store .wrap-content .article-cita .wrap-content-info {
    width: 100%;
    background-color: unset;
    padding: 0;
  }
  .new-store .wrap-content .article-cita .part-content {
    padding: 0 2.7rem 10.7rem 2.7rem;
    max-width: 100%;
    width: calc(100% - 2.7rem);
  }
  .new-store .wrap-content .article-cita .part-img {
    position: relative;
    left: unset;
    width: 100%;
    max-width: 100%;
    padding: 0 0 0 2.7rem;
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  .new-store .wrap-content .article-cita .part-img {
    bottom: 7rem;
  }
}
@media (max-width: 991px) {
  .new-store .wrap-content .article-cita .part-img.isSlider {
    bottom: unset;
    max-width: 100%;
    left: unset;
  }
  .new-store .wrap-content .article-cita .part-img .part-slider {
    margin-top: -5.2rem;
  }
  .new-store .wrap-content .article-cita .part-img .aux-slider {
    width: 100%;
    transform: unset;
  }
  .new-store .wrap-calendly .calendly-content {
    padding: 1.5rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .new-store .wrap-calendly .wrap-info-store {
    position: relative;
  }
  .new-store .wrap-calendly .wrap-calendly-widget {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .new-store .wrap-calendly .calendly-inline-widget {
    position: relative;
    padding-left: 0;
    overflow: hidden !important;
    overflow-y: hidden !important;
    height: 100% !important;
  }
}
.new-store .text.generic p b, .new-store .text.generic b {
  font-family: "Montserrat-SemiBold" !important;
  font-weight: normal;
}
.new-store .text.generic p {
  margin-bottom: 2.5rem;
  font-weight: normal;
}
.new-store .text.generic p:last-of-type {
  margin-bottom: 0;
}
.new-store .wrap-content .article-projekt {
  margin-bottom: 10.7rem;
}
.new-store .wrap-content .article-projekt .arriba .content .part-img {
  bottom: 7rem;
}
.new-store .wrap-content .article-projekt .arriba .content .part-img.isSlider {
  bottom: 7rem;
}
.new-store .wrap-content .article-projekt .wrap-header {
  width: 100%;
}
.new-store .wrap-content .article-projekt .wrap-header .header-title {
  max-width: 38rem;
}
.new-store .wrap-content .article-projekt .wrap-header .header-part-content {
  width: 56%;
  max-width: calc(1080px / 2);
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  background-color: #fff4fb;
  padding: 5.8rem 5rem 3rem 4rem;
  padding-bottom: 0;
}
.new-store .wrap-content .article-projekt .wrap-header .subtitle.text-subtitle {
  margin-bottom: 0 !important;
}
.new-store .wrap-content .article-projekt .wrap-content-info {
  width: 56%;
  margin: 0 auto;
  margin-left: 0;
  background-color: #fff4fb;
  padding: 2rem 5rem 7rem 4rem;
}
.new-store .wrap-content .article-projekt .content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.new-store .wrap-content .article-projekt .content::before {
  content: "";
  position: absolute;
  background-color: #fff4fb;
  width: 85%;
  height: 100%;
  left: 0;
  top: 0;
}
.new-store .wrap-content .article-projekt .part-content {
  width: 100%;
  max-width: calc(1080px / 2);
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  background-color: #fff4fb;
  padding: 0;
}
.new-store .wrap-content .article-projekt .part-content .description {
  padding-bottom: 3rem;
}
.new-store .wrap-content .article-projekt .part-content .wrap-one-img.isImg {
  margin-bottom: 3rem;
}
.new-store .wrap-content .article-projekt .part-img {
  width: 44%;
  position: absolute;
  right: 0;
  bottom: 7rem;
  top: unset;
  aspect-ratio: 3/2;
}
.new-store .wrap-content .article-projekt .part-img .wrap-one-img {
  overflow: hidden;
  position: absolute;
  bottom: 0;
}
.new-store .wrap-content .article-projekt .part-img.isSlider {
  max-width: 36rem;
  bottom: -3rem;
  top: unset;
}
.new-store .wrap-content .article-projekt .part-img .part-slider {
  width: 100%;
  margin-top: 2rem;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider {
  width: 100%;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider .aux-box {
  padding-bottom: 3.7rem;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-arrow {
  display: none !important;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list {
  padding: 0 !important;
  cursor: grab;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.dragging, .new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.draggable:active:hover {
  cursor: grabbing;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item {
  position: relative;
  padding-top: 120%;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new-store .wrap-content .article-projekt .part-img .part-slider .aux-slider.desk {
  flex-grow: 1;
}
.new-store .wrap-content .article-projekt .wrap-btnDefault {
  width: fit-content;
}
.new-store .wrap-content .article-projekt .wrap-btnDefault .btnDefault {
  padding: 1.4rem 4rem;
}

@media (max-width: 991px) {
  .new-store .wrap-content .article-projekt {
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-content .article-projekt .arriba .content .part-img.isSlider {
    position: relative;
    bottom: 0;
    width: 100%;
  }
  .new-store .wrap-content .article-projekt .content {
    flex-direction: column;
  }
  .new-store .wrap-content .article-projekt .content::before {
    content: none;
  }
  .new-store .wrap-content .article-projekt .content .aux-slider {
    width: 100%;
    transform: unset;
  }
  .new-store .wrap-content .article-projekt .wrap-header {
    width: 100%;
  }
  .new-store .wrap-content .article-projekt .wrap-header .header-part-content {
    width: 100%;
    max-width: 100%;
    padding: 4rem 0 2rem 1.5rem;
  }
  .new-store .wrap-content .article-projekt .wrap-header .header-part-content .header-title.box-content {
    width: 50%;
  }
  .new-store .wrap-content .article-projekt .wrap-content-info {
    width: 100%;
    padding: 0;
    background-color: unset;
  }
  .new-store .wrap-content .article-projekt .part-img {
    max-width: 100%;
    width: 100%;
    padding: 0 1.5rem;
  }
  .new-store .wrap-content .article-projekt .part-img.isSlider {
    max-width: 100%;
  }
  .new-store .wrap-content .article-projekt .part-img .part-slider {
    margin-top: -5.2rem;
    left: unset;
    position: relative;
  }
  .new-store .wrap-content .article-projekt .part-content {
    max-width: 100%;
    padding: 1.5rem;
    position: relative;
    background-color: unset;
    padding-bottom: 10.7rem;
    padding-top: 0;
  }
  .new-store .wrap-content .article-projekt .part-content .description {
    position: relative;
  }
  .new-store .wrap-content .article-projekt .part-content::before {
    content: "";
    position: absolute;
    background-color: #fff4fb;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .new-store .wrap-content .article-projekt .wrap-btnDefault .btnDefault {
    font-size: 1.5rem;
    padding: 1.4rem 5.3rem;
  }
}
.article-products {
  margin-bottom: 10.7rem;
}
.article-products.default .header-title .title {
  width: 100%;
  margin-bottom: 0;
}
.article-products.default .content {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.article-products .header-title {
  width: 100%;
}
.article-products .header-title .title {
  width: 56%;
  max-width: 40rem;
}
.article-products .subtitle {
  line-height: 2.4rem;
  font-size: 1.5rem;
  color: #c70087;
}
.article-products .content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}
.article-products .part-right {
  width: 56%;
  order: 2;
}
.article-products .part-left {
  width: 44%;
  display: flex;
  flex-direction: column;
  padding-right: 4rem;
  order: 1;
}
.article-products .part-left > *, .article-products .part-left > * p {
  margin-bottom: 3rem;
  padding-bottom: 0;
}
.article-products .part-left > *:last-of-type, .article-products .part-left > * p:last-of-type {
  margin-bottom: 0;
}
.article-products .part-left .wrap-btnDefault {
  min-width: unset;
  width: fit-content;
  margin: 0 auto;
  margin-left: 0;
  margin-top: 3.5rem;
}
.article-products .part-left .wrap-btnDefault .btnDefault {
  padding: 1.4rem 4rem;
  width: fit-content;
}
.article-products .part-left .wrap-info {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.article-products .part-left .wrap-info .text.generic p:first-of-type,
.article-products .part-left .wrap-info .text.generic p:first-of-type * {
  font-family: "Montserrat-SemiBold" !important;
}
.article-products .wrap-images {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.article-products .wrap-images .item-img {
  overflow: hidden;
  border-radius: 0;
}
.article-products .wrap-images .item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: 0.2s transform ease-in-out;
  object-position: top;
}
.article-products .wrap-images .item-img .wrap-item {
  position: relative;
  height: 100%;
  /**
  &:hover{
      img{
          transform: scale(1.1);
          transition: .2s transform ease-in-out;
      }
  }*/
}
.article-products .wrap-images .item-img.default .wrap-item {
  background-color: #fff4fb;
}
.article-products .wrap-images .item-img.default .wrap-item img {
  height: auto;
  width: 100%;
  width: 15rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: relative;
  object-fit: contain;
}
.article-products .wrap-images .row-img {
  gap: 1.3rem;
  margin-bottom: 1.3rem;
}
.article-products .wrap-images .row-img:last-of-type {
  margin-bottom: 0;
}
.article-products .wrap-images .row-img:nth-child(odd) {
  width: 100%;
  display: flex;
}
.article-products .wrap-images .row-img:nth-child(odd) .item-img:nth-child(even) {
  width: 30%;
}
.article-products .wrap-images .row-img:nth-child(odd) .item-img:nth-child(even) img {
  aspect-ratio: unset;
}
.article-products .wrap-images .row-img:nth-child(odd) .item-img:nth-child(odd) {
  width: 70%;
}
.article-products .wrap-images .row-img:nth-child(odd) .item-img:nth-child(odd) .wrap-item {
  aspect-ratio: 4/1.75;
}
.article-products .wrap-images .row-img:nth-child(even) {
  width: 100%;
  display: flex;
}
.article-products .wrap-images .row-img:nth-child(even) .item-img:nth-child(odd) {
  width: 30%;
}
.article-products .wrap-images .row-img:nth-child(even) .item-img:nth-child(odd) img {
  aspect-ratio: unset;
}
.article-products .wrap-images .row-img:nth-child(even) .item-img:nth-child(even) {
  width: 70%;
}
.article-products .wrap-images .row-img:nth-child(even) .item-img:nth-child(even) .wrap-item {
  aspect-ratio: 4/1.75;
}
@media (max-width: 991px) {
  .article-products {
    margin-bottom: 7.7rem;
  }
  .article-products .header-title .title {
    width: 75%;
  }
  .article-products .content {
    flex-direction: column;
  }
  .article-products .wrap-images .row-img {
    gap: 0.9rem;
    margin-bottom: 0.9rem;
  }
  .article-products .part-right {
    width: 100%;
  }
  .article-products .part-left {
    width: 100%;
    margin-bottom: 3.6rem;
    padding: 0;
  }
  .article-products .part-left .wrap-btnDefault .btnDefault {
    padding: 1.4rem 5.3rem;
    font-size: 1.5rem;
  }
}
.new-store .wrap-content .article-blog {
  margin-bottom: 10.7rem;
}
.new-store .wrap-content .article-blog .wrap-header {
  width: 100%;
  padding-right: 3.5rem;
  padding-top: 3.5rem;
}
.new-store .wrap-content .article-blog .wrap-header .header-title {
  max-width: 38rem;
}
.new-store .wrap-content .article-blog .wrap-header .box-content .title {
  margin-bottom: 0;
  padding-bottom: 2.5rem;
}
.new-store .wrap-content .article-blog .wrap-header .header-part-content {
  width: 56%;
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  padding: 0;
  padding-left: 7.4rem;
  position: relative;
  width: 44%;
  margin: 0 auto;
  margin-right: 0;
}
.new-store .wrap-content .article-blog .wrap-header .subtitle.text-subtitle {
  margin-bottom: 0;
}
.new-store .wrap-content .article-blog .wrap-content-info {
  width: 100%;
  margin: 0 auto;
  margin-left: 0;
  padding: 0 3.5rem 0 0;
}
.new-store .wrap-content .article-blog .content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.new-store .wrap-content .article-blog .content:before {
  content: "";
  position: absolute;
  background-color: #fff4fb;
  width: 83%;
  height: 100%;
  left: unset;
  right: 0;
  top: 0;
}
.new-store .wrap-content .article-blog .content:first-child {
  z-index: 2;
}
.new-store .wrap-content .article-blog .content:first-child:before {
  height: 100%;
  top: 0;
}
.new-store .wrap-content .article-blog .content:last-child:before {
  top: 0;
}
.new-store .wrap-content .article-blog .part-right {
  width: 44%;
  position: relative;
  padding-left: 7.4rem;
}
.new-store .wrap-content .article-blog .part-right .description {
  padding-bottom: 3rem;
}
.new-store .wrap-content .article-blog .part-right .wrap-one-img.isImg {
  margin-bottom: 3rem;
}
.new-store .wrap-content .article-blog .part-left {
  width: 56%;
  position: relative;
  margin: 0 auto;
  margin-left: 0;
  padding: 0;
}
.new-store .wrap-content .article-blog .part-left .part-img {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: -3rem;
}
.new-store .wrap-content .article-blog .part-left .wrap-one-img {
  overflow: hidden;
  bottom: 0;
}
.new-store .wrap-content .article-blog .part-left .wrap-one-img .wrap-img .img-responsive {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.new-store .wrap-content .article-blog .part-left.isSlider {
  max-width: 36rem;
}
.new-store .wrap-content .article-blog .part-left .part-slider {
  width: 100%;
  margin-top: 0;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider {
  width: 100%;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider .aux-box {
  padding-bottom: 3.7rem;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-arrow {
  display: none !important;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list {
  padding: 0 !important;
  cursor: grab;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.dragging, .new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list.draggable:active:hover {
  cursor: grabbing;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item {
  position: relative;
  padding-top: 120%;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider .wrap-slider .slider-slick-detail-store.slick-slider .slick-list .slick-slide .item .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new-store .wrap-content .article-blog .part-left .part-slider .aux-slider.desk {
  flex-grow: 1;
}
.new-store .wrap-content .article-blog .wrap-btnDefault {
  width: fit-content;
  margin-bottom: 3rem;
}
.new-store .wrap-content .article-blog .wrap-btnDefault .btnDefault {
  padding: 1.4rem 4rem;
}
.new-store .wrap-content .article-blog .wrap-content-posts {
  display: flex;
  margin-top: 10.8rem;
}
.new-store .wrap-content .article-blog .wrap-content-posts .header-title.box-content {
  width: 25%;
}
.new-store .wrap-content .article-blog .wrap-content-posts .header-title.box-content .title {
  padding-right: 2.4rem;
  font-size: 2.7rem;
}
.new-store .wrap-content .article-blog .last-2-posts {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new {
  width: 50%;
  padding-right: 2rem;
  background-color: #fff4fb;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .aux-item {
  border-bottom: 5px solid #c70087;
  width: 100%;
  overflow: hidden;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .wrap-img {
  position: relative;
  padding-top: 64%;
  transform: translateY(0);
  transition: transform 0.5s;
  overflow: hidden;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .wrap-img .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #c70087;
  transform: scale(1);
  transition: 0.5s transform linear;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .header,
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .date,
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .author,
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .title {
  padding: 0 1rem;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .header span {
  font-size: 1.3rem;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .header .heart {
  color: #c70087;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .date {
  font-size: 1.3rem;
  color: #c70087;
  opacity: 1;
  display: block;
  margin-bottom: 2rem;
  font-family: "Montserrat-Italic";
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .title {
  font-size: 1.5rem;
  font-family: "Montserrat-SemiBold";
  color: #000;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new:hover .wrap-img {
  transition: transform 0.5s;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new:hover .wrap-img .img {
  transform: scale(1.1);
  transition: 0.5s transform linear;
}
.new-store .wrap-content .article-blog .last-2-posts {
  margin: 0 auto;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new {
  width: calc(50% - 1.5rem);
  padding-right: 0;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .header, .new-store .wrap-content .article-blog .last-2-posts .last-3-new .date {
  margin-bottom: 0;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .title {
  margin-top: 2rem;
}
.new-store .wrap-content .article-blog .last-2-posts .last-3-new .author {
  font-family: "Montserrat-Italic";
  font-size: 1.3rem;
  color: #333;
  line-height: 2.1rem;
  opacity: 1;
  display: block;
}

@media (max-width: 991px) {
  .new-store {
    overflow: hidden;
  }
  .new-store .wrap-container {
    padding: 0 1.5rem;
  }
  .new-store .wrap-content .article-blog {
    margin-bottom: 6.7rem;
    margin-top: 15rem;
  }
  .new-store .wrap-content .article-blog .wrap-btnDefault {
    margin-bottom: 7.2rem;
  }
  .new-store .wrap-content .article-blog .wrap-header {
    padding-top: 3.8rem;
  }
  .new-store .wrap-content .article-blog .wrap-header .header-part-content {
    padding: 0;
    width: 50%;
    margin-left: 3rem;
  }
  .new-store .wrap-content .article-blog .wrap-header .header-part-content .box-content .title {
    width: 100%;
    padding-right: 0;
    margin-bottom: 0;
    padding-bottom: 2.2rem;
  }
  .new-store .wrap-content .article-blog .content::before {
    width: calc(100% - 1.5rem);
  }
  .new-store .wrap-content .article-blog .wrap-content-info {
    flex-direction: column;
    padding: 0 3rem 0 0;
  }
  .new-store .wrap-content .article-blog .part-left {
    width: 100%;
  }
  .new-store .wrap-content .article-blog .part-left .part-img {
    margin-bottom: 0;
    margin-top: -7rem;
  }
  .new-store .wrap-content .article-blog .part-right {
    width: 100%;
    padding-left: 4.5rem;
  }
  .new-store .wrap-content .article-blog .part-right .header-part-content {
    margin-left: 0;
    min-width: 13rem;
    width: 60%;
  }
  .new-store .wrap-content .article-blog .wrap-content-posts {
    flex-direction: column;
    margin-top: 7.7rem;
  }
  .new-store .wrap-content .article-blog .wrap-content-posts .header-title.box-content {
    width: 100%;
  }
  .new-store .wrap-content .article-blog .wrap-content-posts .header-title.box-content .title {
    padding-right: 0;
    margin-bottom: 2.2rem;
  }
  .new-store .wrap-content .article-blog .wrap-content-posts .last-2-posts {
    flex-wrap: nowrap;
    overflow: auto;
    width: calc(100% + 3rem);
    position: relative;
    left: -1.5rem;
    column-gap: 1.6rem;
    padding-bottom: 2rem;
  }
  .new-store .wrap-content .article-blog .wrap-content-posts .last-2-posts .last-3-new {
    width: calc(85% - 1.5rem);
    min-width: calc(85% - 1.5rem);
  }
  .new-store .wrap-content .article-blog .wrap-content-posts .last-2-posts .last-3-new:first-of-type {
    margin-left: 1.5rem;
  }
  .new-store .wrap-content .article-blog .wrap-content-posts .last-2-posts .last-3-new:last-of-type {
    margin-right: 1.5rem;
  }
  .new-store .wrap-content .article-blog .wrap-btnDefault .btnDefault {
    padding: 1.4rem 5.3rem;
  }
}
.new-store .wrap-content .article-tour {
  width: 100%;
  margin-bottom: 10.7rem;
}
.new-store .wrap-content .article-tour .content .wrap-header {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.new-store .wrap-content .article-tour .content .wrap-header .box-content .title {
  margin: 0 auto;
  width: fit-content;
  background-color: #fff;
  padding: 0 4.3rem 1.8rem 4.3rem;
  z-index: 1;
  border-radius: 0;
  margin-bottom: -1.8rem;
}
.new-store .wrap-content .article-tour .content .link-tour {
  margin: 0 auto;
  max-width: 1080px;
  position: relative;
  width: 100%;
}
.new-store .wrap-content .article-tour .content .link-tour .wrap-img {
  position: relative;
}
.new-store .wrap-content .article-tour .content .link-tour .wrap-img::before {
  content: "";
  background-image: url("/images/fl-amplicar-icon.svg");
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: -1;
  transition: 0.2s opacity ease-in-out;
  background-position: center;
}
.new-store .wrap-content .article-tour .content .link-tour .wrap-img:hover::before {
  opacity: 1;
  z-index: 1;
  transition: 0.2s opacity ease-in-out;
}
.new-store .wrap-content .article-tour .content .wrap-img {
  width: 100%;
  height: 100%;
  max-height: 50rem;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
}
.new-store .wrap-content .article-tour .content .wrap-img img.img-responsive {
  width: 100%;
  position: relative;
  max-height: 50rem;
  object-fit: cover;
}
.new-store .wrap-content .article-tour .content .wrap-description {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
}
.new-store .wrap-content .article-tour .content .wrap-description .description {
  width: 31rem;
  margin: 0 auto;
  background-color: #fff;
  padding: 2.8rem 2.5rem 1rem 2.5rem;
  z-index: 1;
  border-radius: 0;
  margin-top: -5rem;
  margin-right: 1rem;
}

@media (max-width: 991px) {
  .new-store .wrap-content .article-tour {
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-content .article-tour .content .link-tour .wrap-img {
    width: calc(100% + 3rem);
    position: relative;
    left: -1.5rem;
  }
  .new-store .wrap-content .article-tour .content .wrap-description .description {
    width: 100%;
    margin-top: 0;
    margin: 0;
    padding: 2.5rem 0;
    padding-bottom: 0;
  }
  .new-store .wrap-content .article-tour .content .wrap-header .box-content .title {
    padding: 0 4.3rem 0.7rem 4.3rem;
  }
  .new-store .wrap-content .article-tour .content .wrap-img img.img-responsive {
    aspect-ratio: 5/2.5;
  }
}
.article-instagram {
  margin-bottom: 9rem;
}
.article-instagram .wrap-btnDefault {
  min-width: unset;
  max-width: 26.6rem;
}
.article-instagram .wrap-content-info {
  width: 100%;
}
.article-instagram .wrap-content-info.izquierda {
  flex-direction: row-reverse;
}
.article-instagram .wrap-content-info.izquierda .part-right {
  padding-left: 0;
  padding-right: 4rem;
}
.article-instagram .header-title {
  width: 100%;
}
.article-instagram .subtitle {
  margin-bottom: 3.4rem;
}
.article-instagram .description {
  margin-bottom: 4.5rem;
}
.article-instagram .description a, .article-instagram .description span {
  font-family: "Montserrat-SemiBold" !important;
}
.article-instagram .content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}
.article-instagram .content.big {
  max-width: 1280px;
}
.article-instagram .part-left {
  width: 30%;
}
.article-instagram .part-left .part-img {
  position: absolute;
  bottom: 0;
  width: 30%;
}
.article-instagram .part-left .part-img .wrap-one-img .wrap-img {
  width: 100%;
  left: 0;
  position: relative;
  margin-top: 14rem;
  opacity: 0.4;
}
.article-instagram .part-left .part-img .wrap-one-img .wrap-img .img-responsive {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.article-instagram .part-right {
  width: 70%;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
}
.article-instagram .part-right > *, .article-instagram .part-right > * p {
  margin-bottom: 3rem;
  padding-bottom: 0;
}
.article-instagram .part-right > *:last-of-type, .article-instagram .part-right > * p:last-of-type {
  margin-bottom: 0;
}
.article-instagram .part-right .wrap-btnDefault {
  min-width: unset;
  width: fit-content;
  margin: 0 auto;
  margin-left: 0;
  margin-top: 3.5rem;
}
.article-instagram .part-right .wrap-btnDefault .btnDefault {
  padding: 1.4rem 4rem;
  width: fit-content;
}
.article-instagram .part-right .wrap-info {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 37rem;
  justify-content: space-between;
}
.article-instagram .wrap-images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.6rem;
  gap: 1.6rem;
  margin-bottom: 15% !important;
}
.article-instagram .wrap-images img {
  aspect-ratio: 1/1;
}
.article-instagram .wrap-images .item-img {
  overflow: hidden;
}
.article-instagram .wrap-images .item-img:nth-child(odd) {
  transform: translateY(30%);
}
.article-instagram .wrap-images .item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-instagram .wrap-images .item-img .wrap-item {
  position: relative;
  height: 100%;
}

@media (max-width: 991px) {
  .article-instagram .wrap-content-info {
    flex-direction: column;
  }
  .article-instagram .wrap-content-info.izquierda {
    flex-direction: column;
    /**
    .part-left .part-img {
        right: unset;
        left: -2.5rem;
    }
    .wrap-btnDefault {
        margin: 0 auto;
        margin-right: 0;
    }
    .description{
        width: 80%;
        margin: 0 auto;
        margin-bottom: 2.5rem;
        margin-right: 0;
    }
    .header-title.box-content{
        margin: 0 auto;
        margin-right: 15%;
    }
    */
  }
  .article-instagram .wrap-content-info.izquierda .part-right {
    padding-left: 0;
    padding-right: 0;
  }
  .article-instagram .description {
    margin-bottom: 2.5rem;
  }
  .article-instagram .part-left {
    width: 100%;
    margin-bottom: 5.9rem;
  }
  .article-instagram .part-left .part-img {
    top: -1.5rem;
    min-width: 14rem;
    right: -2.5rem;
    bottom: unset;
  }
  .article-instagram .part-left .part-img .wrap-one-img .wrap-img {
    margin-top: 0;
  }
  .article-instagram .part-right {
    width: 100%;
    padding-left: 0;
  }
  .article-instagram .part-right .wrap-btnDefault .btnDefault {
    padding: 1.4rem 5.3rem;
    width: fit-content;
  }
  .article-instagram .header-title.box-content {
    width: 65%;
  }
  .article-instagram .header-title.box-content .title {
    width: 100%;
  }
}
.new-store .wrap-container .article-users {
  margin-bottom: 10.7rem;
}
.new-store .wrap-container .article-users .subtitle.text-subtitle {
  max-width: 37rem;
}
.new-store .wrap-container .article-users .content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.new-store .wrap-container .article-users .header-part-content {
  margin: 0 auto;
  margin-left: 0;
  position: relative;
  width: 100%;
}
.new-store .wrap-container .article-users .wrap-users {
  width: 100%;
  /*
  display: flex;
  margin: 0 auto;
  margin-right: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 3rem;
  */
  /**
  &::-webkit-scrollbar {
      -webkit-appearance: none;                 
  }
  &::-webkit-scrollbar:vertical {
      width:5px;
  }
  &::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
      display: none;
  } 
  &::-webkit-scrollbar:horizontal {
      padding-right: .5rem;
      height: 5px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: $colorCorporative;
      border-radius: 0 !important;                    
      border: none;
  }
  &::-webkit-scrollbar-track {
      background-color: rgba($colorCorporative, 0.5);
      border-radius: 0 !important;  
      margin-top: 2rem;
      margin-bottom: 7rem;
  }
  */
}
.new-store .wrap-container .article-users .wrap-users .users3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
.new-store .wrap-container .article-users .wrap-users .users2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.new-store .wrap-container .article-users .wrap-users .slider-users {
  width: calc(100% + 2rem);
}
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-slide {
  margin-right: 2.5rem;
}
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-slide:last-of-type {
  margin-right: 0;
}
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-prev,
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-next {
  width: 4rem;
  height: 4rem;
  z-index: 1;
  top: calc(50% - 2rem);
}
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-prev::before,
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-next::before {
  content: "";
  background-image: url(/images/fl-right.svg);
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
}
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-prev.slick-disabled,
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-next.slick-disabled {
  display: none !important;
}
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-next {
  right: 0;
}
.new-store .wrap-container .article-users .wrap-users .slider-users .slick-prev::before {
  background-image: url(/images/fl-left.svg);
}
.new-store .wrap-container .article-users .wrap-users .item-user .wrap-img {
  overflow: hidden;
  /*
  &:hover{
      .img-responsive{
          transform: scale(1.1);
          transition: .2s transform ease-in-out;
      }
  }
  */
}
.new-store .wrap-container .article-users .wrap-users .item-user .wrap-img .img-responsive {
  aspect-ratio: 3/2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transform: scale(1);
  transition: 0.2s transform ease-in-out;
}
.new-store .wrap-container .article-users .wrap-users .item-user .name {
  font-family: "Montserrat-SemiBold";
  font-size: 1.5rem;
  line-height: 2.3rem;
  margin-top: 1rem;
  color: #333;
  position: relative;
}
.new-store .wrap-container .article-users .wrap-users .item-user .name .js-userMore {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 2rem;
  color: #c70087;
  text-transform: uppercase;
}
.new-store .wrap-container .article-users .wrap-users .item-user .name .js-userMore.is-open::before {
  transition: 0.2s margin-top ease-in-out;
  transform: translateY(-50%) rotate(225deg);
}
.new-store .wrap-container .article-users .wrap-users .item-user .name .js-userMore:before {
  content: "";
  position: absolute;
  color: #c70087;
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid #c70087;
  margin-top: 0;
  transition: 0.2s margin-top ease-in-out;
  transform: translateY(-50%) rotate(45deg);
  border-top: none;
  border-left: none;
  right: 0;
  top: calc(50% - 0.4rem);
}
.new-store .wrap-container .article-users .wrap-users .item-user .wrap-description {
  position: absolute;
  background-color: #fff;
  width: 100%;
  padding: 3.5rem;
  padding-top: 2rem;
  height: auto;
  opacity: 0;
  max-height: 0;
  z-index: -1;
  padding-left: 0;
}
.new-store .wrap-container .article-users .wrap-users .item-user .wrap-description.is-open {
  position: relative;
  z-index: 1;
  max-height: 9999px;
  opacity: 1;
  transition: 1s max-height linear;
}

@media (max-width: 991px) {
  .new-store .wrap-container .article-users {
    margin-bottom: 7.7rem;
  }
  .new-store .wrap-container .article-users .subtitle.text-subtitle {
    max-width: 28rem;
  }
  .new-store .wrap-container .article-users .wrap-users .users2,
  .new-store .wrap-container .article-users .wrap-users .users3 {
    grid-gap: unset;
    overflow: hidden;
    overflow-x: auto;
    width: calc(100% + 3rem);
    position: relative;
    left: -1.5rem;
    column-gap: 1.6rem;
    display: flex;
    padding-bottom: 2rem;
  }
  .new-store .wrap-container .article-users .wrap-users .item-user {
    width: calc(80% - 1.5rem);
    min-width: calc(80% - 1.5rem);
    position: relative;
  }
  .new-store .wrap-container .article-users .wrap-users .item-user .wrap-img .img-responsive {
    aspect-ratio: 1/1;
  }
  .new-store .wrap-container .article-users .wrap-users .item-user .name {
    margin-top: 1.8rem;
  }
  .new-store .wrap-container .article-users .wrap-users .item-user:first-of-type {
    margin-left: 1.5rem;
  }
  .new-store .wrap-container .article-users .wrap-users .item-user:last-of-type {
    margin-right: 1.5rem;
  }
}
.stores-aggrupated-famaliving {
  padding: 0 5rem;
}
.stores-aggrupated-famaliving .limited-wrap {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.stores-aggrupated-famaliving .wrap-title {
  padding-top: 16rem;
  max-width: 30rem;
}
.stores-aggrupated-famaliving .box-content .title {
  margin-bottom: 6.2rem;
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
}
.stores-aggrupated-famaliving .box-content .title h1 {
  font-size: inherit;
}
.stores-aggrupated-famaliving .box-content .title .span1 {
  color: #c70087;
}
.stores-aggrupated-famaliving .box-content .title.small-line:not(.sinBorder):before {
  bottom: -1rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-image img {
  width: 100%;
  object-fit: cover;
  height: 53.2rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-blocks {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-blocks .wrap-shops-block {
  width: 50%;
  padding: 3rem 1.5rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-blocks .wrap-shops-block:first-child {
  background-color: #c70087;
  color: white;
  margin-right: 1.5rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-blocks .wrap-shops-block:nth-child(2) {
  background-color: #f3f6f7;
  color: #c70087;
  margin-left: 1.5rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-blocks .wrap-shops-block .title {
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 2.1rem;
  line-height: 2.6rem;
  text-align: center;
  margin-bottom: 2rem;
  pointer-events: none;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-blocks .wrap-shops-block .content {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 1.5rem;
  line-height: 2.3rem;
  text-align: center;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item {
  width: calc(50% - 4rem);
  margin-left: 4rem;
  margin-bottom: 6rem;
  background-color: transparent;
  position: relative;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item:hover .background-shop {
  opacity: 1;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .shop-item-content {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .background-shop {
  content: "";
  position: absolute;
  width: calc(100% + 4rem);
  height: calc(100% + 4rem);
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  background-color: #f3f6f7;
  transition: opacity 0.5s ease-in-out;
  left: 50%;
  top: 50%;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .title {
  font-size: 2.1rem;
  font-family: "Montserrat-SemiBold", sans-serif;
  pointer-events: none;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .content {
  margin-top: 3.4rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .address, .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .city, .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .phone {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: "Montserrat-Regular", sans-serif;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .btn-more-info {
  width: 100%;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 1.3rem;
  line-height: 2.1rem;
  background-color: #fff4fb;
  transition: all 0.4s ease-in-out;
  margin-top: 0.6rem;
  position: relative;
  padding: 1rem 5rem 1rem 1rem;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .btn-more-info:after {
  content: "";
  position: absolute;
  background-image: url("/images/arrow-link.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.8rem;
  height: 0.8rem;
  transition: all 0.4s ease-in-out;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .btn-more-info:hover {
  background-color: #c70087;
  color: white;
}
.stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .btn-more-info:hover:after {
  background-image: url("/images/arrow-link-white.svg");
}
.stores-aggrupated-famaliving .wrap-tours {
  position: relative;
  margin-top: 5rem;
  height: auto;
  margin-bottom: 10.4rem;
}
.stores-aggrupated-famaliving .wrap-tours .title {
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
}
.stores-aggrupated-famaliving .wrap-tours .subtitle {
  width: 50%;
  margin-top: 2.1rem;
  font-size: 1.5rem;
  line-height: 2.3rem;
  margin-bottom: 4rem;
}
.stores-aggrupated-famaliving .wrap-tours .subtitle b {
  font-family: "Montserrat-Bold", sans-serif;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list {
  width: calc(100% + (100vw - 1280px) / 2);
  left: 0;
  position: relative;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-slide {
  width: 35rem;
  margin-right: 3rem;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-track {
  margin-left: unset;
  margin-right: unset;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-disabled {
  opacity: 0.4;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-next, .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-prev {
  background-image: url("/images/slider-arrow-left.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
  z-index: 2;
  transition: all 0.4s ease-in-out;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-next:hover, .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-prev:hover {
  transform: translate(-1rem, -50%);
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-next:before, .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-prev:before {
  display: none;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-next {
  background-image: url("/images/slider-arrow-right.svg");
  right: 15px;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-next:hover {
  transform: translate(1rem, -50%);
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item {
  width: 35rem;
  background-color: #f3f6f7;
  margin-right: 3rem;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .image-container img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  height: 100%;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content {
  padding: 2.5rem 3.5rem 3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 25rem;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content .title {
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 2.5rem;
  line-height: 3rem;
  flex-grow: 1;
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content .title .span1 {
  color: #c70087;
}
@media (max-width: 1441px) {
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content .title {
    font-size: 2rem;
    line-height: 2.7rem;
  }
}
.stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content .subtitle {
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-family: "Montserrat-Regular", sans-serif;
  margin-top: 1.2rem;
  width: 100%;
  margin-bottom: 0;
}
@media (max-width: 1280px) {
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list {
    width: calc(100% + 3rem);
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content {
    min-height: 27rem;
  }
}
@media (max-width: 991px) {
  .stores-aggrupated-famaliving .title {
    font-family: "Montserrat-SemiBold";
    font-size: 2.5rem;
    line-height: normal;
  }
  .stores-aggrupated-famaliving .limited-wrap {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .stores-aggrupated-famaliving .wrap-title {
    padding-top: 11rem;
  }
  .stores-aggrupated-famaliving .wrap-title .title {
    font-size: 2.5rem;
  }
  .stores-aggrupated-famaliving .wrap-shops {
    display: block !important;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-image {
    max-height: 25rem;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-image img {
    max-height: 25rem;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-left .wrap-shops-block {
    display: none;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list {
    margin-top: 5rem;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item:nth-child(even) .background-shop {
    background-color: #fff4fb;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item:nth-child(even) .shop-item-content .btn-more-info {
    background-color: #fff;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item {
    max-width: unset;
    width: 100%;
    margin-left: 0;
    padding-bottom: 2rem;
    margin-bottom: 0;
    padding-top: 2rem;
    text-align: left;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item::before {
    content: "";
    position: absolute;
    width: calc(100% + 4rem);
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    background-color: #c70087;
    height: 1px;
    z-index: 1;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item:last-of-type::before {
    content: none;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .title.small-line:before {
    display: none;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .background-arrow {
    top: 0;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .arrow {
    top: 3rem !important;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item:nth-child(odd):after {
    background-color: transparent;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item:nth-child(even):after {
    content: "";
    position: absolute;
    width: calc(100% + 4rem);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff4fb;
    height: 100%;
    z-index: -1;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .shop-item-content {
    display: none;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .background-shop {
    opacity: 1;
    background-color: transparent;
    cursor: pointer;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .background-arrow {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    max-height: 10rem;
    background-color: transparent;
    left: 0;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item.collapse .shop-item-content {
    display: none;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item.collapse .arrow {
    transform: rotate(90deg);
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .arrow {
    content: "";
    background-image: url("/images/arrow-grey-simple-right.svg");
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 1rem;
    right: 0;
    cursor: pointer;
    background-size: contain;
    transform: rotate(270deg);
    background-repeat: no-repeat;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
  }
  .stores-aggrupated-famaliving .wrap-shops .wrap-shops-right .wrap-shops-list .shop-item .shop-item-content {
    display: block;
    min-height: 11rem;
  }
  .stores-aggrupated-famaliving .wrap-tours {
    height: auto;
    margin-bottom: 7.7rem;
    margin-top: 7rem;
  }
  .stores-aggrupated-famaliving .wrap-tours .title {
    font-family: "Montserrat-SemiBold";
    font-size: 2.5rem;
  }
  .stores-aggrupated-famaliving .wrap-tours .subtitle {
    width: 100%;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list {
    margin-top: 2.5rem;
    position: relative;
    width: 100%;
    padding: 0;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .aux-slider {
    width: 100%;
    transform: unset;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-list {
    width: calc(100% + 4rem);
    left: -2rem;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-slide {
    max-width: unset;
    width: 100%;
    margin-left: 2rem;
    margin-right: 0;
    max-width: unset;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-prev {
    left: -5px;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .slick-next {
    right: -5px;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item {
    width: 70vw;
    margin-right: 0;
    max-width: unset;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .image-container img {
    height: 100%;
    max-height: unset;
    width: 100%;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content {
    padding: 2rem;
    min-height: 20rem;
  }
  .stores-aggrupated-famaliving .wrap-tours .wrap-tours-list .tour-item .tour-content .title {
    font-size: 2.3rem;
    flex-grow: 1;
  }
}

.layout-default-famaliving:not(.detailStore-famaliving) .article-instagram,
.layout-default-famaliving:not(.detailStore-famaliving) .article-products {
  padding: 0 5rem;
  margin-bottom: 13.5rem;
}
.layout-default-famaliving:not(.detailStore-famaliving) #contact_famaliving2023.container-content.shops.contact {
  margin-bottom: 6.5rem;
}
.layout-default-famaliving:not(.detailStore-famaliving) #contact_famaliving2023.container-content.shops.contact .limited-wrap {
  padding-top: 0;
}

@media (max-width: 991px) {
  .layout-default-famaliving:not(.detailStore-famaliving) #contact_famaliving2023.container-content.shops.contact {
    margin-bottom: 3.5rem;
  }
  .layout-default-famaliving:not(.detailStore-famaliving) #contact_famaliving2023.container-content.shops.contact .limited-wrap {
    padding-top: 0;
  }
  .layout-default-famaliving:not(.detailStore-famaliving) .container-content.stores-aggrupated-famaliving {
    padding: 0;
  }
  .layout-default-famaliving:not(.detailStore-famaliving) .article-instagram,
  .layout-default-famaliving:not(.detailStore-famaliving) .article-products {
    padding: 0 2rem;
    margin-bottom: 7.7rem;
  }
  .layout-default-famaliving:not(.detailStore-famaliving) .article-instagram .wrap-content-info.izquierda .part-left .part-img {
    left: unset;
    right: -2rem;
  }
}
.generic-module .subtitle {
  font-family: "Montserrat-Medium";
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #c70087;
  line-height: 2.5rem;
  margin-top: 3rem;
}
.generic-module .title {
  font-family: "Montserrat-SemiBold";
  font-size: 3.5rem;
  margin-bottom: 4.7rem;
  line-height: normal;
  letter-spacing: 0;
  position: relative;
}
.generic-module .title.center {
  text-align: center;
}
@media (max-width: 991px) {
  .generic-module .title {
    font-family: "Montserrat-SemiBold";
    font-size: 2.5rem;
    padding-right: 0;
  }
}
.generic-module .description {
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
  margin-top: 2.5rem;
  line-height: 2.3rem;
}
.generic-module .description b {
  font-family: Montserrat-SemiBold, sans-serif;
}
.generic-module .small-line:not(.sinBorder)::before {
  bottom: -1.5rem;
}
.generic-module .generic-module-inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  flex-direction: column;
}
.generic-module .generic-module-inner.small {
  max-width: 1060px;
}
.generic-module .generic-module-inner.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.generic-module .generic-module-inner .brands {
  justify-content: space-between;
}
@media (max-width: 991px) {
  .generic-module .generic-module-inner.xs-no-padding {
    padding: 0;
  }
  .generic-module .generic-module-inner.xs-no-padding-top {
    padding-top: 0;
  }
  .generic-module .generic-module-inner .common-column {
    flex-direction: column;
  }
  .generic-module .generic-module-inner .common-column .title {
    font-size: 2.5rem;
  }
  .generic-module .generic-module-inner .common-column .image-left.with-foot {
    padding-right: 0;
  }
  .generic-module .generic-module-inner .common-column .image-left img {
    width: 80%;
  }
  .generic-module .generic-module-inner .common-column .generic-quote {
    font-family: "Montserrat-Medium";
    font-size: 1.9rem;
    text-align: center;
    line-height: 2.9rem;
    margin-top: 6rem;
  }
  .generic-module .generic-module-inner .common-column .generic-quote .generic-quote-wrapper {
    width: 80%;
  }
  .generic-module .generic-module-inner .common-column .generic-quote .generic-quote-wrapper:before {
    width: 10rem;
    height: 7rem;
    top: -2.5rem;
    left: -4rem;
  }
  .generic-module .generic-module-inner .brands {
    flex-direction: column;
  }
}
.generic-module .image-right, .generic-module .image-left {
  display: flex;
  justify-content: flex-end;
}
.generic-module .image-right.with-foot, .generic-module .image-left.with-foot {
  flex-direction: column;
  padding-right: 5%;
}
.generic-module .image-right.with-foot img, .generic-module .image-left.with-foot img {
  width: 50%;
  display: flex;
  align-self: center;
}
.generic-module .image-right.with-foot .foot-image, .generic-module .image-left.with-foot .foot-image {
  max-width: 62rem;
  margin-top: 4rem;
  font-family: "Montserrat-Regular";
  font-size: 1.5rem;
}
.generic-module .image-right img, .generic-module .image-left img {
  width: 80%;
}
.generic-module .image-left {
  justify-content: initial;
}
.generic-module .generic-quote {
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 3.9rem;
  display: flex;
  justify-content: center;
  margin-top: 9rem;
  color: #c70087;
  font-family: "Montserrat-Medium";
  font-size: 2.7rem;
}
.generic-module .generic-quote .generic-quote-wrapper {
  max-width: 53rem;
  position: relative;
}
.generic-module .generic-quote .generic-quote-wrapper:before {
  content: "";
  position: absolute;
  background-image: url("/images/frase.svg");
  width: 13rem;
  height: 9.5rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: -4.5rem;
  left: -5rem;
}
.generic-module .wrap-btn {
  margin-top: 3rem;
}
.generic-module .wrap-btn.blue .wrap-btnDefault {
  border: 1px solid #1197a5;
}
.generic-module .wrap-btn.blue .wrap-btnDefault .btnDefault {
  background-color: #1197a5;
}
.generic-module .wrap-btn.all-width .wrap-btnDefault {
  width: 100%;
}
.generic-module .wrap-btn .wrap-btnDefault {
  align-self: flex-start;
}
.generic-module .common-image-and-logo .column-content {
  position: relative;
}
.generic-module .common-image-and-logo .column-content.with-bg {
  padding-right: 8rem;
}
.generic-module .common-image-and-logo .background-column {
  position: absolute;
  width: 140%;
  height: calc(100% + 10rem);
  max-height: 56rem;
  top: -8rem;
  left: -40%;
  z-index: -1;
}
.generic-module .common-image-and-logo .icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 12.5rem;
  height: 12.5rem;
}
@media (max-width: 991px) {
  .generic-module .common-image-and-logo {
    flex-direction: column;
  }
  .generic-module .common-image-and-logo.with-bg {
    flex-direction: column-reverse;
  }
  .generic-module .common-image-and-logo.with-bg img {
    width: 85%;
    max-height: 31rem;
    object-fit: cover;
    margin-top: 3rem;
  }
  .generic-module .common-image-and-logo .column-content.with-bg {
    padding-left: 6rem;
    padding-right: 3rem;
  }
  .generic-module .common-image-and-logo .column-content.with-bg .title {
    padding-top: 2rem;
  }
  .generic-module .common-image-and-logo .subtitle {
    max-width: 27rem;
  }
  .generic-module .common-image-and-logo .icon {
    width: 9rem;
    height: 9rem;
  }
  .generic-module .common-image-and-logo .image-right {
    margin-top: 5rem;
  }
  .generic-module .common-image-and-logo .image-right img {
    width: 100%;
  }
  .generic-module .common-image-and-logo .background-column {
    position: absolute;
    width: 95%;
    height: calc(100% + 15rem);
    max-height: unset;
    top: -3rem;
    left: 5%;
    z-index: -1;
  }
}
.generic-module .slider-absolute {
  position: relative;
  height: 28rem;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.generic-module .slider-absolute .aux-slider {
  position: absolute;
  width: calc(100vw - 5rem);
  left: calc(50% + 5rem);
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-list {
  padding-right: 0 !important;
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-slide {
  width: 30vw;
  max-width: 54rem;
  margin-right: 3rem;
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-track {
  left: 0;
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-disabled {
  opacity: 1;
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-slide {
  opacity: 1;
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-next, .generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-prev {
  display: none !important;
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .item {
  margin-right: 0;
}
.generic-module .slider-absolute .aux-slider .wrap-slider .slider .item img {
  width: 100%;
  max-width: unset;
}
@media (max-width: 991px) {
  .generic-module .slider-absolute {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .generic-module .slider-absolute .aux-slider {
    left: 0;
    width: calc(100% + 1.5rem);
    top: 0;
    transform: unset;
    position: relative;
  }
  .generic-module .slider-absolute .aux-slider .wrap-slider .slider .slick-slide {
    width: 75vw;
    margin-right: 1.5rem;
    max-width: unset;
  }
  .generic-module .slider-absolute .aux-slider .wrap-slider .slider .item {
    margin-right: 0;
  }
  .generic-module .slider-absolute .aux-slider .wrap-slider .slider .item img {
    width: 75vw;
  }
}
.generic-module .aux-slider .wrap-slider {
  width: 100%;
}
.generic-module .aux-slider .wrap-slider .aux-box {
  padding-bottom: 3.7rem;
}
.generic-module .aux-slider .wrap-slider .slider .slick-list {
  padding: 0 !important;
  padding-right: 10rem !important;
}
.generic-module .aux-slider .wrap-slider .slider .slick-slide {
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
}
.generic-module .aux-slider .wrap-slider .slider .slick-slide.slick-active {
  opacity: 1;
}
.generic-module .aux-slider .wrap-slider .slider .slick-disabled {
  opacity: 0.4;
}
.generic-module .aux-slider .wrap-slider .slider .slick-next, .generic-module .aux-slider .wrap-slider .slider .slick-prev {
  background-image: url("/images/slider-arrow-left.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
  z-index: 2;
  transition: all 0.4s ease-in-out;
}
.generic-module .aux-slider .wrap-slider .slider .slick-next:hover, .generic-module .aux-slider .wrap-slider .slider .slick-prev:hover {
  transform: translate(-1rem, -50%);
}
.generic-module .aux-slider .wrap-slider .slider .slick-next:before, .generic-module .aux-slider .wrap-slider .slider .slick-prev:before {
  display: none;
}
.generic-module .aux-slider .wrap-slider .slider .slick-next {
  background-image: url("/images/slider-arrow-right.svg");
}
.generic-module .aux-slider .wrap-slider .slider .slick-next:hover {
  transform: translate(1rem, -50%);
}
.generic-module .aux-slider .wrap-slider .item {
  position: relative;
  margin-right: 2.5rem;
}
.generic-module .aux-slider .wrap-slider .item .img {
  height: 30rem;
  width: 100%;
  object-fit: cover;
}
.generic-module .aux-slider.desk {
  flex-grow: 1;
}
@media (max-width: 991px) {
  .generic-module .aux-slider {
    transform: translateX(0);
    width: calc(100% + 1.5rem);
  }
  .generic-module .aux-slider .wrap-slider .aux-box {
    padding-bottom: 0;
  }
  .generic-module .aux-slider .wrap-slider .slider .slick-next, .generic-module .aux-slider .wrap-slider .slider .slick-prev {
    display: none !important;
  }
  .generic-module .aux-slider .wrap-slider .slider .slick-next {
    display: none !important;
  }
  .generic-module .aux-slider .wrap-slider .item {
    position: relative;
    margin-right: 1.5rem;
    aspect-ratio: 4/3;
  }
  .generic-module .aux-slider .wrap-slider .item .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.generic-module .brands-group {
  width: calc(100% - 2rem);
  margin-top: 5rem;
  border: 1px solid #c70087;
  position: relative;
  margin-bottom: 5rem;
}
.generic-module .brands-group:before {
  content: "";
  position: absolute;
  width: 100%;
  background-color: #c70087;
  height: 100%;
  left: 2rem;
  top: 2rem;
}
.generic-module .brands-group .brands-group-content {
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff4fb;
}
.generic-module .brands-group .brands-group-content .brands-group-content-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.generic-module .brands-group .brands-group-content .brand {
  max-width: 21rem;
  max-height: 16rem;
  margin-bottom: 5rem;
  object-fit: contain;
  padding: 2rem;
  width: 25%;
}
@media (max-width: 991px) {
  .generic-module .brands-group {
    margin-top: 0;
  }
  .generic-module .brands-group .brands-group-content {
    padding-top: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .generic-module .brands-group .brands-group-content .brands-group-content-row {
    flex-wrap: wrap;
  }
  .generic-module .brands-group .brands-group-content .brand {
    width: 50%;
    margin-bottom: 1rem;
  }
}
.generic-module .box-prices {
  display: flex;
  align-items: center;
  height: auto;
}
.generic-module .box-prices .price-product {
  font-size: 3.5rem;
  color: #c70087;
  white-space: nowrap;
  font-weight: bolder;
}
.generic-module .box-prices .prev-price-product {
  color: #575252;
  font-size: 2rem;
  white-space: nowrap;
  font-weight: bold;
  text-decoration: line-through;
}

.common-two-columns {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 16.4rem;
  flex-direction: column;
}
.common-two-columns.no-padding {
  padding-top: 0;
}
.common-two-columns.margin-bottom {
  margin-bottom: 10rem;
}
.common-two-columns .two-columns {
  display: flex;
  width: 100%;
}
.common-two-columns .two-columns.second {
  margin-top: 5rem;
  margin-bottom: 10rem;
}
.common-two-columns .two-columns .image-right, .common-two-columns .two-columns .image-left {
  display: flex;
  justify-content: flex-end;
}
.common-two-columns .two-columns .image-right img, .common-two-columns .two-columns .image-left img {
  width: 80%;
}
.common-two-columns .two-columns .image-left {
  justify-content: initial;
}
@media (max-width: 991px) {
  .common-two-columns {
    padding-top: 8.5rem;
  }
  .common-two-columns.margin-bottom {
    margin-bottom: 5rem;
  }
  .common-two-columns .two-columns {
    display: flex;
    flex-direction: column;
  }
  .common-two-columns .two-columns.xs-reverse {
    flex-direction: column-reverse;
  }
  .common-two-columns .two-columns.second {
    margin-top: 0;
    margin-bottom: 5rem;
  }
  .common-two-columns .two-columns.second img {
    width: 100%;
    margin-top: 5rem;
  }
  .common-two-columns .two-columns .image-right {
    margin-top: 2rem;
  }
  .common-two-columns .two-columns .image-right img {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .main .common-two-columns {
    padding-top: 9rem;
  }
}
.stores-with-tour {
  margin-bottom: 5rem;
}
.stores-with-tour .common-two-columns {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.stores-with-tour .common-two-columns .title {
  margin-bottom: 4.7rem;
}
.stores-with-tour .stores-tour {
  margin-top: 5rem;
}
.stores-with-tour .stores-tour .input-box {
  margin-bottom: 3rem;
}
.stores-with-tour .stores-tour .input-box:last-of-type {
  margin-bottom: 2rem;
}
.stores-with-tour .stores-tour .input-box select + .label,
.stores-with-tour .stores-tour .select2 + .label {
  position: absolute;
  top: 0;
  font-size: 1.3rem;
  left: 1.7rem;
  color: #575252;
  background-color: #fff4fb;
  padding: 0 1.1rem;
  pointer-events: none;
  transform: translateY(-50%);
  transform-origin: left;
  white-space: nowrap;
  margin: 0;
  transition: top 0.2s linear, left 0.2s linear, transform 0.2s linear;
  font-family: "Montserrat-Medium";
  font-weight: normal;
}
.stores-with-tour .stores-tour .form-group {
  position: relative;
}
.stores-with-tour .stores-tour .tour .tour-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.stores-with-tour .stores-tour .tour .tour-wrapper img {
  width: 100%;
  height: 35rem;
  object-fit: cover;
}
.stores-with-tour .stores-tour .tour .tour-wrapper .tour-content {
  background-color: white;
  padding: 3rem 3.5rem;
  margin-bottom: 4rem;
}
.stores-with-tour .stores-tour .tour .tour-wrapper .tour-content .title {
  font-family: "Montserrat-SemiBold";
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 0;
}
.stores-with-tour .stores-tour .tour .tour-wrapper .tour-content .name {
  font-family: "Montserrat-SemiBold";
  font-size: 2.5rem;
  color: #c70087;
  line-height: 3rem;
}
.stores-with-tour .stores-tour .tour .tour-wrapper .tour-content .description {
  font-family: "Montserrat-Regular";
  font-size: 1.4rem;
  max-width: 50%;
  line-height: 2.1rem;
  margin-top: 2rem;
}
.stores-with-tour .stores-tour .tour .tour-wrapper .tour-content .description .bold {
  font-family: "Montserrat-SemiBold";
  font-size: 1.4rem;
}
@media (max-width: 991px) {
  .stores-with-tour .image-left img {
    object-fit: cover;
    width: 100% !important;
    margin-top: 3rem;
  }
  .stores-with-tour .stores-tour .tour .tour-wrapper img {
    height: 37rem;
  }
  .stores-with-tour .stores-tour .tour .tour-wrapper .tour-content .description {
    max-width: 100%;
  }
}